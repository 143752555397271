import round from 'lodash/round';

import { ICoupon, IPackageQuantity } from '../../interfaces/checkout';

export function calculatePrice(qty: number, unitPrice: number) {
  return qty * unitPrice;
}

export function calculateTotal(items: IPackageQuantity[]) {
  return items
    .map(({ quantity, price }) => calculatePrice(+quantity, price))
    .reduce((sum, i) => sum + i, 0);
}

export function calculateDiscountedPrice(
  qty: number,
  unitPrice: number,
  discountItems: { [key: string]: number }
) {
  const total = calculatePrice(qty, unitPrice);
  const discountItemEntries = Object.entries(discountItems)
    .map(([key, value]) => [+key, value])
    .reverse();
  if (discountItemEntries.length === 0) return total;

  let discountedTotal = total;

  for (const [quantity, percent] of discountItemEntries) {
    if (qty >= quantity) {
      discountedTotal = round(total - (total * percent) / 100);
      break;
    }
  }

  return discountedTotal;
}

export function calculateDiscountedTotal(items: IPackageQuantity[]) {
  return items
    .map(({ quantity, price, discountItems }) =>
      calculateDiscountedPrice(+quantity, price, discountItems)
    )
    .reduce((sum, i) => sum + i, 0);
}

export function getDiscount(coupon: ICoupon | null, total: number) {
  if (!coupon) return 0;
  const { type, discount } = coupon;
  if (type === 'FIXED') return discount;
  return round((total * discount) / 100);
}

export function getDiscountedTotal(coupon: ICoupon | null, total: number) {
  const discount = getDiscount(coupon, total);
  return total - discount;
}
