import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ShortTextIcon from '@material-ui/icons/ShortText';
import SubjectIcon from '@material-ui/icons/Subject';
import DragHandle from '@material-ui/icons/DragHandle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggableLocation,
} from 'react-beautiful-dnd';

import Button from '../shared/Inputs/Button';
import TextField from '../shared/Inputs/TextField';
import Checkbox from '../shared/Inputs/Checkbox';
import DragAndDropIcon from '../../assets/img/drag-and-drop.svg';
import {
  ServiceForm,
  IntakeFormListItem,
  EditIntakeFormDialogData,
} from '../../interfaces/services';
import { getRandomId } from '../../utils/helpers';
import useStyles from './styles';

interface IntakeItem {
  _id: string;
  title: string;
  icon: JSX.Element;
}

interface IntakeFormProps {
  service: ServiceForm;
  intakeFormList: IntakeFormListItem[];
  setIntakeFormList: React.Dispatch<React.SetStateAction<IntakeFormListItem[]>>;
  dialogData: EditIntakeFormDialogData;
  setDialogData: React.Dispatch<React.SetStateAction<EditIntakeFormDialogData>>;
  handleSaveIntakeForm(): void;
}

const ITEMS: IntakeItem[] = [
  {
    _id: 'TEXT',
    title: 'Single line of text',
    icon: <ShortTextIcon />,
  },
  {
    _id: 'TEXT_AREA',
    title: 'Multiple lines of text',
    icon: <SubjectIcon />,
  },
  {
    _id: 'FILE_UPLOAD',
    title: 'File upload',
    icon: <CloudUploadIcon />,
  },
];

function reorder(list: IntakeFormListItem[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function copy(
  source: IntakeItem[],
  destination: IntakeFormListItem[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation | undefined
) {
  const destClone = Array.from(destination);
  const item = source[droppableSource.index];
  const data = generateFieldData(item._id);

  destClone.splice(droppableDestination?.index || 0, 0, data);
  return destClone;
}

function generateFieldData(type: string): IntakeFormListItem {
  const _id = getRandomId();
  const data = {
    _id,
    type,
    name: '',
    required: false,
    multiple: false,
    placeholder: '',
    helperText: '',
  };
  switch (type) {
    case 'FILE_UPLOAD': {
      return { ...data, name: 'File Upload' };
    }
    case 'TEXT': {
      return { ...data, name: 'Text Field' };
    }
    case 'TEXT_AREA': {
      return { ...data, name: 'Multiline Text Field' };
    }
    default: {
      return data;
    }
  }
}

function IntakeForm(props: IntakeFormProps) {
  const {
    service,
    intakeFormList,
    setIntakeFormList,
    dialogData,
    setDialogData,
    handleSaveIntakeForm,
  } = props;
  const classes = useStyles();

  function handleDragEnd(result: DropResult) {
    const { source, destination } = result;
    if (!destination) return;
    switch (source.droppableId) {
      case 'INTAKE_FORM':
        setIntakeFormList(reorder(intakeFormList, source.index, destination.index));
        break;
      case 'INTAKE_FORM_ITEMS':
        setIntakeFormList(copy(ITEMS, intakeFormList, source, destination));
        break;
      default:
        break;
    }
  }

  function handleDeleteIntakeFormListItem(item: IntakeFormListItem): void {
    setIntakeFormList(intakeFormList.filter((data) => data._id !== item._id));
  }

  function handleOpenCloseDialog(open: boolean, data: IntakeFormListItem) {
    setDialogData({ open, data });
  }

  function handleDialogDataChange(name: string, value: string | boolean) {
    setDialogData({ ...dialogData, data: { ...dialogData.data, [name]: value } });
  }

  function handleSaveDialogData(data: IntakeFormListItem) {
    const itemIndex = intakeFormList.findIndex((item) => item._id === data._id);
    if (itemIndex === -1) return;
    const cloned = Array.from(intakeFormList);
    cloned[itemIndex] = data;
    setIntakeFormList(cloned);
    handleOpenCloseDialog(false, {} as IntakeFormListItem);
  }

  function getFormField(item: IntakeFormListItem) {
    switch (item.type) {
      case 'FILE_UPLOAD': {
        const id = `contained-button-file-${item._id}`;
        return (
          <div>
            <input accept="image/*" className={classes.uploadInput} id={id} multiple type="file" />
            <label htmlFor={id}>
              <Button variant="contained" color="primary" component="span">
                {item.name}
              </Button>
            </label>
          </div>
        );
      }
      default: {
        const isMultiline = item.type === 'TEXT_AREA';
        return (
          <TextField
            label={item.name}
            type={item.type === 'NUMBER' ? 'number' : 'text'}
            multiline={isMultiline}
            variant="outlined"
            fullWidth
            {...(item.placeholder && { placeholder: item.placeholder })}
            {...(item.helperText && { helperText: <span>{item.helperText}</span> })}
            {...(isMultiline && { rows: 4 })}
          />
        );
      }
    }
  }

  function getIntakeFormListItemComponent(provided: DraggableProvided, item: IntakeFormListItem) {
    return (
      <ListItem innerRef={provided.innerRef} {...provided.draggableProps}>
        <ListItemIcon {...provided.dragHandleProps}>
          <DragHandle />
        </ListItemIcon>
        <ListItemText primary={getFormField(item)} />
        <div className={classes.intakeFormFieldActions}>
          <div onClick={() => handleOpenCloseDialog(true, item)}>
            <EditIcon fontSize="inherit" />
          </div>
          <div onClick={() => handleDeleteIntakeFormListItem(item)}>
            <DeleteIcon fontSize="inherit" />
          </div>
        </div>
      </ListItem>
    );
  }

  return (
    <div>
      <div className={classes.intakeFormTitleContainer}>
        <div className={classes.intakeFormTitle}>{`Intake form for ${service.name || ''}`}</div>
        <div className={classes.intakeFormInfoText}>
          Clients get access to this form after buying your service. Their order will remain Pending
          until the form is filled out.
        </div>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4} md={4} className={classes.intakeFormRightBorder}>
            <Droppable droppableId="INTAKE_FORM_ITEMS" isDropDisabled>
              {(provided: DroppableProvided) => (
                <List innerRef={provided.innerRef}>
                  {ITEMS.map((item, index) => (
                    <Draggable key={item._id} draggableId={item._id} index={index}>
                      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                        <>
                          <ListItem
                            classes={{ root: classes.intakeFormItem }}
                            innerRef={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ListItemText
                              classes={{ primary: classes.intakeFormItemText }}
                              primary={item.title}
                            />
                            <ListItemIcon classes={{ root: classes.intakeFormItemIcon }}>
                              {item.icon}
                            </ListItemIcon>
                          </ListItem>
                          {snapshot.isDragging && (
                            <ListItem classes={{ root: classes.intakeFormItem }}>
                              <ListItemText
                                classes={{ primary: classes.intakeFormItemText }}
                                primary={item.title}
                              />
                              <ListItemIcon classes={{ root: classes.intakeFormItemIcon }}>
                                {item.icon}
                              </ListItemIcon>
                            </ListItem>
                          )}
                        </>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <Droppable droppableId="INTAKE_FORM">
              {(provided: DroppableProvided) => (
                <List innerRef={provided.innerRef} className={classes.intakeFormList}>
                  {intakeFormList.length ? (
                    intakeFormList.map((item, index) => (
                      <Draggable key={item._id} draggableId={item._id} index={index}>
                        {(provided: DraggableProvided) =>
                          getIntakeFormListItemComponent(provided, item)
                        }
                      </Draggable>
                    ))
                  ) : (
                    <li className={classes.dragAndDropEmptyList}>
                      <div className={classes.dragAndDropEmptyContainer}>
                        <div>
                          <img src={DragAndDropIcon} alt="" />
                        </div>
                        <div>Drag and drop fields from the right column onto your form here</div>
                      </div>
                    </li>
                  )}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </Grid>
        </Grid>
      </DragDropContext>
      <div className={classes.intakeFormSaveButton}>
        <Button color="primary" variant="contained" onClick={handleSaveIntakeForm}>
          Save
        </Button>
      </div>
      <Dialog
        open={dialogData.open}
        onClose={() => handleOpenCloseDialog(false, {} as IntakeFormListItem)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <TextField
            label="Field name"
            value={dialogData.data.name || ''}
            onChange={(event) => handleDialogDataChange('name', event.target.value)}
            type="text"
            variant="outlined"
            fullWidth
            helperText={<span>The label content</span>}
            className={classes.textField}
          />
          <TextField
            label="Placeholder text"
            value={dialogData.data.placeholder || ''}
            onChange={(event) => handleDialogDataChange('placeholder', event.target.value)}
            type="text"
            variant="outlined"
            fullWidth
            helperText={
              <span>The short hint displayed in the input before the user enters a value</span>
            }
            className={classes.textField}
          />
          <TextField
            label="Helper text"
            value={dialogData.data.helperText || ''}
            onChange={(event) => handleDialogDataChange('helperText', event.target.value)}
            type="text"
            variant="outlined"
            fullWidth
            helperText={<span>The helper text is shown below the input field</span>}
            className={classes.textField}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={Boolean(dialogData.data.required)}
                onChange={(event) => handleDialogDataChange('required', event.target.checked)}
              />
            }
            classes={{ label: classes.formControlLabel }}
            label="This is a required field"
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleOpenCloseDialog(false, {} as IntakeFormListItem)}
          >
            Close
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSaveDialogData(dialogData.data)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default IntakeForm;
