import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

import Button from '../shared/Inputs/LoadingButton';
import TextField from '../shared/Inputs/TextField';

import { ICoupon, IPackageQuantity } from '../../interfaces/checkout';
import { CompleteService, PaymentTypes } from '../../interfaces/services';
import {
  calculateDiscountedPrice,
  calculateDiscountedTotal,
  calculatePrice,
  calculateTotal,
  getDiscount,
  getDiscountedTotal,
} from './util';
import { MUI_THEME } from '../../utils/utils';
import { capitalize } from '../../utils/helpers';
import useStyles from './styles';

const TableCell = withStyles(() =>
  createStyles({
    body: {
      color: '#000000',
      fontWeight: 500,
      borderBottom: 'none',
    },
    footer: {
      color: '#3A4A5E',
      fontSize: '0.875rem',
      fontWeight: 700,
      borderTop: '1px solid',
      borderColor: '#D3DCE7',
    },
  })
)(MuiTableCell);

interface ISummaryProps {
  service: CompleteService;
  packageQuantities: IPackageQuantity[];
  handlePlaceOrder(): void;
  paymentType: PaymentTypes;
  handlePaymentTypeChange(type: string, name: string, value: string): void;
  processing: boolean;
  orderCreated: boolean;
  coupon: ICoupon | null;
  couponCode: string;
  setCouponCode: React.Dispatch<React.SetStateAction<string>>;
  handleApplyCoupon(): void;
}

function Summary(props: ISummaryProps) {
  const {
    service,
    paymentType,
    handlePaymentTypeChange,
    packageQuantities,
    handlePlaceOrder,
    processing,
    orderCreated,
    coupon,
    couponCode,
    setCouponCode,
    handleApplyCoupon,
  } = props;
  const classes = useStyles();
  const packagesWithQuantity = packageQuantities.filter((item) => +item.quantity);
  const isCouponApplied = Boolean(coupon);
  const total = getTotal();

  function getPaymentTypes() {
    const paymentTypes = service.paymentTypes;
    if (!paymentTypes) return [];
    paymentTypes.sort((a, b) => b.localeCompare(a));
    return paymentTypes;
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    handlePaymentTypeChange('paymentType', 'paymentType', event.target.value);
  }

  function getSubscriptionFrequency() {
    const duration = service.orderDuration || NaN;
    const frequency = capitalize((service.frequency || '').toLowerCase());
    return `Occurs every ${duration} ${frequency}${duration > 1 ? 's' : ''}`;
  }

  function getTotal() {
    return calculateDiscountedTotal(packagesWithQuantity);
  }

  return (
    <div className={classes.summaryContainer}>
      {!orderCreated && <div className={classes.summaryDummy}></div>}
      <div className={classes.detailsFormContainer}>
        <p className={classes.sectionTitle}>SUMMARY</p>
        {!packagesWithQuantity.length && (
          <p className={classes.emptySummaryPlaceholder}>No package selected</p>
        )}
        <TableContainer>
          <Table>
            <TableBody>
              {packagesWithQuantity.map((row) => {
                const price = calculatePrice(+row.quantity, row.price);
                const discountedPrice = calculateDiscountedPrice(
                  +row.quantity,
                  row.price,
                  row.discountItems
                );
                return (
                  <TableRow key={row.id}>
                    <TableCell>{`${row.quantity}x`}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align="right">
                      {price > discountedPrice && (
                        <span className={classes.actualPrice}>${price}</span>
                      )}
                      <span>${discountedPrice}</span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>Total</TableCell>
                <TableCell align="right">${total}</TableCell>
              </TableRow>
              {isCouponApplied && (
                <>
                  <TableRow>
                    <TableCell colSpan={2}>Promotion Applied</TableCell>
                    <TableCell align="right">-${getDiscount(coupon, total)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Grand Total</TableCell>
                    <TableCell align="right">${getDiscountedTotal(coupon, total)}</TableCell>
                  </TableRow>
                </>
              )}
            </TableFooter>
          </Table>
        </TableContainer>
        <ThemeProvider theme={MUI_THEME}>
          <div className={classes.paymentTypesContainer}>
            <RadioGroup aria-label="payment-type" row value={paymentType} onChange={handleChange}>
              {getPaymentTypes().map((paymentMethod) => (
                <FormControlLabel
                  key={paymentMethod}
                  value={paymentMethod}
                  control={<Radio color="primary" />}
                  label={paymentMethod === 'DIRECT' ? 'One-Time' : 'Recurring'}
                  disabled={orderCreated}
                />
              ))}
            </RadioGroup>
            {paymentType === 'SUBSCRIPTION' && <p>{getSubscriptionFrequency()}</p>}
          </div>
        </ThemeProvider>
        <TextField
          label="Coupon"
          variant="outlined"
          fullWidth
          size="small"
          value={couponCode}
          onChange={(event) => setCouponCode(event.target.value.toUpperCase())}
          className={classes.couponField}
          disabled={orderCreated}
          InputProps={{
            endAdornment: (
              <Button
                isLoading={false}
                variant="text"
                color="primary"
                onClick={handleApplyCoupon}
                disabled={orderCreated || couponCode.length === 0 || isCouponApplied}
              >
                Apply
              </Button>
            ),
            classes: { adornedEnd: classes.adornedEnd },
          }}
          {...(isCouponApplied && {
            helperText: (
              <span className={classes.couponHelpText}>
                <span>
                  <CheckCircleOutlineIcon htmlColor="inherit" fontSize="small" />
                </span>
                <span>Coupon applied successfully</span>
              </span>
            ),
          })}
        />
        {!orderCreated && (
          <div className={classes.placeOrderContainer}>
            <Button
              onClick={handlePlaceOrder}
              variant="contained"
              color="primary"
              fullWidth
              disabled={calculateTotal(packagesWithQuantity) === 0 || processing}
              isLoading={processing}
              className={classes.completePurchaseButton}
            >
              {processing ? 'Processing your payment...' : 'Complete Purchase'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Summary;
