import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Popover from '@material-ui/core/Popover';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import TextField from '../shared/Inputs/TextField';
import Button from '../shared/Inputs/Button';
import LoadingButton from '../shared/Inputs/LoadingButton';
import useStyles from './styles';

import { IFilterState } from '../../interfaces/sharedAffiliates';

const dateFields = [
  { label: 'Sign-up Date', value: 'signedUpDate' },
  { label: 'Latest Revenue Date', value: 'latestRevenueDate' },
];

interface IProps {
  filters: IFilterState;
  setFilters: React.Dispatch<React.SetStateAction<IFilterState>>;
  onApplyFilters(): void;
  onClearFilters(): void;
  onExportOrders(): void;
  isExporting: boolean;
}

interface IDateFilterProps {
  filters: IFilterState;
  setFilters: React.Dispatch<React.SetStateAction<IFilterState>>;
}

function Filters(props: IProps) {
  const { filters, setFilters, onClearFilters, onExportOrders, isExporting, onApplyFilters } =
    props;
  const classes = useStyles();

  function onChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  }

  return (
    <div className={classes.filtersWithActionsContainer}>
      <div className={classes.filtersContainer}>
        <TextField
          variant="outlined"
          size="small"
          label="Name"
          name="name"
          onChange={onChange}
          value={filters.name}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Email"
          name="email"
          onChange={onChange}
          value={filters.email}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Code"
          name="code"
          onChange={onChange}
          value={filters.code}
        />

        <DateFilter filters={filters} setFilters={setFilters} />
      </div>
      <div className={classes.filterActionsContainer}>
        <div>
          <Button variant="contained" color="primary" size="small" onClick={onApplyFilters}>
            Apply Filters
          </Button>
        </div>
        <div>
          <Button variant="outlined" size="small" onClick={onClearFilters}>
            Clear Filters
          </Button>
        </div>
        <div>
          <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            onClick={onExportOrders}
            disabled={isExporting}
            isLoading={isExporting}
          >
            Export
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}

function DateFilter(props: IDateFilterProps) {
  const { filters, setFilters } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleChange(key: string, value: string) {
    const date = { ...filters.date, [key]: value };
    setFilters({ ...filters, date });
  }

  function getLabel() {
    const { key, from, to } = filters.date;
    if (key && from && to) {
      const field = dateFields.find((f) => f.value === key);
      if (!field) return 'Any Date';
      return `${field.label} Date`;
    }
    return 'Any Date';
  }

  const open = Boolean(anchorEl);
  const id = open ? 'date-filter-popover' : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        size="large"
        className={classes.dateFilterButton}
        fullWidth
      >
        {getLabel()}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={{ paper: classes.dateFilterPopover }}
      >
        <div className={classes.dateFilterContainer}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="date-field-label">Field</InputLabel>
            <Select
              label="Field"
              labelId="date-field-label"
              value={filters.date.key}
              onChange={(event) => handleChange('key', event.target.value as string)}
              MenuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {dateFields.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={classes.dateFilterDatePickerContainer}>
            <div>
              <TextField
                variant="outlined"
                size="small"
                label="From"
                type="date"
                value={filters.date.from}
                onChange={(event) => handleChange('from', event.target.value)}
                InputProps={{ classes: { root: classes.dateFilterDatePicker } }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div>
              <TextField
                variant="outlined"
                size="small"
                label="To"
                type="date"
                value={filters.date.to}
                onChange={(event) => handleChange('to', event.target.value)}
                InputProps={{ classes: { root: classes.dateFilterDatePicker } }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default Filters;
