import axios, { AxiosError } from 'axios';

import localStorage from './utils/local-storage';
import { updateAccessToken } from './requests/auth';
import { backToAdmin, logout } from './utils/utils';

const API_URL = process.env.REACT_APP_API_URL || '';

const instance = axios.create({ baseURL: API_URL });

let isFetchingNewToken = false;
let requests: { (accessToken: string): void }[] = [];

function onAccessTokenFetched(accessToken: string) {
  requests.forEach((callback) => callback(accessToken));
  requests = [];
}
function addRequest(callback: (accessToken: string) => void) {
  requests.push(callback);
}

instance.interceptors.request.use(
  function (config) {
    const paths = [
      '/user/signIn',
      '/user/signUp',
      '/user/resetPassword',
      '/user/updatePassword',
      '/service/fullDetail',
      '/user/countries',
      '/user/refreshToken',
      '/affiliate/track',
    ];
    if (!paths.find((path) => config.url === path)) {
      const accessToken = localStorage.getAccessToken();
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error: AxiosError) {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: AxiosError) {
    if (
      error?.response?.status === 401 &&
      localStorage.hasAccessToken() &&
      localStorage.hasAdminAccessToken()
    ) {
      backToAdmin();
      return Promise.reject(error);
    }

    const refreshToken = localStorage.getRefreshToken();
    if (error?.response?.status === 401 && refreshToken) {
      if (!isFetchingNewToken) {
        isFetchingNewToken = true;
        updateAccessToken(refreshToken)
          .then((res) => {
            isFetchingNewToken = false;
            localStorage.setAccessToken(res.data.jwtToken);
            localStorage.setRefreshToken(res.data.refreshToken);
            onAccessTokenFetched(res.data.jwtToken);
          })
          .catch((error: AxiosError) => {
            isFetchingNewToken = false;
            if (error?.response?.status === 403) {
              const pathname = window.location.pathname;
              logout(pathname && pathname !== '/' ? pathname : undefined);
            }
            return Promise.reject(error);
          });
      }
      return new Promise((resolve) => {
        addRequest((accessToken: string) => {
          if (error.response) {
            error.response.config.headers['Authorization'] = `Bearer ${accessToken}`;
            resolve(instance(error.response.config));
          }
        });
      });
    }
    return Promise.reject(error);
  }
);

export default instance;
