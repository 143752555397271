import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    '& > div:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  buttonTitleText: {
    color: '#545454',
    fontSize: '0.625rem',
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'center',
  },
  title: { color: '#172B70', fontSize: 18, fontWeight: 700 },
  authTypeSwitchText: { color: '#76849F', fontSize: '0.75rem' },
  detailsFormContainer: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    backgroundColor: '#F5F9FD',
    border: '1px solid #F0F0F0',
    borderRadius: 4,
  },
  sectionTitle: { color: '#3A4A5E', fontWeight: 700, marginTop: 0 },
  textField: { padding: theme.spacing(0, 1, 2, 0) },
  orderPerks: {
    color: '#3D4B5B',
    fontSize: '0.75rem',
    backgroundColor: '#FFF5C7',
    borderRadius: 10,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: { marginLeft: 0, marginTop: theme.spacing(2) },
    '& > ul': {
      paddingLeft: theme.spacing(3),
      '& > li': {
        lineHeight: '20px',
      },
    },
  },
  stripeCardElement: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    padding: theme.spacing(1, 2),
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.84)',
    },
  },
  stripeCardElementInvalid: {
    borderColor: '#f44336',
    '&:hover': {
      borderColor: '#f44336',
    },
  },
  acceptedCardProcessors: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    '& > div:not(:last-child)': { marginRight: theme.spacing(0.5) },
  },
  morePaymentProcessorText: {
    paddingTop: theme.spacing(1),
    color: '#6b778c',
    fontSize: '0.625rem',
  },
  summaryContainer: { position: 'sticky', top: 20 },
  summaryDummy: { height: 56, [theme.breakpoints.down('sm')]: { display: 'none' } },
  placeOrderContainer: { margin: theme.spacing(4, 0, 4) },
  error: { color: '#f44336', fontSize: '0.75rem' },
  paymentTypesContainer: { margin: theme.spacing(2, 0) },
  completePurchaseButton: { padding: theme.spacing(1, 2) },
  packageTitle: { verticalAlign: 'middle', marginRight: theme.spacing(0.5) },
  packageDescriptionIcon: { verticalAlign: 'middle' },
  emptySummaryPlaceholder: { margin: theme.spacing(4, 0), fontWeight: 500, color: '#577496' },
  flexGrow: { flexGrow: 1 },
  normalTitle: { fontWeight: 700, color: '#00254d' },
  projectDetailsContainer: { margin: theme.spacing(1, 0), padding: theme.spacing(1, 0) },
  projectDetailsButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      textAlign: 'center',
    },
  },
  cancelButtonContainer: { textAlign: 'right' },
  orText: {
    margin: theme.spacing(1, 0),
    flexGrow: 1,
    textAlign: 'center',
    '& p': { margin: 0 },
    '& p:before, & p:after': {
      backgroundColor: '#8999AB',
      content: '""',
      display: 'inline-block',
      height: 1,
      position: 'relative',
      verticalAlign: 'middle',
      width: '30%',
    },
    '& p:before': { right: '0.5em' },
    '& p:after': { left: '0.5em' },
    '& span': {
      backgroundColor: '#8999AB',
      padding: '2px 5px',
      borderRadius: '50%',
      color: '#ffffff',
    },
  },
  downloadTemplateBtnContainer: { textAlign: 'right' },
  projectTypeTitle: { color: '#3A4A5E', fontWeight: 700 },
  uploadProjectTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uploadInput: { display: 'none' },
  uploadingTitleWithPercentage: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#3A4A5E',
    fontWeight: 500,
  },
  uploadedFileContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div:first-child': { marginRight: theme.spacing(1) },
    '& > div:nth-child(2)': { flexGrow: 1 },
    '& > div:last-child': { marginLeft: theme.spacing(1) },
  },
  projectDetailsAccordion: {
    backgroundColor: '#F5F9FD',
    border: '1px solid #DEDEDE',
    '&.MuiAccordion-root:before': { display: 'none' },
    '&.MuiAccordion-root:not(:last-child)': { borderBottom: 'none' },
    '&.MuiAccordion-root:last-child': { borderBottom: '1px solid #DEDEDE' },
    '&.MuiAccordion-root.Mui-expanded': { borderBottom: '1px solid #DEDEDE' },
  },
  projectDetailsAccordionTitle: { fontSize: '0.875rem', color: '#3D4B5B', fontWeight: 700 },
  projectIntakeItemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    '& > div': { marginBottom: theme.spacing(1) },
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  intakeItemWithDeleteContainer: {
    display: 'flex',
    padding: theme.spacing(1, 0),
    '& > div:first-child': { flexGrow: 1 },
    '& > div:last-child': { marginLeft: theme.spacing(0.5) },
  },
  addMoreButtonContainer: { textAlign: 'right' },
  intakeItemFileName: {
    width: 190,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.875rem',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  backdropText: { marginLeft: theme.spacing(2), fontSize: '1rem' },
  actualPrice: { textDecoration: 'line-through', marginRight: 4 },
  couponField: { margin: theme.spacing(1, 0, 1) },
  adornedEnd: { paddingRight: 0 },
  couponHelpText: {
    color: '#1b5e20',
    '& > span:first-child': { verticalAlign: 'middle', marginRight: 4 },
  },
  checkoutSuccessContent: {
    backgroundColor: '#FBFBFD',
    minHeight: '100vh',
    padding: theme.spacing(3),
  },
}));
