import makeStyles from '@material-ui/core/styles/makeStyles';

import Chase from './Loaders/Chase/Chase';

import Logo from '../../assets/img/logo.svg';

const useStyles = makeStyles(() => ({
  centered: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logoContainer: { marginBottom: 24 },
}));

function StartUpLoader() {
  const classes = useStyles();
  return (
    <div className={classes.centered}>
      <div className={classes.logoContainer}>
        <img src={Logo} alt="StanVentures" />
      </div>
      <Chase />
    </div>
  );
}

export default StartUpLoader;
