import { useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import Services from './Services';
import Strategies from './Strategies';
import Button from '../shared/Inputs/Button';

import { useAuth } from '../../hooks/use-auth';
import EmbedPlaceholder from '../../assets/img/yt-embed-placeholder.png';
import useStyles from './styles';

function Welcome() {
  const { user } = useAuth();
  const classes = useStyles();
  const [showEmbed, setShowEnbed] = useState(false);

  return (
    <>
      <div className={classes.onboardingContainer}>
        <Container maxWidth="lg" disableGutters>
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <p>
                {`${String.fromCodePoint(0x1f44b)} Welcome, ${user?.firstName} ${user?.lastName}`}
              </p>
              <div>
                <h2>Your dashboard is Ready!</h2>
                <p>
                  We have successfully set up your dashboard. Click "Go to Dashboard" and see all
                  updates regarding your ongoing and past orders.
                </p>
              </div>
              <div className={classes.onboardingButtonContainer}>
                <div>
                  <Button
                    component={Link}
                    fullWidth
                    color="primary"
                    variant="contained"
                    to="/services"
                  >
                    Place new order
                  </Button>
                </div>
                <div>
                  <Button component={Link} fullWidth variant="outlined" to="/">
                    Go to Dashboard
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.videoContainer}>
                {showEmbed ? (
                  <iframe
                    src="https://www.youtube.com/embed/-6L9INAD8bc?modestbranding=1&autoplay=1"
                    title="StanVentures Marketing Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <>
                    <IconButton
                      className={classes.playIconButton}
                      onClick={() => setShowEnbed(!showEmbed)}
                    >
                      <PlayArrowIcon />
                    </IconButton>
                    <img src={EmbedPlaceholder} alt="" />
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Services applyMaxWidth />
      <Strategies applyMaxWidth />
    </>
  );
}

export default Welcome;
