import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Link, Redirect, useParams } from 'react-router-dom';
import { GridRowParams, GridRowsProp } from '@material-ui/data-grid';

import AddEditAnnouncement from './AddEditAnnouncement';
import AnnouncementsTable from './AnnouncementsTable';
import Filters from './Filters';
import Button from '../shared/Inputs/Button';

import { useAuth } from '../../hooks/use-auth';
import { useRouter } from '../../hooks/use-router';
import { useSnackbar } from '../../hooks/use-snackbar';
import { isAdmin } from '../../utils/utils';
import { getAnnouncements } from '../../requests/announcements';
import { IFilterState } from '../../interfaces/announcements';
import { IFilter, SearchParams } from '../../interfaces/shared';
import useStyles from './styles';

interface IAnnouncementsState {
  content: GridRowsProp;
  totalElements: number;
}

const initialFilters: IFilterState = {
  name: '',
  visible: 'visible',
};

function Announcements() {
  const classes = useStyles();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const router = useRouter();
  const params = useParams<{ announcementId: string }>();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IFilterState>(initialFilters);
  const [{ page, pageSize, filterFields }, setSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    filterFields: [{ key: 'visible', searchOperation: 'eq', value: true }],
  });
  const [announcements, setAnnouncements] = useState<IAnnouncementsState>({
    content: [],
    totalElements: 0,
  });

  useEffect(() => {
    if (isAdmin(user)) {
      fetchAnnouncements();
    }
    // eslint-disable-next-line
  }, [page, pageSize, filterFields]);

  function fetchAnnouncements() {
    setLoading(true);
    getAnnouncements({
      page,
      pageSize,
      ...(filterFields?.length && { filterFields }),
    })
      .then((res) => {
        setLoading(false);
        setAnnouncements({
          content: res.data.content.map((announcement) => {
            announcement.id = announcement._id;
            return announcement;
          }),
          totalElements: res.data.totalElements,
        });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize, filterFields });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize, filterFields });
  }

  function handleApplyFilters() {
    const newFilters: IFilter[] = [];
    Object.entries(filters).forEach(([filterKey, filterValue]) => {
      if (filterKey === 'visible') {
        if (filterValue === 'all') {
          return;
        }
        newFilters.push({
          key: filterKey,
          searchOperation: 'eq',
          value: filterValue === 'visible',
        });
        return;
      }
      if (filterValue) {
        newFilters.push({
          key: filterKey,
          searchOperation: 'like',
          value: filterValue,
        });
      }
    });
    setAnnouncements({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: newFilters });
  }

  function handleClearFilters() {
    setFilters(initialFilters);
    setAnnouncements({ content: [], totalElements: 0 });
    setSearchParams({
      page: 0,
      pageSize,
      filterFields: [{ key: 'visible', searchOperation: 'eq', value: true }],
    });
  }

  function handleRowClick(params: GridRowParams) {
    router.history.push(`/announcements/${btoa(params.row.id)}`);
  }

  function getAnnouncementId() {
    const announcementId = params.announcementId;
    if (!announcementId) return 'invalid_announcement_id';
    if (announcementId === 'new') return 'new';
    return atob(announcementId);
  }

  if (!isAdmin(user)) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {!params.announcementId ? (
        <>
          <div className={classes.titleContainer}>
            <p className={classes.title}>Announcements</p>
            {isAdmin(user) && (
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  component={Link}
                  to="/announcements/new"
                >
                  Create
                </Button>
              </div>
            )}
          </div>
          <Filters
            filters={filters}
            setFilters={setFilters}
            onApplyFilters={handleApplyFilters}
            onClearFilters={handleClearFilters}
          />
          <AnnouncementsTable
            page={page}
            pageSize={pageSize}
            rows={announcements.content}
            totalElements={announcements.totalElements}
            loading={loading}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onRowClick={handleRowClick}
            fetchAnnouncements={fetchAnnouncements}
          />
        </>
      ) : (
        <AddEditAnnouncement
          announcementId={getAnnouncementId()}
          fetchAnnouncements={fetchAnnouncements}
        />
      )}
    </>
  );
}

export default Announcements;
