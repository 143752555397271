import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        fontFamily: ['"Poppins"', 'sans-serif'].join(', '),
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: ['"Poppins"', 'sans-serif'].join(', '),
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: ['"Poppins"', 'sans-serif'].join(', '),
      },
    },
  },
});

function TextField(props: TextFieldProps) {
  return (
    <ThemeProvider theme={theme}>
      <MuiTextField {...props}>{props.children}</MuiTextField>
    </ThemeProvider>
  );
}

export default TextField;
