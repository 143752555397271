import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import Button from '../shared/Inputs/Button';
import TextField from '../shared/Inputs/TextField';
import { resetPassword } from '../../requests/auth';
import { REGEX } from '../../constants';
import { useRouter } from '../../hooks/use-router';

import LockOpen from '../../assets/img/lock-open.svg';

import useStyles from './styles';

function ResetPassword() {
  const classes = useStyles();
  const router = useRouter();
  const params = useParams<{ token: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isResetSuccess, setIsResetSuccess] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<{ password: string; confirmPassword: string }>({
    defaultValues: { password: '', confirmPassword: '' },
  });

  const onSubmit: SubmitHandler<{ password: string; confirmPassword: string }> = async ({
    password,
  }) => {
    setError(null);
    setIsLoading(true);
    try {
      await resetPassword({ password, token: params.token });
      setIsLoading(false);
      setIsResetSuccess(true);
    } catch (err) {
      const errorMessage = err?.response?.data?.message || 'An error occurred. Please try again.';
      setIsLoading(false);
      setError(errorMessage);
    }
  };

  return (
    <div className={classes.centered}>
      <Paper className={classes.forgotPasswordPaper}>
        {!isResetSuccess ? (
          <>
            <div className={classes.forgotPasswordTitleContainer}>
              <div className={classes.lockIcon}>
                <img src={LockOpen} alt="lock" className={classes.lockIconImg} />
              </div>
              <div className={classes.forgotPasswordTitle}>Reset Password</div>
            </div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true, pattern: REGEX.PASSWORD }}
                  render={({ field: { ref, ...rest } }) => {
                    const hasError = Boolean(errors.password);
                    return (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        type="text"
                        label="New Password"
                        variant="outlined"
                        inputProps={{ maxLength: 32 }}
                        fullWidth
                        className={classes.textField}
                        error={hasError}
                        helperText={
                          <span>
                            Use 8 to 32 characters, one digit, one symbol (!@#$%^&amp;*), one
                            lowercase and uppercase letter
                          </span>
                        }
                      />
                    );
                  }}
                />
                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) => value === watch('password') || "Passwords don't match",
                  }}
                  render={({ field: { ref, ...rest } }) => {
                    const hasError = Boolean(errors.confirmPassword);
                    const hasRequiredError = errors.confirmPassword?.type === 'required';
                    return (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        type="password"
                        label="Confirm Password"
                        variant="outlined"
                        inputProps={{ maxLength: 32 }}
                        fullWidth
                        className={classes.textField}
                        error={hasError}
                        {...(hasError && {
                          helperText: (
                            <span>
                              {hasRequiredError
                                ? 'Confirm password is required'
                                : "Passwords don't match"}
                            </span>
                          ),
                        })}
                      />
                    );
                  }}
                />
                <div>{error && <Alert severity="error">{error}</Alert>}</div>
                <div className={classes.forgotPasswordButtonContainer}>
                  <div>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={[classes.sendEmailBtn, classes.loadingButton].join(' ')}
                      disabled={isLoading}
                    >
                      Submit
                      {isLoading && (
                        <LinearProgress
                          color="primary"
                          variant="indeterminate"
                          className={classes.buttonLinearProgress}
                        />
                      )}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </>
        ) : (
          <div className={classes.textAlignCenter}>
            <div>
              <img src={LockOpen} alt="lock" />
            </div>
            <p className={classes.forgotPasswordTitle}>Password reset successfully</p>
            <p className={classes.forgotPasswordSubTitle}>
              You can now login to your account with your new password
            </p>
            <div className={classes.backToLoginButtonContainer}>
              <Button
                color="primary"
                variant="contained"
                className={classes.sendEmailBtn}
                onClick={() => router.history.replace('/signin')}
              >
                Proceed to Login
              </Button>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
}

export default ResetPassword;
