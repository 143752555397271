import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { SubmitHandler } from 'react-hook-form';

import AddEditTeam from './AddEditTeam';
import Button from '../shared/Inputs/Button';
import { ReactComponent as DeleteIcon } from '../../assets/img/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/img/edit.svg';
import { deleteAdmin, getAdmins, getPrivileges } from '../../requests/admin';
import { useAuth } from '../../hooks/use-auth';
import { IUser } from '../../interfaces/user';
import { EPrivileges, IAddEditAdmin } from '../../interfaces/team';
import { createUpdateAdmin } from '../../requests/admin';
import { isSuperAdmin } from '../../utils/utils';
import DeleteOutlinedIcon from '../../assets/img/delete-outlined.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: { color: '#172B70', fontWeight: 'bold' },
    container: { margin: 24 },
    lisContainer: {
      height: 500,
      overflowY: 'scroll',
      borderRadius: 4,
      border: '1px solid #E4EBF8',
      marginTop: 8,
      position: 'relative',
    },
    stickyList: { backgroundColor: '#E4EAF1', position: 'sticky', top: 0, zIndex: 1 },
    headerTitlePrimary: { color: '#172B70', fontWeight: 600, fontSize: 16 },
    teamList: { paddingTop: 0, paddingBottom: 0 },
    listItemTextPrimary: { color: '#384F7A', fontWeight: 'bold', fontSize: 14 },
    listItemTextSecondary: { color: '#76849F', fontSize: 12 },
    listItemContainer: { width: '50%', flex: 'none' },
    privilege: {
      backgroundColor: '#D0FFEB',
      borderRadius: 24,
      color: '#577496',
      fontSize: 12,
      padding: '4px 6px',
    },
    divider: { borderBottomColor: '#EEEEEE' },
    iconButton: {
      boxShadow: '0px 4px 4px #DCEAFC',
      marginRight: 8,
      color: '#577496',
      '&:hover': {
        backgroundColor: '#FF6F24',
        color: '#FFFFFF',
        boxShadow: 'none',
      },
    },
    svgIcon: { '&:hover': { color: '#FFFFFF' } },
    buttons: { display: 'flex' },
    deleteTextContainer: { textAlign: 'center', marginTop: 24, marginBottom: 24 },
    deleteText: { color: '#577496', fontSize: 16, marginTop: 8 },
    loadingButton: { position: 'relative' },
    buttonLinearProgress: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
  })
);

function Team() {
  const classes = useStyles();

  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [admins, setAdmins] = useState([]);
  const [privileges, setPrivileges] = useState<EPrivileges[]>([]);
  const [dialogData, setDialogData] = useState<{
    open: boolean;
    isLoading: boolean;
    mode: 'add' | 'edit';
    admin: any;
  }>({
    open: false,
    isLoading: false,
    mode: 'add',
    admin: {},
  });
  const [deleteDialogData, setDeleteDialogData] = useState<{
    open: boolean;
    isLoading: boolean;
    admin: any;
  }>({
    open: false,
    isLoading: false,
    admin: {},
  });

  useEffect(() => {
    if (isSuperAdmin(user)) {
      fetchAdmins();
      fetchPrivileges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchAdmins() {
    setIsLoading(true);
    getAdmins()
      .then((res) => {
        setAdmins(res.data.content);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  async function fetchPrivileges() {
    getPrivileges()
      .then((res) => {
        setPrivileges(res.data);
      })
      .catch((error) => {});
  }

  const onSubmit: SubmitHandler<IAddEditAdmin> = async (data) => {
    setError('');
    setDialogData({ ...dialogData, isLoading: true });
    try {
      await createUpdateAdmin({ mode: dialogData.mode, requestBody: data });
      setDialogData({ ...dialogData, isLoading: false });
      handleDialogClose();
      fetchAdmins();
    } catch (err) {
      const errorMessage = err?.response?.data?.message || 'An error occurred. Please try again.';
      setDialogData({ ...dialogData, isLoading: false });
      setError(errorMessage);
    }
  };

  async function handleDeleteAdmin() {
    setError('');
    setDeleteDialogData({ ...deleteDialogData, isLoading: true });
    try {
      await deleteAdmin(deleteDialogData.admin.email);
      setDeleteDialogData({ ...deleteDialogData, isLoading: false });
      handleDialogClose();
      fetchAdmins();
    } catch (err) {
      const errorMessage = err?.response?.data?.message || 'An error occurred. Please try again.';
      setDeleteDialogData({ ...deleteDialogData, isLoading: false });
      setError(errorMessage);
    }
  }

  function handleDialogClose() {
    setError('');
    setDialogData({ open: false, mode: 'add', admin: {}, isLoading: false });
    setDeleteDialogData({ open: false, admin: {}, isLoading: false });
  }

  function renderLoader(count = 1) {
    return Array(count)
      .fill('skeleton-loader')
      .map((name, i) => (
        <ListItem key={`${name}-${i}`}>
          <ListItemText
            primary={<Skeleton width="30%" />}
            secondary={<Skeleton width="15%" />}
          ></ListItemText>
          <ListItemSecondaryAction>
            <Grid container spacing={1}>
              <Grid item>
                <Skeleton variant="circle" width={40} height={40} />
              </Grid>
              <Grid item>
                <Skeleton variant="circle" width={40} height={40} />
              </Grid>
            </Grid>
          </ListItemSecondaryAction>
        </ListItem>
      ));
  }

  if (!isSuperAdmin(user)) {
    return <Redirect to="/" />;
  }

  return (
    <div className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        Team
      </Typography>
      <div className={classes.lisContainer}>
        <List className={classes.stickyList}>
          <ListItem>
            <ListItemText
              primary="Team accounts"
              classes={{ primary: classes.headerTitlePrimary }}
            />
            <ListItemSecondaryAction>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  setDialogData({ open: true, mode: 'add', admin: {}, isLoading: false })
                }
              >
                Add User
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <List className={classes.teamList}>
          {isLoading
            ? renderLoader(3)
            : admins.map((admin: IUser) => {
                return (
                  <ListItem key={admin._id} divider classes={{ divider: classes.divider }}>
                    <ListItemText
                      classes={{
                        primary: classes.listItemTextPrimary,
                        secondary: classes.listItemTextSecondary,
                      }}
                      className={classes.listItemContainer}
                      primary={`${admin.firstName} ${admin.lastName}`}
                      secondary={admin.email}
                    />
                    <ListItemText
                      primary={
                        <span className={classes.privilege}>{admin.privileges || 'N/A'}</span>
                      }
                    />
                    <ListItemSecondaryAction classes={{ root: classes.buttons }}>
                      <div>
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          classes={{ root: classes.iconButton }}
                          onClick={() =>
                            setDialogData({ open: true, mode: 'edit', admin, isLoading: false })
                          }
                        >
                          <SvgIcon component={EditIcon} viewBox="-5 -4 24 24" />
                        </IconButton>
                      </div>
                      <div>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          classes={{ root: classes.iconButton }}
                          onClick={() =>
                            setDeleteDialogData({ open: true, admin, isLoading: false })
                          }
                        >
                          <SvgIcon component={DeleteIcon} viewBox="-5 -4 24 24" />
                        </IconButton>
                      </div>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
        </List>
      </div>
      <AddEditTeam
        open={dialogData.open}
        mode={dialogData.mode}
        isLoading={dialogData.isLoading}
        error={error}
        onClose={handleDialogClose}
        admin={dialogData.admin}
        privileges={privileges}
        onSubmit={onSubmit}
      />
      <Dialog open={deleteDialogData.open} maxWidth="sm" fullWidth onClose={handleDialogClose}>
        <DialogContent className={classes.deleteTextContainer}>
          <div>
            <div>
              <img src={DeleteOutlinedIcon} alt="delete" />
            </div>
            <div className={classes.deleteText}>
              {`Are you sure you want to delete this team account: ${deleteDialogData.admin.firstName} ${deleteDialogData.admin.lastName}?`}
            </div>
          </div>
        </DialogContent>
        <div>{error && <Alert severity="error">{error}</Alert>}</div>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteAdmin}
            color="primary"
            variant="contained"
            disabled={deleteDialogData.isLoading}
          >
            Delete
            {deleteDialogData.isLoading && (
              <LinearProgress
                color="primary"
                variant="indeterminate"
                className={classes.buttonLinearProgress}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Team;
