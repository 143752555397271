import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Logo from '../../assets/img/logo.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
      color: 'inherit',
    },
    grow: { flexGrow: 1 },
    logo: { width: 120 },
  })
);

function MinimalAppHeader() {
  const classes = useStyles();
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <div>
          <Link to="/">
            <img src={Logo} alt="StanVentures" className={classes.logo} />
          </Link>
        </div>
        <div className={classes.grow} />
      </Toolbar>
    </AppBar>
  );
}

export default MinimalAppHeader;
