import React, { createContext, useContext, useState } from 'react';

import { IUser } from '../interfaces/user';
import { getUserDetails } from '../requests/auth';

interface IAuthState {
  user: IUser | null;
  isAuthenticated: boolean;
}

interface IAuthContext extends IAuthState {
  setAuth: React.Dispatch<React.SetStateAction<IAuthState>>;
  fetchUserDetails(): void;
}

const authContext = createContext<IAuthContext>({} as IAuthContext);

export function ProvideAuth({ children }: { children: React.ReactNode }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [{ user, isAuthenticated }, setAuth] = useState<IAuthState>({
    user: null,
    isAuthenticated: false,
  });

  function fetchUserDetails() {
    getUserDetails()
      .then((res) => setAuth({ isAuthenticated: true, user: res.data }))
      .catch((error) => {});
  }

  return { user, isAuthenticated, setAuth, fetchUserDetails };
}
