import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import ReactQuill from 'react-quill';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import SendIcon from '@material-ui/icons/Send';
import RefreshIcon from '@material-ui/icons/Refresh';

import Button from '../shared/Inputs/Button';
import LoadingButton from '../shared/Inputs/LoadingButton';

import { getMessages, getMigratedOrderMessages, sendMessage } from '../../requests/order';
import { MessageThread, MessageType } from '../../interfaces/order';
import { useSnackbar } from '../../hooks/use-snackbar';
import { useAuth } from '../../hooks/use-auth';
import { getFormattedDate, isAdmin } from '../../utils/utils';
import useStyles from './styles';

import 'react-quill/dist/quill.snow.css';
import './styles.css';

const initialMessage = { text: '', html: '' };

function Messages({ orderId, isMigratedOrder }: { orderId: string; isMigratedOrder: boolean }) {
  const classes = useStyles();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [messageType, setMessageType] = useState<MessageType>('Customer');
  const [loading, setLoading] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState({
    Customer: false,
    Admin: false,
    Migrated: false,
  });
  const [showEditor, setShowEditor] = useState(false);
  const [message, setMessage] = useState<{ text: string; html: string }>(initialMessage);
  const [customerMessageThread, setCustomerMessageThread] = useState<MessageThread>({
    threadId: '',
    messages: [],
  });
  const [internalMessageThread, setInternalMessageThread] = useState<MessageThread>({
    threadId: '',
    messages: [],
  });
  const [migratedMessageThread, setMigratedMessageThread] = useState<MessageThread>({
    threadId: '',
    messages: [],
  });

  useEffect(() => {
    if (orderId) {
      fetchAllMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  function fetchAllMessages() {
    fetchMessages('Customer');
    if (isAdmin(user)) {
      fetchMessages('Admin');
    }
    if (isMigratedOrder) {
      fetchMigratedMessages();
    }
  }

  function fetchMessages(messageType: MessageType) {
    setLoadingMessages((prevState) => ({ ...prevState, [messageType]: true }));
    getMessages(orderId, messageType)
      .then((res) => {
        setLoadingMessages((prevState) => ({ ...prevState, [messageType]: false }));
        const setMessageThread =
          messageType === 'Admin' ? setInternalMessageThread : setCustomerMessageThread;
        setMessageThread({
          threadId: res.data.threadId,
          messages: res.data.messages || [],
        });
        setMessage({ text: '', html: '' });
      })
      .catch((error: AxiosError) => {
        setLoadingMessages((prevState) => ({ ...prevState, [messageType]: false }));
        const errorMessage =
          error?.response?.data?.message || 'An error occurred while loading messages.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function fetchMigratedMessages() {
    setLoadingMessages((prevState) => ({ ...prevState, Migrated: true }));
    getMigratedOrderMessages(orderId)
      .then((res) => {
        setLoadingMessages((prevState) => ({ ...prevState, Migrated: false }));
        setMigratedMessageThread({
          threadId: res.data.threadId,
          messages:
            res.data?.messages?.map((message) => ({
              ...message,
              body: (message as any).message,
            })) || [],
        });
      })
      .catch((error: AxiosError) => {
        setLoadingMessages((prevState) => ({ ...prevState, Migrated: false }));
        const errorMessage =
          error?.response?.data?.message || 'An error occurred while loading migrated messages.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function createMessage(messageType: MessageType) {
    setLoading(true);
    sendMessage({ orderId, body: message.html, messageType })
      .then((res) => {
        setLoading(false);
        setShowEditor(false);
        showSnackbar({ severity: 'success', message: 'Message sent successfully' });
        fetchMessages(messageType);
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function isLoadingMessages() {
    return Object.values(loadingMessages).some((l) => l);
  }

  function isSendDisabled() {
    if (loading) return true;
    return showEditor ? message.text.replace(/\n/g, '').length === 0 : false;
  }

  function getMessageThread(messageType: MessageType) {
    switch (messageType) {
      case 'Admin':
        return internalMessageThread;
      case 'Migrated':
        return migratedMessageThread;
      default:
        return customerMessageThread;
    }
  }

  return (
    <div>
      <Box display="flex" alignItems="center">
        <p className={classes.messagesTitle}>Messages</p>
        {isLoadingMessages() ? (
          <CircularProgress color="primary" size={24} className={classes.messagesLoader} />
        ) : (
          <RefreshIcon
            htmlColor="#3A4A5E"
            fontSize="small"
            className={classes.refreshMessageIcon}
            onClick={() => fetchAllMessages()}
          />
        )}
      </Box>
      {(isMigratedOrder || isAdmin(user)) && (
        <Tabs
          value={messageType}
          onChange={(_, newMessageType) => setMessageType(newMessageType)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label={isAdmin(user) ? 'Customer' : 'New'} value="Customer" />
          {isAdmin(user) && <Tab label="Internal" value="Admin" />}
          {isMigratedOrder && <Tab label="Migrated" value="Migrated" />}
        </Tabs>
      )}
      <Paper elevation={0} className={classes.messagesPaperRoot}>
        {getMessageThread(messageType).messages.length > 0 ? (
          getMessageThread(messageType).messages.map((message, index) => (
            <div key={index} className={classes.messageItem}>
              <div className={classes.messageNameContainer}>
                <div>
                  <Avatar className={classes.messageAvatar}></Avatar>
                </div>
                <div>
                  <div>{message.fromName}</div>
                  <div className={classes.messageTime}>{getFormattedDate(message.dateTime)}</div>
                </div>
              </div>
              <div
                className={clsx('ql-editor', classes.messageBody)}
                dangerouslySetInnerHTML={{ __html: message.body || '' }}
              />
            </div>
          ))
        ) : (
          <p className={classes.emptyMessagePlaceholder}>No messages found</p>
        )}
      </Paper>
      {showEditor && (
        <div className={clsx('message-quill-editor', classes.messageEditor)}>
          <ReactQuill
            theme="snow"
            modules={{
              toolbar: [
                [{ size: ['small', false, 'large', 'huge'] }],
                ['bold', 'italic'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link', 'clean'],
              ],
            }}
            value={message.html}
            onChange={(content, delta, source, editor) => {
              setMessage({ text: editor.getText(), html: editor.getHTML() });
            }}
          />
        </div>
      )}
      {messageType !== 'Migrated' && (
        <div className={classes.messageButtons}>
          <div>
            <LoadingButton
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              onClick={() => {
                if (showEditor) {
                  createMessage(messageType);
                } else {
                  setShowEditor(true);
                }
              }}
              disabled={isSendDisabled()}
              isLoading={loading}
            >
              Send Message
            </LoadingButton>
          </div>
          {showEditor && (
            <div>
              <Button
                variant="outlined"
                onClick={() => {
                  setShowEditor(false);
                  setMessage(initialMessage);
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Messages;
