import classes from './styles.module.css';

function Chase() {
  return (
    <div className={classes['sk-chase']}>
      <div className={classes['sk-chase-dot']}></div>
      <div className={classes['sk-chase-dot']}></div>
      <div className={classes['sk-chase-dot']}></div>
      <div className={classes['sk-chase-dot']}></div>
      <div className={classes['sk-chase-dot']}></div>
      <div className={classes['sk-chase-dot']}></div>
    </div>
  );
}

export default Chase;
