import Rating from '@material-ui/lab/Rating';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ReviewCarousel from './ReviewCarousel';

import Logo from '../../assets/img/logo.svg';
import StandingMen from '../../assets/img/standing-men.svg';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    background: 'linear-gradient(0deg, #FFFAF6, #FFFAF6)',
    paddingLeft: 50,
    paddingRight: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  logo: { paddingTop: 60, marginBottom: 30 },
  testimonial: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    '& > div': {
      position: 'relative',
      minHeight: 400,
    },
  },
  testimonialContainer: {
    border: '2px solid #FFE7DD',
    borderRadius: 25,
    backgroundColor: '#FFFFFF',
    width: '60%',
    minHeight: 225,
    position: 'absolute',
    top: 20,
    left: 130,
  },
  rating: { display: 'flex', alignItems: 'center', margin: '30px 0px' },
  positiveReviewsLeft: { paddingRight: 16 },
  positiveReviewsLeftText: { color: '#172B70', fontSize: 14, marginBottom: 4 },
  positiveReviewsRight: { paddingLeft: 16, display: 'flex', alignItems: 'center' },
  percentage: { color: '#172B70', fontSize: 24, fontWeight: 'bold' },
  percentageText: { color: '#94AAC8', fontSize: 12, maxWidth: '180px', paddingLeft: 8 },
  divider: { height: 50, width: 2, backgroundColor: '#E2E2E2' },
  copyright: { color: '#E3C9B4', marginBottom: 8, fontSize: 12 },
}));

function SideTestimonial() {
  const classes = useStyles();
  const year = new Date().getFullYear();
  return (
    <>
      <div className={classes.root}>
        <div className={classes.logo}>
          <img src={Logo} alt="StanVentures" />
        </div>
        <div className={classes.testimonial}>
          <div>
            <div>
              <img src={StandingMen} alt="" />
            </div>
            <div className={classes.testimonialContainer}>
              <ReviewCarousel />
            </div>
          </div>
        </div>
        <div className={classes.rating}>
          <div className={classes.positiveReviewsLeft}>
            <div className={classes.positiveReviewsLeftText}>Over 900 positive reviews</div>
            <div>
              <Rating value={5} size="small" readOnly />
            </div>
          </div>
          <div className={classes.divider}></div>
          <div className={classes.positiveReviewsRight}>
            <div className={classes.percentage}>95%</div>
            <div className={classes.percentageText}>
              of customers that buy from this merchant give them a 4 or 5-star rating.
            </div>
          </div>
        </div>
        <div className={classes.copyright}>Copyright &copy; {`${year} - ${year + 1}`}</div>
      </div>
    </>
  );
}

export default SideTestimonial;
