import Slider from 'react-slick';
import Rating from '@material-ui/lab/Rating';
import makeStyles from '@material-ui/core/styles/makeStyles';

import './styles.css';

const REVIEWS = [
  {
    clientName: 'sitejabber',
    reviewerName: 'Mark W',
    rating: 4.5,
    reviewText: `I never knew it could be so easy to get more quality backlinks for my website until I discovered Stan Ventures. Each month, we review the prior month's accomplishments and performance, make tweaks, then add any number of additional backlinks I want.`,
  },
];

const useStyles = makeStyles((theme) => ({
  review: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconEmpty: { color: '#ffffff' },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: 25,
  },
  rating: { padding: theme.spacing(0.5, 1) },
  ratingText: {
    backgroundColor: '#f15a24',
    color: '#ffffff',
    width: 45,
    height: 45,
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    textAlign: 'center',
    borderRadius: 25,
    fontSize: 18,
  },
  clientName: { color: '#454D5C' },
  reviewerName: { color: '#454D5C' },
  reviewText: { color: '#232323', padding: '0 16px', textAlign: 'center', marginBottom: 0 },
}));

function ReviewCarousel() {
  const classes = useStyles();
  return (
    <Slider
      dots={false}
      infinite
      slidesToShow={1}
      slidesToScroll={1}
      speed={500}
      autoplay
      autoplaySpeed={10000}
      className="review-slider"
    >
      {REVIEWS.map((review, i) => (
        <div key={i}>
          <div className={classes.review}>
            <h2 className={classes.clientName}>{review.clientName}</h2>
            <div className={classes.ratingContainer}>
              <div className={classes.rating}>
                <Rating
                  value={review.rating}
                  precision={0.5}
                  size="large"
                  readOnly
                  classes={{ iconEmpty: classes.iconEmpty }}
                />
              </div>
              <div className={classes.ratingText}>
                <div>{review.rating}</div>
              </div>
            </div>
            <p className={classes.reviewText}>{review.reviewText}</p>
            <h2 className={classes.reviewerName}>{review.reviewerName}</h2>
          </div>
        </div>
      ))}
    </Slider>
  );
}

export default ReviewCarousel;
