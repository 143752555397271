import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createTheme } from '@material-ui/core/styles';

import { OrderSubStatus } from '../../interfaces/order';

export const MUI_THEME = createTheme({
  palette: { primary: { main: '#FF6329' } },
  typography: { fontFamily: ['"Poppins"', 'sans-serif'].join(', ') },
  overrides: {
    MuiStepper: { root: { marginBottom: 8, border: '1px solid #E4EBF8', borderRadius: 4 } },
    MuiStepConnector: {
      active: { '& $line': { borderColor: '#FF6F24' } },
      completed: { '& $line': { borderColor: '#FF6F24' } },
      line: { borderColor: '#577496' },
    },
    MuiStepLabel: {
      label: {
        color: '#577496',
        '&$active': { color: '#577496' },
        '&$completed': { color: '#FF6F24' },
      },
    },
    MuiStepIcon: {
      root: {
        border: '1px solid #577496',
        borderRadius: '50%',
        '& circle': { fill: 'transparent' },
      },
      text: { fill: '#577496' },
      active: {
        border: 'unset',
        borderRadius: 'unset',
        '& text': { fill: '#FFFFFF' },
        '& circle': { fill: '#577496' },
      },
      completed: { border: 'unset', borderRadius: 'unset', '& circle': { fill: 'currentColor' } },
    },
  },
});

interface IProps {
  subStatuses: OrderSubStatus[];
}

function OrderTimeline(props: IProps) {
  const { subStatuses } = props;

  function getSteps() {
    return subStatuses.map((status) => status.name);
  }

  function getActiveStep() {
    let activeStep = -1;
    subStatuses.forEach((status, index) => {
      if (status.completed) {
        activeStep = index + 1;
      }
    });
    return activeStep;
  }

  return (
    <ThemeProvider theme={MUI_THEME}>
      <Stepper activeStep={getActiveStep()} alternativeLabel>
        {getSteps().map((label) => (
          <Step key={label} classes={{}}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </ThemeProvider>
  );
}

export default OrderTimeline;
