import makeStyles from '@material-ui/core/styles/makeStyles';
import { Controller, Control, FormState } from 'react-hook-form';

import TextField from './Inputs/TextField';
import { ISignUpInput } from '../../interfaces/auth';
import { REGEX } from '../../constants';

const useStyles = makeStyles(() => ({
  textField: { marginTop: 8, marginBottom: 8 },
  textFields: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div:first-child': { marginRight: 4 },
    '& > div:last-child': { marginLeft: 4 },
  },
}));

function SignUpForm({
  control,
  formState,
}: {
  control: Control<ISignUpInput>;
  formState: FormState<ISignUpInput>;
}) {
  const classes = useStyles();
  const { errors } = formState;

  return (
    <>
      <div className={classes.textFields}>
        <Controller
          name="firstName"
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, ...rest } }) => {
            const hasError = Boolean(errors.firstName);
            const hasRequiredError = errors?.firstName?.type === 'required';
            return (
              <TextField
                {...rest}
                inputRef={ref}
                type="text"
                label="First Name"
                variant="outlined"
                fullWidth
                className={classes.textField}
                error={hasError}
                {...(hasError && {
                  helperText: <span>{hasRequiredError && 'First name is required'}</span>,
                })}
              />
            );
          }}
        />
        <Controller
          name="lastName"
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, ...rest } }) => {
            const hasError = Boolean(errors.lastName);
            const hasRequiredError = errors?.lastName?.type === 'required';
            return (
              <TextField
                {...rest}
                inputRef={ref}
                type="text"
                label="Last Name"
                variant="outlined"
                fullWidth
                className={classes.textField}
                error={hasError}
                {...(hasError && {
                  helperText: <span>{hasRequiredError && 'Last name is required'}</span>,
                })}
              />
            );
          }}
        />
      </div>
      <Controller
        name="email"
        control={control}
        rules={{ required: true, pattern: REGEX.EMAIL }}
        render={({ field: { ref, ...rest } }) => {
          const hasError = Boolean(errors.email);
          const hasRequiredError = errors?.email?.type === 'required';
          return (
            <TextField
              {...rest}
              inputRef={ref}
              type="email"
              label="Email"
              variant="outlined"
              fullWidth
              className={classes.textField}
              error={hasError}
              {...(hasError && {
                helperText: (
                  <span>
                    {hasRequiredError ? 'Email is required' : 'Please provide a valid email'}
                  </span>
                ),
              })}
            />
          );
        }}
      />
      <Controller
        name="password"
        control={control}
        rules={{ required: true, pattern: REGEX.PASSWORD }}
        render={({ field: { ref, ...rest } }) => {
          const hasError = Boolean(errors.password);
          return (
            <TextField
              {...rest}
              inputRef={ref}
              type="password"
              label="Password"
              variant="outlined"
              inputProps={{ maxLength: 32 }}
              fullWidth
              className={classes.textField}
              error={hasError}
              helperText={
                <span>
                  Use 8 to 32 characters, one digit, one symbol (!@#$%^&amp;*), one lowercase and
                  uppercase letter
                </span>
              }
            />
          );
        }}
      />
    </>
  );
}

export default SignUpForm;
