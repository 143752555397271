import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    onboardingContainer: {
      backgroundColor: '#FDF0E9',
      padding: theme.spacing(4),
    },
    onboardingButtonContainer: {
      display: 'flex',
      paddingTop: theme.spacing(2),
      '& > div:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        '& > div': {
          marginTop: theme.spacing(1),
        },
        '& > div:not(:last-child)': {
          marginRight: theme.spacing(0),
        },
      },
    },
    videoContainer: {
      padding: theme.spacing(1),
      borderRadius: 12,
      border: '3px solid #ffffff',
      backgroundColor: '#f7f0ee',
      position: 'relative',
      '& iframe, & img': {
        width: '100%',
        height: '315px',
        borderRadius: 12,
      },
    },
    playIconButton: {
      position: 'absolute',
      top: '45%',
      left: '45%',
      color: '#E66A28',
      backgroundColor: '#ffffff',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
      transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
      '-webkit-transition': 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
      '&:hover': {
        backgroundColor: '#ffffff',
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
      },
    },
    servicesContainer: {
      backgroundColor: '#E66A28',
      padding: theme.spacing(2, 4, 2),
      '&.bg-light': {
        backgroundColor: 'inherit',
        '& $servicesContainerHeading': { color: '#293140' },
        '& $serviceCard': {
          color: '#000000',
          '&:hover': { backgroundColor: '#E66A28', color: '#ffffff' },
        },
      },
    },
    servicesContainerHeading: { color: '#FFFFFF' },
    serviceCard: {
      backgroundColor: 'transparent',
      color: '#ffffff',
      maxWidth: 320,
      cursor: 'pointer',
      '&:hover': { backgroundColor: '#FFA048' },
      '&:hover $goToServiceIconButton': { visibility: 'visible' },
    },
    serviceCardHeader: { paddingBottom: theme.spacing(1) },
    serviceCardContent: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
    serviceCardTitle: { fontWeight: 600 },
    serviceCardDescription: { fontSize: '0.75rem' },
    serviceCardActions: { justifyContent: 'flex-end' },
    goToServiceIconButton: {
      visibility: 'hidden',
      color: '#ffffff',
      '&:hover': { backgroundColor: '#ffffff', color: '#FF6F24' },
    },
    strategiesMainContainer: { padding: theme.spacing(2, 4, 2) },
    strategiesContainer: {
      backgroundColor: '#F9FBFD',
      padding: theme.spacing(2, 4, 2),
    },
    strategyDescription: { fontSize: '0.75rem' },
    revenueGrowthImage: { width: '75%', [theme.breakpoints.down('xs')]: { width: '85%' } },
    dashboardContainer: { margin: theme.spacing(-3) },
    announcementContainer: { padding: theme.spacing(2, 4, 2), margin: theme.spacing(1, 0) },
    announcementTitle: { marginBottom: theme.spacing(0.5) },
    announcementSubTitle: { color: '#577496', fontSize: '0.75rem', marginTop: 0 },
    announcementList: { '& > li:not(:first-child)': { marginTop: theme.spacing(2) } },
    announcementListItem: {
      backgroundColor: '#F1F1F1',
      borderRadius: 4,
      border: '1px solid #E4EBF8',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    announcementListItemText: { flex: '1 1 75%' },
    announcementListItemTextPrimary: { color: '#000000', fontSize: '0.875rem' },
    announcementListItemTextSecondary: { color: '#577496', fontSize: '0.75rem' },
    welcomeContainer: { padding: theme.spacing(2, 4, 2), margin: theme.spacing(1, 0) },
    welcomeTitle: { marginBottom: theme.spacing(0.5) },
    welcomeSubTitle: { color: '#577496', fontSize: '0.75rem', marginTop: 0 },
    minimalOrderTableContainer: { paddingTop: theme.spacing(1) },
    title: { color: '#172B70', fontSize: 22, fontWeight: 700 },
    loader: { marginLeft: theme.spacing(2), color: '#FF6329 !important' },
    buttonContainer: {
      margin: theme.spacing(2, 0, 1),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dateRangePicker: { minWidth: 256 },
    chartContainer: {
      width: '100%',
      height: 320,
      border: '1px solid #E5E5E5',
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      padding: theme.spacing(3, 1, 1),
      margin: theme.spacing(1, 0),
    },
    cardsContainer: { marginTop: theme.spacing(1) },
    card: {
      padding: theme.spacing(2, 3),
      borderRadius: 25,
      height: 200,
      '&.revenue': {
        background: 'linear-gradient(0deg, #FFDED5, #FFDED5)',
        '& > div:nth-child(2)': {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          height: 'calc(100% - 72px)',
          fontSize: '2.25rem',
          fontWeight: 'bold',
          color: '#313238',
        },
      },
      '&.agents': {
        background: 'linear-gradient(0deg, #FFEACB, #FFEACB)',
        overflowY: 'scroll',
        '& > div:nth-child(2)': { fontWeight: 500, color: '#1A1C1F' },
      },
      '&.clients': {
        background: 'linear-gradient(0deg, #F3EBEE, #F3EBEE)',
        overflowY: 'scroll',
        '& > div:nth-child(2)': { fontWeight: 500, color: '#1A1C1F' },
      },
      '&.status': {
        background: 'linear-gradient(0deg, #E6EFE5, #E6EFE5)',
        overflowY: 'scroll',
        '& > div:nth-child(2)': {
          display: 'flex',
          flexWrap: 'wrap',
          '& > div': {
            flexBasis: '50%',
            marginBottom: theme.spacing(3),
            '& > p': { marginTop: 0, marginBottom: theme.spacing(0.5) },
            '& > p:first-child': { color: '#2C3A6B', fontSize: '1rem', fontWeight: 'bold' },
            '& > p:last-child': { color: '#1E2F44', fontSize: '0.75rem', fontWeight: 600 },
          },
        },
      },
      '&.comparison': { background: 'linear-gradient(0deg, #C5DCFF, #C5DCFF)' },
      '&.new-clients': { background: 'linear-gradient(0deg, #FFDED5, #FFDED5)' },
      '&.refund': {
        background: 'linear-gradient(0deg, #F2E1FF, #F2E1FF)',
        overflowY: 'scroll',
        '& > div:nth-child(2)': { fontWeight: 500, color: '#1A1C1F' },
      },
    },
    cardHeader: { display: 'flex', justifyContent: 'space-between' },
    cardTitle: { color: '#1A1C1F', fontWeight: 700, fontSize: '1rem', marginTop: 0 },
  })
);
