import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LinearProgress from '@material-ui/core/LinearProgress';

import PageTitleWithBack from '../shared/PageTitleWithBack';
import Button from '../shared/Inputs/Button';
import TextField from '../shared/Inputs/TextField';
import LoadingButton from '../shared/Inputs/LoadingButton';

import { useRouter } from '../../hooks/use-router';
import { useSnackbar } from '../../hooks/use-snackbar';
import { MUI_THEME, isAdmin } from '../../utils/utils';
import { REGEX } from '../../constants';
import { Creatives, CreativesForm } from '../../interfaces/creatives';
import useStyles from './styles';
import { uploadFile } from '../../requests/common';
import { getCreativesById, saveCreative } from '../../requests/creatives';
import dayjs from 'dayjs';
import { FormControl, Select, MenuItem, FormHelperText } from '@material-ui/core';
import { useAuth } from '../../hooks/use-auth';

interface IProps {
  creativeId: string;
  fetchCreatives(): void;
}

function AddEditCreatives(props: IProps) {
  const { creativeId, fetchCreatives } = props;
  const router = useRouter();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const [creative, setCreative] = useState<Creatives>({} as Creatives);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
    handleSubmit,
  } = useForm<CreativesForm>({
    defaultValues: {
      name: '',
      description: '',
      validity: '',
      docOrImageUrl: '',
      url: '',
    },
  });
  const imageUrl = watch('docOrImageUrl');

  const operation = creativeId === 'new' ? 'create' : 'update';
  const isNewCreative = operation === 'create';
  const statusStateFields = [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'INACTIVE', value: 'INACTIVE' },
  ];
  const { user } = useAuth();
  const [readOnlyFieldFlag, setReadOnlyFieldFlag] = useState<boolean | undefined>(false);

  useEffect(() => {
    if (!isNewCreative && creativeId) {
      fetchCreative();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creativeId, isNewCreative]);

  useEffect(() => {
    setReadOnlyFieldFlag(!isAdmin(user));
  }, [user]);

  function fetchCreative() {
    getCreativesById(creativeId)
      .then((res) => {
        setCreative(res.data);
        resetCreativesForm(res.data);
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function resetCreativesForm(data: Creatives) {
    reset({
      name: data.name,
      description: data.description,
      validity: dayjs(data.validity).format('YYYY-MM-DD'),
      docOrImageUrl: data.docOrImageUrl,
      url: data.url,
      status: data.creativeStatus,
    });
  }

  function handleImageChange(files: FileList | null) {
    if (!files?.length) return;
    const formData = new FormData();
    formData.append('file', files[0]);
    setIsImageUploading(true);
    uploadFile(formData)
      .then((res) => {
        setIsImageUploading(false);
        const url = res.data.url;
        setValue('docOrImageUrl', url);
      })
      .catch((error: AxiosError) => {
        setIsImageUploading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleSaveCreative(data: CreativesForm) {
    const requestBody: Partial<Creatives> = {
      ...(!isNewCreative && { _id: creativeId }),
      name: data.name,
      description: data.description,
      validity: data.validity,
      docOrImageUrl: data.docOrImageUrl,
      url: data.url,
      creativeStatus: data.status,
      ...(creative?.version && { version: creative.version }),
    };
    setIsLoading(true);
    saveCreative(requestBody as Creatives)
      .then((res) => {
        setIsLoading(false);
        if (isNewCreative) {
          router.history.replace(`/creatives/${btoa(res.data._id)}`);
          showSnackbar({ severity: 'success', message: 'Creative created successfully' });
        } else {
          resetCreativesForm(res.data);
          showSnackbar({ severity: 'success', message: 'Creative updated successfully' });
        }
        fetchCreatives();
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function getPageTitle() {
    if (readOnlyFieldFlag) {
      return 'View Creatives';
    } else return `${isNewCreative ? 'Add' : 'Edit'} Creatives`;
  }

  return (
    <ThemeProvider theme={MUI_THEME}>
      <Container maxWidth="md" disableGutters>
        <PageTitleWithBack
          title={getPageTitle()}
          disableGutters
          onBackClick={() => router.history.replace('/creatives')}
        />
        <form onSubmit={handleSubmit(handleSaveCreative)}>
          <div className={classes.creativeDetailsContainer}>
            <div className={classes.creativeDetailsForm}>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Title</div>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, onChange, ...rest } }) => {
                    const hasError = Boolean(errors.name);
                    return (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        variant="outlined"
                        fullWidth
                        size="small"
                        error={hasError}
                        onChange={onChange}
                        {...(hasError && { helperText: 'Title is required' })}
                        InputProps={{
                          readOnly: readOnlyFieldFlag,
                        }}
                      />
                    );
                  }}
                />
              </div>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Description</div>
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { ref, ...rest } }) => {
                    const hasError = Boolean(errors.description);
                    return (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        fullWidth
                        variant="outlined"
                        size="small"
                        multiline
                        minRows={3}
                        maxRows={3}
                        InputProps={{
                          readOnly: readOnlyFieldFlag,
                        }}
                        {...(hasError && { helperText: 'Description is required' })}
                      />
                    );
                  }}
                />
              </div>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>URL</div>
                <Controller
                  name="url"
                  control={control}
                  rules={{ required: true, pattern: REGEX.URL }}
                  render={({ field: { ref, onChange, ...rest } }) => {
                    const hasError = Boolean(errors.url);
                    const hasRequiredError = errors.url?.type === 'required';
                    const hasPatternError = errors.url?.type === 'pattern';
                    return (
                      <TextField
                        {...rest}
                        InputProps={{
                          readOnly: readOnlyFieldFlag,
                        }}
                        inputRef={ref}
                        fullWidth
                        variant="outlined"
                        size="small"
                        error={hasError}
                        onChange={onChange}
                        {...(hasError && {
                          helperText: (
                            <>
                              {hasRequiredError && 'URL is required'}
                              {hasPatternError && 'URL is not valid'}
                            </>
                          ),
                        })}
                      />
                    );
                  }}
                />
              </div>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Status</div>
                <Controller
                  name="status"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, ...rest } }) => {
                    const hasError = Boolean(errors.status);
                    const hasRequiredError = errors?.status?.type === 'required';
                    return (
                      <FormControl variant="outlined" fullWidth size="small" error={hasError}>
                        <Select
                          readOnly={readOnlyFieldFlag}
                          {...rest}
                          value={value}
                          key={value}
                          MenuProps={{
                            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                            transformOrigin: { vertical: 'top', horizontal: 'center' },
                            getContentAnchorEl: null,
                          }}
                        >
                          {statusStateFields.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {hasRequiredError && <FormHelperText>Status is required</FormHelperText>}
                      </FormControl>
                    );
                  }}
                />
              </div>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Validity</div>
                <Controller
                  name="validity"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, onChange, ...rest } }) => {
                    const hasError = Boolean(errors.validity);
                    const hasRequiredError = errors?.validity?.type === 'required';
                    return (
                      <TextField
                        InputProps={{
                          readOnly: readOnlyFieldFlag,
                        }}
                        {...rest}
                        inputRef={ref}
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="date"
                        error={hasError}
                        onChange={(event) => {
                          const value = event.target.value;
                          onChange(value);
                        }}
                        {...(hasError && {
                          helperText: <>{hasRequiredError && 'Validity is required'}</>,
                        })}
                      />
                    );
                  }}
                />
              </div>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Image</div>
                <div>
                  <Controller
                    name="docOrImageUrl"
                    control={control}
                    rules={{ required: false }}
                    render={() => {
                      const hasImage = Boolean(imageUrl);
                      const hasRequiredError = errors.docOrImageUrl?.type === 'required';
                      return !hasImage ? (
                        <>
                          <input
                            readOnly={readOnlyFieldFlag}
                            accept="image/*"
                            className={classes.uploadInput}
                            id="creative-image-upload-button"
                            type="file"
                            onChange={(event) => handleImageChange(event.target.files)}
                          />
                          <label htmlFor="creative-image-upload-button">
                            {!readOnlyFieldFlag && (
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                startIcon={<PublishIcon />}
                                component="span"
                                disabled={isImageUploading}
                              >
                                Upload Image
                              </Button>
                            )}
                            {hasRequiredError && (
                              <span className={classes.errorHelperText}>Image is required</span>
                            )}
                            {isImageUploading && (
                              <LinearProgress color="primary" variant="indeterminate" />
                            )}
                          </label>
                        </>
                      ) : (
                        <div className={classes.uploadedImageContainer}>
                          <p className={classes.imageName}>
                            {imageUrl.split('/').slice(-1).join('')}
                          </p>
                          <div>
                            <IconButton
                              size="small"
                              className={classes.viewDeleteIconButton}
                              onClick={() => window.open(imageUrl, '_blank')}
                            >
                              <VisibilityIcon fontSize="small" />
                            </IconButton>
                            {!readOnlyFieldFlag && (
                              <IconButton
                                size="small"
                                className={classes.viewDeleteIconButton}
                                onClick={() => setValue('docOrImageUrl', '')}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
              <div className={classes.saveButtonContainer}>
                {!readOnlyFieldFlag && (
                  <LoadingButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Save
                  </LoadingButton>
                )}
              </div>
            </div>
          </div>
        </form>
      </Container>
    </ThemeProvider>
  );
}

export default AddEditCreatives;
