import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    dateRangePickerContainer: {
      display: 'flex',
      '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
    },
    dateRangePicker: { minWidth: 256 },
    reportsTable: { height: 'calc(100vh - 250px) !important', marginTop: theme.spacing(1) },
    linkBlue: {
      color: '#384F7A',
      '&:hover': { color: '#384F7A' },
      '&:visited': { color: '#384F7A' },
    },
  })
);
