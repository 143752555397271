import { Link } from 'react-router-dom';
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridValueFormatterParams,
} from '@material-ui/data-grid';

import useStyles from './styles';
import { getFormattedDate } from '../../utils/utils';

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  totalElements: number;
  loading: boolean;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
}

function TrafficLogsTable(props: IProps) {
  const classes = useStyles();
  const { page, pageSize, rows, totalElements, loading, onPageChange, onPageSizeChange } = props;

  const columns: GridColDef[] = [
    {
      field: 'referrerClientName',
      headerName: 'Affiliate Name',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Link
          className={classes.linkBlue}
          to={`/clients/${btoa(params.row.referrerClientId as string)}`}
          onClick={(event) => event.stopPropagation()}
        >
          {params.row.referrerClientName}
        </Link>
      ),
    },
    { field: 'affiliateCode', headerName: 'Affiliate Code', flex: 1, sortable: false },
    {
      field: 'createdTime',
      headerName: 'Date',
      flex: 1,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) =>
        getFormattedDate(params.getValue(params.id, 'createdTime') as string),
    },
    {
      field: 'referrerUrl',
      headerName: 'Referrer',
      flex: 1,
      sortable: false,
      renderCell: (params) =>
        params.row.referrerUrl ? (
          <a href={params.row.referrerUrl} className={classes.linkBlue}>
            {params.row.referrerUrl}
          </a>
        ) : (
          ''
        ),
    },
    {
      field: 'userAgent',
      headerName: 'User Agent',
      flex: 2,
      sortable: false,
      renderCell: (params) => <div title={params.row.userAgent}>{params.row.userAgent}</div>,
    },
  ];

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableSelectionOnClick
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      rowCount={totalElements}
      page={page}
      pageSize={pageSize}
      paginationMode="server"
      loading={loading}
      pagination
      classes={{ root: classes.trafficLogsTable }}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
    />
  );
}

export default TrafficLogsTable;
