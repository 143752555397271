import axios from '../axios';

import {
  ICompleteCheckoutRequest,
  ICompleteCheckoutResponse,
  ICoupon,
  ICouponApply,
} from '../interfaces/checkout';
import { PaymentTypes } from '../interfaces/services';

export function completeCheckout(requestBody: ICompleteCheckoutRequest) {
  return axios.post<ICompleteCheckoutResponse>('/checkout/complete', requestBody);
}

export function applyCoupon(requestBody: ICouponApply) {
  return axios.post<ICoupon>('/checkout/coupon/apply', requestBody);
}

export function getAppliedCoupon(serviceId: string, couponCode: string, paymentType: PaymentTypes) {
  return axios.get<ICoupon>('/checkout/coupon/apply/success', {
    params: { serviceId, couponCode, paymentType },
  });
}
