import { AxiosError } from 'axios';
import { useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams,
} from '@material-ui/data-grid';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ConfirmDeleteDialog from '../shared/ConfirmDeleteDialog';

import { getFormattedDate } from '../../utils/utils';
import { deleteAnnouncement } from '../../requests/announcements';
import { useSnackbar } from '../../hooks/use-snackbar';
import useStyles from './styles';

interface DeleteDialogData {
  open: boolean;
  isLoading: boolean;
  announcementId: string;
}

const initialDialogData: DeleteDialogData = {
  open: false,
  isLoading: false,
  announcementId: '',
};

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  totalElements: number;
  loading: boolean;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  onRowClick(params: GridRowParams): void;
  fetchAnnouncements(): void;
}

function AnnouncementsTable(props: IProps) {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogData, setDialogData] = useState<DeleteDialogData>(initialDialogData);
  const {
    page,
    pageSize,
    rows,
    totalElements,
    loading,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    fetchAnnouncements,
  } = props;

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Title', flex: 1, sortable: false },
    {
      field: 'visible',
      headerName: 'Visible',
      flex: 1,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) =>
        (params.getValue(params.id, 'visible') as boolean) ? 'Yes' : 'No',
    },
    {
      field: 'createdTime',
      headerName: 'Created At',
      flex: 1,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) =>
        getFormattedDate(params.getValue(params.id, 'createdTime') as string),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <IconButton
          size="small"
          onClick={(event) =>
            handleOptionsClick(event, params.getValue(params.id, '_id') as string)
          }
        >
          <MoreIcon fontSize="small" />
        </IconButton>
      ),
    },
  ];

  function handleOptionsClick(event: React.MouseEvent<HTMLElement>, id: string) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setDialogData({
      ...initialDialogData,
      announcementId: id,
    });
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleDialogClose() {
    setDialogData(initialDialogData);
  }

  function handleDeleteAnnouncementClick() {
    handleMenuClose();
    setDialogData({ ...dialogData, open: true });
  }

  function handleDeleteAnnouncement() {
    setDialogData({ ...dialogData, isLoading: true });
    deleteAnnouncement(dialogData.announcementId)
      .then(() => {
        showSnackbar({ severity: 'success', message: 'Announcement deleted successfully' });
        handleDialogClose();
        fetchAnnouncements();
      })
      .catch((error: AxiosError) => {
        setDialogData({ ...dialogData, isLoading: false });
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        rowCount={totalElements}
        page={page}
        pageSize={pageSize}
        paginationMode="server"
        loading={loading}
        pagination
        classes={{ root: classes.announcementsTable, row: classes.cursorPointer }}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onRowClick={onRowClick}
      />
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleDeleteAnnouncementClick()}>Delete</MenuItem>
      </Menu>
      <ConfirmDeleteDialog
        open={dialogData.open}
        isLoading={dialogData.isLoading}
        onClose={handleDialogClose}
        onConfirm={handleDeleteAnnouncement}
      />
    </>
  );
}

export default AnnouncementsTable;
