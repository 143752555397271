import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createTheme } from '@material-ui/core/styles';

interface ButtonComponent {
  component?: any;
  download?: boolean;
  to?: string;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF6329',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: ['"Poppins"', 'sans-serif'].join(', '),
        textTransform: 'unset',
      },
      containedPrimary: {
        color: '#FFFFFF',
      },
      contained: {
        boxShadow: 'none',
      },
    },
  },
});

function Button(props: ButtonProps & ButtonComponent) {
  return (
    <ThemeProvider theme={theme}>
      <MuiButton {...props}>{props.children}</MuiButton>
    </ThemeProvider>
  );
}

export default Button;
