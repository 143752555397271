import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import EditOutlined from '@material-ui/icons/EditOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import TextField from '../shared/Inputs/TextField';
import Button from '../shared/Inputs/Button';

import { updateUserDetails, updateProfilePicture } from '../../requests/auth';
import { getCountries } from '../../requests/common';
import { REGEX } from '../../constants';
import { useAuth } from '../../hooks/use-auth';
import { useSnackbar } from '../../hooks/use-snackbar';
import useStyles from './styles';
import { IUser } from '../../interfaces/user';
import { Countries } from '../../interfaces/shared';

function Profile() {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const { user, fetchUserDetails } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [countries, setCountries] = useState<Countries>([]);
  const [profilePictureUrl, setProfilePictureUrl] = useState(user?.photoUrl || '');

  function fetchCountries() {
    getCountries().then((res) => {
      setCountries(res.data);
    });
  }

  useEffect(() => {
    fetchCountries();
  }, []);

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<IUser>({ defaultValues: getDefaultValues() });

  useEffect(() => {
    if (user) {
      reset(getDefaultValues());
    }
    // eslint-disable-next-line
  }, [user, reset]);

  function getDefaultValues(): IUser {
    return {
      email: user?.email || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      password: '',
      phoneNo: user?.phoneNo || '',
      address: user?.address || '',
      city: user?.city || '',
      country: user?.country || '',
      state: user?.state || '',
      zipCode: user?.zipCode || '',
    } as IUser;
  }

  const onSubmit: SubmitHandler<IUser> = async (data) => {
    try {
      await updateUserDetails({ ...data, photoUrl: profilePictureUrl });
      showSnackbar({ severity: 'success', message: 'Profile updated successfully' });
      fetchUserDetails();
    } catch (err) {
      const errorMessage = err?.response?.data?.message || 'An error occurred. Please try again.';
      showSnackbar({ severity: 'error', message: errorMessage });
    }
  };

  function onFileChange(files: FileList | null) {
    if (files?.length) {
      const file = files[0];
      const formData = new FormData();
      formData.append('file', file);
      updateProfilePicture(formData)
        .then((res) => {
          setProfilePictureUrl(res.data.url || '');
        })
        .catch((err: AxiosError) => {
          const errorMessage =
            err?.response?.data?.message || 'An error occurred. Please try again.';
          showSnackbar({ severity: 'error', message: errorMessage });
        });
    }
  }

  return (
    <Container maxWidth="md">
      <div className={classes.title}>Edit your details</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems="center" className={classes.container}>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.textField}
              fullWidth
              label="Email"
              variant="outlined"
              InputProps={{ readOnly: true }}
              value={user?.email || ''}
            />
            <Controller
              name="password"
              control={control}
              rules={{ pattern: REGEX.PASSWORD }}
              render={({ field: { ref, ...rest } }) => {
                const hasError = Boolean(errors.password);
                return (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    label="Password"
                    variant="outlined"
                    inputProps={{ maxLength: 32 }}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    className={classes.textField}
                    error={hasError}
                    helperText={
                      <span>
                        Use 8 to 32 characters, one digit, one symbol (!@#$%^&amp;*), one lowercase
                        and uppercase letter
                      </span>
                    }
                  />
                );
              }}
            />
            <Controller
              name="firstName"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...rest } }) => {
                const hasError = Boolean(errors.firstName);
                const hasRequiredError = errors?.firstName?.type === 'required';
                return (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    type="text"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    error={hasError}
                    {...(hasError && {
                      helperText: <span>{hasRequiredError && 'First name is required'}</span>,
                    })}
                  />
                );
              }}
            />
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...rest } }) => {
                const hasError = Boolean(errors.lastName);
                const hasRequiredError = errors?.lastName?.type === 'required';
                return (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    type="text"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    error={hasError}
                    {...(hasError && {
                      helperText: <span>{hasRequiredError && 'Last name is required'}</span>,
                    })}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.avatarContainer}>
              <Avatar
                className={classes.avatar}
                alt={user?.firstName || ''}
                src={profilePictureUrl}
              />
              <div className={classes.avatarEditButtonContainer}>
                <input
                  accept="image/jpeg"
                  className={classes.uploadInput}
                  id="profile-picture-edit"
                  type="file"
                  onChange={(event) => onFileChange(event.target.files)}
                />
                <label htmlFor="profile-picture-edit">
                  <IconButton size="small" className={classes.avatarEditButton} component="span">
                    <EditOutlined fontSize="small" />
                  </IconButton>
                </label>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <div className={classes.billingTitle}>Billing address</div>
          <Grid item xs={12}>
            <div className={classes.textFields}>
              <Controller
                name="address"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...rest } }) => {
                  const hasError = Boolean(errors.address);
                  const hasRequiredError = errors?.address?.type === 'required';
                  return (
                    <TextField
                      {...rest}
                      inputRef={ref}
                      type="text"
                      label="Address"
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                      error={hasError}
                      {...(hasError && {
                        helperText: <span>{hasRequiredError && 'Address is required'}</span>,
                      })}
                    />
                  );
                }}
              />
              <Controller
                name="city"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...rest } }) => {
                  const hasError = Boolean(errors.city);
                  const hasRequiredError = errors?.city?.type === 'required';
                  return (
                    <TextField
                      {...rest}
                      inputRef={ref}
                      type="text"
                      label="City"
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                      error={hasError}
                      {...(hasError && {
                        helperText: <span>{hasRequiredError && 'City is required'}</span>,
                      })}
                    />
                  );
                }}
              />
            </div>
            <div className={classes.textFields}>
              <Controller
                name="country"
                control={control}
                rules={{ required: true }}
                render={({ field: { name, value, ...rest } }) => {
                  const hasError = Boolean(errors.country);
                  const hasRequiredError = errors?.country?.type === 'required';
                  return (
                    <FormControl
                      variant="outlined"
                      error={hasError}
                      className={classes.textField}
                      fullWidth
                    >
                      <InputLabel htmlFor="outlined-country-native-simple">Country</InputLabel>
                      <Select
                        {...rest}
                        labelId="outlined-country-native-simple"
                        label="Country"
                        value={value}
                      >
                        <MenuItem value=""></MenuItem>
                        {countries.map((countryName) => (
                          <MenuItem key={countryName} value={countryName}>
                            {countryName}
                          </MenuItem>
                        ))}
                      </Select>
                      {hasRequiredError && <FormHelperText>Country is required</FormHelperText>}
                    </FormControl>
                  );
                }}
              />
              <Controller
                name="state"
                control={control}
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <TextField
                      {...rest}
                      inputRef={ref}
                      type="text"
                      label="State/Province/Region"
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                    />
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="zipCode"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...rest } }) => {
                const hasError = Boolean(errors.zipCode);
                const hasRequiredError = errors?.zipCode?.type === 'required';
                return (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    type="text"
                    label="Postal/Zip Code"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    error={hasError}
                    {...(hasError && {
                      helperText: <span>{hasRequiredError && 'Postal/Zip Code is required'}</span>,
                    })}
                  />
                );
              }}
            />
            <Controller
              name="phoneNo"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...rest } }) => {
                const hasError = Boolean(errors.phoneNo);
                const hasRequiredError = errors?.phoneNo?.type === 'required';
                return (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    type="text"
                    label="Phone"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    error={hasError}
                    {...(hasError && {
                      helperText: <span>{hasRequiredError && 'Phone is required'}</span>,
                    })}
                  />
                );
              }}
            />
            <div className={classes.saveBtn}>
              <Button color="primary" type="submit" variant="contained">
                Save Changes
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default Profile;
