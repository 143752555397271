import { useState } from 'react';
import { AxiosError } from 'axios';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';

import AddEditPackage from './AddEditPackage';
import ConfirmDeleteDialog from '../shared/ConfirmDeleteDialog';
import Button from '../shared/Inputs/Button';
import useStyles from './styles';
import { useSnackbar } from '../../hooks/use-snackbar';
import { Package, PackageForm, IAddEditPackage } from '../../interfaces/services';
import { createUpdatePackage, deletePackage } from '../../requests/service';

interface PackagesProps {
  packages: Package[];
  serviceId: string;
  fetchPackages(): void;
}

interface DeleteDialogData {
  open: boolean;
  isLoading: boolean;
  packageId: string;
}

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: '#E4EAF1',
      color: '#172B70',
      fontWeight: 600,
      fontSize: 14,
    },
    body: {
      fontSize: 12,
      color: '#384F7A',
    },
  })
)(TableCell);

const initialPackageData: PackageForm = {
  name: '',
  description: '',
  price: 1,
  addOnItems: [],
  discountItems: [],
};
const initialPackageDialogData: IAddEditPackage = {
  open: false,
  isLoading: false,
  mode: 'add',
  packageId: '',
  data: initialPackageData,
};
const initialDeleteDialogData: DeleteDialogData = {
  open: false,
  isLoading: false,
  packageId: '',
};

function Packages(props: PackagesProps) {
  const { packages, serviceId, fetchPackages } = props;
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const [dialogData, setDialogData] = useState<IAddEditPackage>(initialPackageDialogData);
  const [deleteDialogData, setDeleteDialogData] =
    useState<DeleteDialogData>(initialDeleteDialogData);

  function handleAddEditPackage(mode: 'add' | 'edit', data: Package) {
    setDialogData({
      open: true,
      isLoading: false,
      mode,
      packageId: data.serviceOptionId || '',
      data: {
        name: data.name || '',
        description: data.description || '',
        price: data.price || 1,
        addOnItems: data.addOnItems || [],
        discountItems: data.discountItems || [],
      },
    });
  }

  function handleDeletePackageClick(data: Package) {
    setDeleteDialogData({
      open: true,
      isLoading: false,
      packageId: data.serviceOptionId || '',
    });
  }

  function handleDialogClose() {
    setDialogData(initialPackageDialogData);
    setDeleteDialogData(initialDeleteDialogData);
  }

  function handleSavePackage(data: PackageForm) {
    setDialogData({ ...dialogData, isLoading: true });
    const mode = dialogData.mode;

    data.serviceId = serviceId;
    if (mode === 'edit') {
      data.serviceOptionId = dialogData.packageId;
    }
    data.addOnItems = data.addOnItems.map((item) => ({ ...item, price: +item.price }));
    data.discountItems = data.discountItems.map((item) => ({
      count: +item.count,
      percent: +item.percent,
    }));

    createUpdatePackage(data)
      .then((res) => {
        showSnackbar({ severity: 'success', message: `Package ${mode}ed successfully` });
        handleDialogClose();
        fetchPackages();
      })
      .catch((error: AxiosError) => {
        setDialogData({ ...dialogData, isLoading: false });
        showSnackbar({ severity: 'error', message: 'An error occurred. Please try again.' });
      });
  }

  function handleDeletePackage() {
    setDeleteDialogData({ ...deleteDialogData, isLoading: true });
    deletePackage(deleteDialogData.packageId, serviceId)
      .then((res) => {
        showSnackbar({ severity: 'success', message: 'Package deleted successfully' });
        handleDialogClose();
        fetchPackages();
      })
      .catch((error: AxiosError) => {
        setDeleteDialogData({ ...deleteDialogData, isLoading: false });
        showSnackbar({ severity: 'error', message: 'An error occurred. Please try again.' });
      });
  }

  return (
    <>
      <div className={classes.packageTitleContainer}>
        <div className={classes.packageTitle}>Add Package Details</div>
        <div>
          <Button
            startIcon={<AddIcon />}
            color="primary"
            variant="contained"
            onClick={() => handleAddEditPackage('add', {} as Package)}
          >
            Add
          </Button>
        </div>
      </div>
      <TableContainer
        component={Paper}
        elevation={0}
        variant="outlined"
        className={classes.tableBorder}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Domain Authority Name</StyledTableCell>
              <StyledTableCell className={classes.descriptionColumn}>Description</StyledTableCell>
              <StyledTableCell>Price</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!packages.length ? (
              <TableRow>
                <StyledTableCell colSpan={4} align="center">
                  No packages found
                </StyledTableCell>
              </TableRow>
            ) : (
              packages.map((row) => (
                <TableRow key={row.serviceOptionId}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell>{row.description}</StyledTableCell>
                  <StyledTableCell>${row.price}</StyledTableCell>
                  <TableCell align="center">
                    <div className={classes.packageActions}>
                      <IconButton onClick={() => handleAddEditPackage('edit', row)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeletePackageClick(row)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AddEditPackage
        open={dialogData.open}
        mode={dialogData.mode}
        isLoading={dialogData.isLoading}
        data={dialogData.data}
        onSubmit={handleSavePackage}
        onClose={handleDialogClose}
      />
      <ConfirmDeleteDialog
        open={deleteDialogData.open}
        isLoading={deleteDialogData.isLoading}
        onClose={handleDialogClose}
        onConfirm={handleDeletePackage}
      />
    </>
  );
}

export default Packages;
