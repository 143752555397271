import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import StartUpLoader from '../shared/StartUpLoader';

import { useRouter } from '../../hooks/use-router';
import { getParsedUserAgent } from '../../utils/utils';
import { trackAffiliate } from '../../requests/affiliates';
import { REGEX, STAN_VENTURES_BASE_URL } from '../../constants';

function Referral() {
  const { referralId } = useParams<{ referralId: string }>();
  const router = useRouter();
  const path = (router.query.p as string) || '/';
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const parsedUserAgent = getParsedUserAgent();
    const referrer = document.referrer;
    setIsLoading(true);
    trackAffiliate({
      affiliateCode: referralId,
      referrerUrl: referrer,
      userAgent: parsedUserAgent.ua,
    }).finally(() => {
      setIsLoading(false);
      const decodedPath = decodeURIComponent(path);
      const redirectUrl = `${STAN_VENTURES_BASE_URL}${decodedPath}`;
      if (REGEX.URL.test(redirectUrl)) {
        window.location.href = redirectUrl;
      } else {
        window.location.href = STAN_VENTURES_BASE_URL;
      }
    });
  }, [path, referralId, router.history]);

  return isLoading ? <StartUpLoader /> : null;
}

export default Referral;
