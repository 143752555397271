import useStyles from './styles';
import { isAdmin } from '../../utils/utils';
import { useAuth } from '../../hooks/use-auth';
import Button from '../shared/Inputs/Button';
import { Link, useParams } from 'react-router-dom';
import AddEditCreatives from './AddEditCreatives';
import { useEffect, useState } from 'react';
import { getCreatives } from '../../requests/creatives';
import { GridRowParams, GridRowsProp } from '@material-ui/data-grid';
import { AxiosError } from 'axios';
import { useSnackbar } from '../../hooks/use-snackbar';
import { IDateFilter, IFilter, SearchParams } from '../../interfaces/shared';
import Filters from './Filters';
import { IFilterState } from '../../interfaces/creatives';
import CreativesTable from './CreativesTable';
import { useRouter } from '../../hooks/use-router';

interface ICreativesState {
  content: GridRowsProp;
  totalElements: number;
}

const initialFilters: IFilterState = {
  name: '',
  status: 'ALL',
  date: { key: '', from: '', to: '' },
};

function Creatives() {
  const classes = useStyles();
  const { user } = useAuth();
  const router = useRouter();
  const { showSnackbar } = useSnackbar();
  const params = useParams<{ creativesId: string }>();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IFilterState>(initialFilters);
  const [{ page, pageSize, filterFields }, setSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    filterFields: [],
  });
  const [creatives, setCreatives] = useState<ICreativesState>({
    content: [],
    totalElements: 0,
  });

  useEffect(() => {
    fetchCreatives();

    // eslint-disable-next-line
  }, [page, pageSize, filterFields]);

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize, filterFields });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize, filterFields });
  }
  function getCreativesId() {
    const creativesId = params.creativesId;
    if (!creativesId) return 'invalid_announcement_id';
    if (creativesId === 'new') return 'new';
    return atob(creativesId);
  }

  function fetchCreatives() {
    setLoading(true);
    getCreatives({
      page,
      pageSize,
      ...(filterFields?.length && { filterFields }),
    })
      .then((res) => {
        setLoading(false);
        setCreatives({
          content: res.data.content.map((creative) => {
            creative.id = creative._id;
            return creative;
          }),
          totalElements: res.data.totalElements,
        });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleApplyFilters() {
    const newFilters: IFilter[] = [];
    Object.entries(filters).forEach(([filterKey, filterValue]) => {
      if (filterKey === 'status') {
        if (filterValue === 'ALL') {
          return;
        }
        newFilters.push({
          key: 'creativeStatus',
          searchOperation: 'eq',
          value: filterValue,
        });
        return;
      }
      if (filterKey === 'date') {
        const { from, to } = filterValue as IDateFilter;
        const fromValue = from ? `${from}T00:00:00.000Z` : '';
        const toValue = to ? `${to}T23:59:59.999Z` : '';
        if (fromValue && toValue) {
          newFilters.push({
            key: 'validity',
            searchOperation: 'btn',
            value: fromValue,
            toValue,
          });
        }
        return;
      }
      if (filterValue) {
        newFilters.push({
          key: filterKey,
          searchOperation: 'like',
          value: filterValue,
        });
      }
    });
    setCreatives({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: newFilters });
  }
  function handleClearFilters() {
    setFilters(initialFilters);
    setCreatives({ content: [], totalElements: 0 });
    setSearchParams({
      page: 0,
      pageSize,
      filterFields: [],
    });
  }

  function handleRowClick(params: GridRowParams) {
    router.history.push(`/creatives/${btoa(params.row.id)}`);
  }

  return (
    <>
      {!params.creativesId ? (
        <>
          <div className={classes.titleContainer}>
            <p className={classes.title}>Creatives</p>
            {isAdmin(user) && (
              <div>
                <Button color="primary" variant="contained" component={Link} to="/creatives/new">
                  Create
                </Button>
              </div>
            )}
          </div>
          <Filters
            filters={filters}
            setFilters={setFilters}
            onApplyFilters={handleApplyFilters}
            onClearFilters={handleClearFilters}
          />
          <CreativesTable
            page={page}
            pageSize={pageSize}
            rows={creatives.content}
            totalElements={creatives.totalElements}
            loading={loading}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onRowClick={handleRowClick}
            fetchCreatives={fetchCreatives}
          />
        </>
      ) : (
        <AddEditCreatives creativeId={getCreativesId()} fetchCreatives={fetchCreatives} />
      )}
    </>
  );
}
export default Creatives;
