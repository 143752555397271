import axios from '../axios';

import { EPrivileges, IAddEditAdmin } from '../interfaces/team';
import {
  AdminDashboardData,
  AgentReport,
  ClientComparisonReport,
  ClientReport,
} from '../interfaces/admin';
import { IAssignedUser } from '../interfaces/shared';

export function getAdmins() {
  return axios.post('/admin/find', { page: 0, pageSize: 100 });
}

export function getPrivileges() {
  return axios.get<EPrivileges[]>('/admin/privileges');
}

export function createUpdateAdmin({
  mode,
  requestBody,
}: {
  mode: 'add' | 'edit';
  requestBody: IAddEditAdmin;
}) {
  return axios.post(`/admin/${mode === 'add' ? 'create' : 'update'}`, requestBody);
}

export function deleteAdmin(email: string) {
  return axios.post('/admin/delete', { email });
}

export function getAssignedUserList() {
  return axios.get<IAssignedUser[]>('/admin/dropDown');
}

export function getAdminDashboardData(start: string, end: string) {
  return axios.get<AdminDashboardData>('/admin/dashboard', {
    params: { fromDate: start, toDate: end },
  });
}

export function getAgentsReportData(start: string, end: string) {
  return axios.get<AgentReport[]>('/admin/report/agents', {
    params: { fromDate: start, toDate: end },
  });
}

export function exportAgentsReportData(start: string, end: string) {
  return axios.get('/admin/report/agents/export', {
    params: { fromDate: start, toDate: end },
    responseType: 'blob',
  });
}

export function getClientsReportData(start: string, end: string) {
  return axios.get<ClientReport[]>('/admin/report/clients', {
    params: { fromDate: start, toDate: end },
  });
}

export function exportClientsReportData(start: string, end: string) {
  return axios.get('/admin/report/clients/export', {
    params: { fromDate: start, toDate: end },
    responseType: 'blob',
  });
}

export function getNewClientsReportData(start: string, end: string) {
  return axios.get<ClientReport[]>('/admin/report/newClients', {
    params: { fromDate: start, toDate: end },
  });
}

export function exportNewClientsReportData(start: string, end: string) {
  return axios.get('/admin/report/newClients/export', {
    params: { fromDate: start, toDate: end },
    responseType: 'blob',
  });
}

export function getClientsComparisonReportData(
  start1: string,
  end1: string,
  start2: string,
  end2: string
) {
  return axios.get<ClientComparisonReport[]>('/admin/report/compare', {
    params: { fromDate: start1, toDate: end1, fromDate1: start2, toDate1: end2 },
  });
}
