import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import capitalize from 'lodash/capitalize';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import LinearProgress from '@material-ui/core/LinearProgress';

import ProjectDetails from './ProjectDetails';
import OrderTimeline from './OrderTimeline';
import Reports from './Reports';
import History from './History';
import Messages from './Messages';
import PageTitleWithBack from '../shared/PageTitleWithBack';
import StatusHeader from './StatusHeader';
import Button from '../shared/Inputs/Button';
import LoadingButton from '../shared/Inputs/LoadingButton';
import TextField from '../shared/Inputs/TextField';

import {
  Invoice,
  OrderData,
  OrderHistory,
  OrderInfo,
  OrderReport,
  OrderStatus,
} from '../../interfaces/order';
import { IAssignedUser } from '../../interfaces/shared';
import {
  getInvoice,
  getOrderData,
  getOrderHistory,
  getOrderInfo,
  getOrderReport,
  updateOrderData,
} from '../../requests/order';
import { IProjectDetailsForm, IProjectPackageData } from '../../interfaces/checkout';
import { CompleteService } from '../../interfaces/services';
import { getCompleteServiceDetails } from '../../requests/service';
import { useRouter } from '../../hooks/use-router';
import { getFormattedDate, isAdmin, isUser, MUI_THEME } from '../../utils/utils';
import { useSnackbar } from '../../hooks/use-snackbar';
import { useAuth } from '../../hooks/use-auth';
import useStyles from './styles';
import { ORDER_STATUS_MAP } from '../../constants';
import { generateInvoice } from './utils';
import { IconButton, TextareaAutosize } from '@material-ui/core';
import { uploadFile } from '../../requests/common';

interface IProps {
  orderId: string;
  assignedUserOptions: IAssignedUser[];
}

function OrderDetails(props: IProps) {
  const { orderId, assignedUserOptions } = props;
  const classes = useStyles();
  const router = useRouter();
  const { showSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [currentTabIndex, setcurrentTabIndex] = useState(
    getInitialActiveTabIndexFromLocationState()
  );
  const [orderInfo, setOrderInfo] = useState<OrderInfo>({} as OrderInfo);
  const [orderData, setOrderData] = useState<OrderData>({} as OrderData);
  const [orderReport, setOrderReport] = useState<OrderReport>({} as OrderReport);
  const [orderHistory, setOrderHistory] = useState<OrderHistory>({} as OrderHistory);
  const [service, setService] = useState({} as CompleteService);
  const [invoice, setInvoice] = useState({} as Invoice);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [projectDetails, setProjectDetails] = useState<IProjectDetailsForm>({
    fileUrl: '',
    manualEntries: [],
  });
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getOrderInfo(orderId).then((res) => {
      setOrderInfo(res.data);
      getCompleteServiceDetails(res.data.serviceId).then((res) => setService(res.data));
      if (res.data.invoiceId) {
        getInvoice(res.data.invoiceId).then((res) => setInvoice(res.data));
      }
    });
    fetchOrderData(orderId);
    fetchOrderReport(orderId);
    getOrderHistory(orderId).then((res) => setOrderHistory(res.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    if (Object.keys(invoice).length && Object.keys(orderData).length) {
      const projectPackageData: IProjectPackageData[] = [];
      invoice.invoiceItems.forEach((invoiceItem) => {
        const packageData = orderData.projectPackageData?.find(
          (packageData) => packageData.packageId === invoiceItem.packageId
        );
        if (packageData) {
          projectPackageData.push(packageData);
        } else {
          projectPackageData.push({
            packageId: invoiceItem.packageId,
            packageName: invoiceItem.name,
            values: [],
          });
        }
      });
      setProjectDetails({
        fileUrl: orderData.fileUploadUrl || '',
        manualEntries: projectPackageData,
      });
    }
  }, [invoice, orderData]);

  function getInitialActiveTabIndexFromLocationState() {
    const state = router.location.state as { activeTabIndex: number };
    if (state && state.activeTabIndex !== undefined) {
      return state.activeTabIndex as number;
    }
    return 0;
  }

  function fetchOrderData(orderId: string) {
    getOrderData(orderId).then((res) => setOrderData(res.data));
  }

  function fetchOrderReport(orderId: string) {
    getOrderReport(orderId).then((res) => setOrderReport(res.data));
  }

  function handleBack() {
    router.history.replace('/orders');
  }

  function handleImageChange(files: FileList | null) {
    if (!files?.length) return;
    const formData = new FormData();
    formData.append('file', files[0]);
    setIsImageUploading(true);
    uploadFile(formData)
      .then((res) => {
        setIsImageUploading(false);
        const url = res.data.url;
        handleFileUploadUrlChange(url);
      })
      .catch((error: AxiosError) => {
        setIsImageUploading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function getFormattedOrderInfo(orderInfo: OrderInfo) {
    const isManual = orderInfo.paymentType === 'MANUAL';
    const isSubscription = orderInfo.paymentType === 'SUBSCRIPTION';
    return {
      ...(isUser(user) && { Status: ORDER_STATUS_MAP[orderData.status] || orderData.status }),
      ...(isAdmin(user) && {
        Client: (
          <Link to={`/clients/${btoa(orderInfo.clientId)}`} className={classes.link}>
            {orderInfo.clientName}
          </Link>
        ),
      }),
      'Payment Type': capitalize(orderInfo.paymentType),
      ...(isSubscription && { Subscription: 'Active' }),
      ...(!isManual && {
        [isSubscription ? 'Subscription ID' : 'Payment ID']: orderInfo.stripePaymentId || 'N/A',
      }),
      Amount: `$${orderInfo.totalAmount === undefined ? 'N/A' : orderInfo.totalAmount}`,
      ...(orderInfo.invoiceId && {
        Invoice: (
          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={isEmpty(service) || isEmpty(invoice)}
            startIcon={<VisibilityIcon />}
            onClick={() => generateInvoice(service, invoice)}
          >
            {orderInfo.invoiceId || ''}
          </Button>
        ),
      }),
      Created: getFormattedDate(orderInfo.createdDate),
      ...(orderInfo.startedDate && { Started: getFormattedDate(orderInfo.startedDate) }),
      ...(orderInfo.paidDate && { Paid: getFormattedDate(orderInfo.paidDate) }),
      Service: (
        <Link
          to={`/services/${btoa(orderInfo.serviceId)}${isUser(user) ? '/details' : ''}`}
          className={classes.link}
        >
          {orderInfo.serviceName}
        </Link>
      ),
    };
  }

  function handleSave(orderData: OrderData) {
    const requestBody: OrderData = {
      ...orderData,
      projectPackageData: projectDetails.manualEntries.map((p) => ({
        ...p,
        values: p.values.map((v) => {
          const { id, ...rest } = v;
          const entryId = id || '';
          return {
            ...rest,
            ...(entryId.length > 15 && { id: entryId }),
            items: v.items.map((item) => {
              const { id, ...rest } = item;
              const itemId = id || '';
              return {
                ...rest,
                ...(itemId.length > 15 && { id: itemId }),
              };
            }),
          };
        }),
      })),
    };
    setIsLoading(true);
    updateOrderData(requestBody)
      .then((res) => {
        setOpen(false);
        setIsLoading(false);

        const response = res.data;
        setOrderData(response);
        setProjectDetails({
          fileUrl: response.fileUploadUrl || '',
          manualEntries: response.projectPackageData || [],
        });
        showSnackbar({ severity: 'success', message: 'Order updated successfully' });
        getOrderHistory(orderId).then((res) => setOrderHistory(res.data));
        fetchOrderReport(orderId);
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleStatusChange(status: OrderStatus) {
    if (status === 'Cancelled') {
      return setOpen(true);
    }
    setOrderData({ ...orderData, status });
  }

  function handleCommentChange(comment: string) {
    setOrderData({ ...orderData, comment });
  }

  function handleFileUploadUrlChange(fileUploadUrl: string) {
    setOrderData({ ...orderData, fileUploadUrl });
  }

  function handleOrderNotes(orderNotes: string) {
    setOrderData({ ...orderData, orderNotes });
  }

  function handleAssigneeChange(assignee: IAssignedUser | null) {
    let assignedToAdminId: string | null = null;
    let assignedToAdminName: string | null = null;
    if (assignee) {
      assignedToAdminId = assignee.id;
      assignedToAdminName = assignee.name;
    }
    setOrderData({
      ...orderData,
      assignedToAdminId,
      assignedToAdminName,
    });
  }

  function handleSubStatusChange(subStatusIndex: number) {
    const selectedSubStatus = orderData.orderSubStatuses[subStatusIndex];
    setOrderData({
      ...orderData,
      orderSubStatuses: orderData.orderSubStatuses.map((status, index) => ({
        ...status,
        completed: selectedSubStatus.completed ? index < subStatusIndex : index <= subStatusIndex,
      })),
    });
  }

  function getTabComponent(tabIndex: number) {
    switch (tabIndex) {
      case 0:
        return (
          <>
            {isAdmin(user) && (
              <StatusHeader
                status={orderData.status}
                subStatuses={orderData.orderSubStatuses || []}
                assignedTo={orderData.assignedToAdminId}
                assignedUserOptions={assignedUserOptions}
                handleStatusChange={handleStatusChange}
                handleAssigneeChange={handleAssigneeChange}
                handleSubStatusChange={handleSubStatusChange}
              />
            )}
            <ProjectDetails
              orderData={orderData}
              projectDetails={projectDetails}
              setProjectDetails={setProjectDetails}
              service={service}
              invoice={invoice}
            />
            <div className={classes.formField}>
              <div className={classes.orderNotesTitle}>Order Detail Notes</div>
              <div>
                <TextareaAutosize
                  className={classes.orderNotes}
                  style={{ height: '100px' }}
                  placeholder="Enter Order Notes here"
                  value={orderData.orderNotes || ''}
                  onChange={(event) => handleOrderNotes(event.target.value)}
                ></TextareaAutosize>
              </div>

              <div style={{ marginTop: '15px' }}>
                {!orderData.fileUploadUrl ? (
                  <>
                    <input
                      accept=".doc,.docx,.xls,.xlsx,.pdf"
                      className={classes.uploadInput}
                      id="creative-image-upload-button"
                      type="file"
                      onChange={(event) => handleImageChange(event.target.files)}
                      name="fileUploadUrl" // Add name prop here
                    />
                    <label htmlFor="creative-image-upload-button">
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        startIcon={<PublishIcon />}
                        component="span"
                        disabled={isImageUploading}
                      >
                        Upload Order Details
                      </Button>
                      {isImageUploading && (
                        <LinearProgress color="primary" variant="indeterminate" />
                      )}
                    </label>
                  </>
                ) : (
                  <div className={classes.uploadedImageContainer}>
                    <p className={classes.imageName}>
                      {orderData.fileUploadUrl.split('/').slice(-1).join('')}
                    </p>
                    <div>
                      <IconButton
                        size="small"
                        className={classes.viewDeleteIconButton}
                        onClick={() => window.open(orderData.fileUploadUrl, '_blank')}
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        className={classes.viewDeleteIconButton}
                        onClick={() => handleFileUploadUrlChange('')}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {(isAdmin(user) || projectDetails.manualEntries.length > 0) && (
              <div className={classes.saveButtonContainer}>
                <Button color="primary" variant="contained" onClick={() => handleSave(orderData)}>
                  Save
                </Button>
              </div>
            )}
          </>
        );
      case 1:
        return (
          <Reports
            user={user}
            orderReport={orderReport}
            setOrderReport={setOrderReport}
            fetchOrderData={fetchOrderData}
          />
        );
      case 2:
        return <History orderHistory={orderHistory} />;

      default:
        return <div>Unknown Tab</div>;
    }
  }

  return (
    <>
      <Container maxWidth="lg" disableGutters>
        <PageTitleWithBack
          title={`Order ${orderInfo.orderId ? `#${orderInfo.orderId}` : ''}`}
          disableGutters
          onBackClick={handleBack}
        />
        {(orderData.orderSubStatuses || []).length > 0 && (
          <OrderTimeline subStatuses={orderData.orderSubStatuses || []} />
        )}
        <Grid container spacing={2} className={classes.orderDetailsGrid}>
          <Grid item xs={12} md={8}>
            <ThemeProvider theme={MUI_THEME}>
              <Tabs
                value={currentTabIndex}
                onChange={(_, newTabIndex) => setcurrentTabIndex(newTabIndex)}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Order Details" />
                <Tab label="Reports" />
                {isAdmin(user) && <Tab label="History" />}
              </Tabs>
              <Paper className={classes.tabPaperRoot} elevation={0}>
                {getTabComponent(currentTabIndex)}
              </Paper>
              <div style={{ display: currentTabIndex === 0 ? 'unset' : 'none' }}>
                {orderInfo.orderId && (
                  <Messages
                    orderId={orderInfo.orderId}
                    isMigratedOrder={orderInfo.createdBy === 'Migration'}
                  />
                )}
              </div>
            </ThemeProvider>
          </Grid>
          <Grid item xs={12} md={4} component="aside">
            <div className={classes.orderInfoContainer}>
              <div className={classes.orderInfo}>
                <p className={classes.serviceName}>{orderInfo.serviceName}</p>
                {Object.entries(getFormattedOrderInfo(orderInfo)).map(([key, value], i) => (
                  <div key={i} className={classes.orderInfoItem}>
                    <div>{key}</div>
                    <div>{value}</div>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={() => setOpen(false)}>
        <DialogContent>
          <div className={classes.reasonForCancelation}>Reason for cancellation</div>
          <TextField
            fullWidth
            variant="outlined"
            multiline
            minRows={3}
            maxRows={3}
            placeholder="Enter your comments"
            value={orderData.comment || ''}
            onChange={(event) => handleCommentChange(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" variant="outlined">
            Close
          </Button>
          <LoadingButton
            onClick={() => handleSave({ ...orderData, status: 'Cancelled' })}
            color="primary"
            variant="contained"
            isLoading={isLoading}
            disabled={isLoading}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OrderDetails;
