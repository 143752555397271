import { Route, Switch } from 'react-router-dom';

import PrivateRoutes from './PrivateRoutes';
import SignIn from '../Auth/SignIn';
import SignUp from '../Auth/SignUp';
import ForgotPassword from '../Auth/ForgotPassword';
import ResetPassword from '../Auth/ResetPassword';
import MinimalAppLayout from '../shared/MinimalAppLayout';
import Referral from '../Referral/Referral';

function Routes() {
  return (
    <Switch>
      <Route exact path="/signin">
        <SignIn />
      </Route>
      <Route exact path="/signup">
        <SignUp />
      </Route>
      <Route exact path="/password/reset">
        <ForgotPassword />
      </Route>
      <Route exact path="/password/reset/:token">
        <ResetPassword />
      </Route>
      <Route exact path={['/services/:serviceId/checkout']}>
        <MinimalAppLayout />
      </Route>
      <Route exact path="/r/:referralId">
        <Referral />
      </Route>
      <PrivateRoutes />
      <Route path="">
        <div>404 | Page not found</div>
      </Route>
    </Switch>
  );
}

export default Routes;
