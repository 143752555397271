import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams,
} from '@material-ui/data-grid';

import useStyles from './styles';
import { getFormattedDate } from '../../utils/utils';

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  totalElements: number;
  loading: boolean;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  onRowClick(params: GridRowParams): void;
}

const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'First Name', flex: 1, sortable: false },
  { field: 'lastName', headerName: 'Last Name', flex: 1, sortable: false },
  { field: 'email', headerName: 'Email', flex: 1, sortable: false },
  {
    field: 'lastLogin',
    headerName: 'Last Login',
    flex: 1,
    sortable: false,
    valueFormatter: (params: GridValueFormatterParams) =>
      getFormattedDate(params.getValue(params.id, 'lastLogin') as string),
  },
];

function ClientsTable(props: IProps) {
  const classes = useStyles();
  const {
    page,
    pageSize,
    rows,
    totalElements,
    loading,
    onPageChange,
    onPageSizeChange,
    onRowClick,
  } = props;
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableSelectionOnClick
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      rowCount={totalElements}
      page={page}
      pageSize={pageSize}
      paginationMode="server"
      loading={loading}
      pagination
      classes={{ root: classes.clientsTable, row: classes.cursorPointer }}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onRowClick={onRowClick}
    />
  );
}

export default ClientsTable;
