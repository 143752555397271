import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridRowsProp,
  GridSortModel,
  GridValueFormatterParams,
} from '@material-ui/data-grid';
import { Link } from 'react-router-dom';

import useStyles from './styles';

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  sortModel: GridSortModel;
  totalElements: number;
  loading: boolean;
  isAdmin: boolean;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  onRowClick(params: GridRowParams): void;
  onSortModelChange(model: GridSortModel): void;
}

function OrdersTable(props: IProps) {
  const classes = useStyles();
  const {
    page,
    pageSize,
    sortModel,
    rows,
    totalElements,
    loading,
    isAdmin,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    onSortModelChange,
  } = props;

  const clientColumns: GridColDef[] = [
    { field: 'serviceName', headerName: 'Service Details', flex: 1, sortable: false },
    { field: 'orderId', headerName: 'Order ID', flex: 1, sortable: false },
    {
      field: 'totalAmount',
      headerName: 'Amount',
      flex: 1,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) =>
        `$${params.getValue(params.id, 'totalAmount')}`,
    },
    { field: 'createdTime', headerName: 'Created Date', flex: 1, sortable: true },
    { field: 'completedDate', headerName: 'Completed Date', flex: 1, sortable: false },
    { field: 'assignedToName', headerName: 'Assigned', flex: 1, sortable: false },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) =>
        `${params.getValue(params.id, 'status') || ''}`.split('_').join(' '),
      sortable: false,
    },
  ];

  const adminColumns: GridColDef[] = [
    {
      field: 'clientName',
      headerName: 'Client Name',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Link
          className={classes.linkBlue}
          to={`/clients/${btoa(params.row.clientId as string)}`}
          onClick={(event) => event.stopPropagation()}
        >
          {params.row.clientName}
        </Link>
      ),
    },
    ...clientColumns,
  ];

  return (
    <DataGrid
      rows={rows}
      columns={isAdmin ? adminColumns : clientColumns}
      sortModel={sortModel}
      disableColumnFilter
      disableColumnMenu
      disableSelectionOnClick
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      rowCount={totalElements}
      page={page}
      pageSize={pageSize}
      paginationMode="server"
      sortingMode="server"
      loading={loading}
      pagination
      classes={{ root: classes.ordersTable, row: classes.cursorPointer }}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onRowClick={onRowClick}
      onSortModelChange={onSortModelChange}
    />
  );
}

export default OrdersTable;
