import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import YoutubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import makeStyles from '@material-ui/core/styles/makeStyles';

import LogoWithTagline from '../../assets/img/logo-with-tagline.svg';

import { STAN_VENTURES_BASE_URL, FOOTER_CATEGORIES } from '../../constants';

const SOCIAL_MEDIAS = [
  { name: 'youtube', icon: <YoutubeIcon />, url: 'https://www.youtube.com/user/StanVentures' },
  {
    name: 'linkedin',
    icon: <LinkedInIcon />,
    url: 'https://www.linkedin.com/company/stanventures',
  },
  { name: 'facebook', icon: <FacebookIcon />, url: 'https://www.facebook.com/StanVentures' },
  { name: 'twitter', icon: <TwitterIcon />, url: 'https://twitter.com/stanventures' },
  { name: 'instagram', icon: <InstagramIcon />, url: 'https://www.instagram.com/stanventures' },
];

const useStyles = makeStyles((theme) => ({
  root: { backgroundColor: '#545454', padding: theme.spacing(4, 2, 2) },
  logoContainer: { padding: theme.spacing(2) },
  logo: { width: 150 },
  socialContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    '& > div': {
      width: 32,
      height: 32,
      backgroundColor: 'rgb(189 189 189 / 25%)',
      cursor: 'pointer',
      transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
      '-webkit-transition': 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
      '& > a': { textDecoration: 'none', color: 'inherit', display: 'flex' },
    },
    '& > div:hover': { backgroundColor: 'rgb(189 189 189 / 50%)', color: '#FFFFFF' },
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  listHeader: { color: '#FFFFFF', marginTop: 0 },
  listItem: {
    fontSize: '0.875rem',
    color: '#a8a8a8',
    marginBottom: '10px',
    display: 'block',
    textDecoration: 'none',
    lineHeight: 1.6,
    '&:hover': { color: '#FFFFFF' },
  },
  categoriesContainer: { padding: theme.spacing(2) },
  copyrightContainer: {
    borderTop: '1px solid #7e7e7e',
    padding: theme.spacing(2, 2, 0),
    marginTop: theme.spacing(2),
  },
  copyright: { margin: 0, color: '#FFFFFF' },
  termsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > a:not(:last-child)': { marginRight: theme.spacing(3) },
    [theme.breakpoints.down('sm')]: { justifyContent: 'start', marginTop: theme.spacing(1) },
  },
}));

function Footer() {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className={classes.root}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={4} md={4}>
            <div className={classes.logoContainer}>
              <img src={LogoWithTagline} alt="StanVentures" className={classes.logo} />
            </div>
            <div className={classes.socialContainer}>
              {SOCIAL_MEDIAS.map((social) => (
                <Avatar key={social.name} variant="rounded">
                  <a href={social.url}>{social.icon}</a>
                </Avatar>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <div className={classes.categoriesContainer}>
              <Grid container spacing={4}>
                {FOOTER_CATEGORIES.map((category) => (
                  <Grid key={category.title} item xs={12} sm={4}>
                    <h3 className={classes.listHeader}>{category.title}</h3>
                    {category.categories.map((c) => (
                      <a key={c.url} className={classes.listItem} href={c.url}>
                        {c.title}
                      </a>
                    ))}
                  </Grid>
                ))}
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div className={classes.copyrightContainer}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={8}>
              <h4 className={classes.copyright}>Copyright &copy; {currentYear} Stan Ventures</h4>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className={classes.termsContainer}>
                <a className={classes.listItem} href={`${STAN_VENTURES_BASE_URL}/privacy-policy`}>
                  Privacy
                </a>
                <a
                  className={classes.listItem}
                  href={`${STAN_VENTURES_BASE_URL}/terms-and-conditions`}
                >
                  Terms
                </a>
                <a className={classes.listItem} href={`${STAN_VENTURES_BASE_URL}/refund-policy`}>
                  Refund Policy
                </a>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
