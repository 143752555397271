import { useState } from 'react';
import { AxiosError } from 'axios';
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridValueFormatterParams,
} from '@material-ui/data-grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';

import ConfirmActionDialog from '../shared/ConfirmActionDialog';

import { useSnackbar } from '../../hooks/use-snackbar';
import { getFormattedDate } from '../../utils/utils';
import { cancelSubscription, chargeSubscription } from '../../requests/subscriptions';
import useStyles from './styles';
import { Link } from 'react-router-dom';

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  totalElements: number;
  loading: boolean;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  fetchSubscriptions(): void;
}

interface DialogData {
  open: boolean;
  isLoading: boolean;
  subscriptionId: string;
  operation: string;
  disableChargeNow: boolean;
  disableCancel: boolean;
}

const initialDialogData: DialogData = {
  open: false,
  isLoading: false,
  subscriptionId: '',
  operation: '',
  disableChargeNow: false,
  disableCancel: false,
};

function SubscriptionsTable(props: IProps) {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const {
    page,
    pageSize,
    rows,
    totalElements,
    loading,
    onPageChange,
    onPageSizeChange,
    fetchSubscriptions,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogData, setDialogData] = useState<DialogData>(initialDialogData);

  const columns: GridColDef[] = [
    {
      field: 'clientName',
      headerName: 'Client Name',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Link
          className={classes.linkBlue}
          to={`/clients/${btoa(params.getValue(params.id, 'clientId') as string)}`}
          onClick={(event) => event.stopPropagation()}
        >
          {params.getValue(params.id, 'clientName')}
        </Link>
      ),
    },
    { field: 'stripeId', headerName: 'Subscription ID', flex: 2, sortable: false },
    { field: 'initialOrderId', headerName: 'Order ID', flex: 1, sortable: false },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) =>
        `${params.getValue(params.id, 'status') || ''}`.split('_').join(' '),
      sortable: false,
    },
    { field: 'currentCycle', headerName: 'Total Orders', flex: 1, sortable: false },
    {
      field: 'nextPayment',
      headerName: 'Next Payment',
      flex: 1,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) =>
        getFormattedDate(params.getValue(params.id, 'nextPayment') as string),
    },
    {
      field: 'createdTime',
      headerName: 'Created On',
      flex: 1,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) =>
        getFormattedDate(params.getValue(params.id, 'createdTime') as string),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <IconButton
          size="small"
          onClick={(event) =>
            handleOptionsClick(
              event,
              params.getValue(params.id, 'id') as string,
              params.getValue(params.id, 'status') as string
            )
          }
        >
          <MoreIcon fontSize="small" />
        </IconButton>
      ),
    },
  ];

  function handleOptionsClick(event: React.MouseEvent<HTMLElement>, id: string, status: string) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setDialogData({
      ...initialDialogData,
      subscriptionId: id,
      disableChargeNow: ['cancelled', 'past_due'].includes((status || '').toLowerCase()),
      disableCancel: ['cancelled'].includes((status || '').toLowerCase()),
    });
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleDialogClose() {
    setDialogData(initialDialogData);
  }

  function handleMenuItemClick(operation: string) {
    handleMenuClose();
    setDialogData({ ...dialogData, operation, open: true });
  }

  function handleConfirm() {
    switch (dialogData.operation) {
      case 'charge-now':
        handleChargeNow();
        break;
      case 'cancel':
        handleCancelSubscription();
        break;
      default:
        showSnackbar({ severity: 'error', message: 'Unknown operation' });
        break;
    }
  }

  function handleChargeNow() {
    setDialogData({ ...dialogData, isLoading: true });
    chargeSubscription(dialogData.subscriptionId)
      .then(() => {
        showSnackbar({ severity: 'success', message: 'Subscription charged successfully' });
        handleDialogClose();
        fetchSubscriptions();
      })
      .catch((error: AxiosError) => {
        setDialogData({ ...dialogData, isLoading: false });
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleCancelSubscription() {
    setDialogData({ ...dialogData, isLoading: true });
    cancelSubscription(dialogData.subscriptionId)
      .then(() => {
        showSnackbar({ severity: 'success', message: 'Subscription canceled successfully' });
        handleDialogClose();
        fetchSubscriptions();
      })
      .catch((error: AxiosError) => {
        setDialogData({ ...dialogData, isLoading: false });
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        rowCount={totalElements}
        page={page}
        pageSize={pageSize}
        paginationMode="server"
        loading={loading}
        pagination
        classes={{ root: classes.subscriptionsTable }}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          disabled={dialogData.disableChargeNow}
          onClick={() => handleMenuItemClick('charge-now')}
        >
          Charge Now
        </MenuItem>
        <MenuItem disabled={dialogData.disableCancel} onClick={() => handleMenuItemClick('cancel')}>
          Cancel Subscription
        </MenuItem>
      </Menu>
      <ConfirmActionDialog
        open={dialogData.open}
        isLoading={dialogData.isLoading}
        onClose={handleDialogClose}
        onConfirm={handleConfirm}
      />
    </>
  );
}

export default SubscriptionsTable;
