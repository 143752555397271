import React, { useEffect, useState } from 'react';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { Redirect } from 'react-router';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import RemoveIcon from '@material-ui/icons/Remove';

import PageTitleWithBack from '../shared/PageTitleWithBack';
import TextField from '../shared/Inputs/TextField';
import LoadingButton from '../shared/Inputs/LoadingButton';
import Tooltip from '../shared/Tooltip';

import useStyles from './styles';
import { useRouter } from '../../hooks/use-router';
import { useSnackbar } from '../../hooks/use-snackbar';
import { IAssignedUser, IFilterOptions } from '../../interfaces/shared';
import { IPackageQuantity } from '../../interfaces/checkout';
import { getCompleteServiceDetails, getServiceListOptions } from '../../requests/service';
import { Package } from '../../interfaces/services';
import { getClientListOptions } from '../../requests/clients';
import { calculateTotal } from '../Checkout/util';
import { REGEX } from '../../constants';
import { createOrder } from '../../requests/order';
import { useAuth } from '../../hooks/use-auth';
import { isAdmin } from '../../utils/utils';
import { CreateOrderForm } from '../../interfaces/order';

function CreateOrder() {
  const classes = useStyles();
  const router = useRouter();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [serviceList, setServiceList] = useState<IFilterOptions[]>([]);
  const [clientList, setClientList] = useState<IAssignedUser[]>([]);
  const [packageQuantities, setPackageQuantities] = useState<IPackageQuantity[]>([]);
  const [isCreating, setIsCreating] = useState(false);
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const [isLoadingService, setIsLoadingService] = useState(false);
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<CreateOrderForm>({
    defaultValues: { serviceId: '', clientId: '', paidDate: '', transactionDetails: '' },
  });
  const serviceId = watch('serviceId');
  const orderTotal = getOrderTotal();
  let cancelToken: CancelTokenSource;

  useEffect(() => {
    if (isAdmin(user)) {
      getServiceListOptions().then((res) => {
        setServiceList(res.data);
      });
    }
  }, [user]);

  function fetchClientListOptions(event: React.ChangeEvent<{}>, search: string) {
    if (event.type === 'change' && search.length > 1) {
      if (typeof cancelToken !== 'undefined') {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      cancelToken = axios.CancelToken.source();

      setIsClientsLoading(true);
      getClientListOptions(search, cancelToken.token)
        .then((res) => {
          setIsClientsLoading(false);
          setClientList(res.data);
        })
        .catch((error: AxiosError) => {
          setIsClientsLoading(false);
        });
    }
  }

  useEffect(() => {
    if (serviceId) {
      setIsLoadingService(true);
      getCompleteServiceDetails(serviceId)
        .then((res) => {
          setIsLoadingService(false);
          const packages = res.data.serviceOptions;
          if (packages?.length) {
            const formattedPackages = packages?.map<IPackageQuantity>((serviceOption: Package) => {
              const packageId = serviceOption.serviceOptionId;
              return {
                id: packageId,
                name: serviceOption.name,
                description: serviceOption.description,
                price: serviceOption.price,
                quantity: '0',
                discountItems:
                  serviceOption.discountItems?.reduce((acc, item) => {
                    acc[`${item.count}`] = item.percent;
                    return acc;
                  }, {} as { [key: string]: number }) || {},
              };
            });
            setPackageQuantities(formattedPackages);
          }
        })
        .catch((error: AxiosError) => {
          setIsLoadingService(false);
          const errMessage =
            error.response?.data?.message ||
            'An error occurred while fetching service details. Please try again.';
          showSnackbar({ severity: 'error', message: errMessage });
        });
    }
    if (serviceId === '') {
      setPackageQuantities([]);
    }
    // eslint-disable-next-line
  }, [serviceId]);

  function handleCreateOrder(data: CreateOrderForm) {
    const packageList = packageQuantities.filter((item) => +item.quantity);
    const packageQuantityList = packageList.map((item) => ({
      packageId: item.id,
      count: +item.quantity,
    }));
    setIsCreating(true);
    createOrder({
      clientId: data.clientId,
      serviceId: data.serviceId,
      packageCounts: packageQuantityList,
      totalPrice: orderTotal,
      paidDate: dayjs(data.paidDate).toISOString(),
      transactionDetails: data.transactionDetails,
    })
      .then((res) => {
        setIsCreating(false);
        showSnackbar({ severity: 'success', message: 'Order created successfully' });
        router.history.push(`/orders/${btoa(res.data)}`);
      })
      .catch((error: AxiosError) => {
        setIsCreating(false);
        const errMessage = error.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errMessage });
      });
  }

  function getOrderTotal() {
    return calculateTotal(packageQuantities);
  }

  function handleQuantityChange(packageId: string, quantity: string) {
    const serviceOptionIndex = packageQuantities.findIndex((item) => item.id === packageId);
    if (serviceOptionIndex === -1) return;
    const serviceOption = { ...packageQuantities[serviceOptionIndex], quantity };
    packageQuantities[serviceOptionIndex] = serviceOption;
    setPackageQuantities([...packageQuantities]);
  }

  function incOrDecQuantity(mode: string, packageId: string, quantity: string) {
    const count = Number(quantity);
    if (mode === 'inc') {
      handleQuantityChange(packageId, `${count + 1}`);
    }
    if (mode === 'dec' && count) {
      handleQuantityChange(packageId, `${count - 1}`);
    }
  }

  if (!isAdmin(user)) {
    return <Redirect to="/" />;
  }

  return (
    <Container maxWidth="md" disableGutters>
      <PageTitleWithBack
        title="Create Order"
        disableGutters
        onBackClick={() => router.history.replace('/orders')}
      />
      <form onSubmit={handleSubmit(handleCreateOrder)}>
        <div className={classes.createOrderContainer}>
          <div className={classes.createOrderForm}>
            <div className={classes.formField}>
              <div className={classes.fieldTitle}>Client</div>
              <Controller
                name="clientId"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, value, onChange } }) => {
                  const hasError = Boolean(errors.clientId);
                  return (
                    <Autocomplete
                      ref={ref}
                      size="small"
                      options={clientList}
                      loading={isClientsLoading}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option) => option.id === value}
                      value={clientList.find((option) => option.id === value) || null}
                      onChange={(_, newValue) => onChange(newValue ? newValue.id : '')}
                      onInputChange={debounce(fetchClientListOptions, 500)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Type client name to search"
                          variant="outlined"
                          error={hasError}
                          {...(hasError && {
                            helperText: 'Select a client',
                          })}
                        />
                      )}
                    />
                  );
                }}
              />
            </div>
            <div className={classes.formField}>
              <div className={classes.fieldTitle}>Service</div>
              <Controller
                name="serviceId"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, value, onChange } }) => {
                  const hasError = Boolean(errors.serviceId);
                  return (
                    <Autocomplete
                      ref={ref}
                      size="small"
                      options={serviceList}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option) => option.id === value}
                      value={serviceList.find((option) => option.id === value) || null}
                      onChange={(_, newValue) => onChange(newValue ? newValue.id : '')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={hasError}
                          {...(hasError && {
                            helperText: 'Select a service',
                          })}
                        />
                      )}
                    />
                  );
                }}
              />
            </div>
            {serviceId && (
              <div className={classes.detailsFormContainer}>
                <p className={classes.sectionTitle}>Choose the quantity</p>
                <Grid container>
                  {isLoadingService
                    ? 'Fetching packages...'
                    : !packageQuantities.length
                    ? 'No packages found'
                    : Object.values(packageQuantities).map((item) => {
                        const serviceOptionId = item.id;
                        return (
                          <React.Fragment key={serviceOptionId}>
                            <Grid item xs={12} sm={12} md={9}>
                              <p>
                                <span className={classes.packageTitle}>{item.name}</span>
                                <span className={classes.packageDescriptionIcon}>
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title={item.description || 'No description available'}
                                  >
                                    <InfoIcon htmlColor="#C4C4C4" fontSize="small" />
                                  </Tooltip>
                                </span>
                              </p>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                              <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        edge="end"
                                        size="small"
                                        onClick={() =>
                                          incOrDecQuantity('inc', serviceOptionId, item.quantity)
                                        }
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton
                                        edge="start"
                                        size="small"
                                        onClick={() =>
                                          incOrDecQuantity('dec', serviceOptionId, item.quantity)
                                        }
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                value={item.quantity || ''}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  if (value !== '') {
                                    if (!REGEX.NUMBERS.test(value)) {
                                      return;
                                    }
                                  }
                                  handleQuantityChange(serviceOptionId, value);
                                }}
                              />
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                </Grid>
              </div>
            )}
            <div className={classes.formField}>
              <div className={classes.fieldTitle}>Paid Date</div>
              <Controller
                name="paidDate"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, onChange, ...rest } }) => {
                  const hasError = Boolean(errors.paidDate);
                  const hasRequiredError = errors?.paidDate?.type === 'required';
                  return (
                    <TextField
                      {...rest}
                      inputRef={ref}
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="date"
                      error={hasError}
                      onChange={(event) => {
                        const value = event.target.value;
                        onChange(value);
                      }}
                      {...(hasError && {
                        helperText: <>{hasRequiredError && 'Paid date is required'}</>,
                      })}
                    />
                  );
                }}
              />
            </div>
            <div className={classes.formField}>
              <div className={classes.fieldTitle}>Transaction Id</div>
              <Controller
                name="transactionDetails"
                rules={{ required: true }}
                control={control}
                render={({ field: { ref, ...rest } }) => {
                  const hasError = Boolean(errors.transactionDetails);
                  const hasRequiredError = errors?.transactionDetails?.type === 'required';
                  return (
                    <TextField
                      {...rest}
                      inputRef={ref}
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={hasError}
                      {...(hasError && {
                        helperText: <>{hasRequiredError && 'Transaction details are required'}</>,
                      })}
                    />
                  );
                }}
              />
            </div>
            <div className={classes.formField}>
              <div className={classes.fieldTitle}>Total</div>
              <div className={classes.total}>{`$${orderTotal}`}</div>
            </div>
            <div className={classes.createButtonContainer}>
              <LoadingButton
                disabled={orderTotal === 0 || isCreating}
                isLoading={isCreating}
                type="submit"
                color="primary"
                variant="contained"
              >
                Create
              </LoadingButton>
            </div>
          </div>
        </div>
      </form>
    </Container>
  );
}

export default CreateOrder;
