import axios from '../axios';

import { ICoupon } from '../interfaces/coupons';
import { PageResponse, SearchParams } from '../interfaces/shared';

export function getCoupons(requestBody: SearchParams) {
  return axios.post<PageResponse<ICoupon>>('/coupon/find', requestBody);
}

export function getCouponById(couponId: string) {
  return axios.get<ICoupon>('/coupon/detail', { params: { id: couponId } });
}

export function saveCoupon(coupon: ICoupon) {
  return axios.post<ICoupon>('/coupon', coupon);
}

export function deleteCoupon(couponId: string) {
  return axios.delete('/coupon', { params: { id: couponId } });
}
