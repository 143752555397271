import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Button from './Inputs/Button';
import LoadingButton from './Inputs/LoadingButton';
import DeleteOutlinedIcon from '../../assets/img/delete-outlined.svg';

interface ConfirmDeleteDialogProps {
  open: boolean;
  isLoading: boolean;
  onClose(): void;
  onConfirm(): void;
}

const useStyles = makeStyles(() =>
  createStyles({
    deleteTextContainer: { textAlign: 'center', marginTop: 24, marginBottom: 24 },
    deleteText: { color: '#577496', fontSize: 16, marginTop: 8 },
  })
);

function ConfirmDeleteDialog(props: ConfirmDeleteDialogProps) {
  const { open, isLoading, onClose, onConfirm } = props;
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogContent className={classes.deleteTextContainer}>
        <div>
          <div>
            <img src={DeleteOutlinedIcon} alt="delete" />
          </div>
          <div className={classes.deleteText}>Are you sure you want to delete?</div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          onClick={onConfirm}
          color="primary"
          variant="contained"
          isLoading={isLoading}
          disabled={isLoading}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDeleteDialog;
