import { useState } from 'react';
import { AxiosError } from 'axios';
import { UseFormReturn } from 'react-hook-form';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import FileIcon from '@material-ui/icons/InsertDriveFile';

import Button from '../shared/Inputs/Button';
import { useSnackbar } from '../../hooks/use-snackbar';
import useStyles from './styles';

import { IProjectDetailsForm } from '../../interfaces/checkout';
import { uploadFile } from '../../requests/common';

interface IProps {
  templateUrl: string;
  projectDetailsForm: UseFormReturn<IProjectDetailsForm>;
}

const initialUploadData = { uploading: false, percentCompleted: 0 };

function UploadProjectDetails(props: IProps) {
  const { templateUrl, projectDetailsForm } = props;
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const [uploadInfo, setUploadInfo] = useState(initialUploadData);

  const fileUrl = projectDetailsForm.watch('fileUrl');

  const isUploading = uploadInfo.uploading;
  const uploadPercentCompleted = getPercentCompleted();

  function handleUploadProgress(progressEvent: any) {
    const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setUploadInfo({ uploading: true, percentCompleted: percentage });
  }

  function handleFileChange(files: FileList | null) {
    if (!files?.length) return;
    const formData = new FormData();
    formData.append('file', files[0]);
    setUploadInfo({ ...initialUploadData, uploading: true });
    uploadFile(formData, handleUploadProgress)
      .then((res) => {
        setUploadInfo(initialUploadData);
        const url = res.data.url;
        projectDetailsForm.setValue('fileUrl', url);
      })
      .catch((error: AxiosError) => {
        setUploadInfo(initialUploadData);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleDeleteFile() {
    projectDetailsForm.setValue('fileUrl', '');
  }

  function getPercentCompleted() {
    const percentCompleted = uploadInfo.percentCompleted;
    return percentCompleted === 100 && isUploading ? percentCompleted - 1 : percentCompleted;
  }

  return (
    <>
      <div className={classes.uploadProjectTitleContainer}>
        <p className={classes.projectTypeTitle}>Upload Project</p>
        <div className={classes.downloadTemplateBtnContainer}>
          <Button
            color="primary"
            variant="text"
            endIcon={<GetAppIcon />}
            component="a"
            href={templateUrl}
            download
            disabled={!templateUrl}
          >
            Download Project Template
          </Button>
        </div>
      </div>
      <div>
        {!fileUrl && !isUploading ? (
          <>
            <input
              accept=".doc,.docx,.xls,.xlsx,.pdf"
              className={classes.uploadInput}
              id="project-details-upload-button"
              type="file"
              onChange={(event) => handleFileChange(event.target.files)}
            />
            <label htmlFor="project-details-upload-button">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<PublishIcon />}
                component="span"
                disabled={isUploading}
              >
                Upload Project Details
              </Button>
            </label>
          </>
        ) : (
          <div className={classes.uploadedFileContainer}>
            <div>
              <Avatar>
                <FileIcon />
              </Avatar>
            </div>
            <div>
              <div className={classes.uploadingTitleWithPercentage}>
                <span>{isUploading ? 'Uploading' : fileUrl.split('/').slice(-1).join('')}</span>
                {isUploading && <span>{`${uploadPercentCompleted}%`}</span>}
              </div>
              {isUploading && (
                <LinearProgress
                  color="primary"
                  variant="determinate"
                  value={uploadPercentCompleted}
                />
              )}
            </div>
            <div>
              <IconButton
                color="secondary"
                edge="end"
                aria-label="delete"
                size="small"
                onClick={handleDeleteFile}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UploadProjectDetails;
