import { UAParser } from 'ua-parser-js';
import { createTheme } from '@material-ui/core/styles';
import dayjs from 'dayjs';

import {
  CLIENT_STATUS_MAP,
  CLIENT_TYPE_MAP,
  COMMISSION_STATUS_MAP,
  LOCAL_STORAGE_TOKEN_KEY_MAP,
  ORDER_STATUS_MAP,
  USER_ROLE_MAP,
} from '../constants';
import { EPrivileges } from '../interfaces/team';
import { IUser } from '../interfaces/user';
import localStorage from './local-storage';

export const MUI_THEME = createTheme({
  palette: {
    primary: {
      main: '#FF6329',
    },
  },
  typography: {
    fontFamily: ['"Poppins"', 'sans-serif'].join(', '),
  },
});

export function isAdmin(user: IUser | null) {
  return user?.role === 'ADMIN';
}

export function isSuperAdmin(user: IUser | null) {
  return isAdmin(user) && user?.privileges === EPrivileges.SUPER_ADMIN;
}

export function isUser(user: IUser | null) {
  return user?.role === 'CUSTOMER';
}

export function getFormattedRole(role: string) {
  const formattedRole = USER_ROLE_MAP[role];
  return formattedRole || role;
}

export function getOrderStatusOptions() {
  return Object.entries(ORDER_STATUS_MAP).map(([status, formattedStatus]) => ({
    label: formattedStatus,
    value: status,
  }));
}

export function downloadBlob(prefix: string, data: any) {
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  const date = new Date().toISOString().split('T');
  const filename = `${prefix}-${date[0]}-${date[1].split('.')[0].split(':').join('-')}.xlsx`;
  a.href = url;
  a.download = filename;
  a.click();
  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 150);
}

export function getFormattedDate(date: string, placeholder = 'N/A') {
  if (!date) return placeholder;
  return dayjs(date).format('D MMM YY hh:mm A');
}

export function getFormattedDateForInvoice(date: string, placeholder = 'N/A') {
  if (!date) return placeholder;
  return dayjs(date).format('MMMM DD, YYYY');
}

export function getFormattedValidity(date: string, placeholder = 'N/A') {
  if (!date) return placeholder;
  return dayjs(date).format('D MMM YY');
}

export function getClientStatusOptions() {
  return Object.entries(CLIENT_STATUS_MAP).map(([status, formattedStatus]) => ({
    label: formattedStatus,
    value: status,
  }));
}

export function getClientTypeOptions() {
  return Object.entries(CLIENT_TYPE_MAP).map(([status, formattedStatus]) => ({
    label: formattedStatus,
    value: status,
  }));
}

export function getCommissionStatusOptions() {
  return Object.entries(COMMISSION_STATUS_MAP).map(([status, formattedStatus]) => ({
    label: formattedStatus,
    value: status,
  }));
}

export function logout(redirectUrl?: string) {
  localStorage.clear();
  window.location.href = `/signin${redirectUrl ? `?redirectUrl=${redirectUrl}` : ''}`;
}

export function backToAdmin() {
  const adminAccessToken = localStorage.getAdminAccessToken();

  if (adminAccessToken) {
    localStorage.setAccessToken(adminAccessToken);
    localStorage.remove(LOCAL_STORAGE_TOKEN_KEY_MAP.ADMIN_ACCESS_TOKEN);
    return (window.location.href = '/');
  }

  logout();
}

export function loginAsUser(userAccessToken: string) {
  const adminAccessToken = localStorage.getAccessToken();
  if (adminAccessToken) {
    localStorage.setAccessToken(userAccessToken);
    localStorage.setAdminAccessToken(adminAccessToken);
    window.location.href = '/';
  }
}

export function getParsedUserAgent(uaString?: string) {
  const uaParser = new UAParser(uaString);
  return uaParser.getResult();
}
