import React, { useState, useRef } from 'react';
import debounce from 'lodash/debounce';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Popover from '@material-ui/core/Popover';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Checkbox from '../shared/Inputs/Checkbox';
import TextField from '../shared/Inputs/TextField';
import Button from '../shared/Inputs/Button';
import LoadingButton from '../shared/Inputs/LoadingButton';
import useStyles from './styles';

import { IAssignedUser, IDateFilter, IFilterOptions } from '../../interfaces/shared';
import { getFormattedRole, getOrderStatusOptions } from '../../utils/utils';
import { IFilterState } from '../../interfaces/order';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const dateFields = [
  { label: 'Created', value: 'createdTime' },
  { label: 'Completed', value: 'completedDate' },
];

interface IProps {
  assignedUserOptions: IAssignedUser[];
  serviceOptions: IFilterOptions[];
  filters: IFilterState;
  setFilters: React.Dispatch<React.SetStateAction<IFilterState>>;
  onFilterChange(key: string, value: string | string[] | IDateFilter): void;
  onClearFilters(): void;
  onExportOrders(): void;
  isExporting: boolean;
}

interface IDateFilterProps {
  filters: IFilterState;
  setFilters: React.Dispatch<React.SetStateAction<IFilterState>>;
  onFilterChange(key: string, value: string[] | IDateFilter): void;
}

function Filters(props: IProps) {
  const { filters, setFilters, onFilterChange, onClearFilters, onExportOrders, isExporting } =
    props;
  const classes = useStyles();
  const orderIdRef = useRef<HTMLInputElement>(null);

  function handleClearFilters() {
    onClearFilters();
    if (orderIdRef.current) {
      orderIdRef.current.value = '';
    }
  }

  return (
    <div className={classes.filtersWithActionsContainer}>
      <div className={classes.filtersContainer}>
        <TextField
          inputRef={orderIdRef}
          variant="outlined"
          size="small"
          label="Order Id"
          name="orderId"
          onChange={debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            onFilterChange(event.target.name, event.target.value);
          }, 500)}
        />
        <Autocomplete
          multiple
          size="small"
          limitTags={2}
          options={getOrderStatusOptions()}
          value={filters.status}
          getOptionLabel={(option) => option.label}
          disableCloseOnSelect
          onChange={(_, newValue) => {
            onFilterChange(
              'status',
              newValue.map((s) => s.value)
            );
            setFilters({ ...filters, status: newValue });
          }}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                color="primary"
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <span className={classes.autoCompleteLabel}>{option.label}</span>
            </>
          )}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Status" />}
        />
        <Autocomplete
          multiple
          size="small"
          limitTags={2}
          options={props.serviceOptions}
          value={filters.service}
          getOptionLabel={(option) => option.name}
          disableCloseOnSelect
          onChange={(_, newValue) => {
            onFilterChange(
              'serviceId',
              newValue.map((s) => s.id)
            );
            setFilters({ ...filters, service: newValue });
          }}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                color="primary"
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <span className={classes.autoCompleteLabel}>{option.name}</span>
            </>
          )}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Service" />}
        />
        <Autocomplete
          multiple
          size="small"
          limitTags={2}
          options={props.assignedUserOptions}
          value={filters.assignedUser}
          getOptionLabel={(option) => option.name}
          disableCloseOnSelect
          onChange={(_, newValue) => {
            onFilterChange(
              'assignedToAdminId',
              newValue.map((a) => a.id)
            );
            setFilters({ ...filters, assignedUser: newValue });
          }}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                color="primary"
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <span className={classes.autoCompleteLabel}>
                <span className={classes.assignedUserName}>{option.name}</span>
                <br />
                <span className={classes.assignedUserRole}>{getFormattedRole(option.role)}</span>
              </span>
            </>
          )}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Assigned User" />
          )}
          getOptionDisabled={(option) => {
            if (
              filters.assignedUser.find((a) => a.id === 'unassigned') &&
              option.id !== 'unassigned'
            ) {
              return true;
            } else if (
              filters.assignedUser.filter((a) => a.id !== 'unassigned').length &&
              option.id === 'unassigned'
            ) {
              return true;
            }
            return false;
          }}
        />
        <DateFilter filters={filters} setFilters={setFilters} onFilterChange={onFilterChange} />
      </div>
      <div className={classes.filterActionsContainer}>
        <div>
          <Button variant="outlined" size="small" onClick={handleClearFilters}>
            Clear Filters
          </Button>
        </div>
        <div>
          <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            onClick={onExportOrders}
            disabled={isExporting}
            isLoading={isExporting}
          >
            Export
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}

function DateFilter(props: IDateFilterProps) {
  const { filters, setFilters, onFilterChange } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleChange(key: string, value: string) {
    const date = { ...filters.date, [key]: value };
    setFilters({ ...filters, date });

    const from = date.from ? `${date.from}T00:00:00.000Z` : '';
    const to = date.to ? `${date.to}T23:59:59.999Z` : '';
    if (filters.date.key && from && to) {
      onFilterChange(filters.date.key, { key: date.key, from, to });
    }
  }

  function getLabel() {
    const { key, from, to } = filters.date;
    if (key && from && to) {
      const field = dateFields.find((f) => f.value === key);
      if (!field) return 'Any Date';
      return `${field.label} Date`;
    }
    return 'Any Date';
  }

  const open = Boolean(anchorEl);
  const id = open ? 'date-filter-popover' : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        size="large"
        className={classes.dateFilterButton}
        fullWidth
      >
        {getLabel()}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={{ paper: classes.dateFilterPopover }}
      >
        <div className={classes.dateFilterContainer}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="date-field-label">Field</InputLabel>
            <Select
              label="Field"
              labelId="date-field-label"
              value={filters.date.key}
              onChange={(event) => handleChange('key', event.target.value as string)}
              MenuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {dateFields.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={classes.dateFilterDatePickerContainer}>
            <div>
              <TextField
                variant="outlined"
                size="small"
                label="From"
                type="date"
                value={filters.date.from}
                onChange={(event) => handleChange('from', event.target.value)}
                InputProps={{ classes: { root: classes.dateFilterDatePicker } }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div>
              <TextField
                variant="outlined"
                size="small"
                label="To"
                type="date"
                value={filters.date.to}
                onChange={(event) => handleChange('to', event.target.value)}
                InputProps={{ classes: { root: classes.dateFilterDatePicker } }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default Filters;
