import { ButtonProps } from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Button from './Button';

interface ILoadingButtonProps {
  component?: any;
  download?: boolean;
  isLoading: boolean;
}

const useStyles = makeStyles(() => ({
  loadingButton: { position: 'relative' },
  buttonLinearProgress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
}));

function LoadingButton(props: ButtonProps & ILoadingButtonProps) {
  const { isLoading, children, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Button {...rest} className={`${classes.loadingButton} ${className}`}>
      {children}
      {isLoading && (
        <LinearProgress
          color="primary"
          variant="indeterminate"
          className={classes.buttonLinearProgress}
        />
      )}
    </Button>
  );
}

export default LoadingButton;
