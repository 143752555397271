import { Service, ServiceForm, IntakeItem, IntakeFormListItem } from '../../interfaces/services';

export function convertServiceToServiceForm(service: Service): ServiceForm {
  return {
    _id: service._id,
    name: service.name,
    description: service.description,
    checkoutDescription: service.checkoutDescription,
    serviceType: service.serviceType,
    paymentTypes: service.paymentTypes,
    orderDuration: service.orderDuration,
    frequency: service.frequency,
    visibility: service.visibility,
    templateUrl: service.templateUrl,
    subStatuses: service.subStatuses,
  };
}

export function convertIntakeItemsToIntakeFormList(intakeItem: IntakeItem): IntakeFormListItem {
  return {
    _id: intakeItem?.serviceIntakeId || '',
    name: intakeItem.name,
    type: intakeItem.itemType,
    required: Boolean(intakeItem.mandatory),
    placeholder: intakeItem.placeHolder || '',
    helperText: intakeItem.helpText || '',
  };
}

export function convertIntakeFormListToIntakeItems(
  intakeItem: IntakeFormListItem,
  index: number
): IntakeItem {
  return {
    ...(intakeItem._id.length > 15 && { serviceIntakeId: intakeItem._id }),
    name: intakeItem.name,
    itemType: intakeItem.type,
    mandatory: Boolean(intakeItem.required),
    placeHolder: intakeItem.placeholder || '',
    helpText: intakeItem.helperText || '',
    sequence: index,
  };
}
