import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

const drawerWidth = 240;

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
      color: 'inherit',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerPaper: {
      background:
        'linear-gradient(270deg, rgba(255, 241, 233, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%)',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      backgroundColor: '#E5E5E5',
    },
    grow: {
      flexGrow: 1,
    },
    logo: {
      width: 120,
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    rounded: {
      color: 'inherit',
      backgroundColor: 'transparent',
      width: 24,
      height: 24,
    },
    fallback: { width: 24, height: 24 },
    headerNavItemList: {
      display: 'flex',
      '& > a': { marginRight: theme.spacing(0.5) },
    },
    linkListItemSelected: {
      color: '#FF6F24',
      backgroundColor: '#FFE5D7 !important',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: '#FFE5D7 !important',
      },
    },
    headerLinkListItemSelected: {
      color: '#FF6F24',
      backgroundColor: '#FFE5D7 !important',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderBottom: '2px solid #FF6F24',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: '#FFE5D7 !important',
      },
    },
    linkListItemIcon: { minWidth: 40 },
    linkListItemText: { fontSize: '0.875rem' },
    menuButton: { marginRight: theme.spacing(1) },
    notificationsDrawerPaper: {
      width: 400,
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: { width: 296 },
    },
    notificationsTitle: { color: '#172B70' },
    notificationsEmptyContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    noNotificationsMessage: { color: '#577496', maxWidth: '85%', textAlign: 'center' },
    notificationItem: {
      border: '1px solid #D8E1F2',
      borderRadius: 4,
      marginBottom: 12,
      padding: 8,
      color: '#172B70',
      textDecoration: 'none',
      display: 'block',
      '&:hover': { backgroundColor: '#E7F3FF' },
      '&.read': { color: '#ACB2B5', '& $notificationTime': { color: '#ACB2B5' } },
    },
    notificationTime: { color: '#76849F', marginTop: 2 },
    loader: { marginLeft: theme.spacing(2), color: '#FF6329 !important' },
  })
);
