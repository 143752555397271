import { useState } from 'react';
import clsx from 'clsx';
import { AxiosError } from 'axios';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Controller, SubmitHandler, useFieldArray, UseFormReturn } from 'react-hook-form';
import ReactQuill from 'react-quill';

import Button from '../shared/Inputs/Button';
import TextField from '../shared/Inputs/TextField';

import useStyles from './styles';
import { useSnackbar } from '../../hooks/use-snackbar';

import { REGEX } from '../../constants';
import { ICustomerInterfaceForm } from '../../interfaces/services';
import { uploadFile } from '../../requests/common';

import 'react-quill/dist/quill.snow.css';
import './styles.css';

interface CustomerInterfaceProps {
  customerInterfaceForm: UseFormReturn<ICustomerInterfaceForm>;
  onSubmit: SubmitHandler<ICustomerInterfaceForm>;
}

function CustomerInterface(props: CustomerInterfaceProps) {
  const { customerInterfaceForm, onSubmit } = props;
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<{ [key: number]: boolean }>({ 0: false });
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = customerInterfaceForm;
  const {
    fields: sampleReportDetailsFields,
    append: appendSampleReportDetail,
    remove: removeSampleReportDetail,
  } = useFieldArray({ control, name: 'sampleReportDetails' });
  const {
    fields: faqFields,
    append: appendFaq,
    remove: removeFaq,
  } = useFieldArray({ control, name: 'faqs' });
  const sampleReportDetails = watch('sampleReportDetails');
  const faqs = watch('faqs');
  const controlledSampleReportDetails = sampleReportDetailsFields.map((field, index) => {
    return {
      ...field,
      ...sampleReportDetails[index],
    };
  });
  const controlledFaqs = faqFields.map((field, index) => {
    return {
      ...field,
      ...faqs[index],
    };
  });

  function handleReportImageChange(files: FileList | null, index: number) {
    if (!files?.length) return;
    const formData = new FormData();
    formData.append('file', files[0]);
    setLoading({ ...loading, [index]: true });
    uploadFile(formData)
      .then((res) => {
        setLoading({ ...loading, [index]: false });
        const url = res.data.url;

        updateReportImageUrl(url, index);
      })
      .catch((error: AxiosError) => {
        setLoading({ ...loading, [index]: false });
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleDeleteImage(index: number) {
    updateReportImageUrl('', index);
  }

  function updateReportImageUrl(url: string, index: number) {
    setValue(`sampleReportDetails.${index}.imageUrl` as const, url as never);
  }

  return (
    <div className={classes.customerInterfaceContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.reportContainer}>
          <div className={classes.packageTitleContainer}>
            <div className={classes.packageTitle}>Reports</div>
            <div>
              <Button
                startIcon={<AddIcon />}
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => appendSampleReportDetail({ title: '', reportUrl: '', imageUrl: '' })}
                disabled={controlledSampleReportDetails.length === 5}
              >
                Add Report
              </Button>
            </div>
          </div>
          {controlledSampleReportDetails.length === 0 && <p>No reports found</p>}
          {controlledSampleReportDetails.map((sampleReport, index) => {
            const hasImage = Boolean(sampleReport.imageUrl);
            const isImageUploading = Boolean(loading[index]);
            return (
              <div key={sampleReport.id} className={classes.reportItemContainer}>
                <div className={classes.deleteIconContainer}>
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => removeSampleReportDetail(index)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </div>
                <div>
                  <Controller
                    name={`sampleReportDetails.${index}.title` as const}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={sampleReport.title}
                    render={({ field: { ref, ...rest } }) => {
                      const hasError = Boolean(errors?.sampleReportDetails?.[index]?.title);
                      const hasRequiredError =
                        errors?.sampleReportDetails?.[index]?.title?.type === 'required';
                      return (
                        <TextField
                          {...rest}
                          inputRef={ref}
                          variant="outlined"
                          label="Title"
                          fullWidth
                          className={classes.textField}
                          error={hasError}
                          {...(hasError && {
                            helperText: <span>{hasRequiredError && 'Title is required'}</span>,
                          })}
                        />
                      );
                    }}
                  />
                  <Controller
                    name={`sampleReportDetails.${index}.imageUrl` as const}
                    control={control}
                    rules={{ required: true }}
                    render={() => {
                      const hasRequiredError =
                        errors?.sampleReportDetails?.[index]?.imageUrl?.type === 'required';
                      return !hasImage ? (
                        <>
                          <input
                            accept="image/*"
                            className={classes.uploadInput}
                            id={`sample-report-image-upload-button-${index}`}
                            type="file"
                            onChange={(event) => handleReportImageChange(event.target.files, index)}
                          />
                          <label htmlFor={`sample-report-image-upload-button-${index}`}>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              startIcon={<PublishIcon />}
                              component="span"
                              disabled={isImageUploading}
                            >
                              Upload Report Image
                            </Button>
                            {hasRequiredError && (
                              <span className={classes.errorHelperText}>Image is required</span>
                            )}
                            {isImageUploading && (
                              <LinearProgress color="primary" variant="indeterminate" />
                            )}
                          </label>
                        </>
                      ) : (
                        <div className={classes.uploadedImageContainer}>
                          <p className={classes.imageName}>
                            {sampleReport.imageUrl.split('/').slice(-1).join('')}
                          </p>
                          <div>
                            <IconButton
                              size="small"
                              className={classes.viewDeleteIconButton}
                              onClick={() => window.open(sampleReport.imageUrl, '_blank')}
                            >
                              <VisibilityIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              className={classes.viewDeleteIconButton}
                              onClick={() => handleDeleteImage(index)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </div>
                        </div>
                      );
                    }}
                  />
                  <Controller
                    name={`sampleReportDetails.${index}.reportUrl` as const}
                    control={control}
                    rules={{ required: true, pattern: REGEX.URL }}
                    defaultValue={sampleReport.reportUrl}
                    render={({ field: { ref, ...rest } }) => {
                      const hasError = Boolean(errors?.sampleReportDetails?.[index]?.reportUrl);
                      const hasRequiredError =
                        errors?.sampleReportDetails?.[index]?.reportUrl?.type === 'required';
                      const hasPatternError =
                        errors?.sampleReportDetails?.[index]?.reportUrl?.type === 'pattern';
                      return (
                        <TextField
                          {...rest}
                          inputRef={ref}
                          type="url"
                          variant="outlined"
                          label="URL"
                          fullWidth
                          className={classes.textField}
                          error={hasError}
                          {...(hasError && {
                            helperText: (
                              <span>
                                {hasRequiredError && 'URL is required'}
                                {hasPatternError && 'URL is not valid'}
                              </span>
                            ),
                          })}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className={classes.faqContainer}>
          <div className={classes.packageTitleContainer}>
            <div className={classes.packageTitle}>FAQ</div>
            <div>
              <Button
                startIcon={<AddIcon />}
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => appendFaq({ question: '', answer: '' })}
              >
                Add Q&amp;A
              </Button>
            </div>
          </div>
          {controlledFaqs.length === 0 && <p>No FAQs found</p>}
          {controlledFaqs.map((faq, index) => {
            return (
              <div key={faq.id} className={classes.reportItemContainer}>
                <div className={classes.deleteIconContainer}>
                  <IconButton size="small" color="secondary" onClick={() => removeFaq(index)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </div>
                <div>
                  <Controller
                    name={`faqs.${index}.question` as const}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={faq.question}
                    render={({ field: { ref, ...rest } }) => {
                      const hasError = Boolean(errors?.faqs?.[index]?.question);
                      const hasRequiredError = errors?.faqs?.[index]?.question?.type === 'required';
                      return (
                        <TextField
                          {...rest}
                          inputRef={ref}
                          variant="outlined"
                          label="Question"
                          fullWidth
                          className={classes.textField}
                          error={hasError}
                          {...(hasError && {
                            helperText: <span>{hasRequiredError && 'Question is required'}</span>,
                          })}
                        />
                      );
                    }}
                  />
                  <div className={clsx(['answer-quill-editor', classes.editor])}>
                    <p className={classes.fieldTitle}>Answer</p>
                    <Controller
                      name={`faqs.${index}.answer` as const}
                      control={control}
                      rules={{ required: true }}
                      defaultValue={faq.answer}
                      render={({ field: { ref, onChange, value } }) => {
                        const hasRequiredError = errors?.faqs?.[index]?.answer?.type === 'required';
                        return (
                          <>
                            <ReactQuill
                              modules={{
                                toolbar: [
                                  ['bold', { list: 'ordered' }, { list: 'bullet' }, 'clean'],
                                ],
                              }}
                              theme="snow"
                              ref={ref}
                              onChange={onChange}
                              value={value}
                            />
                            {hasRequiredError && (
                              <span className={classes.errorHelperText}>Answer is required</span>
                            )}
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
}

export default CustomerInterface;
