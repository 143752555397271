import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './styles';
import { getVisibleServices } from '../../requests/service';
import { Service } from '../../interfaces/services';
import clsx from 'clsx';

function Services({
  applyMaxWidth,
  isLightTheme,
}: {
  applyMaxWidth?: boolean;
  isLightTheme?: boolean;
}) {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState([] as Service[]);

  useEffect(() => {
    setIsLoading(true);
    getVisibleServices()
      .then((res) => {
        setIsLoading(false);
        setServices(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  function handleNavigateToService(service: Service) {
    history.push(`/services/${btoa(service._id)}/details`);
  }

  function renderLoader(count = 1) {
    return Array(count)
      .fill('skeleton-loader')
      .map((name, i) => (
        <Grid key={`${name}-${i}`} item xs={12} sm={6} md={4}>
          <Card elevation={0} className={classes.serviceCard}>
            <CardHeader
              className={classes.serviceCardHeader}
              avatar={<Skeleton variant="circle" width={40} height={40} />}
            />
            <CardContent className={classes.serviceCardContent}>
              <Skeleton width="80%" />
              <Skeleton width="40%" />
            </CardContent>
          </Card>
        </Grid>
      ));
  }

  return (
    <div className={clsx(classes.servicesContainer, { 'bg-light': Boolean(isLightTheme) })}>
      <Container disableGutters maxWidth={applyMaxWidth ? 'lg' : 'xl'}>
        <h2 className={classes.servicesContainerHeading}>Explore Our Services</h2>
        <Grid container>
          {isLoading
            ? renderLoader(5)
            : services.map((service, i) => (
                <Grid key={`${service._id}-${i}`} item xs={12} sm={6} md={4}>
                  <Card
                    elevation={0}
                    className={classes.serviceCard}
                    onClick={() => handleNavigateToService(service)}
                  >
                    <CardHeader
                      className={classes.serviceCardHeader}
                      avatar={<img src={service.imageUrl} alt="" width={42} height={42} />}
                    />
                    <CardContent className={classes.serviceCardContent}>
                      <p className={classes.serviceCardTitle}>{service.name}</p>
                      <p
                        className={classes.serviceCardDescription}
                        dangerouslySetInnerHTML={{ __html: service.description }}
                      ></p>
                    </CardContent>
                    <CardActions disableSpacing className={classes.serviceCardActions}>
                      <IconButton className={classes.goToServiceIconButton} size="small">
                        <ArrowForwardIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
        </Grid>
      </Container>
    </div>
  );
}

export default Services;
