import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '../shared/Inputs/Button';
import LoadingButton from '../shared/Inputs/LoadingButton';
import SignInForm from '../shared/SignInForm';
import SignUpForm from '../shared/SignUpForm';

import useStyles from './styles';
import { ISignInInput, ISignUpInput } from '../../interfaces/auth';
import { signIn, signUp } from '../../requests/auth';
import { useSnackbar } from '../../hooks/use-snackbar';
import { useAuth } from '../../hooks/use-auth';
import localStorage from '../../utils/local-storage';

type AuthType = 'signin' | 'signup';

interface IAuthDialog {
  authType: AuthType;
  open: boolean;
  onClose(): void;
  onAuthTypeChange(authType: AuthType): void;
}

const initialSignInData = { email: '', password: '' };
const initialSignUpData = { firstName: '', lastName: '', email: '', password: '' };

function AuthDialog({ authType, open, onClose, onAuthTypeChange }: IAuthDialog) {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { fetchUserDetails } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const {
    control: signInControl,
    formState: signInFormState,
    handleSubmit: handleSignIn,
    reset: resetSignInForm,
  } = useForm<ISignInInput>({
    defaultValues: initialSignInData,
  });
  const {
    control: signUpControl,
    formState: signUpFormState,
    handleSubmit: handleSignUp,
    reset: resetSignUpForm,
  } = useForm<ISignUpInput>({
    defaultValues: initialSignUpData,
  });

  useEffect(() => {
    resetSignInForm(initialSignInData);
    resetSignUpForm(initialSignUpData);
    setIsLoading(false);
    setIsAccountCreated(false);
    //eslint-disable-next-line
  }, [open]);

  const onSignInSubmit: SubmitHandler<ISignInInput> = async (data) => {
    setIsLoading(true);
    try {
      const { jwtToken, refreshToken } = (await signIn(data)).data;
      localStorage.setAccessToken(jwtToken);
      localStorage.setRefreshToken(refreshToken);
      setIsLoading(false);
      showSnackbar({ severity: 'success', message: 'Authenticated successfully' });
      fetchUserDetails();
      onClose();
    } catch (error) {
      const errorMessage =
        (error as AxiosError)?.response?.data?.message || 'An error occurred. Please try again.';
      setIsLoading(false);
      showSnackbar({ severity: 'error', message: errorMessage });
    }
  };

  const onSignUpSubmit: SubmitHandler<ISignUpInput> = async (data) => {
    setIsLoading(true);
    try {
      await signUp(data);
      setIsLoading(false);
      setIsAccountCreated(true);
      showSnackbar({
        severity: 'success',
        message: 'Account created successfully. Please sign in to continue.',
      });
      signUpSuccess(data);
    } catch (error) {
      const errorMessage =
        (error as AxiosError)?.response?.data?.message || 'An error occurred. Please try again.';
      setIsLoading(false);
      showSnackbar({ severity: 'error', message: errorMessage });
      if (errorMessage.includes('User Already exists')) {
        signUpSuccess(data);
      }
    }
  };

  function signUpSuccess(data: ISignUpInput) {
    onAuthTypeChange('signin');
    resetSignInForm({ email: data.email, password: data.password });
    handleSubmit(onSignInSubmit)();
  }

  const label = `Sign ${authType === 'signin' ? 'In' : 'Up'}`;
  const handleSubmit = authType === 'signin' ? handleSignIn : handleSignUp;

  return (
    <Dialog open={open} onClose={onClose}>
      <form
        onSubmit={
          authType === 'signin' ? handleSubmit(onSignInSubmit) : handleSubmit(onSignUpSubmit)
        }
      >
        <DialogContent>
          {authType === 'signin' ? (
            <>
              <p className={classes.title}>Welcome Back!</p>
              <SignInForm control={signInControl} formState={signInFormState} />
              {!isAccountCreated && (
                <p className={classes.authTypeSwitchText}>
                  Don't have an account?
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => onAuthTypeChange('signup')}
                    disabled={isLoading}
                  >
                    Sign Up
                  </Button>
                </p>
              )}
            </>
          ) : (
            <>
              <p className={classes.title}>Get your free Stan account now!</p>
              <SignUpForm control={signUpControl} formState={signUpFormState} />
              <p className={classes.authTypeSwitchText}>
                Already have an account?
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={() => onAuthTypeChange('signin')}
                  disabled={isLoading}
                >
                  Sign In
                </Button>
              </p>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {label}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AuthDialog;
