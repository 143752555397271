import { Link } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import { DataGrid, GridColDef } from '@material-ui/data-grid';

import { AgentReport, ClientComparisonReport, ClientReport } from '../../interfaces/admin';
import { getFormattedDate } from '../../utils/utils';
import useStyles from './styles';

interface IProps {
  reportType: string;
  loading: boolean;
  page: number;
  pageSize: number;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  data: ClientReport[] | ClientComparisonReport[] | AgentReport[];
}

function ReportsTable(props: IProps) {
  const { reportType, loading, data, page, pageSize, onPageChange, onPageSizeChange } = props;
  const classes = useStyles();

  function getColumns() {
    let columns: GridColDef[] = [];
    switch (reportType) {
      case 'agents':
        columns = [
          { field: 'agentName', headerName: 'Agent Name', flex: 1 },
          { field: 'clientsCount', headerName: 'No. of Clients', flex: 1 },
          { field: 'ordersCount', headerName: 'No. of Orders', flex: 1 },
          { field: 'newRevenue', headerName: 'New Revenue ($)', flex: 1 },
          { field: 'totalRevenue', headerName: 'Total Revenue ($)', flex: 1 },
        ];
        break;
      case 'clients':
      case 'new-clients':
        columns = [
          {
            field: 'clientName',
            headerName: 'Client Name',
            flex: 1,
            renderCell: (params) => (
              <Link
                className={classes.linkBlue}
                to={`/clients/${btoa(params.row.clientId as string)}`}
              >
                {params.row.clientName}
              </Link>
            ),
          },
          { field: 'clientEmail', headerName: 'Client Email', flex: 1 },
          { field: 'agentName', headerName: 'Agent Name', flex: 1 },
          {
            field: 'clientStatus',
            headerName: 'Status',
            flex: 1,
            sortable: false,
            valueFormatter: (params) =>
              capitalize((params.row.clientStatus || '').split('_').join(' ')),
          },
          { field: 'ordersCount', headerName: 'No. of Orders', flex: 1 },
          { field: 'totalRevenue', headerName: 'Total Revenue ($)', flex: 1 },
          {
            field: 'lastPaymentDate',
            headerName: 'Last Payment Date',
            flex: 1,
            sortable: false,
            valueFormatter: (params) => getFormattedDate(params.row.lastPaymentDate),
          },
        ];
        break;
      case 'clients-comparison':
        columns = [
          {
            field: 'clientName',
            headerName: 'Client Name',
            flex: 1,
            renderCell: (params) => (
              <Link
                className={classes.linkBlue}
                to={`/clients/${btoa(params.row.clientId as string)}`}
              >
                {params.row.clientName}
              </Link>
            ),
          },
          { field: 'clientEmail', headerName: 'Client Email', flex: 1 },
          { field: 'agentName', headerName: 'Agent Name', flex: 1 },
          { field: 'ordersCount', headerName: '(R1) Orders', flex: 1 },
          { field: 'totalRevenue', headerName: '(R1) Total Revenue ($)', flex: 1 },
          { field: 'ordersCount1', headerName: '(R2) Orders', flex: 1 },
          { field: 'totalRevenue1', headerName: '(R2) Total Revenue ($)', flex: 1 },
        ];
        break;
      default:
        break;
    }
    return columns;
  }

  return (
    <DataGrid
      disableSelectionOnClick
      rows={data}
      columns={getColumns()}
      loading={loading}
      pagination
      paginationMode="client"
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      page={page}
      pageSize={pageSize}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      classes={{ root: classes.reportsTable }}
    />
  );
}

export default ReportsTable;
