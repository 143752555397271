import axios from '../axios';

import { Countries, INotification } from '../interfaces/shared';

export function uploadFile(requestBody: FormData, onUploadProgress?: (progressEvent: any) => void) {
  return axios.post<{ url: string }>('/upload', requestBody, {
    headers: { 'content-type': 'multipart/form-data' },
    ...(onUploadProgress && { onUploadProgress }),
  });
}

export function getCountries() {
  return axios.get<Countries>('/user/countries');
}

export function getNotifications() {
  return axios.get<INotification[]>('/notification');
}

export function updateNotificationAsRead(notificationId: string) {
  return axios.put('/notification', undefined, { params: { id: notificationId } });
}
