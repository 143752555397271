import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Link, useParams } from 'react-router-dom';
import { GridRowParams, GridRowsProp, GridSortModel } from '@material-ui/data-grid';

import Filters from './Filters';
import OrdersTable from './OrdersTable';
import OrderDetails from './OrderDetails';
import CreateOrder from './CreateOrder';
import Button from '../shared/Inputs/Button';

import useStyles from './styles';
import { useAuth } from '../../hooks/use-auth';
import { useRouter } from '../../hooks/use-router';
import { useSnackbar } from '../../hooks/use-snackbar';
import { exportOrders, getOrders } from '../../requests/order';
import { downloadBlob, isAdmin, isUser } from '../../utils/utils';
import { IAssignedUser, IDateFilter, IFilterOptions, SearchParams } from '../../interfaces/shared';
import { getAssignedUserList } from '../../requests/admin';
import { getServiceListOptions } from '../../requests/service';
import { IFilterState } from '../../interfaces/order';

const dateFilterKeys = ['createdTime', 'completedDate'];
const initialFilters = {
  status: [],
  service: [],
  assignedUser: [],
  date: { key: '', from: '', to: '' },
};
const unassignedOption = { id: 'unassigned', name: 'Unassigned', role: '' };

interface IOrdersState {
  content: GridRowsProp;
  totalElements: number;
}

function Orders() {
  const { user } = useAuth();
  const classes = useStyles();
  const router = useRouter();
  const { showSnackbar } = useSnackbar();
  const params = useParams<{ orderId: string }>();
  const [{ page, pageSize, filterFields, sortBy, sortDirection }, setSearchParams] =
    useState<SearchParams>({
      page: 0,
      pageSize: 10,
      sortBy: 'createdTime',
      sortDirection: 'desc',
      filterFields: isUser(user)
        ? [{ key: 'clientId', searchOperation: 'eq', value: user?._id || '' }]
        : [],
    });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'createdTime', sort: 'desc' },
  ]);
  const [filters, setFilters] = useState<IFilterState>(initialFilters);
  const [orders, setOrders] = useState<IOrdersState>({ content: [], totalElements: 0 });
  const [assignedUserList, setAssignedUserList] = useState<IAssignedUser[]>([unassignedOption]);
  const [serviceList, setServiceList] = useState<IFilterOptions[]>([]);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    if (isAdmin(user)) {
      getAssignedUserList().then((res) => setAssignedUserList([unassignedOption].concat(res.data)));
      getServiceListOptions().then((res) => setServiceList(res.data));
    }
  }, [user]);

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line
  }, [page, pageSize, filterFields, sortBy, sortDirection]);

  function fetchOrders() {
    setLoading(true);
    getOrders({
      page,
      pageSize,
      ...(filterFields?.length && { filterFields }),
      ...(sortBy && sortDirection && { sortBy, sortDirection }),
    })
      .then((res) => {
        setLoading(false);
        setOrders({ content: res.data.content, totalElements: res.data.totalElements });
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleExportOrders() {
    setExporting(true);
    showSnackbar({
      severity: 'info',
      message: 'Please wait while we prepare the data. Your file will be downloaded any moment',
    });
    exportOrders({ ...(filterFields?.length && { filterFields }) })
      .then((res) => {
        downloadBlob('Orders', res.data);
        setExporting(false);
        showSnackbar({ severity: 'success', message: 'File has been downloaded' });
      })
      .catch(() => {
        setExporting(false);
        showSnackbar({ severity: 'error', message: 'An error occurred while exporting' });
      });
  }

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize, filterFields });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize, filterFields });
  }

  function handleSortModelChange(model: GridSortModel) {
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      let newSortBy = '';
      let newSortDirection: string | undefined = '';
      if (model.length) {
        newSortBy = model[0].field;
        newSortDirection = model[0].sort as string | undefined;
      }
      setSortModel(model);
      setSearchParams({
        page,
        pageSize,
        filterFields,
        ...(newSortBy &&
          newSortDirection && { sortBy: newSortBy, sortDirection: newSortDirection }),
      });
    }
  }

  function handleFilterChange(key: string, value: string | string[] | IDateFilter) {
    let newFilters = filterFields?.filter((filter) => filter.key !== key) || [];
    if (typeof value === 'string' && value.length) {
      newFilters.push({ key, searchOperation: 'like', value });
    } else if (Array.isArray(value) && value.length) {
      if (key === 'assignedToAdminId' && value.length === 1 && value[0] === 'unassigned') {
        newFilters.push({ key, searchOperation: 'isnull' });
      } else {
        newFilters.push({ key, searchOperation: 'in', value });
      }
    } else if (dateFilterKeys.includes(key)) {
      newFilters = newFilters.filter((filter) => !dateFilterKeys.includes(filter.key));
      const { key, from, to } = value as IDateFilter;
      if (key && from && to) {
        newFilters.push({ key, searchOperation: 'btn', value: from, toValue: to });
      }
    }
    setOrders({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: newFilters });
  }

  function handleClearFilters() {
    setFilters(initialFilters);
    setOrders({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: [] });
  }

  function handleRowClick(params: GridRowParams) {
    router.history.push(`/orders/${btoa(params.row.id)}`);
  }

  return (
    <>
      {!params.orderId ? (
        <>
          <div className={classes.titleContainer}>
            <p className={classes.title}>Orders</p>
            {isAdmin(user) && (
              <div>
                <Button color="primary" variant="contained" component={Link} to="/orders/new">
                  Create Order
                </Button>
              </div>
            )}
          </div>
          {isAdmin(user) && (
            <Filters
              assignedUserOptions={assignedUserList}
              serviceOptions={serviceList}
              filters={filters}
              setFilters={setFilters}
              onFilterChange={handleFilterChange}
              onClearFilters={handleClearFilters}
              onExportOrders={handleExportOrders}
              isExporting={exporting}
            />
          )}
          <OrdersTable
            page={page}
            pageSize={pageSize}
            sortModel={sortModel}
            rows={orders.content}
            totalElements={orders.totalElements}
            loading={loading}
            isAdmin={isAdmin(user)}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onRowClick={handleRowClick}
            onSortModelChange={handleSortModelChange}
          />
        </>
      ) : params.orderId === 'new' ? (
        <CreateOrder />
      ) : (
        <OrderDetails orderId={atob(params.orderId)} assignedUserOptions={assignedUserList} />
      )}
    </>
  );
}

export default Orders;
