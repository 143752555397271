import { AxiosError } from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { useForm, SubmitHandler } from 'react-hook-form';

import SideTestimonial from './SideTestimonial';
import SignUpForm from '../shared/SignUpForm';
import Button from '../shared/Inputs/Button';

import { signIn, signUp } from '../../requests/auth';
import { useSnackbar } from '../../hooks/use-snackbar';
import { useRouter } from '../../hooks/use-router';
import localStorage from '../../utils/local-storage';
import { ISignUpInput } from '../../interfaces/auth';
import useStyles from './styles';

function SignUp() {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAccountCreated, setIsAccountCreated] = useState(false);

  const { control, formState, handleSubmit } = useForm<ISignUpInput>({
    defaultValues: { firstName: '', lastName: '', email: '', password: '' },
  });

  const onSubmit: SubmitHandler<ISignUpInput> = async (data) => {
    setError(null);
    setIsLoading(true);
    try {
      await signUp(data);
      setIsLoading(false);
      setIsAccountCreated(true);
      showSnackbar({ severity: 'success', message: 'Account created successfully' });
      setIsLoading(true);
      const { jwtToken, refreshToken } = (
        await signIn({ email: data.email, password: data.password })
      ).data;
      localStorage.setAccessToken(jwtToken);
      localStorage.setRefreshToken(refreshToken);
      setIsLoading(false);
      router.replace('/');
    } catch (error) {
      const errorMessage =
        (error as AxiosError)?.response?.data?.message || 'An error occurred. Please try again.';
      setIsLoading(false);
      setError(errorMessage);
      showSnackbar({ severity: 'error', message: errorMessage });
    }
  };

  return (
    <Grid container className={classes.root}>
      <Hidden xsDown>
        <Grid item sm={6} md={6}>
          <SideTestimonial />
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={6} md={6}>
        <div className={classes.paper}>
          <p className={classes.title}>Get your free Stan account now!</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SignUpForm control={control} formState={formState} />
            <div>
              {error && <Alert severity="error">{error}</Alert>}
              {isAccountCreated && <Alert severity="success">Account created successfully</Alert>}
            </div>
            <div className={classes.btnContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                classes={{ root: classes.button }}
                disabled={isAccountCreated || isLoading}
                className={classes.loadingButton}
              >
                SIGN UP
                {isLoading && (
                  <LinearProgress
                    color="primary"
                    variant="indeterminate"
                    className={classes.buttonLinearProgress}
                  />
                )}
              </Button>
            </div>
          </form>
          <p className={classes.existingText}>
            Already have an account?{' '}
            <Link to="/signin" className={classes.link}>
              Sign In here
            </Link>
          </p>
        </div>
      </Grid>
    </Grid>
  );
}

export default SignUp;
