import clsx from 'clsx';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

import PageTitleWithBack from '../shared/PageTitleWithBack';
import TextField from '../shared/Inputs/TextField';
import Checkbox from '../shared/Inputs/Checkbox';
import LoadingButton from '../shared/Inputs/LoadingButton';

import { useRouter } from '../../hooks/use-router';
import { useSnackbar } from '../../hooks/use-snackbar';
import { MUI_THEME } from '../../utils/utils';
import { IFilterOptions } from '../../interfaces/shared';
import { getServiceListOptionsOnSearch } from '../../requests/service';
import { ICoupon, ICouponForm } from '../../interfaces/coupons';
import { getCouponById, saveCoupon } from '../../requests/coupons';
import { REGEX } from '../../constants';
import useStyles from './styles';
import { debounce } from 'lodash';

interface IProps {
  couponId: string;
  fetchCoupons(): void;
}

function AddEditCoupon(props: IProps) {
  const { couponId } = props;
  const router = useRouter();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const [serviceList, setServiceList] = useState<IFilterOptions[]>([]);
  const [selectedServiceList, setSelectedServiceList] = useState<IFilterOptions[]>([]);
  const [coupon, setCoupon] = useState<ICoupon>({} as ICoupon);
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
    watch,
  } = useForm<ICouponForm>({
    defaultValues: {
      code: '',
      description: '',
      type: 'FIXED',
      discount: '',
      serviceIds: [],
      limitOnePerCustomer: false,
      limitUsages: false,
      setExpiry: false,
      expiryDate: '',
      limit: '',
    },
  });
  const isDiscountTypeFixed = watch('type') === 'FIXED';
  const hasUsageLimit = watch('limitUsages');
  const hasExpiryDate = watch('setExpiry');

  const operation = couponId === 'new' ? 'create' : 'update';
  const isNewCoupon = operation === 'create';
  const [isServicesLoading, setIsServicesLoading] = useState(false);
  let cancelToken: CancelTokenSource;

  function fetchServicesListOptionsOnSearch(event: React.ChangeEvent<{}>, search: string) {
    console.log(event?.type, search);
    if (event?.type === 'change' && search.length > 1) {
      if (typeof cancelToken !== 'undefined') {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      cancelToken = axios.CancelToken.source();

      setIsServicesLoading(true);
      getServiceListOptionsOnSearch(search, cancelToken.token)
        .then((res) => {
          setIsServicesLoading(false);
          setServiceList(res.data);
        })
        .catch((error: AxiosError) => {
          setIsServicesLoading(false);
        });
    }
  }

  useEffect(() => {
    if (!isNewCoupon && couponId) {
      fetchCoupon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponId, isNewCoupon]);

  function fetchCoupon() {
    getCouponById(couponId)
      .then((res) => {
        setCoupon(res.data);
        resetCouponForm(res.data);
        setSelectedServiceList(res.data.services);
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function resetCouponForm(data: ICoupon) {
    reset({
      code: data.code,
      description: data.description,
      type: data.type,
      discount: `${data.discount}`,
      serviceIds: data.serviceIds,
      limitOnePerCustomer: data.limitOnePerCustomer,
      limitUsages: data.limitUsages,
      setExpiry: data.setExpiry,
      expiryDate: convertExpiryDateToLocal(data.expiryDate) || '',
      limit: `${data.limit !== null ? data.limit : ''}`,
    });
  }

  function convertExpiryDateToUTC(date: string | null) {
    if (!date) return null;
    return dayjs(date).toISOString();
  }

  function convertExpiryDateToLocal(date: string | null) {
    if (!date) return '';
    return dayjs(date).format('YYYY-MM-DDTHH:mm');
  }

  function handleSaveCoupon(data: ICouponForm) {
    const requestBody: Partial<ICoupon> = {
      ...(!isNewCoupon && { _id: couponId }),
      code: data.code,
      description: data.description,
      type: data.type,
      status: 'ACTIVE',
      serviceIds: data.serviceIds,
      discount: Number(data.discount),
      limitOnePerCustomer: data.limitOnePerCustomer,
      limitUsages: data.limitUsages,
      limit: data.limit === '' ? null : Number(data.limit),
      setExpiry: data.setExpiry,
      expiryDate: convertExpiryDateToUTC(data.expiryDate),
      ...(coupon?.version && { version: coupon.version }),
    };
    setIsLoading(true);
    saveCoupon(requestBody as ICoupon)
      .then((res) => {
        setIsLoading(false);
        if (isNewCoupon) {
          router.history.replace(`/coupons/${btoa(res.data._id)}`);
          showSnackbar({ severity: 'success', message: 'Coupon created successfully' });
        } else {
          resetCouponForm(res.data);
          showSnackbar({ severity: 'success', message: 'Coupon updated successfully' });
        }
        props.fetchCoupons();
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function getPageTitle() {
    if (isNewCoupon) return 'Add Coupon';
    return coupon?.code || '';
  }

  function getServiceList() {
    const tempArray: IFilterOptions[] = [...serviceList];
    const serviceIdMap = serviceList.reduce<Record<string, IFilterOptions>>((acc, option) => {
      acc[option.id] = option;
      return acc;
    }, {});
    selectedServiceList.forEach((option) => {
      if (!serviceIdMap[option.id]) {
        tempArray.push(option);
      }
    });
    return tempArray;
  }

  return (
    <ThemeProvider theme={MUI_THEME}>
      <Container maxWidth="md" disableGutters>
        <PageTitleWithBack
          title={getPageTitle()}
          disableGutters
          onBackClick={() => router.history.replace('/coupons')}
        />
        <form onSubmit={handleSubmit(handleSaveCoupon)}>
          <div className={classes.couponDetailsContainer}>
            <div className={classes.couponDetailsForm}>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Coupon Code</div>
                <Controller
                  name="code"
                  control={control}
                  rules={{ required: true, minLength: 3 }}
                  render={({ field: { ref, onChange, ...rest } }) => {
                    const hasError = Boolean(errors.code);
                    const hasRequiredError = errors?.code?.type === 'required';
                    const hasMinLengthError = errors?.code?.type === 'minLength';
                    return (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        variant="outlined"
                        size="small"
                        error={hasError}
                        helperText={
                          hasError
                            ? hasRequiredError
                              ? 'Coupon code is required'
                              : hasMinLengthError
                              ? 'Code should has minimum 3 characters'
                              : ''
                            : 'This is what clients will use to get the discount.'
                        }
                        onChange={(event) => {
                          onChange(event.target.value.toUpperCase());
                        }}
                      />
                    );
                  }}
                />
              </div>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Description</div>
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { ref, ...rest } }) => {
                    return (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        variant="outlined"
                        size="small"
                        multiline
                        minRows={3}
                        maxRows={3}
                        helperText="Not visible to clients, helps you remember what the coupon is for."
                      />
                    );
                  }}
                />
              </div>
              <div className={classes.formField}>
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...rest } }) => {
                    return (
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className={classes.fieldTitle}>
                          Discount Type
                        </FormLabel>
                        <RadioGroup {...rest} ref={ref} aria-label="discount-type" row>
                          <FormControlLabel
                            classes={{ label: classes.formControlLabel }}
                            value="FIXED"
                            control={<Radio color="primary" />}
                            label="Fixed Amount"
                          />
                          <FormControlLabel
                            classes={{ label: classes.formControlLabel }}
                            value="PERCENTAGE"
                            control={<Radio color="primary" />}
                            label="Percentage"
                          />
                        </RadioGroup>
                      </FormControl>
                    );
                  }}
                />
              </div>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Discount</div>
                <Controller
                  name="discount"
                  control={control}
                  rules={{
                    required: true,
                    validate: (value: string) => {
                      const discount = Number(value);
                      if (!Number(discount)) return false;
                      if (!isDiscountTypeFixed && discount > 100) return false;
                      return true;
                    },
                  }}
                  render={({ field: { ref, value, onChange, ...rest } }) => {
                    const hasError = Boolean(errors.discount);
                    return (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        variant="outlined"
                        size="small"
                        InputProps={{
                          ...(isDiscountTypeFixed && {
                            startAdornment: <span className={classes.adornmentText}>$</span>,
                          }),
                          ...(!isDiscountTypeFixed && {
                            endAdornment: <span className={classes.adornmentText}>%</span>,
                          }),
                        }}
                        value={value}
                        error={hasError}
                        onChange={(event) => {
                          const value = event.target.value;
                          if (value !== '') {
                            if (!REGEX.NUMBERS.test(value)) {
                              return;
                            }
                          }
                          onChange(value);
                        }}
                        {...(hasError && {
                          helperText: `Discount should be ${
                            isDiscountTypeFixed ? 'greater than or equal to 1' : 'between 1 and 100'
                          }`,
                        })}
                      />
                    );
                  }}
                />
              </div>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Services</div>
                <Controller
                  name="serviceIds"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, value, onChange } }) => {
                    const hasError = Boolean(errors.serviceIds);
                    return (
                      <Autocomplete
                        ref={ref}
                        multiple
                        size="small"
                        limitTags={1}
                        options={getServiceList()}
                        loading={isServicesLoading}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        value={getServiceList().filter((option) => value.includes(option.id))}
                        disableCloseOnSelect
                        onChange={(_, newValue) => {
                          onChange(newValue.map((v) => v.id));
                          setSelectedServiceList(newValue);
                        }}
                        openOnFocus
                        onInputChange={debounce(fetchServicesListOptionsOnSearch, 500)}
                        renderOption={(option, { selected }) => (
                          <>
                            {
                              <Checkbox
                                color="primary"
                                style={{ marginRight: 8 }}
                                checked={selected}
                                size="small"
                              />
                            }
                            <span
                              className={clsx(classes.autoCompleteLabel, {
                                [classes.autoCompleteSelectAllLabel]: option.id === 'all',
                              })}
                            >
                              {option.name}
                            </span>
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Type service name to search"
                            variant="outlined"
                            error={hasError}
                            {...(hasError && {
                              helperText: 'Atleast one service should be selected',
                            })}
                          />
                        )}
                      />
                    );
                  }}
                />
              </div>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Redemption Limits</div>
                <div>
                  <Controller
                    name="limitOnePerCustomer"
                    control={control}
                    render={({ field: { name, value, onChange } }) => {
                      return (
                        <FormControlLabel
                          classes={{ label: classes.formControlLabel }}
                          control={
                            <Checkbox
                              name={name}
                              color="primary"
                              checked={value}
                              onChange={(event) => onChange(event.target.checked)}
                            />
                          }
                          label="Limit to one use per customer"
                        />
                      );
                    }}
                  />
                </div>
                <div className={classes.redemptionLimitCheckboxContainer}>
                  <div>
                    <Controller
                      name="limitUsages"
                      control={control}
                      render={({ field: { name, value, onChange } }) => {
                        return (
                          <FormControlLabel
                            classes={{ label: classes.formControlLabel }}
                            control={
                              <Checkbox
                                name={name}
                                color="primary"
                                checked={value}
                                onChange={(event) => {
                                  onChange(event.target.checked);
                                  setValue('limit', '');
                                }}
                              />
                            }
                            label="Limit number of uses in total"
                          />
                        );
                      }}
                    />
                  </div>
                  {hasUsageLimit && (
                    <div>
                      <Controller
                        name="limit"
                        control={control}
                        rules={{ required: true, min: 1 }}
                        render={({ field: { ref, onChange, ...rest } }) => {
                          const hasError = Boolean(errors.limit);
                          const hasRequiredError = errors?.limit?.type === 'required';
                          const hasMinValueError = errors?.limit?.type === 'min';
                          return (
                            <TextField
                              {...rest}
                              inputRef={ref}
                              variant="outlined"
                              size="small"
                              error={hasError}
                              onChange={(event) => {
                                const value = event.target.value;
                                if (value !== '') {
                                  if (!REGEX.NUMBERS.test(value)) {
                                    return;
                                  }
                                }
                                onChange(value);
                              }}
                              {...(hasError && {
                                helperText: (
                                  <>
                                    {hasRequiredError && 'Usage count is required'}
                                    {hasMinValueError &&
                                      'Usage count should be greater than or equal to 1'}
                                  </>
                                ),
                              })}
                            />
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className={classes.redemptionLimitCheckboxContainer}>
                  <div>
                    <Controller
                      name="setExpiry"
                      control={control}
                      render={({ field: { name, value, onChange } }) => {
                        return (
                          <FormControlLabel
                            classes={{ label: classes.formControlLabel }}
                            control={
                              <Checkbox
                                name={name}
                                color="primary"
                                checked={value}
                                onChange={(event) => {
                                  onChange(event.target.checked);
                                  setValue('expiryDate', '');
                                }}
                              />
                            }
                            label="Set expiry date"
                          />
                        );
                      }}
                    />
                  </div>
                  {hasExpiryDate && (
                    <div>
                      <Controller
                        name="expiryDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { ref, onChange, ...rest } }) => {
                          const hasError = Boolean(errors.expiryDate);
                          const hasRequiredError = errors?.expiryDate?.type === 'required';
                          return (
                            <TextField
                              {...rest}
                              inputRef={ref}
                              variant="outlined"
                              size="small"
                              type="datetime-local"
                              className={classes.dateTimePickerText}
                              error={hasError}
                              onChange={(event) => {
                                const value = event.target.value;
                                onChange(value);
                              }}
                              {...(hasError && {
                                helperText: <>{hasRequiredError && 'Expiry date is required'}</>,
                              })}
                            />
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.saveButtonContainer}>
                <LoadingButton
                  disabled={isLoading}
                  isLoading={isLoading}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </ThemeProvider>
  );
}

export default AddEditCoupon;
