import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { INotification } from '../interfaces/shared';
import { updateNotificationAsRead } from '../requests/common';

type Severity = 'error' | 'info' | 'success' | 'warning';

interface ISnackbarState {
  open: boolean;
  severity: Severity;
  message: string;
  isNotification: boolean;
  onAction?(): void;
}

interface ISnackbarContext {
  snackbar: ISnackbarState;
  showSnackbar({ severity, message }: { severity: Severity; message: string }): void;
  showNotification(notification: INotification): void;
  hideSnackbar(): void;
}

const snackbarContext = createContext<ISnackbarContext>({} as ISnackbarContext);

export function ProvideSnackbar({ children }: { children: React.ReactNode }) {
  const snackbar = useProvideSnackbar();
  return <snackbarContext.Provider value={snackbar}>{children}</snackbarContext.Provider>;
}

export function useSnackbar() {
  return useContext(snackbarContext);
}

function useProvideSnackbar() {
  const [snackbar, setSnackbar] = useState<ISnackbarState>({
    open: false,
    severity: 'info',
    message: '',
    isNotification: false,
  });
  const history = useHistory();

  function showSnackbar({ severity, message }: { severity: Severity; message: string }): void {
    setSnackbar({ open: true, isNotification: false, severity, message });
  }

  function showNotification(notification: INotification) {
    setSnackbar({
      ...snackbar,
      open: true,
      isNotification: true,
      message: notification.description,
      onAction: () => {
        updateNotificationAsRead(notification._id);
        switch (notification.type) {
          case 'ORDER': {
            history.push(`/orders/${btoa(notification.typeSystemId)}`);
            break;
          }
          case 'SERVICE': {
            history.push(`/services/${btoa(notification.typeSystemId)}`);
            break;
          }
          case 'USER': {
            history.push(`/clients/${btoa(notification.typeSystemId)}`);
            break;
          }
          default:
            break;
        }
        hideSnackbar();
      },
    });
  }

  function hideSnackbar(): void {
    setSnackbar({ ...snackbar, open: false, isNotification: false });
  }

  return { snackbar, showSnackbar, showNotification, hideSnackbar };
}
