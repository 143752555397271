import { useEffect } from 'react';

import { useAuth } from './use-auth';
import { useRouter } from './use-router';
import localStorage from '../utils/local-storage';

export function useRequireAuth() {
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!localStorage.hasAccessToken()) {
      router.push('/signin');
    }
  }, [auth, router]);
  return auth;
}
