import React from 'react';

import TextField from '../shared/Inputs/TextField';
import Button from '../shared/Inputs/Button';

import useStyles from './styles';
import { IFilterState } from '../../interfaces/affiliates';

interface IProps {
  filters: IFilterState;
  setFilters: React.Dispatch<React.SetStateAction<IFilterState>>;
  onApplyFilters(): void;
  onClearFilters(): void;
}

function Filters(props: IProps) {
  const classes = useStyles();
  const { filters, setFilters, onApplyFilters, onClearFilters } = props;

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  }

  return (
    <div className={classes.filtersWithActionsContainer}>
      <div className={classes.filtersContainer}>
        <TextField
          variant="outlined"
          size="small"
          label="Affiliate Name"
          name="referrerClientName"
          value={filters.referrerClientName}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Affiliate Code"
          name="affiliateCode"
          value={filters.affiliateCode}
          onChange={handleChange}
        />
      </div>
      <div className={classes.filterActionsContainer}>
        <div>
          <Button variant="contained" color="primary" size="small" onClick={onApplyFilters}>
            Apply Filters
          </Button>
        </div>
        <div>
          <Button variant="outlined" size="small" onClick={onClearFilters}>
            Clear Filters
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Filters;
