import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  title: { color: '#172B70', fontSize: 18, fontWeight: 700 },
  billingTitle: { color: '#000000', fontSize: 18, fontWeight: 700, marginBottom: theme.spacing(2) },
  container: {
    padding: theme.spacing(2),
    margin: '10px 0px 10px',
    border: '1px solid #E4EBF8',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
  },
  textField: { marginTop: 8, marginBottom: 8 },
  textFields: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div:first-child': { marginRight: 4 },
    '& > div:last-child': { marginLeft: 4 },
  },
  avatarContainer: { position: 'relative' },
  avatar: { width: theme.spacing(20), height: theme.spacing(20), margin: '0 auto' },
  avatarEditButtonContainer: {
    position: 'absolute',
    bottom: '-10px',
    left: '47%',
    padding: '2px 2px 0px',
    backgroundColor: '#ffffff',
    borderRadius: '50%',
  },
  avatarEditButton: {
    color: '#ffffff',
    backgroundColor: '#000000',
    '&:hover': { backgroundColor: '#000000' },
  },
  saveBtn: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  uploadInput: { display: 'none' },
}));
