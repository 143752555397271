import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { GridRowsProp } from '@material-ui/data-grid';

import SubscriptionsTable from './SubscriptionsTable';
import Filters from './Filters';

import { useAuth } from '../../hooks/use-auth';
import { useSnackbar } from '../../hooks/use-snackbar';
import { IDateFilter, IFilter, SearchParams } from '../../interfaces/shared';
import { getSubscriptions } from '../../requests/subscriptions';
import { IFilterState } from '../../interfaces/subscriptions';
import { isAdmin } from '../../utils/utils';
import useStyles from './styles';

interface ISubscriptionsState {
  content: GridRowsProp;
  totalElements: number;
}

const initialFilters: IFilterState = {
  initialOrderId: '',
  status: '',
  stripeId: '',
  date: { key: '', from: '', to: '' },
};

function Subscriptions() {
  const classes = useStyles();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IFilterState>(initialFilters);
  const [{ page, pageSize, filterFields }, setSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    filterFields: [],
  });
  const [subscriptions, setSubscriptions] = useState<ISubscriptionsState>({
    content: [],
    totalElements: 0,
  });

  useEffect(() => {
    fetchSubscriptions();
    // eslint-disable-next-line
  }, [page, pageSize, filterFields]);

  function fetchSubscriptions() {
    setLoading(true);
    getSubscriptions({ page, pageSize, ...(filterFields?.length && { filterFields }) })
      .then((res) => {
        setLoading(false);
        setSubscriptions({
          content: res.data.content,
          totalElements: res.data.totalElements,
        });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize, filterFields });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize, filterFields });
  }

  function handleApplyFilters() {
    const newFilters: IFilter[] = [];
    Object.entries(filters).forEach(([filterKey, filterValue]) => {
      if (filterKey === 'date') {
        const { key, from, to } = filterValue as IDateFilter;
        const fromValue = from ? `${from}T00:00:00.000Z` : '';
        const toValue = to ? `${to}T23:59:59.999Z` : '';
        if (key && fromValue && toValue) {
          newFilters.push({
            key,
            searchOperation: 'btn',
            value: fromValue,
            toValue,
          });
        }
      } else if (filterValue) {
        newFilters.push({
          key: filterKey,
          searchOperation: 'like',
          value: filterValue,
        });
      }
    });
    setSubscriptions({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: newFilters });
  }

  function handleClearFilters() {
    setFilters(initialFilters);
    setSubscriptions({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: [] });
  }

  if (!isAdmin(user)) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <p className={classes.title}>Subscriptions</p>
      <Filters
        filters={filters}
        setFilters={setFilters}
        onApplyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
      />
      <SubscriptionsTable
        page={page}
        pageSize={pageSize}
        rows={subscriptions.content}
        totalElements={subscriptions.totalElements}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        fetchSubscriptions={fetchSubscriptions}
      />
    </>
  );
}

export default Subscriptions;
