export interface IAddEditAdmin {
  email: string;
  firstName: string;
  lastName: string;
  privileges: string;
  password?: string;
}

export enum EPrivileges {
  MANAGER = 'Manager',
  ADMIN = 'Admin',
  SUPER_ADMIN = 'SuperAdmin',
}
