import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams,
} from '@material-ui/data-grid';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ConfirmDeleteDialog from '../shared/ConfirmDeleteDialog';

import { getFormattedDate, getFormattedValidity, isAdmin } from '../../utils/utils';
import { useSnackbar } from '../../hooks/use-snackbar';
import useStyles from './styles';
import { deleteCreative } from '../../requests/creatives';
import { useAuth } from '../../hooks/use-auth';

interface DeleteDialogData {
  open: boolean;
  isLoading: boolean;
  creativeId: string;
}

const initialDialogData: DeleteDialogData = {
  open: false,
  isLoading: false,
  creativeId: '',
};

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  totalElements: number;
  loading: boolean;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  onRowClick(params: GridRowParams): void;
  fetchCreatives(): void;
}

function CreativesTable(props: IProps) {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [readOnlyFieldFlag, setReadOnlyFieldFlag] = useState<boolean | undefined>(false);
  const { user } = useAuth();
  const [dialogData, setDialogData] = useState<DeleteDialogData>(initialDialogData);
  const {
    page,
    pageSize,
    rows,
    totalElements,
    loading,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    fetchCreatives,
  } = props;

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Title', flex: 1, sortable: false },
    { field: 'creativeStatus', headerName: 'Status', flex: 1, sortable: false },

    {
      field: 'createdTime',
      headerName: 'Created At',
      flex: 1,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) =>
        getFormattedDate(params.getValue(params.id, 'createdTime') as string),
    },
    {
      field: 'validity',
      headerName: 'Validity',
      flex: 1,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) =>
        getFormattedValidity(params.getValue(params.id, 'validity') as string),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      align: 'center',
      hide: readOnlyFieldFlag,
      headerAlign: 'center',
      renderCell: (params) => (
        <IconButton
          size="small"
          onClick={(event) =>
            handleOptionsClick(event, params.getValue(params.id, '_id') as string)
          }
        >
          <MoreIcon fontSize="small" />
        </IconButton>
      ),
    },
  ];
  useEffect(() => {
    setReadOnlyFieldFlag(!isAdmin(user));
  }, [user]);

  function handleOptionsClick(event: React.MouseEvent<HTMLElement>, id: string) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setDialogData({
      ...initialDialogData,
      creativeId: id,
    });
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleDialogClose() {
    setDialogData(initialDialogData);
  }

  function handleDeleteCreativeClick() {
    handleMenuClose();
    setDialogData({ ...dialogData, open: true });
  }

  function handleDeleteCreative() {
    setDialogData({ ...dialogData, isLoading: true });
    deleteCreative(dialogData.creativeId)
      .then(() => {
        showSnackbar({ severity: 'success', message: 'Creative deleted successfully' });
        handleDialogClose();
        fetchCreatives();
      })
      .catch((error: AxiosError) => {
        setDialogData({ ...dialogData, isLoading: false });
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        rowCount={totalElements}
        page={page}
        pageSize={pageSize}
        paginationMode="server"
        loading={loading}
        pagination
        classes={{ root: classes.creativesTable, row: classes.cursorPointer }}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onRowClick={onRowClick}
      />
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleDeleteCreativeClick()}>Delete</MenuItem>
      </Menu>
      <ConfirmDeleteDialog
        open={dialogData.open}
        isLoading={dialogData.isLoading}
        onClose={handleDialogClose}
        onConfirm={handleDeleteCreative}
      />
    </>
  );
}

export default CreativesTable;
