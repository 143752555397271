import ManualProjectDetails from './ManualProjectDetails';
import { IProjectDetailsForm } from '../../interfaces/checkout';
import { Invoice, OrderData } from '../../interfaces/order';
import { CompleteService } from '../../interfaces/services';

interface IProps {
  orderData: OrderData;
  projectDetails: IProjectDetailsForm;
  setProjectDetails: React.Dispatch<React.SetStateAction<IProjectDetailsForm>>;
  service: CompleteService;
  invoice: Invoice;
}

function ProjectDetails(props: IProps) {
  const { projectDetails, invoice, setProjectDetails, service } = props;

  return (
    <div>
      <ManualProjectDetails
        service={service}
        projectDetails={projectDetails}
        invoice={invoice}
        setProjectDetails={setProjectDetails}
      />
    </div>
  );
}

export default ProjectDetails;
