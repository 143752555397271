import { useState } from 'react';
import MuiTooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface IProps extends TooltipProps {
  show?: boolean;
}

const useStyles = makeStyles((theme) => ({
  arrow: { color: theme.palette.common.black },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: '0.75rem',
  },
}));

function Tooltip({ children, show, ...rest }: IProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  function handleOpenCloseTooltip(open: boolean) {
    if (show === undefined || show === true) {
      setOpen(open);
    }
  }

  return (
    <MuiTooltip
      {...rest}
      open={open}
      onOpen={() => handleOpenCloseTooltip(true)}
      onClose={() => handleOpenCloseTooltip(false)}
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
    >
      {children}
    </MuiTooltip>
  );
}

export default Tooltip;
