import { useState } from 'react';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import IconButton from '@material-ui/core/IconButton';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';

import LinearProgressWithLabel from '../shared/Loaders/LinearProgressWithLabel';
import useStyles from './styles';
import { useSnackbar } from '../../hooks/use-snackbar';
import { OrderReport } from '../../interfaces/order';
import { IUser } from '../../interfaces/user';
import { deleteReportFile, uploadReport } from '../../requests/order';
import { isAdmin } from '../../utils/utils';

interface IProps {
  user: IUser | null;
  orderReport: OrderReport;
  setOrderReport: React.Dispatch<React.SetStateAction<OrderReport>>;
  fetchOrderData(orderId: string): void;
}

const initialUploadData = { uploading: false, percentCompleted: 0 };

function Reports(props: IProps) {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { user, orderReport, setOrderReport, fetchOrderData } = props;
  const [uploadInfo, setUploadInfo] = useState(initialUploadData);

  const isUploading = uploadInfo.uploading;
  const uploadPercentCompleted = getPercentCompleted();

  function getPercentCompleted() {
    const percentCompleted = uploadInfo.percentCompleted;
    return percentCompleted === 100 && isUploading ? percentCompleted - 1 : percentCompleted;
  }

  function handleUploadProgress(progressEvent: any) {
    const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setUploadInfo({ uploading: true, percentCompleted: percentage });
  }

  function handleDrop(acceptedFiles: File[]) {
    if (!acceptedFiles.length) return;

    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('id', orderReport._id);
    showSnackbar({ severity: 'info', message: 'Uploading files...' });
    uploadReport(formData, handleUploadProgress)
      .then((res) => {
        setUploadInfo(initialUploadData);
        setOrderReport(res.data);
        fetchOrderData(orderReport._id);
        showSnackbar({ severity: 'success', message: 'Uploaded successfully' });
      })
      .catch((error: AxiosError) => {
        setUploadInfo(initialUploadData);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleDeleteReport(reportId: string) {
    if (window.confirm('Are you sure you want to delete this report?')) {
      deleteReportFile({ id: orderReport._id, reportId })
        .then((res) => {
          setOrderReport(res.data);
          fetchOrderData(orderReport._id);
          showSnackbar({ severity: 'success', message: 'Report deleted successfully' });
        })
        .catch((error: AxiosError) => {
          const errorMessage =
            error?.response?.data?.message || 'An error occurred. Please try again.';
          showSnackbar({ severity: 'error', message: errorMessage });
        });
    }
  }

  function handleDownloadReport(url: string) {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', 'true');
    element.click();
  }

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    disabled: isUploading,
    onDrop: handleDrop,
    accept:
      'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  return (
    <div>
      {isAdmin(user) && (
        <div
          {...getRootProps()}
          className={clsx([
            classes.dropZone,
            isDragActive && classes.dropActive,
            isDragAccept && classes.dropAccept,
            isDragReject && classes.dropReject,
          ])}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <>
              <p>Drag 'n' drop files to upload, or click to select files</p>
              <div>Supported files are PDF, Word and Excel</div>
            </>
          )}
        </div>
      )}
      {isUploading && (
        <div className={classes.uploadingReportContainer}>
          <div>Uploading report files</div>
          <LinearProgressWithLabel value={uploadPercentCompleted} />
        </div>
      )}
      <p className={classes.reportUploadedTitle}>Uploaded report files</p>
      {orderReport.reports && orderReport.reports.length
        ? orderReport.reports.map((report) => (
            <div key={report.id} className={classes.reportFilesContainer}>
              <div className={classes.reportFileIconContainer}>
                <FileIcon htmlColor="#bdbdbd" />
              </div>
              <div>
                <div className={classes.reportTitle}>
                  <span>{report.url.split('/').slice(-1).join('')}</span>
                </div>
              </div>
              <div>
                <IconButton
                  edge="end"
                  aria-label="download"
                  size="small"
                  onClick={() => handleDownloadReport(report.url)}
                >
                  <GetAppIcon fontSize="small" />
                </IconButton>
                {isAdmin(user) && (
                  <IconButton
                    color="secondary"
                    edge="end"
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDeleteReport(report.id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}
              </div>
            </div>
          ))
        : 'No reports found'}
    </div>
  );
}

export default Reports;
