import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LinearProgress from '@material-ui/core/LinearProgress';

import PageTitleWithBack from '../shared/PageTitleWithBack';
import Button from '../shared/Inputs/Button';
import TextField from '../shared/Inputs/TextField';
import Checkbox from '../shared/Inputs/Checkbox';
import LoadingButton from '../shared/Inputs/LoadingButton';

import { useRouter } from '../../hooks/use-router';
import { useSnackbar } from '../../hooks/use-snackbar';
import { MUI_THEME } from '../../utils/utils';
import { REGEX } from '../../constants';
import { Announcement, AnnouncementForm } from '../../interfaces/announcements';
import { getAnnouncementById, saveAnnouncement } from '../../requests/announcements';
import useStyles from './styles';
import { uploadFile } from '../../requests/common';

interface IProps {
  announcementId: string;
  fetchAnnouncements(): void;
}

function AddEditAnnouncement(props: IProps) {
  const { announcementId, fetchAnnouncements } = props;
  const router = useRouter();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const [announcement, setAnnouncement] = useState<Announcement>({} as Announcement);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
    handleSubmit,
  } = useForm<AnnouncementForm>({
    defaultValues: {
      name: '',
      description: '',
      visible: true,
      imageUrl: '',
      linkUrl: '',
    },
  });
  const imageUrl = watch('imageUrl');

  const operation = announcementId === 'new' ? 'create' : 'update';
  const isNewAnnouncement = operation === 'create';

  useEffect(() => {
    if (!isNewAnnouncement && announcementId) {
      fetchAnnouncement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcementId, isNewAnnouncement]);

  function fetchAnnouncement() {
    getAnnouncementById(announcementId)
      .then((res) => {
        setAnnouncement(res.data);
        resetAnnouncementForm(res.data);
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function resetAnnouncementForm(data: Announcement) {
    reset({
      name: data.name,
      description: data.description,
      visible: data.visible,
      imageUrl: data.imageUrl,
      linkUrl: data.linkUrl,
    });
  }

  function handleImageChange(files: FileList | null) {
    if (!files?.length) return;
    const formData = new FormData();
    formData.append('file', files[0]);
    setIsImageUploading(true);
    uploadFile(formData)
      .then((res) => {
        setIsImageUploading(false);
        const url = res.data.url;

        setValue('imageUrl', url);
      })
      .catch((error: AxiosError) => {
        setIsImageUploading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleSaveAnnouncement(data: AnnouncementForm) {
    const requestBody: Partial<Announcement> = {
      ...(!isNewAnnouncement && { _id: announcementId }),
      name: data.name,
      description: data.description,
      visible: data.visible,
      imageUrl: data.imageUrl,
      linkUrl: data.linkUrl,
      ...(announcement?.version && { version: announcement.version }),
    };
    setIsLoading(true);
    saveAnnouncement(requestBody as Announcement)
      .then((res) => {
        setIsLoading(false);
        if (isNewAnnouncement) {
          router.history.replace(`/announcements/${btoa(res.data._id)}`);
          showSnackbar({ severity: 'success', message: 'Announcement created successfully' });
        } else {
          resetAnnouncementForm(res.data);
          showSnackbar({ severity: 'success', message: 'Announcement updated successfully' });
        }
        fetchAnnouncements();
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function getPageTitle() {
    return `${isNewAnnouncement ? 'Add' : 'Edit'} Announcement`;
  }

  return (
    <ThemeProvider theme={MUI_THEME}>
      <Container maxWidth="md" disableGutters>
        <PageTitleWithBack
          title={getPageTitle()}
          disableGutters
          onBackClick={() => router.history.replace('/announcements')}
        />
        <form onSubmit={handleSubmit(handleSaveAnnouncement)}>
          <div className={classes.announcementDetailsContainer}>
            <div className={classes.announcementDetailsForm}>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Title</div>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, onChange, ...rest } }) => {
                    const hasError = Boolean(errors.name);
                    return (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        variant="outlined"
                        fullWidth
                        size="small"
                        error={hasError}
                        onChange={onChange}
                        {...(hasError && { helperText: 'Title is required' })}
                      />
                    );
                  }}
                />
              </div>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Description</div>
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...rest } }) => {
                    const hasError = Boolean(errors.description);
                    return (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        fullWidth
                        variant="outlined"
                        size="small"
                        multiline
                        minRows={3}
                        maxRows={3}
                        {...(hasError && { helperText: 'Description is required' })}
                      />
                    );
                  }}
                />
              </div>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>URL</div>
                <Controller
                  name="linkUrl"
                  control={control}
                  rules={{ required: true, pattern: REGEX.URL }}
                  render={({ field: { ref, onChange, ...rest } }) => {
                    const hasError = Boolean(errors.linkUrl);
                    const hasRequiredError = errors.linkUrl?.type === 'required';
                    const hasPatternError = errors.linkUrl?.type === 'pattern';
                    return (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        fullWidth
                        variant="outlined"
                        size="small"
                        error={hasError}
                        onChange={onChange}
                        {...(hasError && {
                          helperText: (
                            <>
                              {hasRequiredError && 'URL is required'}
                              {hasPatternError && 'URL is not valid'}
                            </>
                          ),
                        })}
                      />
                    );
                  }}
                />
              </div>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Visibility</div>
                <div>
                  <Controller
                    name="visible"
                    control={control}
                    render={({ field: { name, value, onChange } }) => {
                      return (
                        <FormControlLabel
                          classes={{ label: classes.formControlLabel }}
                          control={
                            <Checkbox
                              name={name}
                              color="primary"
                              checked={value}
                              onChange={(event) => onChange(event.target.checked)}
                            />
                          }
                          label="Show in announcements"
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div className={classes.formField}>
                <div className={classes.fieldTitle}>Image</div>
                <div>
                  <Controller
                    name="imageUrl"
                    control={control}
                    rules={{ required: true }}
                    render={() => {
                      const hasImage = Boolean(imageUrl);
                      const hasRequiredError = errors.imageUrl?.type === 'required';
                      return !hasImage ? (
                        <>
                          <input
                            accept="image/*"
                            className={classes.uploadInput}
                            id="announcement-image-upload-button"
                            type="file"
                            onChange={(event) => handleImageChange(event.target.files)}
                          />
                          <label htmlFor="announcement-image-upload-button">
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              startIcon={<PublishIcon />}
                              component="span"
                              disabled={isImageUploading}
                            >
                              Upload Image
                            </Button>
                            {hasRequiredError && (
                              <span className={classes.errorHelperText}>Image is required</span>
                            )}
                            {isImageUploading && (
                              <LinearProgress color="primary" variant="indeterminate" />
                            )}
                          </label>
                        </>
                      ) : (
                        <div className={classes.uploadedImageContainer}>
                          <p className={classes.imageName}>
                            {imageUrl.split('/').slice(-1).join('')}
                          </p>
                          <div>
                            <IconButton
                              size="small"
                              className={classes.viewDeleteIconButton}
                              onClick={() => window.open(imageUrl, '_blank')}
                            >
                              <VisibilityIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              className={classes.viewDeleteIconButton}
                              onClick={() => setValue('imageUrl', '')}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
              <div className={classes.saveButtonContainer}>
                <LoadingButton
                  disabled={isLoading}
                  isLoading={isLoading}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </ThemeProvider>
  );
}

export default AddEditAnnouncement;
