import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createTheme } from '@material-ui/core/styles';

import Routes from './components/shared/Routes';
import Snackbar from './components/shared/Snackbar';
import ScrollToTopOnNavigation from './components/shared/ScrollToTopOnNavigation';

import { ProvideAuth } from './hooks/use-auth';
import { ProvideSnackbar } from './hooks/use-snackbar';

import 'react-datepicker/dist/react-datepicker.css';

const theme = createTheme({
  typography: { fontFamily: ['"Poppins"', 'sans-serif'].join(', ') },
});

function App() {
  return (
    <ProvideAuth>
      <ThemeProvider theme={theme}>
        <ScrollToTopOnNavigation />
        <CssBaseline />
        <ProvideSnackbar>
          <Snackbar />
          <Routes />
        </ProvideSnackbar>
      </ThemeProvider>
    </ProvideAuth>
  );
}

export default App;
