import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import useStyles from './styles';
import RevenueGrowthMobile from '../../assets/img/revenue-growth-mobile.svg';

function Strategies({ applyMaxWidth }: { applyMaxWidth?: boolean }) {
  const classes = useStyles();

  return (
    <div className={classes.strategiesMainContainer}>
      <Container disableGutters maxWidth={applyMaxWidth ? 'lg' : 'xl'}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} sm={7} md={7}>
            <div className={classes.strategiesContainer}>
              <h2>How we improve your revenue</h2>
              <p className={classes.strategyDescription}>
                We are #1 Indo American Whitelable Digital Agency. A company that brings together
                the best skills from India and the US to help clients achieve their business goals.
              </p>
              <p className={classes.strategyDescription}>
                The convergence of talented marketers from India and the US has helped us deliver
                the best of both worlds.
              </p>
              <p className={classes.strategyDescription}>
                We offer advanced Digital Marketing and Web Development solutions at an affordable
                cost like no other with our unique service delivery process.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <img src={RevenueGrowthMobile} alt="" className={classes.revenueGrowthImage} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Strategies;
