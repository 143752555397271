import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  title: { color: '#172B70', fontSize: 22, fontWeight: 700 },
  buttonContainer: {
    margin: theme.spacing(2, 0, 1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  couponsTable: { height: 'calc(100vh - 250px) !important' },
  cursorPointer: { cursor: 'pointer' },
  couponDetailsContainer: {
    background: '#FFFFFF',
    border: '1px solid #E4EBF8',
    borderRadius: 4,
  },
  couponDetailsForm: {
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.sm,
      padding: theme.spacing(2, 0),
    },
  },
  formField: { margin: theme.spacing(1, 0), padding: theme.spacing(1, 0) },
  fieldTitle: {
    color: '#172B70',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    fontSize: '0.875rem',
  },
  formControlLabel: { color: '#2F3034', fontSize: '0.875rem' },
  adornmentText: { fontWeight: 'bold', color: '#577496', paddingRight: theme.spacing(1) },
  autoCompleteLabel: { fontSize: '0.875rem', color: '#384F7A' },
  autoCompleteSelectAllLabel: { padding: theme.spacing(1, 0) },
  redemptionLimitCheckboxContainer: {
    display: 'flex',
    margin: theme.spacing(1, 0),
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  dateTimePickerText: { '& input': { fontSize: '0.875rem' } },
  saveButtonContainer: { margin: theme.spacing(2, 0) },
}));
