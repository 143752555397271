import React, { useState } from 'react';
import { AxiosError } from 'axios';
import {
  UseFormReturn,
  useFieldArray,
  Control,
  UseFormWatch,
  UseFormSetValue,
  Controller,
} from 'react-hook-form';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';

import Button from '../shared/Inputs/Button';
import LoadingButton from '../shared/Inputs/LoadingButton';
import TextField from '../shared/Inputs/TextField';

import useStyles from './styles';
import { useSnackbar } from '../../hooks/use-snackbar';
import { CompleteService } from '../../interfaces/services';
import {
  IPackageQuantity,
  IProjectDetailsForm,
  IProjectIntakeItem,
} from '../../interfaces/checkout';
import { uploadFile } from '../../requests/common';

interface IProps {
  service: CompleteService;
  packageQuantities: IPackageQuantity[];
  projectDetailsForm: UseFormReturn<IProjectDetailsForm>;
}

function ManualProjectDetails(props: IProps) {
  const classes = useStyles();
  const { service, packageQuantities, projectDetailsForm } = props;
  const { control, watch, getValues, setValue } = projectDetailsForm;
  const { append } = useFieldArray({ control, name: 'manualEntries' });
  const intakeItems = service?.intakeItems || [];

  function getPackagesWithCount() {
    return packageQuantities.filter((p) => +p.quantity);
  }

  function handleAdd(item: IPackageQuantity) {
    let packageInFormIndex = getPackageInFormIndex(item.id);
    if (packageInFormIndex === -1) {
      append(
        {
          packageId: item.id,
          packageName: item.name,
          values: [{ items: getNewProjectIntakeItem() }],
        },
        { shouldFocus: false }
      );
    }
  }

  function getPackageInFormIndex(packageId: string) {
    return getValues().manualEntries.findIndex((p) => p.packageId === packageId);
  }

  function getNewProjectIntakeItem() {
    return intakeItems.map<IProjectIntakeItem>((item) => ({
      name: item.name,
      placeHolder: item.placeHolder,
      helpText: item.helpText,
      sequence: item.sequence,
      itemType: item.itemType,
      mandatory: item.mandatory,
      value: '',
    }));
  }

  const packagesWithCount = getPackagesWithCount();
  return (
    <>
      <p className={classes.projectTypeTitle}>Add Project Details</p>
      <div>
        {packagesWithCount.length === 0 && <p>No package selected</p>}
        {packagesWithCount.map((p) => {
          const packageInFormIndex = getPackageInFormIndex(p.id);
          return (
            <Accordion key={p.id} elevation={0} classes={{ root: classes.projectDetailsAccordion }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.projectDetailsAccordionTitle}>{p.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box width="100%">
                  {packageInFormIndex !== -1 ? (
                    <IntakeItem
                      nestIndex={packageInFormIndex}
                      control={control}
                      watch={watch}
                      setValue={setValue}
                      getNewProjectIntakeItem={getNewProjectIntakeItem}
                    />
                  ) : (
                    <p>No entries found. Please create a new entry.</p>
                  )}
                </Box>
              </AccordionDetails>
              {packageInFormIndex === -1 && (
                <AccordionActions>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => handleAdd(p)}
                  >
                    Create
                  </Button>
                </AccordionActions>
              )}
            </Accordion>
          );
        })}
      </div>
    </>
  );
}

function IntakeItem({
  nestIndex,
  control,
  watch,
  setValue,
  getNewProjectIntakeItem,
}: {
  nestIndex: number;
  control: Control<IProjectDetailsForm>;
  watch: UseFormWatch<IProjectDetailsForm>;
  setValue: UseFormSetValue<IProjectDetailsForm>;
  getNewProjectIntakeItem(): IProjectIntakeItem[];
}) {
  const classes = useStyles();
  const name = `manualEntries.${nestIndex}.values` as const;
  const { fields, remove, append } = useFieldArray({ control, name });

  return (
    <>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <div className={classes.intakeItemWithDeleteContainer}>
            <div>
              <IntakeItemField
                nestIndex1={nestIndex}
                nestIndex2={index}
                control={control}
                watch={watch}
                setValue={setValue}
              />
            </div>
            <div>
              {fields.length !== 1 && (
                <IconButton size="small" color="secondary" onClick={() => remove(index)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          </div>
          {fields.length === index + 1 && (
            <div className={classes.addMoreButtonContainer}>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => append({ items: getNewProjectIntakeItem() }, { shouldFocus: false })}
              >
                Add More
              </Button>
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  );
}

function IntakeItemField({
  nestIndex1,
  nestIndex2,
  control,
  watch,
  setValue,
}: {
  nestIndex1: number;
  nestIndex2: number;
  control: Control<IProjectDetailsForm>;
  watch: UseFormWatch<IProjectDetailsForm>;
  setValue: UseFormSetValue<IProjectDetailsForm>;
}) {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const [isUploading, setIsUploading] = useState(false);
  const name = `manualEntries.${nestIndex1}.values.${nestIndex2}.items` as const;
  const { fields } = useFieldArray({ control, name });
  const values = watch(name);
  const controlledValues = fields.map((field, index) => {
    return {
      ...field,
      ...(values[index] as IProjectIntakeItem),
    };
  });

  function handleFileChange(index: number, files: FileList | null) {
    if (!files?.length) return;
    const formData = new FormData();
    formData.append('file', files[0]);
    setIsUploading(true);
    uploadFile(formData)
      .then((res) => {
        setIsUploading(false);
        const url = res.data.url;
        setValue(
          `manualEntries.${nestIndex1}.values.${nestIndex2}.items.${index}.value` as const,
          url as never
        );
      })
      .catch((error: AxiosError) => {
        setIsUploading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleRemoveFile(index: number) {
    setValue(
      `manualEntries.${nestIndex1}.values.${nestIndex2}.items.${index}.value` as const,
      '' as never
    );
  }

  return (
    <div className={classes.projectIntakeItemContainer}>
      {controlledValues.map((item, index) => (
        <Controller
          key={item.id}
          control={control}
          name={`manualEntries.${nestIndex1}.values.${nestIndex2}.items.${index}.value` as const}
          defaultValue=""
          render={({ field: { ref, name, value, ...rest } }) => {
            switch (item.itemType) {
              case 'FILE_UPLOAD': {
                const id = `contained-button-file-${item.id}`;
                return (
                  <div>
                    {!value ? (
                      <>
                        <input
                          className={classes.uploadInput}
                          id={id}
                          name={name}
                          type="file"
                          accept=".doc,.docx,.xls,.xlsx"
                          onChange={(event) => handleFileChange(index, event.target.files)}
                        />
                        <label htmlFor={id}>
                          <LoadingButton
                            variant="contained"
                            color="primary"
                            component="span"
                            isLoading={isUploading}
                            disabled={isUploading}
                          >
                            {item.name}
                          </LoadingButton>
                        </label>
                      </>
                    ) : (
                      <List dense disablePadding>
                        <ListItem>
                          <ListItemText
                            classes={{ primary: classes.intakeItemFileName }}
                            primary={`${value}`.split('/').slice(-1)}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              size="small"
                              color="secondary"
                              onClick={() => handleRemoveFile(index)}
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    )}
                  </div>
                );
              }
              default: {
                const isMultiline = item.itemType === 'TEXT_AREA';
                return (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    label={item.name}
                    type={item.itemType === 'NUMBER' ? 'number' : 'text'}
                    multiline={isMultiline}
                    variant="outlined"
                    {...(item.placeHolder && { placeholder: item.placeHolder })}
                    {...(item.helpText && { helperText: <span>{item.helpText}</span> })}
                    {...(isMultiline && { rows: 3 })}
                  />
                );
              }
            }
          }}
        />
      ))}
    </div>
  );
}

export default ManualProjectDetails;
