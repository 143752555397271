import { Switch, Route, useParams } from 'react-router-dom';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import MinimalAppHeader from './MinimalAppHeader';
import Checkout from '../Checkout/Checkout';
import Footer from '../shared/Footer';

import { STRIPE_KEY_OLD, STRIPE_KEY_NEW } from '../../constants';
import { getCompleteServiceDetails } from '../../requests/service';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useSnackbar } from '../../hooks/use-snackbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: theme.mixins.toolbar,
    content: { padding: theme.spacing(3), backgroundColor: '#FBFBFD', minHeight: '100vh' },
  })
);

function MinimalAppLayout() {
  const classes = useStyles();
  const params = useParams<{ serviceId: string }>();
  const serviceId = atob(params.serviceId);
  const { showSnackbar } = useSnackbar();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(() =>
    Promise.resolve(null)
  );
  const [stripeKey, setStripeKey] = useState<number>();

  useEffect(() => {
    getCompleteServiceDetails(serviceId)
      .then((res) => {
        const serviceType = res.data.serviceType;
        const tempPromise =
          serviceType === 'REGULAR' ? loadStripe(STRIPE_KEY_NEW) : loadStripe(STRIPE_KEY_OLD);
        const tempKey = serviceType === 'REGULAR' ? 1 : 2;

        setStripePromise(tempPromise);
        setStripeKey(tempKey);
      })
      .catch((error: AxiosError) => {
        const errMessage =
          error.response?.data?.message ||
          'An error occurred while fetching service details. Please try again.';
        showSnackbar({
          severity: 'error',
          message: errMessage,
        });
      });

    // eslint-disable-next-line
  }, [serviceId]);

  useEffect(() => {
    console.log(stripePromise);
  }, [stripePromise]);

  return (
    <>
      <MinimalAppHeader />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route exact path="/services/:serviceId/checkout">
            <Elements key={stripeKey} stripe={stripePromise}>
              <Checkout />
            </Elements>
          </Route>
        </Switch>
      </div>
      <Footer />
    </>
  );
}

export default MinimalAppLayout;
