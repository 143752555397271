import MuiCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF6329',
    },
  },
  overrides: {
    MuiCheckbox: {
      indeterminate: {
        color: '#FF6329',
      },
    },
  },
});

function Checkbox(props: CheckboxProps) {
  return (
    <ThemeProvider theme={theme}>
      <MuiCheckbox {...props} />
    </ThemeProvider>
  );
}

export default Checkbox;
