import dayjs from 'dayjs';
import Avatar from '@material-ui/core/Avatar';

import { OrderAudit, OrderHistory } from '../../interfaces/order';
import useStyles from './styles';

interface IProps {
  orderHistory: OrderHistory;
}

function History(props: IProps) {
  const { orderHistory } = props;
  const classes = useStyles();

  function getOrderAudits() {
    return orderHistory?.orderAudits || [];
  }

  function getDetails(audit: OrderAudit) {
    return audit.details || [];
  }

  function getFormattedDate(date: string) {
    return dayjs(date).format('DD MMM YYYY');
  }

  function getFormattedTime(date: string) {
    return dayjs(date).format('h:mm A');
  }

  const orderAudits = getOrderAudits();
  return (
    <div>
      {orderAudits.length ? (
        orderAudits.map((audit) => (
          <div key={audit.id} className={classes.orderHistoryItem}>
            <div>
              <div className={classes.orderHistoryNameContainer}>
                <div>
                  <Avatar className={classes.orderHistoryAvatar}></Avatar>
                </div>
                <div className={classes.orderHistoryName}>{audit.modifiedByName}</div>
              </div>
              {getDetails(audit).map((d, i) => (
                <div key={i}>{d}</div>
              ))}
            </div>
            <div>
              <div className={classes.orderHistoryDate}>{getFormattedDate(audit.modifiedTime)}</div>
              <div className={classes.orderHistoryTime}>{getFormattedTime(audit.modifiedTime)}</div>
            </div>
          </div>
        ))
      ) : (
        <div>No history available</div>
      )}
    </div>
  );
}

export default History;
