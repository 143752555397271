import { Redirect } from 'react-router-dom';
import { downloadBlob, isAdmin } from '../../utils/utils';
import { useAuth } from '../../hooks/use-auth';
import Filters from './Filters';
import { useEffect, useState } from 'react';
import useStyles from './styles';
import { useSnackbar } from '../../hooks/use-snackbar';
import { IDateFilter, IFilter, SearchParams } from '../../interfaces/shared';
import { IAffiliatesState, IFilterState } from '../../interfaces/sharedAffiliates';
import { exportAffiliates, getAffiliates } from '../../requests/affiliates';
import { AxiosError } from 'axios';
import AffiliatesTable from './AffiliatesTable';

const initialFilters = {
  name: '',
  email: '',
  code: '',
  date: { key: '', from: '', to: '' },
};

function Affiliates() {
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();
  const { user } = useAuth();
  const [filters, setFilters] = useState<IFilterState>(initialFilters);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [{ page, pageSize, filterFields }, setSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    filterFields: [],
  });
  const [affiliates, setAffiliates] = useState<IAffiliatesState>({
    content: [],
    totalElements: 0,
  });
  useEffect(() => {
    fetchAffiliates();
    // eslint-disable-next-line
  }, [page, pageSize, filterFields]);

  if (!isAdmin(user)) {
    return <Redirect to="/" />;
  }

  function handleApplyFilters() {
    const newFilters: IFilter[] = [];
    Object.entries(filters).forEach(([filterKey, filterValue]) => {
      if (filterKey === 'date') {
        const { key, from, to } = filterValue as IDateFilter;
        const fromValue = from ? `${from}T00:00:00.000Z` : '';
        const toValue = to ? `${to}T23:59:59.999Z` : '';
        if (key && fromValue && toValue) {
          newFilters.push({
            key,
            searchOperation: 'btn',
            value: fromValue,
            toValue,
          });
        }
        return;
      }
      if (filterValue) {
        newFilters.push({
          key: filterKey,
          searchOperation: 'like',
          value: filterValue,
        });
        return;
      }
    });
    setAffiliates({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: newFilters });
  }

  function handleClearFilters() {
    setFilters(initialFilters);
    setAffiliates({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: [] });
  }

  function fetchAffiliates() {
    setLoading(true);
    getAffiliates({
      page,
      pageSize,
      ...(filterFields?.length && { filterFields }),
    })
      .then((res) => {
        setLoading(false);
        setAffiliates({
          content:
            res.data.content?.map((commission) => ({ ...commission, id: commission._id })) || [],
          totalElements: res.data.totalElements,
        });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize, filterFields });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize, filterFields });
  }

  function handleExportOrders() {
    setExporting(true);
    showSnackbar({
      severity: 'info',
      message: 'Please wait while we prepare the data. Your file will be downloaded any moment',
    });
    exportAffiliates({ ...(filterFields?.length && { filterFields }) })
      .then((res) => {
        downloadBlob('Affiliates', res.data);
        setExporting(false);
        showSnackbar({ severity: 'success', message: 'File has been downloaded' });
      })
      .catch(() => {
        setExporting(false);
        showSnackbar({ severity: 'error', message: 'An error occurred while exporting' });
      });
  }
  return (
    <>
      <p className={classes.title}>Affiliates</p>
      <Filters
        filters={filters}
        setFilters={setFilters}
        onApplyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
        onExportOrders={handleExportOrders}
        isExporting={exporting}
      />
      <AffiliatesTable
        page={page}
        pageSize={pageSize}
        rows={affiliates.content}
        totalElements={affiliates.totalElements}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        fetchAffiliates={fetchAffiliates}
      />
    </>
  );
}
export default Affiliates;
