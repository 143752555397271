import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import TextField from '../shared/Inputs/TextField';
import Button from '../shared/Inputs/Button';

import useStyles from './styles';
import { IFilterState } from '../../interfaces/announcements';

interface IProps {
  filters: IFilterState;
  setFilters: React.Dispatch<React.SetStateAction<IFilterState>>;
  onApplyFilters(): void;
  onClearFilters(): void;
}

const announcementStateFields = [
  { label: 'All', value: 'all' },
  { label: 'Visible', value: 'visible' },
  { label: 'Not Visible', value: 'not-visible' },
];

function Filters(props: IProps) {
  const classes = useStyles();
  const { filters, setFilters, onApplyFilters, onClearFilters } = props;

  function onChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  }

  function handleChange(name: string, value: any) {
    setFilters({ ...filters, [name]: value });
  }

  return (
    <div className={classes.filtersWithActionsContainer}>
      <div className={classes.filtersContainer}>
        <TextField
          variant="outlined"
          size="small"
          label="Title"
          name="name"
          value={filters.name}
          onChange={onChange}
        />
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel id="date-field-label">Visibility</InputLabel>
          <Select
            label="Visibility"
            labelId="Visibility-label"
            value={filters.visible}
            onChange={(event) => handleChange('visible', event.target.value)}
            MenuProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              transformOrigin: { vertical: 'top', horizontal: 'center' },
              getContentAnchorEl: null,
            }}
          >
            {announcementStateFields.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.filterActionsContainer}>
        <div>
          <Button variant="contained" color="primary" size="small" onClick={onApplyFilters}>
            Apply Filters
          </Button>
        </div>
        <div>
          <Button variant="outlined" size="small" onClick={onClearFilters}>
            Clear Filters
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Filters;
