import React, { useEffect } from 'react';
import { StripeCardElementChangeEvent, StripeCardElementOptions } from '@stripe/stripe-js';
import { CardElement } from '@stripe/react-stripe-js';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import RemoveIcon from '@material-ui/icons/Remove';
import Autocomplete from '@material-ui/lab/Autocomplete';

import TextField from '../shared/Inputs/TextField';
import Tooltip from '../shared/Tooltip';
import useStyles from './styles';

import { CompleteService, Package } from '../../interfaces/services';
import { Countries } from '../../interfaces/shared';
import { IPackageQuantity } from '../../interfaces/checkout';
import { IAddress, IUser } from '../../interfaces/user';

import Visa from '../../assets/img/visa.png';
import MasterCard from '../../assets/img/mastercard.svg';
import Amex from '../../assets/img/amex.svg';
import Discover from '../../assets/img/discover.jpeg';

const CARD_PROCESSORS = [
  { name: 'visa', icon: Visa, width: 32, height: 32 },
  { name: 'mastercard', icon: MasterCard, width: 32, height: 32 },
  { name: 'amex', icon: Amex, width: 32, height: 32 },
  { name: 'discover', icon: Discover, width: 32, height: 22, align: 'middle' },
];

interface ICheckoutFormProps {
  service: CompleteService;
  isAuthenticated: boolean;
  countries: Countries;
  billingAddress: IAddress;
  packageQuantities: IPackageQuantity[];
  setPackageQuantities: React.Dispatch<React.SetStateAction<IPackageQuantity[]>>;
  handleDetailsChange(type: string, name: string, value: string): void;
  errors: { [key: string]: boolean | string };
  userDetails: Partial<IUser>;
  phone: [string, React.Dispatch<React.SetStateAction<string>>];
  revalidateFields(): void;
  handleCardElementChange(event: StripeCardElementChangeEvent): void;
}

const CARD_OPTIONS: StripeCardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      fontWeight: 500,
      fontFamily: 'Poppins, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      color: 'rgba(0, 0, 0, 0.84)',
    },
  },
};

function CheckoutForm(props: ICheckoutFormProps) {
  const {
    service,
    userDetails,
    phone,
    isAuthenticated,
    billingAddress,
    countries,
    packageQuantities,
    setPackageQuantities,
    handleDetailsChange,
    revalidateFields,
    errors,
    handleCardElementChange,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    const packages = service.serviceOptions;
    if (packages?.length) {
      const formattedPackages = packages?.map<IPackageQuantity>((serviceOption: Package) => {
        const packageId = serviceOption.serviceOptionId;
        return {
          id: packageId,
          name: serviceOption.name,
          description: serviceOption.description,
          price: serviceOption.price,
          quantity: '0',
          discountItems:
            serviceOption.discountItems?.reduce((acc, item) => {
              acc[`${item.count}`] = item.percent;
              return acc;
            }, {} as { [key: string]: number }) || {},
        };
      });
      setPackageQuantities(formattedPackages);
    }
    // eslint-disable-next-line
  }, [service]);

  function handleQuantityChange(packageId: string, quantity: string) {
    const serviceOptionIndex = packageQuantities.findIndex((item) => item.id === packageId);
    if (serviceOptionIndex === -1) return;
    const serviceOption = { ...packageQuantities[serviceOptionIndex], quantity };
    packageQuantities[serviceOptionIndex] = serviceOption;
    setPackageQuantities([...packageQuantities]);
  }

  function incOrDecQuantity(mode: string, packageId: string, quantity: string) {
    const count = Number(quantity);
    if (mode === 'inc') {
      handleQuantityChange(packageId, `${count + 1}`);
    }
    if (mode === 'dec' && count) {
      handleQuantityChange(packageId, `${count - 1}`);
    }
  }

  function handleAddressChange(event: React.ChangeEvent<HTMLInputElement>) {
    handleDetailsChange('billingAddress', event.target.name, event.target.value);
  }

  function handleUserDetailsChange(event: React.ChangeEvent<HTMLInputElement>) {
    handleDetailsChange('userDetails', event.target.name, event.target.value);
  }

  function hasFieldError(fieldName: string) {
    return Boolean(errors[fieldName]);
  }

  const hasAddressError = hasFieldError('address');
  const hasCityError = hasFieldError('city');
  const hasCountryError = hasFieldError('country');
  const hasZipCodeError = hasFieldError('zipCode');
  const hasCardElementError = hasFieldError('cardElement');

  return (
    <div>
      <div className={classes.detailsFormContainer}>
        <p className={classes.sectionTitle}>Customer Details</p>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              name="firstName"
              label="First name"
              size="small"
              fullWidth
              className={classes.textField}
              InputProps={{ readOnly: isAuthenticated }}
              value={userDetails.firstName}
              onChange={handleUserDetailsChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              name="lastName"
              label="Last name"
              size="small"
              fullWidth
              className={classes.textField}
              InputProps={{ readOnly: isAuthenticated }}
              value={userDetails.lastName}
              onChange={handleUserDetailsChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              name="email"
              label="Email"
              size="small"
              fullWidth
              className={classes.textField}
              InputProps={{ readOnly: isAuthenticated }}
              value={userDetails.email}
              onChange={handleUserDetailsChange}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.detailsFormContainer}>
        <p className={classes.sectionTitle}>Choose your quantity</p>
        <Grid container>
          <Grid item container xs={12} sm={12} md={7}>
            {Object.values(packageQuantities).map((item) => {
              const serviceOptionId = item.id;
              return (
                <React.Fragment key={serviceOptionId}>
                  <Grid item xs={12} sm={12} md={8}>
                    <p>
                      <span className={classes.packageTitle}>{item.name}</span>
                      <span className={classes.packageDescriptionIcon}>
                        <Tooltip
                          arrow
                          placement="top"
                          title={item.description || 'No description available'}
                        >
                          <InfoIcon htmlColor="#C4C4C4" fontSize="small" />
                        </Tooltip>
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              size="small"
                              onClick={() =>
                                incOrDecQuantity('inc', serviceOptionId, item.quantity)
                              }
                            >
                              <AddIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              edge="start"
                              size="small"
                              onClick={() =>
                                incOrDecQuantity('dec', serviceOptionId, item.quantity)
                              }
                            >
                              <RemoveIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={item.quantity || ''}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (value !== '') {
                          if (!/^\d+$/.test(value)) {
                            return;
                          }
                        }
                        handleQuantityChange(serviceOptionId, value);
                      }}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            {service?.checkoutDescription && (
              <div
                className={classes.orderPerks}
                dangerouslySetInnerHTML={{ __html: service.checkoutDescription }}
              ></div>
            )}
          </Grid>
        </Grid>
      </div>
      <div className={classes.detailsFormContainer}>
        <p className={classes.sectionTitle}>Billing Address</p>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              name="address"
              label="Address"
              size="small"
              fullWidth
              className={classes.textField}
              value={billingAddress.address}
              onChange={handleAddressChange}
              onBlur={revalidateFields}
              error={hasAddressError}
              {...(hasAddressError && { helperText: 'Address is required' })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              name="city"
              label="City"
              size="small"
              fullWidth
              className={classes.textField}
              value={billingAddress.city}
              onChange={handleAddressChange}
              onBlur={revalidateFields}
              error={hasCityError}
              {...(hasCityError && { helperText: 'City is required' })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              options={[''].concat(countries)}
              autoHighlight
              getOptionLabel={(option) => option}
              value={billingAddress.country}
              className={classes.textField}
              disableClearable
              onChange={(_, value) => handleDetailsChange('billingAddress', 'country', value || '')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="country"
                  label="Country"
                  size="small"
                  fullWidth
                  error={hasCountryError}
                  onBlur={revalidateFields}
                  {...(hasCountryError && { helperText: 'Country is required' })}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              name="state"
              label="State/Province/Region"
              size="small"
              fullWidth
              className={classes.textField}
              value={billingAddress.state}
              onChange={handleAddressChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              name="zipCode"
              label="Postal/Zip Code"
              size="small"
              fullWidth
              className={classes.textField}
              value={billingAddress.zipCode}
              onChange={handleAddressChange}
              error={hasZipCodeError}
              onBlur={revalidateFields}
              {...(hasZipCodeError && { helperText: 'Zip Code is required' })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              name="phoneNo"
              label="Phone"
              size="small"
              fullWidth
              className={classes.textField}
              value={phone[0]}
              onChange={(event) => phone[1](event.target.value)}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.detailsFormContainer}>
        <p className={classes.sectionTitle}>Card Details</p>
        <div>
          <div className={classes.acceptedCardProcessors}>
            {CARD_PROCESSORS.map((cardProcessor, i) => (
              <React.Fragment key={cardProcessor.name}>
                <div>
                  <img
                    src={cardProcessor.icon}
                    alt={cardProcessor.name}
                    width={cardProcessor.width}
                    height={cardProcessor.height}
                    {...(cardProcessor.align && { align: cardProcessor.align })}
                  />
                </div>
                {i === CARD_PROCESSORS.length - 1 && (
                  <div className={classes.morePaymentProcessorText}>and more...</div>
                )}
              </React.Fragment>
            ))}
          </div>
          <CardElement
            options={{
              ...CARD_OPTIONS,
              classes: {
                base: classes.stripeCardElement,
                invalid: classes.stripeCardElementInvalid,
              },
            }}
            onChange={handleCardElementChange}
          />
          {hasCardElementError && (
            <FormHelperText className={classes.error}>{errors.cardElement}</FormHelperText>
          )}
        </div>
      </div>
    </div>
  );
}

export default CheckoutForm;
