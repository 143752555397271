import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  title: { color: '#172B70', fontSize: 22, fontWeight: 700 },
  subscriptionsTable: { height: 'calc(100vh - 250px) !important' },
  filtersWithActionsContainer: { display: 'flex', alignItems: 'center' },
  filtersContainer: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    marginRight: theme.spacing(1),
    flex: 1,
    flexWrap: 'wrap',
    '& > div': { width: 200, marginBottom: theme.spacing(1) },
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  filterActionsContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  dateFilterButton: {
    padding: '6px 21px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
    justifyContent: 'space-between',
  },
  dateFilterPopover: { marginTop: theme.spacing(0.5) },
  dateFilterContainer: { padding: theme.spacing(2) },
  dateFilterDatePickerContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  dateFilterDatePicker: { fontSize: '0.875rem' },
  linkBlue: {
    color: '#384F7A',
    '&:hover': { color: '#384F7A' },
    '&:visited': { color: '#384F7A' },
  },
}));
