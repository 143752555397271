import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { Invoice } from '../../interfaces/order';
import { IAddress } from '../../interfaces/user';

import Logo from '../../assets/img/logo.png';
import PoppinsRegular from '../../assets/fonts/Poppins/Poppins-Regular.ttf';
import PoppinsBold from '../../assets/fonts/Poppins/Poppins-Bold.ttf';
import { getFormattedDate, getFormattedDateForInvoice } from '../../utils/utils';
import { calculateDiscountedPrice } from '../Checkout/util';
import { CompleteService, Service } from '../../interfaces/services';
import { IInvoiceDetails } from '../../interfaces/invoice';

function getClientAddress(address: IAddress) {
  if (!address) return '';
  return `${address.address}, ${address.city}\n${address.state ? address.state + ' ' : ''}${
    address.zipCode
  }, ${address.country}`;
}

export function generateInvoice(service: CompleteService, invoice: Invoice) {
  const doc = new jsPDF();
  doc.addFont(PoppinsRegular, 'Poppins', 'normal');
  doc.addFont(PoppinsBold, 'Poppins', 'bold');

  doc.setFont('Poppins', 'bold');
  doc.setFontSize(22);
  doc.setTextColor('#FF6F24');
  doc.text('INVOICE', 12, 16);

  const pageSize = doc.internal.pageSize;
  const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
  const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

  doc.addImage(Logo, 'PNG', pageWidth - 60, 6, 40, 15);

  autoTable(doc, {
    margin: { right: 0, left: 0 },
    startY: 30,
    theme: 'plain',
    showHead: 'never',
    showFoot: 'never',
    styles: { font: 'Poppins', textColor: '#384F7A', cellWidth: pageWidth / 3 },
    bodyStyles: { fillColor: '#FFF9F6' },
    columnStyles: {
      0: { cellPadding: { left: 12, top: 4, right: 4, bottom: 4 } },
    },
    body: [
      [
        {
          content: `Invoiced to:\n\n${invoice.clientName}\n${getClientAddress(
            invoice.clientAddress
          )}`,
          rowSpan: 3,
        },
        {
          content: `Order Id: ${invoice.orderId}`,
          styles: { cellPadding: { top: 4, right: 2, bottom: 0, left: 2 } },
        },
        {
          content: `Issued On: ${getFormattedDate(invoice.createdTime)}`,
          styles: { cellPadding: { top: 4, right: 2, bottom: 0, left: 2 } },
        },
      ],
      [
        {
          content: `Invoice Number: ${invoice.invoiceId}`,
          styles: { cellPadding: { top: 2, right: 2, bottom: 0, left: 2 } },
        },
        {
          content: `Paid On: ${getFormattedDate(invoice.paidDate)}`,
          styles: { cellPadding: { top: 2, right: 2, bottom: 0, left: 2 } },
        },
      ],
      [
        {
          content: `Transaction Id: ${invoice.transactionId || 'N/A'}`,
          styles: { cellPadding: { top: 0, right: 2, bottom: 4, left: 2 } },
        },
      ],
    ],
  });

  let subTotal = 0;
  const invoiceItems = invoice.invoiceItems.map((item) => {
    const packageItem = service.serviceOptions.find(
      (packageItem) => packageItem.serviceOptionId === item.packageId
    );
    const discountItems =
      packageItem?.discountItems?.reduce((acc, item) => {
        acc[`${item.count}`] = item.percent;
        return acc;
      }, {} as { [key: string]: number }) || {};

    const totalPrice = item.price * item.quantity;
    const discountedPrice = calculateDiscountedPrice(item.quantity, item.price, discountItems);
    subTotal = subTotal + discountedPrice;
    return [
      item.name,
      `$${item.price}`,
      `${item.quantity}`,
      `${
        totalPrice !== discountedPrice ? `(-$${totalPrice - discountedPrice}) ` : ''
      }$${discountedPrice}`,
    ];
  });
  const tax = 0;
  const discountedPrice = invoice.discountedAmount || 0;
  const discount = subTotal - discountedPrice;
  const grandTotal = subTotal - discount + tax;

  autoTable(doc, {
    margin: { right: 12, left: 12 },
    startY: (doc as any).lastAutoTable.finalY + 5,
    theme: 'plain',
    rowPageBreak: 'avoid',
    showFoot: 'lastPage',
    styles: { font: 'Poppins', textColor: '#384F7A' },
    alternateRowStyles: { fillColor: '#FFFFFF' },
    bodyStyles: {
      fillColor: '#FFF1E9',
      cellPadding: { horizontal: 2, vertical: 4 },
    },
    columnStyles: { 2: { halign: 'center' }, 3: { halign: 'right' } },
    headStyles: { fillColor: '#577496', textColor: '#FFFFFF' },
    head: [
      [
        'Item Description',
        'Price',
        { content: 'Quantity', styles: { halign: 'center' } },
        { content: 'Total', styles: { halign: 'right' } },
      ],
    ],
    body: [...invoiceItems],
    footStyles: { cellPadding: { horizontal: 2, vertical: 4 } },
    foot: [
      [
        '',
        { content: 'Subtotal', colSpan: 2 },
        { content: `$${subTotal}`, styles: { halign: 'right' } },
      ],
      [
        '',
        { content: 'Promotion Applied', colSpan: 2 },
        { content: `-$${discount}`, styles: { halign: 'right' } },
      ],
      ['', { content: 'Tax', colSpan: 2 }, { content: `$${tax}`, styles: { halign: 'right' } }],
      [
        '',
        {
          content: 'Grand Total',
          colSpan: 2,
          styles: { textColor: '#FF6F24', fontSize: 14 },
        },
        {
          content: `$${grandTotal}`,
          styles: { textColor: '#FF6F24', halign: 'right', fontSize: 14 },
        },
      ],
    ],
    didDrawPage: () => {
      doc.setLineWidth(0.2);
      doc.setDrawColor('#8999AB');
      doc.line(12, pageHeight - 12, pageWidth - 12, pageHeight - 12);

      doc.setFont('Poppins', 'normal');
      doc.setFontSize(8);
      doc.setTextColor('#384F7A');
      doc.text(
        '2nd Floor, GK Complex, Embassy Golf Links Road, Amarjyoti Layout, Domlur, Bengaluru, Karnataka 560071 India',
        pageWidth / 2,
        pageHeight - 6,
        { align: 'center' }
      );

      doc.setLineWidth(2.5);
      doc.setDrawColor('#FF6F24');
      doc.line(0, pageHeight, pageWidth, pageHeight);
    },
  });

  window.open(URL.createObjectURL(doc.output('blob')));
}

export function generateServiceInvoice(
  service: Service,
  checkOUtUrl: string,
  invoiceDetail: IInvoiceDetails
) {
  const doc = new jsPDF();
  doc.addFont(PoppinsRegular, 'Poppins', 'normal');
  doc.addFont(PoppinsBold, 'Poppins', 'bold');

  doc.setFont('Poppins', 'bold');
  doc.setFontSize(22);
  doc.setTextColor('#FF6F24');
  doc.text('INVOICE', 12, 16);

  const pageSize = doc.internal.pageSize;
  const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
  const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
  let stanVentures = '';
  let address = '';
  let bankAccount = '';
  if (invoiceDetail.invoiceCountry === 'IN') {
    stanVentures =
      'STAN SOFTTECH PRIVATE LIMITED\n257, Second Floor, GK Complex, \nAmarjyothi HBCS Layout, \nInner Ring Road, Domlur, \nBangalore - 560071 \nKarnataka ';
    address =
      '2nd Floor, GK Complex, Embassy Golf Links Road, Amarjyoti Layout, Domlur, Bengaluru, Karnataka 560071 India';
    bankAccount =
      'Account Name: STAN SOFTECH PRIVATE LIMITED \nAccount Number: 50200001004090\nBank: HDFC BANK LTD \nBranch: Namakkal\nIFSC CODE: HDFC0000695 \nMICR Code: 637240002 \nSWIFT Code : HDFCINBBCHE';
  } else {
    stanVentures =
      'Stan Ventures LLC\n8 The Green STE A Dover,\nDelaware , Kent County,\nDelaware, Delaware 19901\nUnited States\n+1 773-757-6456';
    address =
      'The Green STE A Dover, Delaware, Kent County, Delaware, Delaware 19901 United States';
    bankAccount =
      'Beneficiary Name : Stan Ventures LLC \nAccount Number : 9800438066 \nRouting Number : 084106768 \nType of Account : Checking \nBank Name : Evolve Bank & Trust';
  }

  doc.addImage(Logo, 'PNG', pageWidth - 60, 6, 40, 15);

  autoTable(doc, {
    margin: { right: 0, left: 0 },
    startY: 30,
    theme: 'plain',
    showHead: 'never',
    showFoot: 'never',
    styles: { font: 'Poppins', textColor: '#384F7A', cellWidth: pageWidth / 3 },
    bodyStyles: { fillColor: '#FFF9F6' },
    columnStyles: {
      0: { cellPadding: { left: 12, top: 4, right: 4, bottom: 4 } },
    },
    body: [
      [
        {
          content: `Invoice Number : ${invoiceDetail.invoiceNo}`,
          styles: { cellPadding: { top: 4, right: 2, bottom: 0, left: 12 } },
        },
        {
          content: ``,
          styles: { cellPadding: { top: 4, right: 2, bottom: 0, left: 2 } },
        },
        {
          content: `Date of Issue :  ${getFormattedDateForInvoice(new Date().toISOString())}`,
          styles: { cellPadding: { top: 4, right: 2, bottom: 0, left: 2 } },
        },
      ],
      [
        {
          content: ``,
          styles: { cellPadding: { top: 4, right: 2, bottom: 0, left: 12 } },
        },
        {
          content: ``,
          styles: { cellPadding: { top: 4, right: 2, bottom: 0, left: 2 } },
        },
        {
          content: `Date Due :         ${getFormattedDateForInvoice(invoiceDetail.dueDate)}`,
          styles: { cellPadding: { top: 4, right: 2, bottom: 0, left: 2 } },
        },
      ],
      [
        {
          content: ``,
          styles: { cellPadding: { top: 4, right: 2, bottom: 0, left: 12 } },
        },
        {
          content: ``,
          styles: { cellPadding: { top: 4, right: 2, bottom: 0, left: 2 } },
        },
        {
          content: `Invoiced to :`,
          styles: { cellPadding: { top: 4, right: 2, bottom: 0, left: 2 } },
        },
      ],
      [
        {
          content: stanVentures,
          rowSpan: 1,
          styles: { cellPadding: { top: 4, right: 2, bottom: 0, left: 12 } },
        },
        {
          content: ``,
          styles: { cellPadding: { top: 4, right: 2, bottom: 0, left: 2 } },
        },
        {
          content: `${invoiceDetail.name}\n${invoiceDetail.email}\n${invoiceDetail.address}\n${
            invoiceDetail.city
          }\n${invoiceDetail.state + ' ' + invoiceDetail.zipCode}\n${invoiceDetail.country}`,
          rowSpan: 1,
          styles: { cellPadding: { top: 4, right: 2, bottom: 0, left: 2 } },
        },
      ],
    ],
  });

  let grandTotal = 0;
  const invoiceItems = service.serviceOptions.map((item) => {
    grandTotal = grandTotal + item.price;
    return [item.name, `$${item.price}`, 1, `$${item.price}`];
  });
  autoTable(doc, {
    startY: (doc as any).lastAutoTable.finalY + 15,
    theme: 'plain',
    showHead: 'never',
    showFoot: 'never',
    styles: { font: 'Poppins', textColor: '#384F7A' },
    bodyStyles: { fillColor: '#FFF9F6' },
    body: [
      [
        {
          content: `$${grandTotal} USD due ${getFormattedDateForInvoice(invoiceDetail.dueDate)}`,
          styles: { textColor: '#FF6F24', halign: 'left', fontSize: 14 },
        },
      ],
    ],
  });
  const linkText = 'Pay Online';
  const xCoordinate = 16; // Adjust as needed
  const yCoordinate = (doc as any).lastAutoTable.finalY + 5; // Adjust as needed
  doc.setFont('Poppins');
  doc.setFontSize(14);
  doc.setTextColor(0, 112, 224);
  doc.textWithLink(linkText, xCoordinate, yCoordinate, { url: `${checkOUtUrl}` }); // Replace 'https://example.com' with your link

  // Simulate underline
  const textWidth = doc.getStringUnitWidth(linkText) * 5;
  const underlineOffset = 1; // Adjust as needed
  const underlineHeight = 0.1; // Adjust as needed
  doc.setDrawColor(0, 112, 224); // Set color to link text blue
  doc.setLineWidth(underlineHeight);
  doc.line(
    xCoordinate,
    yCoordinate + underlineOffset,
    xCoordinate + textWidth,
    yCoordinate + underlineOffset
  );

  autoTable(doc, {
    margin: { right: 12, left: 12 },
    startY: (doc as any).lastAutoTable.finalY + 15,
    theme: 'plain',
    rowPageBreak: 'avoid',
    showFoot: 'lastPage',
    styles: { font: 'Poppins', textColor: '#384F7A' },
    alternateRowStyles: { fillColor: '#FFFFFF' },
    bodyStyles: {
      fillColor: '#FFF1E9',
      cellPadding: { horizontal: 2, vertical: 4 },
    },
    columnStyles: { 2: { halign: 'center' }, 3: { halign: 'right' } },
    headStyles: { fillColor: '#577496', textColor: '#FFFFFF' },
    head: [
      [
        'Item Description',
        'Price',
        { content: 'Quantity', styles: { halign: 'center' } },
        { content: 'Total', styles: { halign: 'right' } },
      ],
    ],
    body: [...invoiceItems],
    footStyles: { cellPadding: { horizontal: 2, vertical: 4 } },
    foot: [
      [
        '',
        { content: 'Total', colSpan: 2 },
        { content: `$${grandTotal}`, styles: { halign: 'right' } },
      ],
      [
        '',
        {
          content: 'Amount Due',
          colSpan: 2,
          styles: { textColor: '#FF6F24', fontSize: 14 },
        },
        {
          content: `$${grandTotal} USD`,
          styles: { textColor: '#FF6F24', halign: 'right', fontSize: 14 },
        },
      ],
    ],
  });
  autoTable(doc, {
    startY: (doc as any).lastAutoTable.finalY + 15,
    theme: 'plain',
    showHead: 'never',
    showFoot: 'never',
    styles: { font: 'Poppins', textColor: '#384F7A' },
    bodyStyles: { fillColor: '#FFF9F6' },
    body: [
      [{ content: 'Pay with ACH or wire transfer', styles: { halign: 'left' } }],
      [
        'Bank transfers, also known as ACH payments, can take up to five business days. \nTo pay via ACH, transfer funds using the following bank information.',
      ],
      [bankAccount],
    ],
    didDrawPage: () => {
      doc.setLineWidth(0.2);
      doc.setDrawColor('#8999AB');
      doc.line(12, pageHeight - 12, pageWidth - 12, pageHeight - 12);

      doc.setFont('Poppins', 'normal');
      doc.setFontSize(8);
      doc.setTextColor('#384F7A');
      doc.text(address, pageWidth / 2, pageHeight - 6, { align: 'center' });

      doc.setLineWidth(2.5);
      doc.setDrawColor('#FF6F24');
      doc.line(0, pageHeight, pageWidth, pageHeight);
    },
  });
  window.open(URL.createObjectURL(doc.output('blob')));
}
