import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MuiSnackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Button from './Inputs/Button';

import { useSnackbar } from '../../hooks/use-snackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': { backgroundColor: '#ffffff', color: 'unset' },
    '& .MuiSnackbarContent-message': {
      maxWidth: 304,
      [theme.breakpoints.down('xs')]: { maxWidth: 208 },
    },
  },
}));

function Snackbar() {
  const {
    snackbar: { open, severity, message, isNotification, onAction },
    hideSnackbar,
  } = useSnackbar();
  const classes = useStyles();

  function handleHideSnackbar(event?: React.SyntheticEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    hideSnackbar();
  }

  if (isNotification) {
    return (
      <MuiSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleHideSnackbar}
        message={message}
        classes={{ root: classes.root }}
        action={
          <React.Fragment>
            <Button color="primary" size="small" onClick={onAction}>
              VIEW
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleHideSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    );
  }

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={5000}
      onClose={handleHideSnackbar}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleHideSnackbar}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      <Alert elevation={6} variant="filled" severity={severity} onClose={handleHideSnackbar}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
}

export default Snackbar;
