import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  title: { color: '#172B70', fontSize: 22, fontWeight: 700 },
  filtersWithActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filtersContainer: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    flex: 1,
    flexWrap: 'wrap',
    '& > div': { width: 200, marginBottom: theme.spacing(1) },
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  filterActionsContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  tabPaperRoot: { flexGrow: 1, padding: theme.spacing(2) },
  packageTabPaperRoot: { padding: theme.spacing(1, 0, 0) },
  serviceRoot: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  textField: { marginTop: 8, marginBottom: 8 },
  paymentTypeContainer: { marginTop: theme.spacing(4), marginBottom: theme.spacing(1) },
  paymentTypeTitle: { color: '#000000CC', fontWeight: 600 },
  fieldTitle: { color: '#000000', fontWeight: 500 },
  adornedEnd: { paddingRight: '0px' },
  orderFrequencySelect: { backgroundColor: 'rgba(0, 0, 0, 0.09)', width: '100px' },
  visibilityHelpText: { fontSize: '12px', color: '#577496', marginLeft: theme.spacing(4) },
  serviceFormButtonContainer: { marginTop: theme.spacing(4), display: 'flex' },
  packageTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  packageTitle: { color: '#172B70', fontSize: 16, fontWeight: 600 },
  tableBorder: { border: '1px solid #E4EBF8' },
  descriptionColumn: { width: '40%' },
  packageActions: { display: 'flex', justifyContent: 'center' },
  editor: { marginBottom: theme.spacing(2) },
  dollarSign: { fontWeight: 'bold', color: '#577496', paddingRight: theme.spacing(1) },
  defaultCheckbox: { padding: 4 },
  addAddOnButton: { display: 'flex', justifyContent: 'flex-end', margin: theme.spacing(1, 0) },
  uploadInput: { display: 'none' },
  uploadButtonContainer: {
    padding: theme.spacing(12),
    backgroundColor: '#F4F8FF',
    border: '1px dashed #DEDEDE',
    borderRadius: 4,
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(6),
    },
  },
  servicesTable: { height: 'calc(100vh - 250px) !important' },
  cursorPointer: { cursor: 'pointer' },
  serviceImageInfo: {
    fontSize: '0.75rem',
    color: '#172B70',
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 1),
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': { textDecoration: 'underline', color: '#172B70' },
  },
  intakeFormTitleContainer: { marginTop: 8, marginBottom: '3vw' },
  intakeFormTitle: { color: '#172B70', fontSize: 16, fontWeight: 600 },
  intakeFormInfoText: { color: '#577496', fontSize: 12 },
  intakeFormRightBorder: {
    borderRight: '1px solid #C4D8FF',
    [theme.breakpoints.down('md')]: {
      borderRight: 'unset',
    },
  },
  intakeFormList: { minHeight: 400 },
  dragAndDropEmptyList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'inherit',
  },
  dragAndDropEmptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#577496',
    maxWidth: 300,
    textAlign: 'center',
  },
  intakeFormItem: { color: '#172B70', '&:hover': { color: '#FF502E' } },
  intakeFormItemText: { color: 'inherit', fontSize: '0.875rem' },
  intakeFormItemIcon: { color: 'inherit' },
  intakeFormFieldActions: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    color: '#5f6368',
    marginLeft: 5,
    '& > div': {
      padding: '4px 4px 0px',
      borderRadius: 4,
      cursor: 'pointer',
      backgroundColor: '#F0F6FE',
    },
    '& > div:first-child': {
      marginRight: 5,
      '&:hover': { backgroundColor: '#1976d2', color: '#ffffff' },
    },
    '& > div:last-child': { '&:hover': { backgroundColor: '#d32f2f', color: '#ffffff' } },
  },
  formControlLabel: { fontSize: '0.875rem' },
  intakeFormSaveButton: { marginTop: theme.spacing(2), marginLeft: theme.spacing(2) },
  customerInterfaceContainer: {
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
  reportContainer: { margin: theme.spacing(0, 0, 1) },
  faqContainer: { margin: theme.spacing(4, 0, 4) },
  reportItemContainer: {
    borderRadius: 4,
    border: '1px solid #C4C4C4',
    padding: theme.spacing(2, 3),
    marginBottom: theme.spacing(2),
  },
  deleteIconContainer: { textAlign: 'right' },
  uploadedImageContainer: {
    padding: theme.spacing(1),
    backgroundColor: '#EAEAEA',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  viewDeleteIconButton: { margin: theme.spacing(0, 0.5) },
  imageName: { color: '#172B70', margin: 0, fontSize: '0.875rem', fontWeight: 500 },
  errorHelperText: {
    color: '#f44336',
    fontSize: '0.75rem',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    marginTop: 3,
    marginLeft: 14,
    marginRight: 14,
  },
  quillFieldError: { borderColor: '#f44336' },
  detailedServiceGrid: { [theme.breakpoints.down('sm')]: { flexDirection: 'column-reverse' } },
  serviceListContainer: { position: 'sticky', top: 100 },
  serviceList: {
    background: '#FFFFFF',
    border: '1px solid #E4EBF8',
    borderRadius: 4,
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(3),
  },
  titleClassName: { fontSize: '1rem', fontWeight: 'normal' },
  serviceListItemText: { fontSize: '0.875rem' },
  buyNowButton: { width: 220, [theme.breakpoints.down('sm')]: { width: '100%' } },
  serviceDescription: { fontSize: '0.875rem', color: '#577496', marginBottom: theme.spacing(3) },
  sampleReportContainer: { padding: theme.spacing(2, 1), margin: theme.spacing(4, 0) },
  sampleReportGrid: { position: 'relative' },
  reportImage: { height: 200 },
  viewReportButton: {
    backgroundColor: '#577496',
    color: '#ffffff',
    '&:hover': { backgroundColor: '#577496' },
  },
  faqAccordionContainer: { boxShadow: '0px 0px 15px rgba(87, 116, 150, 0.14)' },
  faqAccordionExpanded: {
    border: '1px solid #FF6F24',
    boxShadow: '0px 0px 15px rgba(87, 116, 150, 0.14)',
  },
  faqAccordionTitle: { fontSize: '0.875rem', fontWeight: 500 },
  faqAccordionDetails: {
    '& > p': {
      margin: theme.spacing(0.5, 0),
    },
  },
  exploreServicesContainer: {
    margin: theme.spacing(-3),
    minHeight: '100vh',
    backgroundColor: '#F4F4FA',
  },
  subStatusContainer: { paddingTop: theme.spacing(1) },
  subStatusAddContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  serviceTemplateContainer: { marginBottom: theme.spacing(1) },
  uploadedServiceTemplateContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  dialogTitle: { color: '#172B70', fontSize: 18, fontWeight: 700 },
  container: {
    padding: theme.spacing(2),
    margin: '10px 0px 10px',
    border: '1px solid #E4EBF8',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
  },
}));
