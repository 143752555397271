import { CancelToken } from 'axios';

import axios from '../axios';

import { IUser } from '../interfaces/user';
import { IAssignedUser, PageResponse, SearchParams } from '../interfaces/shared';
import { IUpdateClient } from '../interfaces/clients';

export function getClients(requestBody: SearchParams) {
  return axios.post<PageResponse<IUser>>('/user/find', requestBody);
}

export function updateClientDetails(id: string, data: IUpdateClient) {
  return axios.put<boolean>(`/user/detail/${id}`, data);
}

export function getClientListOptions(search: string, cancelToken?: CancelToken) {
  return axios.get<IAssignedUser[]>(`/user/dropDown/${search}`, { cancelToken });
}
