import axios from '../axios';

import { ISubscription } from '../interfaces/subscriptions';
import { PageResponse, SearchParams } from '../interfaces/shared';

export function getSubscriptions(requestBody: SearchParams) {
  return axios.post<PageResponse<ISubscription>>('/subscription/find', requestBody);
}

export function cancelSubscription(subscriptionId: string) {
  return axios.put('/subscription/cancel', undefined, { params: { id: subscriptionId } });
}

export function chargeSubscription(subscriptionId: string) {
  return axios.put('/subscription/chargeNow', undefined, { params: { id: subscriptionId } });
}
