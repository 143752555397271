import axios from '../axios';

import { Announcement } from '../interfaces/announcements';
import { PageResponse, SearchParams } from '../interfaces/shared';

export function getAnnouncements(requestBody: SearchParams) {
  return axios.post<PageResponse<Announcement>>('/announcement/find', requestBody);
}

export function getVisibleAnnouncements() {
  return axios.get<Announcement[]>('/announcement/visible');
}

export function getAnnouncementById(announcementId: string) {
  return axios.get<Announcement>('/announcement/detail', { params: { id: announcementId } });
}

export function saveAnnouncement(announcement: Announcement) {
  return axios.post<Announcement>('/announcement', announcement);
}

export function deleteAnnouncement(announcementId: string) {
  return axios.delete('/announcement', { params: { id: announcementId } });
}
