import clsx from 'clsx';
import { UseFormReturn } from 'react-hook-form';
import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Publish';

import UploadProjectDetails from './UploadProjectDetails';
import ManualProjectDetails from './ManualProjectDetails';
import Button from '../shared/Inputs/Button';
import Tooltip from '../shared/Tooltip';

import { IUser } from '../../interfaces/user';
import { CompleteService } from '../../interfaces/services';
import { IPackageQuantity } from '../../interfaces/checkout';
import { IProjectDetailsForm, ProjectDetailTypes } from '../../interfaces/checkout';
import useStyles from './styles';
import LoadingButton from '../shared/Inputs/LoadingButton';

interface IProps {
  orderId: string;
  user: IUser | null;
  service: CompleteService;
  packageQuantities: IPackageQuantity[];
  projectDetailsType: ProjectDetailTypes;
  projectDetailsForm: UseFormReturn<IProjectDetailsForm>;
  isLoading: boolean;
  handleSaveProjectDetails(): void;
  handleRedirectToOrderDetails(): void;
  handleProjectDetailsTypeChange(type: ProjectDetailTypes): void;
}

function OrderSuccess(props: IProps) {
  const classes = useStyles();
  const {
    orderId,
    user,
    service,
    packageQuantities,
    projectDetailsType,
    projectDetailsForm,
    isLoading,
    handleSaveProjectDetails,
    handleRedirectToOrderDetails,
    handleProjectDetailsTypeChange,
  } = props;

  function shouldDisableManualAdd() {
    return (
      Object.values(packageQuantities).filter((p) => +p.quantity).length === 0 ||
      service?.intakeItems?.length === 0
    );
  }

  return (
    <div className={clsx([classes.detailsFormContainer, classes.flexGrow])}>
      <h3>Order #{orderId}</h3>
      <p>{`Thank you, ${user?.firstName} ${user?.lastName}!`}</p>
      <p>
        We've accepted your order, and we're getting it ready. A confirmation email has been to{' '}
        <span className={classes.normalTitle}>{user?.email}.</span>
      </p>
      <div className={classes.projectDetailsContainer}>
        <h3>Project Details</h3>
        {projectDetailsType === '' ? (
          <div className={classes.projectDetailsButtonContainer}>
            <div>
              <Tooltip
                arrow
                placement="top"
                enterDelay={1000}
                title={
                  shouldDisableManualAdd()
                    ? ''
                    : 'Suitable for small data. For large data, please use file upload.'
                }
              >
                <span>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<EditIcon />}
                    fullWidth
                    onClick={() => handleProjectDetailsTypeChange('manual')}
                    disabled={shouldDisableManualAdd()}
                  >
                    Add Project Details
                  </Button>
                </span>
              </Tooltip>
            </div>
            <div className={classes.orText}>
              <p>
                <span>or</span>
              </p>
            </div>
            <div>
              <Button
                color="primary"
                variant="contained"
                startIcon={<PublishIcon />}
                fullWidth
                onClick={() => handleProjectDetailsTypeChange('upload')}
              >
                Upload Project Details
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.cancelButtonContainer}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => handleProjectDetailsTypeChange('')}
              >
                Cancel
              </Button>
            </div>
            {projectDetailsType === 'upload' ? (
              <UploadProjectDetails
                templateUrl={service.templateUrl || ''}
                projectDetailsForm={projectDetailsForm}
              />
            ) : (
              <ManualProjectDetails
                service={service}
                packageQuantities={packageQuantities}
                projectDetailsForm={projectDetailsForm}
              />
            )}
          </>
        )}
      </div>
      <div className={classes.buttonsContainer}>
        <div>
          <Button
            color="primary"
            variant="outlined"
            disabled={isLoading}
            onClick={handleRedirectToOrderDetails}
          >
            Goto Order
          </Button>
        </div>
        <div>
          <LoadingButton
            color="primary"
            variant="contained"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleSaveProjectDetails}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}

export default OrderSuccess;
