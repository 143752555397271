import { useAuth } from '../../hooks/use-auth';
import { isAdmin } from '../../utils/utils';
import AdminDashboard from './AdminDashboard';
import UserDashboard from './UserDashboard';

function Dashboard() {
  const { user } = useAuth();
  return isAdmin(user) ? <AdminDashboard /> : <UserDashboard />;
}

export default Dashboard;
