import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Redirect } from 'react-router-dom';
import { GridRowsProp } from '@material-ui/data-grid';

import Filters from './Filters';
import TrafficLogsTable from './TrafficLogsTable';

import { useAuth } from '../../hooks/use-auth';
import { useSnackbar } from '../../hooks/use-snackbar';
import { IFilter, SearchParams } from '../../interfaces/shared';
import { IFilterState } from '../../interfaces/affiliates';
import { isAdmin } from '../../utils/utils';
import { getTrafficLogs } from '../../requests/affiliates';
import useStyles from './styles';

interface ITrafficLogState {
  content: GridRowsProp;
  totalElements: number;
}

const initialFilters: IFilterState = {
  referrerClientName: '',
  affiliateCode: '',
};

function TrafficLogs() {
  const classes = useStyles();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IFilterState>(initialFilters);
  const [{ page, pageSize, filterFields }, setSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    filterFields: [],
  });
  const [trafficLogs, setTrafficLogs] = useState<ITrafficLogState>({
    content: [],
    totalElements: 0,
  });

  useEffect(() => {
    fetchTrafficLogs();
    // eslint-disable-next-line
  }, [page, pageSize, filterFields]);

  function fetchTrafficLogs() {
    if (isAdmin(user)) {
      setLoading(true);
      getTrafficLogs({ page, pageSize, ...(filterFields?.length && { filterFields }) })
        .then((res) => {
          setLoading(false);
          setTrafficLogs({
            content: res.data.content.map((track) => {
              track.id = track._id;
              return track;
            }),
            totalElements: res.data.totalElements,
          });
        })
        .catch((error: AxiosError) => {
          const errorMessage =
            error?.response?.data?.message || 'An error occurred. Please try again.';
          showSnackbar({ severity: 'error', message: errorMessage });
        });
    }
  }

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize });
  }

  function handleApplyFilters() {
    const newFilters: IFilter[] = [];
    Object.entries(filters).forEach(([filterKey, filterValue]) => {
      if (filterValue) {
        newFilters.push({
          key: filterKey,
          searchOperation: 'like',
          value: filterValue,
        });
      }
    });
    setTrafficLogs({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: newFilters });
  }

  function handleClearFilters() {
    setFilters(initialFilters);
    setTrafficLogs({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: [] });
  }

  if (!isAdmin(user)) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <p className={classes.title}>Traffic Logs</p>
      <Filters
        filters={filters}
        setFilters={setFilters}
        onApplyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
      />
      <TrafficLogsTable
        page={page}
        pageSize={pageSize}
        rows={trafficLogs.content}
        totalElements={trafficLogs.totalElements}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </>
  );
}

export default TrafficLogs;
