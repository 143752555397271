import axios, { AxiosError, CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';

import LoadingButton from '../shared/Inputs/LoadingButton';
import Button from '../shared/Inputs/Button';
import TextField from '../shared/Inputs/TextField';

import useStyles from './styles';
import { useSnackbar } from '../../hooks/use-snackbar';
import { IUser } from '../../interfaces/user';
import { IFilterOptions } from '../../interfaces/shared';
import { getServiceListOptions, getServiceListOptionsOnSearch } from '../../requests/service';
import { CommissionMetaData, CommissionMetaDataForm } from '../../interfaces/affiliates';
import { APP_URL, REGEX } from '../../constants';
import { updateClientDetails } from '../../requests/clients';
import { debounce } from 'lodash';

interface IProps {
  open: boolean;
  clientDetails: IUser | null;
  fetchUserDetails(showLoading?: boolean): void;
  onClose(): void;
}

function AffiliateDetails(props: IProps) {
  const { open, clientDetails, fetchUserDetails, onClose } = props;
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({ defaultValues: { commissionMetaDataList: [] as CommissionMetaDataForm[] } });
  const {
    fields: commissionItemFields,
    append: appendCommissionItem,
    remove: removeCommissionItem,
  } = useFieldArray({ control, name: 'commissionMetaDataList' });
  const [serviceList, setServiceList] = useState<IFilterOptions[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const commissionItems = watch('commissionMetaDataList');
  const controlledCommissionItems = commissionItemFields.map((field, index) => {
    return {
      ...field,
      ...commissionItems[index],
    };
  });
  const [isServicesLoading, setIsServicesLoading] = useState(false);
  const [serviceOptionsOnSearch, setServiceOptionsOnSearch] = useState<IFilterOptions[]>([]);
  let cancelToken: CancelTokenSource;
  useEffect(() => {
    getServiceListOptions().then((res) => {
      setServiceList(res.data);
    });
  }, []);
  useEffect(() => {
    if (clientDetails && serviceList.length) {
      reset({
        commissionMetaDataList:
          clientDetails?.commissionMetaDataList?.map((item) => {
            const service = serviceList.find((service) => service.id === item.serviceId);
            return {
              service: service || null,
              percent: `${item.percent || 0}`,
              isFirstTime: item.firstTime || false,
              isPerpetual: item.perpetual || false,
            };
          }) || [],
      });
    }
  }, [open, serviceList, clientDetails, reset]);

  function handleSave({
    commissionMetaDataList,
  }: {
    commissionMetaDataList: CommissionMetaDataForm[];
  }) {
    if (clientDetails) {
      setIsLoading(true);
      updateClientDetails(clientDetails._id, {
        isAffiliate: true,
        commissionMetaDataList: commissionMetaDataList.map<CommissionMetaData>((item) => ({
          serviceId: (item.service as IFilterOptions).id,
          percent: Number(item.percent),
          firstTime: item.isFirstTime,
          perpetual: item.isPerpetual,
        })),
      })
        .then((res) => {
          setIsLoading(false);
          if (res.data) {
            fetchUserDetails(false);
            showSnackbar({ severity: 'success', message: 'Updated successfully' });
          } else {
            throw new Error('An error occurred. Please try again.');
          }
        })
        .catch((error: AxiosError) => {
          fetchUserDetails(false);
          const errorMessage =
            error?.response?.data?.message || 'An error occurred. Please try again.';
          showSnackbar({ severity: 'error', message: errorMessage });
        });
    }
  }

  function handleClose(event: {}, reason: 'backdropClick' | 'escapeKeyDown') {
    if (isLoading && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      return;
    }
    onClose();
  }

  function fetchServicesListOptionsOnSearch(event: React.ChangeEvent<{}>, search: string) {
    if (event?.type === 'change' && search.length > 1) {
      if (typeof cancelToken !== 'undefined') {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      cancelToken = axios.CancelToken.source();

      setIsServicesLoading(true);
      getServiceListOptionsOnSearch(search, cancelToken.token)
        .then((res) => {
          setIsServicesLoading(false);
          setServiceOptionsOnSearch(res.data);
        })
        .catch((error: AxiosError) => {
          setIsServicesLoading(false);
        });
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogTitle>Affiliate Details</DialogTitle>
        <DialogContent>
          {clientDetails?.affiliateCode && (
            <div className={classes.affiliateUrl}>
              Affiliate URL:{' '}
              <a
                href={`${APP_URL}/r/${clientDetails.affiliateCode}`}
                target="_blank"
                rel="noreferrer"
              >
                {`${APP_URL}/r/${clientDetails.affiliateCode}`}
              </a>
            </div>
          )}
          <div>
            <TableContainer>
              <Table aria-label="add on items table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '50%' }}>Service</TableCell>
                    <TableCell>Percentage</TableCell>
                    <TableCell align="center" style={{ width: 100 }}>
                      First Time
                    </TableCell>
                    <TableCell align="center">Perpetual</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {controlledCommissionItems.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No data
                      </TableCell>
                    </TableRow>
                  ) : (
                    controlledCommissionItems.map((row, index) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell>
                            <Controller
                              name={`commissionMetaDataList.${index}.service` as const}
                              control={control}
                              rules={{ required: true }}
                              defaultValue={row.service}
                              render={({ field: { ref, value, onChange } }) => {
                                const hasError = Boolean(
                                  errors?.commissionMetaDataList?.[index]?.service
                                );
                                return (
                                  <Autocomplete
                                    ref={ref}
                                    size="small"
                                    loading={isServicesLoading}
                                    options={serviceOptionsOnSearch}
                                    getOptionLabel={(option) => option.name}
                                    getOptionDisabled={(option) =>
                                      Boolean(
                                        commissionItems.find(
                                          (item) =>
                                            (value as IFilterOptions)?.id !== option.id &&
                                            item.service?.id === option.id
                                        )
                                      )
                                    }
                                    value={value}
                                    onChange={(_, newValue) => {
                                      onChange(newValue);
                                      setServiceOptionsOnSearch([]);
                                    }}
                                    onInputChange={debounce(fetchServicesListOptionsOnSearch, 500)}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        error={hasError}
                                        placeholder="Type service name to search"
                                        {...(hasError && {
                                          helperText: 'Select a service',
                                        })}
                                      />
                                    )}
                                  />
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Controller
                              name={`commissionMetaDataList.${index}.percent` as const}
                              control={control}
                              rules={{
                                required: true,
                                validate: (value: string) => {
                                  const commission = Number(value);
                                  if (!Number(commission)) return false;
                                  if (commission > 100) return false;
                                  return true;
                                },
                              }}
                              defaultValue={row.percent}
                              render={({ field: { ref, onChange, ...rest } }) => {
                                const hasError = Boolean(
                                  errors?.commissionMetaDataList?.[index]?.percent
                                );
                                return (
                                  <TextField
                                    {...rest}
                                    inputRef={ref}
                                    variant="outlined"
                                    size="small"
                                    onChange={(event) => {
                                      const value = event.target.value;
                                      if (value !== '') {
                                        if (!REGEX.NUMBERS.test(value)) {
                                          return;
                                        }
                                      }
                                      onChange(value);
                                    }}
                                    error={hasError}
                                    InputProps={{
                                      endAdornment: (
                                        <span className={classes.percentageSign}>%</span>
                                      ),
                                    }}
                                    {...(hasError && {
                                      helperText: 'Input between 1 and 100',
                                    })}
                                  />
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Controller
                              name={`commissionMetaDataList.${index}.isFirstTime` as const}
                              control={control}
                              defaultValue={row.isFirstTime}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <Checkbox
                                    color="primary"
                                    size="small"
                                    className={classes.commissionTypeCheckbox}
                                    checked={value}
                                    onChange={(event) => onChange(event.target.checked)}
                                  />
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Controller
                              name={`commissionMetaDataList.${index}.isPerpetual` as const}
                              control={control}
                              defaultValue={row.isPerpetual}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <Checkbox
                                    color="primary"
                                    size="small"
                                    className={classes.commissionTypeCheckbox}
                                    checked={value}
                                    onChange={(event) => onChange(event.target.checked)}
                                  />
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <IconButton size="small" onClick={() => removeCommissionItem(index)}>
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
              <div className={classes.addCommissionItemButton}>
                <Button
                  startIcon={<AddIcon />}
                  color="primary"
                  variant="text"
                  onClick={() =>
                    appendCommissionItem({
                      service: null,
                      percent: '',
                      isFirstTime: false,
                      isPerpetual: false,
                    })
                  }
                >
                  Add
                </Button>
              </div>
            </TableContainer>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" disabled={isLoading} onClick={onClose}>
            Close
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AffiliateDetails;
