import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import Announcements from './Announcements';
import Services from './Services';
import Strategies from './Strategies';
import MinimalOrderTable from '../Orders/MinimalOrderTable';

import useStyles from './styles';
import { useAuth } from '../../hooks/use-auth';
import { useSnackbar } from '../../hooks/use-snackbar';
import { getOrdersForDashboard } from '../../requests/order';
import { IDashboardOrderState } from '../../interfaces/order';

function UserDashboard() {
  const classes = useStyles();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [orders, setOrders] = useState<IDashboardOrderState>({
    isLoading: false,
    content: [],
    totalElements: 0,
  });

  useEffect(() => {
    setOrders({ ...orders, isLoading: true });
    getOrdersForDashboard()
      .then((res) => {
        setOrders({
          isLoading: false,
          content: res.data.content,
          totalElements: res.data.totalElements,
        });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          error.response?.data?.message || 'An error occurred while fetching orders';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.dashboardContainer}>
      <div className={classes.welcomeContainer}>
        <h2 className={classes.welcomeTitle}>
          {`${String.fromCodePoint(0x1f44b)} Welcome, ${user?.firstName} ${user?.lastName}`}!
        </h2>
        <p className={classes.welcomeSubTitle}>Here's what's going on with your campaigns</p>
        <div className={classes.minimalOrderTableContainer}>
          <MinimalOrderTable orders={orders} />
        </div>
      </div>
      <Announcements />
      <Services />
      <Strategies />
    </div>
  );
}

export default UserDashboard;
