import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridValueFormatterParams,
} from '@material-ui/data-grid';

import { getFormattedDate } from '../../utils/utils';
import useStyles from './styles';
import { Link } from 'react-router-dom';

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  totalElements: number;
  loading: boolean;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  fetchAffiliates(): void;
}

function AffiliateTable(props: IProps) {
  const classes = useStyles();
  const { page, pageSize, rows, totalElements, loading, onPageChange, onPageSizeChange } = props;

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Link
          className={classes.linkBlue}
          to={`/clients/${btoa(params.row.userId as string)}`}
          onClick={(event) => event.stopPropagation()}
        >
          {params.row.name}
        </Link>
      ),
    },
    { field: 'email', headerName: 'Email', flex: 2, sortable: false },
    { field: 'code', headerName: 'Affiliate Code', flex: 0.8, sortable: false },
    {
      field: 'totalRevenue',
      headerName: 'Total Revenue',
      flex: 0.8,
      sortable: false,
      renderCell: (params) => `$${params.row.totalRevenue || 0}`,
    },
    {
      field: 'signedUpDate',
      headerName: 'Sign-up Date',
      flex: 0.9,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) =>
        getFormattedDate(params.getValue(params.id, 'signedUpDate') as string),
    },
    {
      field: 'latestRevenue',
      headerName: 'Latest Revenue',
      flex: 0.9,
      sortable: false,
      renderCell: (params) => `$${params.row.latestRevenue || 0}`,
    },
    {
      field: 'latestRevenueDate',
      headerName: 'Latest Revenue Date',
      flex: 1.1,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) =>
        getFormattedDate(params.getValue(params.id, 'latestRevenueDate') as string),
    },
  ];

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        rowCount={totalElements}
        page={page}
        pageSize={pageSize}
        paginationMode="server"
        loading={loading}
        pagination
        classes={{ root: classes.affiliatesTable, row: classes.cursorPointer }}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </>
  );
}

export default AffiliateTable;
