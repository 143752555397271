import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  root: { height: '100vh' },
  title: { color: '#172B70', fontSize: 18, fontWeight: 700 },
  subTitle: { color: '#273156', fontSize: 24, margin: '0px 0px 8px', fontWeight: 100 },
  button: { padding: '13px 60px', margin: '8px 0px 24px' },
  textField: { marginTop: 8, marginBottom: 8 },
  textFields: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div:first-child': { marginRight: 4 },
    '& > div:last-child': { marginLeft: 4 },
  },
  paper: {
    height: '100vh',
    margin: theme.spacing(0, 14),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  btnContainer: { borderBottom: '1px solid #E8E8E8', marginBottom: 8 },
  signInBtnContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  existingText: { color: '#76849F', fontSize: 12, marginTop: 16, textAlign: 'center' },
  link: { color: '#FF6329' },
  troubleSigningInContainer: { margin: '8px 0px 24px' },
  troubleSigningIn: { color: '#94AAC8', textDecoration: 'none', fontSize: 12 },
  centered: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F4F4FA',
  },
  forgotPasswordPaper: {
    width: 400,
    boxShadow: '0px 4px 30px rgba(191, 221, 248, 0.35)',
    borderRadius: 4,
    padding: 40,
  },
  forgotPasswordTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
  },
  forgotPasswordTitle: { color: '#172B70', fontWeight: 500, fontSize: 24 },
  forgotPasswordSubTitle: { color: '#577496', fontSize: 14 },
  lockIcon: { marginRight: 16 },
  lockIconImg: { width: 38 },
  sendEmailBtn: { padding: '10px 16px' },
  forgotPasswordButtonContainer: { marginTop: 24 },
  emailIcon: { width: 72 },
  textAlignCenter: { textAlign: 'center' },
  backToLoginButtonContainer: { margin: '16px 0px' },
  cursorPointer: { cursor: 'pointer' },
  loadingButton: { position: 'relative' },
  buttonLinearProgress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  '@media screen and (max-width: 1024px)': {
    paper: {
      margin: theme.spacing(0, 4),
    },
  },
  logo: { position: 'absolute', top: 30 },
}));
