import clsx from 'clsx';
// import dayjs from 'dayjs';
import { Link, useHistory } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Button from '../shared/Inputs/Button';
import { IDashboardOrderState } from '../../interfaces/order';
import Empty from '../../assets/img/empty.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: { color: '#172B70', fontWeight: 600 },
    tableContainer: {
      border: '1px solid #E4EBF8',
      borderRadius: 4,
    },
    headTableCell: { color: '#172B70', borderColor: '#ECE9F1' },
    tableCell: { borderColor: '#ECE9F1', color: '#000000' },
    tableRow: { cursor: 'pointer' },
    orderId: { color: '#FF6F24' },
    noDataTitle: { color: '#000000', fontWeight: 500, fontSize: '1.125rem', marginBottom: 0 },
    noDataSubTitle: { color: '#577496', fontSize: '0.75rem', marginTop: theme.spacing(0.5) },
  })
);

interface IMinimalOrderTableProps {
  orders: IDashboardOrderState;
}

function MinimalOrderTable(props: IMinimalOrderTableProps) {
  const { orders } = props;
  const classes = useStyles();
  const history = useHistory();

  // function getFormattedDate(orderDate: string) {
  //   return dayjs(orderDate).format('D MMM YY');
  // }

  return (
    <>
      <p className={classes.title}>My Orders</p>
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headTableCell}>Order Id</TableCell>
              <TableCell className={classes.headTableCell}>Service</TableCell>
              <TableCell className={classes.headTableCell}></TableCell>
              <TableCell className={classes.headTableCell}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.content.length ? (
              orders.content.map((order) => (
                <TableRow
                  key={order.id}
                  onClick={() => history.push(`/orders/${btoa(order.id)}`)}
                  className={classes.tableRow}
                  hover
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className={clsx([classes.tableCell, classes.orderId])}
                  >
                    {order.orderId}
                  </TableCell>
                  <TableCell className={classes.tableCell}>{order.serviceName}</TableCell>
                  <TableCell className={classes.tableCell}>
                    {order.status === 'Completed' && (
                      <Chip
                        variant="outlined"
                        label="View Report"
                        onClick={(event) => {
                          event.stopPropagation();
                          history.push(`/orders/${btoa(order.id)}`, { activeTabIndex: 1 });
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {`${order.status || ''}`.split('_').join(' ')}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  {orders.isLoading ? (
                    <>
                      <CircularProgress />
                    </>
                  ) : (
                    <>
                      <img src={Empty} alt="empty" width="300" />
                      <p className={classes.noDataTitle}>No data found</p>
                      <p className={classes.noDataSubTitle}>
                        Looks like you’re all set! We’ll let you know here when we need something
                        from you.
                      </p>
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {orders.totalElements > 5 && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={6} align="center" className={classes.tableCell}>
                  <Button variant="contained" color="primary" component={Link} to="/orders">
                    Show More
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default MinimalOrderTable;
