import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function ScrollToTopOnNavigation() {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (history.action !== 'POP') {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default ScrollToTopOnNavigation;
