import { forwardRef } from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

const ReactDatePickerMuiTextField = forwardRef((props: any, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      variant="outlined"
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <DateRangeIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  );
});

export default ReactDatePickerMuiTextField;
