import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Button from './Inputs/Button';
import LoadingButton from './Inputs/LoadingButton';

interface ConfirmActionDialogProps {
  open: boolean;
  isLoading: boolean;
  title?: string;
  description?: string;
  onClose(): void;
  onConfirm(): void;
}

const useStyles = makeStyles(() => createStyles({ text: { color: '#577496', fontSize: 16 } }));

function ConfirmActionDialog(props: ConfirmActionDialogProps) {
  const {
    open,
    isLoading,
    title = 'Confirm',
    description = 'Are you sure you want to proceed?',
    onClose,
    onConfirm,
  } = props;
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className={classes.text}>{description}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Close
        </Button>
        <LoadingButton
          onClick={onConfirm}
          color="primary"
          variant="contained"
          isLoading={isLoading}
          disabled={isLoading}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmActionDialog;
