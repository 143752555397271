import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { GridRowParams, GridRowsProp } from '@material-ui/data-grid';

import Filters from './Filters';
import ClientsTable from './ClientsTable';
import ClientDetails from './ClientDetails';

import { useAuth } from '../../hooks/use-auth';
import { useSnackbar } from '../../hooks/use-snackbar';
import { useRouter } from '../../hooks/use-router';
import { IFilter, SearchParams } from '../../interfaces/shared';
import { getClients } from '../../requests/clients';
import { IFilterState } from '../../interfaces/clients';
import { isAdmin } from '../../utils/utils';
import useStyles from './styles';

interface IClientsState {
  content: GridRowsProp;
  totalElements: number;
}

const initialFilters: IFilterState = {
  firstName: '',
  lastName: '',
  email: '',
};

function Clients() {
  const classes = useStyles();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const router = useRouter();
  const params = useParams<{ clientId: string }>();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IFilterState>(initialFilters);
  const [{ page, pageSize, filterFields }, setSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    filterFields: [],
  });
  const [clients, setClients] = useState<IClientsState>({ content: [], totalElements: 0 });

  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line
  }, [page, pageSize, filterFields]);

  function fetchClients() {
    setLoading(true);
    getClients({ page, pageSize, ...(filterFields?.length && { filterFields }) })
      .then((res) => {
        setLoading(false);
        setClients({
          content: res.data.content.map((client) => {
            client.id = client._id;
            return client;
          }),
          totalElements: res.data.totalElements,
        });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize, filterFields });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize, filterFields });
  }

  function handleApplyFilters() {
    const newFilters: IFilter[] = [];
    Object.entries(filters).forEach(([filterKey, filterValue]) => {
      if (filterValue) {
        newFilters.push({
          key: filterKey,
          searchOperation: 'like',
          value: filterValue,
        });
      }
    });
    setClients({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: newFilters });
  }

  function handleClearFilters() {
    setFilters(initialFilters);
    setClients({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: [] });
  }

  function handleRowClick(params: GridRowParams) {
    router.history.push(`/clients/${btoa(params.row.id)}`);
  }

  if (!isAdmin(user)) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {!params.clientId ? (
        <>
          <p className={classes.title}>Clients</p>
          <Filters
            filters={filters}
            setFilters={setFilters}
            onApplyFilters={handleApplyFilters}
            onClearFilters={handleClearFilters}
          />
          <ClientsTable
            page={page}
            pageSize={pageSize}
            rows={clients.content}
            totalElements={clients.totalElements}
            loading={loading}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onRowClick={handleRowClick}
          />
        </>
      ) : (
        <ClientDetails clientId={atob(params.clientId)} />
      )}
    </>
  );
}

export default Clients;
