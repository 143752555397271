import { useState, useEffect, MouseEvent } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import Calendar from 'dayjs/plugin/calendar';
import { Link, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuIcon from '@material-ui/icons/Menu';

import { USER } from './NavItems';
import { useRouter } from '../../hooks/use-router';
import { useSnackbar } from '../../hooks/use-snackbar';
import Logo from '../../assets/img/logo.svg';
import useStyles from './styles';
import { useAuth } from '../../hooks/use-auth';
import { isUser, logout } from '../../utils/utils';
import { INotification } from '../../interfaces/shared';
import { getNotifications, updateNotificationAsRead } from '../../requests/common';

import BellIcon from '../../assets/img/notification-bell.svg';
import { ReactComponent as BellIconSmall } from '../../assets/img/notification-bell-small.svg';

dayjs.extend(Calendar);

interface IProps {
  onMenuButtonClick?(): void;
}

function AppHeader(props: IProps) {
  const { onMenuButtonClick } = props;
  const classes = useStyles();
  const router = useRouter();
  const location = useLocation();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isAffiliate = Boolean(user?.affiliate);

  useEffect(() => {
    if (openNotifications) {
      fetchNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openNotifications]);

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const openNotificationsDrawer = () => {
    handleMobileMenuClose();
    setOpenNotifications(true);
  };

  function fetchNotifications() {
    setIsNotificationsLoading(true);
    getNotifications()
      .then((res) => {
        setIsNotificationsLoading(false);
        setNotifications(res.data);
      })
      .catch((error) => {
        setIsNotificationsLoading(false);
        showSnackbar({
          severity: 'error',
          message: 'An error occurred while fetching notifications',
        });
      });
  }

  function handleNotificationClick(notification: INotification) {
    setOpenNotifications(false);
    if (!notification.read) {
      updateNotificationAsRead(notification._id);
    }
  }

  const getUrl = (notification: INotification) => {
    switch (notification.type) {
      case 'ORDER': {
        return `/orders/${btoa(notification.typeSystemId)}`;
      }
      case 'SERVICE': {
        return `/services/${btoa(notification.typeSystemId)}`;
      }
      case 'USER': {
        return `/clients/${btoa(notification.typeSystemId)}`;
      }
      default:
        return '';
    }
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          router.history.push('/profile');
          handleMenuClose();
        }}
      >
        Profile
      </MenuItem>
      <MenuItem
        onClick={() => {
          logout();
          handleMenuClose();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={openNotificationsDrawer}>
        <IconButton color="inherit">
          <SvgIcon component={BellIconSmall} />
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton aria-haspopup="true" color="inherit">
          <Avatar
            variant="rounded"
            classes={{ root: classes.rounded, fallback: classes.fallback }}
            src={user?.photoUrl}
            alt={user?.firstName.charAt(0).toUpperCase()}
          />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  function getNavItems() {
    if (isUser(user)) {
      if (!isAffiliate) {
        const menuForNonAffiliateClients = USER.map((item) => ({
          ...item,
          data: item.data.filter((obj) => obj.title !== 'Creatives'),
        }));
        return menuForNonAffiliateClients;
      } else return USER;
    } else return [];
  }

  return (
    <div>
      <div className={classes.grow}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            {isUser(user) && onMenuButtonClick && (
              <Hidden smUp implementation="css">
                <IconButton
                  color="inherit"
                  edge="start"
                  className={classes.menuButton}
                  onClick={onMenuButtonClick}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
            )}
            <div>
              <Link to="/">
                <img src={Logo} alt="StanVentures" className={classes.logo} />
              </Link>
            </div>
            <div className={classes.grow} />
            <Hidden xsDown implementation="css">
              {getNavItems().map((list, i) => (
                <List
                  component="div"
                  key={`${list.title}-${i}`}
                  disablePadding
                  className={classes.headerNavItemList}
                >
                  {list.data.map((item, index) => {
                    return (
                      <ListItem
                        button
                        key={`${item.title}-${index}`}
                        component={Link}
                        to={item.path}
                        selected={
                          item.isActive
                            ? item.isActive(location.pathname)
                            : location.pathname === item.path
                        }
                        classes={{ selected: classes.headerLinkListItemSelected }}
                      >
                        <ListItemIcon classes={{ root: classes.linkListItemIcon }}>
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.title}
                          classes={{ primary: classes.linkListItemText }}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              ))}
            </Hidden>
            <div className={classes.sectionDesktop}>
              <div>
                <IconButton color="inherit" onClick={openNotificationsDrawer}>
                  <SvgIcon component={BellIconSmall} />
                </IconButton>
              </div>
              <div>
                <IconButton
                  edge="end"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <Avatar
                    variant="rounded"
                    classes={{ root: classes.rounded, fallback: classes.fallback }}
                    src={user?.photoUrl}
                    alt={user?.firstName.charAt(0).toUpperCase()}
                  />
                </IconButton>
              </div>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        <Drawer
          classes={{ paper: classes.notificationsDrawerPaper }}
          anchor="right"
          open={openNotifications}
          onClose={() => setOpenNotifications(false)}
        >
          <Box display="flex" alignItems="center">
            <h3 className={classes.notificationsTitle}>Notifications</h3>
            {isNotificationsLoading && (
              <CircularProgress color="primary" size={24} className={classes.loader} />
            )}
          </Box>
          {notifications.length === 0 ? (
            <div className={classes.notificationsEmptyContainer}>
              <div>
                <img src={BellIcon} alt="" />
              </div>
              <p className={classes.noNotificationsMessage}>
                No notifications available. Notifications about your activity will show up here.
              </p>
            </div>
          ) : (
            notifications.map((notification) => (
              <Link
                to={getUrl(notification)}
                key={notification._id}
                className={clsx(classes.notificationItem, { read: notification.read })}
                onClick={() => handleNotificationClick(notification)}
              >
                <div>{notification.description}</div>
                <div className={classes.notificationTime}>
                  {dayjs(notification.createdTime).calendar(null, {
                    sameDay: 'h:mm A',
                    lastWeek: 'DD/MM/YY h:mm A',
                    sameElse: 'DD/MM/YY h:mm A',
                  })}
                </div>
              </Link>
            ))
          )}
        </Drawer>
      </div>
    </div>
  );
}

export default AppHeader;
