import axios from '../axios';

import { PageResponse, SearchParams } from '../interfaces/shared';
import { Creatives } from '../interfaces/creatives';

export function getCreatives(requestBody: SearchParams) {
  return axios.post<PageResponse<Creatives>>('/creative/find', requestBody);
}

export function getCreativesById(creativesId: string) {
  return axios.get<Creatives>('/creative/detail', { params: { id: creativesId } });
}
export function saveCreative(creative: Creatives) {
  return axios.post<Creatives>('/creative', creative);
}
export function deleteCreative(creativesId: string) {
  return axios.delete('/creative', { params: { id: creativesId } });
}
