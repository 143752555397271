import { FormControl, Select, MenuItem, capitalize } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridSelectionModel,
  GridValueFormatterParams,
} from '@material-ui/data-grid';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { useState } from 'react';
import { getCommissionStatusOptions, getFormattedDate } from '../../utils/utils';
import LoadingButton from '../shared/Inputs/LoadingButton';
import useStyles from './styles';
import { AxiosError } from 'axios';
import { useSnackbar } from '../../hooks/use-snackbar';
import { updateCommissionStatus } from '../../requests/affiliates';
import Button from '../shared/Inputs/Button';
import { CommissionStatus } from '../../interfaces/affiliates';

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  totalElements: number;
  loading: boolean;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  fetchCommissions(): void;
}

function CommissionsTable(props: IProps) {
  const {
    page,
    pageSize,
    rows,
    totalElements,
    loading,
    onPageChange,
    onPageSizeChange,
    fetchCommissions,
  } = props;
  const { showSnackbar } = useSnackbar();

  const [isCommissionStatusUpdating, setIsCommissionStatusUpdating] = useState(false);

  const [commissionSelectionModel, setCommissionSelectionModel] = useState<GridSelectionModel>([]);
  const classes = useStyles();
  const [fieldValues, setFieldValues] = useState<{ [key: string]: string | boolean }>({
    status: '',
    clientType: '',
    isAffiliate: false,
    commissionStatus: '',
    transferOrder: '',
  });
  function handleUpdateFieldValues(name: string, value: string) {
    setFieldValues({ ...fieldValues, [name]: value });
  }
  function handleUpdateCommissionStatus() {
    setIsCommissionStatusUpdating(true);
    updateCommissionStatus({
      commissionIds: commissionSelectionModel as string[],
      newStatus: fieldValues['commissionStatus'] as CommissionStatus,
    })
      .then(() => {
        showSnackbar({ severity: 'success', message: 'Updated successfully' });
        setIsCommissionStatusUpdating(false);
        fetchCommissions();
        setCommissionSelectionModel([]);
        setFieldValues({ ...fieldValues, commissionStatus: '' });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }
  function getCommissionColumns() {
    const columns: GridColDef[] = [
      {
        field: 'orderId',
        headerName: 'Order ID',
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Button
            component={Link}
            to={`/orders/${btoa(params.getValue(params.id, 'orderBySystemId') as string)}`}
            size="small"
            variant="text"
            style={{ color: '#4c86ef' }}
          >
            {params.getValue(params.id, 'orderId')}
          </Button>
        ),
      },
      {
        field: 'refereeClientName',
        headerName: 'Customer Name',
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Link
            className={classes.linkBlue}
            to={`/clients/${btoa(params.row.refereeClientId as string)}`}
            onClick={(event) => event.stopPropagation()}
          >
            {params.row.refereeClientName}
          </Link>
        ),
      },
      {
        field: 'referrerClientName',
        headerName: 'Affiliate Name',
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Link
            className={classes.linkBlue}
            to={`/clients/${btoa(params.row.referrerClientId as string)}`}
            onClick={(event) => event.stopPropagation()}
          >
            {params.row.referrerClientName}
          </Link>
        ),
      },
      {
        field: 'orderAmount',
        headerName: 'Order Amount',
        flex: 1,
        sortable: false,
        renderCell: (params) => `$${params.row.orderAmount || 0}`,
      },
      {
        field: 'amount',
        headerName: 'Commission Amount',
        flex: 1,
        sortable: false,
        renderCell: (params) => `$${params.row.amount || 0}`,
      },
      {
        field: 'orderPaidDate',
        headerName: 'Order Paid Date',
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams) =>
          getFormattedDate(params.getValue(params.id, 'orderPaidDate') as string),
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => {
          const status = (params.getValue(params.id, 'status') as string) || '';
          return (
            <div className={clsx(classes.commissionStatus, status.toLowerCase())}>
              {capitalize(status.split('_').join(' '))}
            </div>
          );
        },
        sortable: false,
      },
    ];
    return columns;
  }
  function getSelectField({
    name,
    value,
    options,
    label,
    classNames,
    onChange,
  }: {
    name: string;
    value: string | undefined;
    options: {
      label: string;
      value: string;
    }[];
    label: JSX.Element | string | undefined;
    classNames?: string;
    onChange: (name: string, value: string) => void;
  }) {
    return (
      <FormControl
        variant="outlined"
        size="small"
        className={clsx(classes.statusSelectField, classNames)}
      >
        {label && <div className={classes.fieldTitle}>{label}</div>}
        <Select
          name={name}
          labelId={`${name}-field-label`}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            transformOrigin: { vertical: 'top', horizontal: 'center' },
            getContentAnchorEl: null,
          }}
          value={value}
          onChange={(event) => onChange(event.target.name as string, event.target.value as string)}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
  function handleCommissionSelectionModelChange(selectionModel: GridSelectionModel) {
    setCommissionSelectionModel(selectionModel);
  }

  return (
    <>
      {commissionSelectionModel.length > 0 && (
        <div className={classes.commissionStatusContainer}>
          <div>
            {getSelectField({
              name: 'commissionStatus',
              value: fieldValues['commissionStatus'] as string,
              options: getCommissionStatusOptions(),
              label: 'New Commission Status',
              classNames: classes.commissionStatusSelectField,
              onChange: handleUpdateFieldValues,
            })}
          </div>
          <div>
            <LoadingButton
              color="primary"
              variant="contained"
              isLoading={isCommissionStatusUpdating}
              disabled={!fieldValues['commissionStatus'] || isCommissionStatusUpdating}
              onClick={handleUpdateCommissionStatus}
            >
              Update Status
            </LoadingButton>
          </div>
        </div>
      )}
      <DataGrid
        page={page}
        pageSize={pageSize}
        rows={rows}
        columns={getCommissionColumns()}
        disableColumnFilter
        disableSelectionOnClick
        paginationMode="server"
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        rowCount={totalElements}
        loading={loading}
        checkboxSelection
        pagination
        classes={{ root: classes.creativesTable, row: classes.cursorPointer }}
        selectionModel={commissionSelectionModel}
        onPageChange={onPageChange}
        // onPageChange={(page) =>
        //   setCommissionSearchParams({ commissionPage: page, commissionPageSize })
        // }
        // onPageSizeChange={(pageSize) =>
        //   setCommissionSearchParams({ commissionPage: 0, commissionPageSize: pageSize })
        // }
        onPageSizeChange={onPageSizeChange}
        onSelectionModelChange={handleCommissionSelectionModelChange}
      />
    </>
  );
}
export default CommissionsTable;
