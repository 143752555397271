import { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import TextField from '@material-ui/core/TextField';

import ExploreServices from '../Dashboard/Services';
import Button from '../shared/Inputs/Button';
import { useRouter } from '../../hooks/use-router';
import { useAuth } from '../../hooks/use-auth';
import { useSnackbar } from '../../hooks/use-snackbar';
import useStyles from './styles';
import ServicesTable from './ServicesTable';
import { getServices } from '../../requests/service';
import { IFilter, SearchParams } from '../../interfaces/shared';
import { GridRowParams, GridRowsProp } from '@material-ui/data-grid';
import { isAdmin } from '../../utils/utils';
import { IFilterState } from '../../interfaces/services';

interface IServicesState {
  content: GridRowsProp;
  totalElements: number;
}

const initialFilters: IFilterState = {
  name: '',
  serviceType: 'REGULAR',
};

function Services() {
  const classes = useStyles();
  const router = useRouter();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState<IFilterState>(initialFilters);
  const [{ page, pageSize, filterFields }, setSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    filterFields: [{ key: 'serviceType', searchOperation: 'eq', value: 'REGULAR' }],
  });
  const [services, setServices] = useState<IServicesState>({ content: [], totalElements: 0 });
  const serviceNameRef = useRef<HTMLInputElement>(null);

  function handleAddServiceClick() {
    router.history.push('/services/new');
  }

  useEffect(() => {
    if (isAdmin(user)) {
      fetchServices();
    }
    // eslint-disable-next-line
  }, [page, pageSize, filterFields]);

  function fetchServices() {
    setIsLoading(true);
    getServices({ page, pageSize, ...(filterFields?.length && { filterFields }) })
      .then((res) => {
        setServices({
          content: res.data.content.map((service) => {
            service.id = service._id;
            return service;
          }),
          totalElements: res.data.totalElements,
        });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      })
      .finally(() => setIsLoading(false));
  }

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize, filterFields });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize, filterFields });
  }

  function handleRowClick(params: GridRowParams) {
    router.history.push(`/services/${btoa(params.row.id)}`);
  }

  function handleFilterChange(name: string, value: string) {
    const updatedFilters = { ...filters, [name]: value };
    setFilters(updatedFilters);

    const newFilters: IFilter[] = [];
    Object.entries(updatedFilters).forEach(([filterKey, filterValue]) => {
      if (filterValue) {
        newFilters.push({
          key: filterKey,
          searchOperation: filterKey === 'serviceType' ? 'eq' : 'like',
          value: filterValue,
        });
      }
    });
    setServices({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: newFilters });
  }

  function handleResetFilters() {
    if (serviceNameRef.current) {
      serviceNameRef.current.value = '';
    }
    setFilters(initialFilters);
    setSearchParams({
      page: 0,
      pageSize,
      filterFields: [{ key: 'serviceType', searchOperation: 'eq', value: 'REGULAR' }],
    });
  }

  return (
    <>
      {isAdmin(user) ? (
        <div>
          <div className={classes.title}>Services</div>
          <div className={classes.filtersWithActionsContainer}>
            <div className={classes.filtersContainer}>
              <TextField
                select
                fullWidth
                size="small"
                variant="outlined"
                label="Service Type"
                name="serviceType"
                SelectProps={{ native: true }}
                value={filters.serviceType}
                onChange={(e) => handleFilterChange(e.target.name, e.target.value)}
              >
                <option value="REGULAR">Regular</option>
                <option value="CUSTOM">Custom</option>
              </TextField>
              <TextField
                inputRef={serviceNameRef}
                variant="outlined"
                size="small"
                label="Service Name"
                name="name"
                onChange={debounce(
                  (event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFilterChange(event.target.name, event.target.value),
                  500
                )}
              />
            </div>
            <div className={classes.filterActionsContainer}>
              <div>
                <Button color="primary" variant="outlined" onClick={handleResetFilters}>
                  Reset Filters
                </Button>
              </div>
              <div>
                <Button color="primary" variant="contained" onClick={handleAddServiceClick}>
                  Add Service
                </Button>
              </div>
            </div>
          </div>
          <ServicesTable
            page={page}
            pageSize={pageSize}
            rows={services.content}
            totalElements={services.totalElements}
            loading={isLoading}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onRowClick={handleRowClick}
            fetchServices={fetchServices}
          />
        </div>
      ) : (
        <div className={classes.exploreServicesContainer}>
          <ExploreServices isLightTheme />
        </div>
      )}
    </>
  );
}

export default Services;
