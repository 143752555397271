import axios from '../axios';
import {
  OrderListItem,
  OrderInfo,
  OrderData,
  OrderReport,
  OrderHistory,
  Invoice,
  MessageThread,
  SendMessage,
  MessageType,
  CreateOrder,
  UserOrderDetail,
  updateOrderClient,
} from '../interfaces/order';
import { IFilter, PageResponse, SearchParams } from '../interfaces/shared';

export function getOrders(requestBody: SearchParams) {
  return axios.post<PageResponse<OrderListItem>>('/order/find', requestBody);
}

export function exportOrders(requestBody: { filterFields?: IFilter[] }) {
  return axios.post('/order/export', requestBody, { responseType: 'blob' });
}

export function getOrderInfo(orderId: string) {
  return axios.get<OrderInfo>('/order/metaData', { params: { id: orderId } });
}

export function getOrderData(orderId: string) {
  return axios.get<OrderData>('/order/details', { params: { id: orderId } });
}

export function getOrderReport(orderId: string) {
  return axios.get<OrderReport>('/order/reports', { params: { id: orderId } });
}

export function getOrderHistory(orderId: string) {
  return axios.get<OrderHistory>('/order/history', { params: { id: orderId } });
}

export function uploadReport(
  requestBody: FormData,
  onUploadProgress?: (progressEvent: any) => void
) {
  return axios.post<OrderReport>('/order/reports', requestBody, {
    headers: { 'content-type': 'multipart/form-data' },
    ...(onUploadProgress && { onUploadProgress }),
  });
}

export function deleteReportFile({ id, reportId }: { id: string; reportId: string }) {
  return axios.delete<OrderReport>('/order/reports', { params: { id, reportId } });
}

export function updateOrderData(requestBody: OrderData) {
  return axios.post<OrderData>('/order/details', requestBody);
}

export function getOrdersForDashboard() {
  return axios.get<PageResponse<OrderListItem>>('/order/user/dashboard');
}

export function getInvoice(invoiceId: string) {
  return axios.get<Invoice>('/order/invoice', { params: { id: invoiceId } });
}

export function getMessages(orderId: string, messageType: MessageType) {
  return axios.get<MessageThread>('/order/messages', { params: { orderId, messageType } });
}

export function getMigratedOrderMessages(orderId: string) {
  return axios.get<MessageThread>('/order/spp-messages', { params: { orderId } });
}

export function sendMessage(requestBody: SendMessage) {
  return axios.post('/order/messages', requestBody);
}

export function createOrder(requestBody: CreateOrder) {
  return axios.post<string>('/order/manual', requestBody);
}

export function getUserOrderDetailsById(id: string) {
  return axios.get<UserOrderDetail>(`/order/sum/client/${id}`);
}

export function updateOrderClientDetails(requestBody: updateOrderClient) {
  return axios.post('/order/change/client', requestBody);
}
