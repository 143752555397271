import { AxiosError } from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { useForm, SubmitHandler } from 'react-hook-form';

import SideTestimonial from './SideTestimonial';
import SignInForm from '../shared/SignInForm';
import LoadingButton from '../shared/Inputs/LoadingButton';
import { useRouter } from '../../hooks/use-router';

import useStyles from './styles';
import { signIn } from '../../requests/auth';
import { ISignInInput } from '../../interfaces/auth';
import { useSnackbar } from '../../hooks/use-snackbar';
import localStorage from '../../utils/local-storage';

import Logo from '../../assets/img/logo.svg';

function SignIn() {
  const classes = useStyles();
  const router = useRouter();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { control, formState, handleSubmit } = useForm<ISignInInput>({
    defaultValues: { email: '', password: '' },
  });

  const onSubmit: SubmitHandler<ISignInInput> = async (data) => {
    setIsLoading(true);
    try {
      const { jwtToken, refreshToken } = (await signIn(data)).data;
      localStorage.setAccessToken(jwtToken);
      localStorage.setRefreshToken(refreshToken);
      setIsLoading(false);
      const pathname = (router.query.redirectUrl as string) || '/';
      router.replace(pathname);
    } catch (error) {
      const errorMessage =
        (error as AxiosError)?.response?.data?.message || 'An error occurred. Please try again.';
      setIsLoading(false);
      showSnackbar({ severity: 'error', message: errorMessage });
    }
  };

  return (
    <Grid container className={classes.root}>
      <Hidden smDown>
        <Grid item sm={6} md={6}>
          <SideTestimonial />
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={12} md={6}>
        <div className={classes.paper}>
          <Hidden mdUp>
            <div className={classes.logo}>
              <img src={Logo} alt="StanVentures" />
            </div>
          </Hidden>
          <p className={classes.title}>Welcome Back!</p>
          <p className={classes.subTitle}>Sign In</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SignInForm control={control} formState={formState} />
            <div className={`${classes.btnContainer} ${classes.signInBtnContainer}`}>
              <div>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  type="submit"
                  classes={{ root: classes.button }}
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  SIGN IN
                </LoadingButton>
              </div>
              <div className={classes.troubleSigningInContainer}>
                <Link to="/password/reset" className={classes.troubleSigningIn}>
                  Trouble signing in?
                </Link>
              </div>
            </div>
          </form>
          <p className={classes.existingText}>
            Don't have an account?{' '}
            <Link to="/signup" className={classes.link}>
              Sign Up
            </Link>
          </p>
        </div>
      </Grid>
    </Grid>
  );
}

export default SignIn;
