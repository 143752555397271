import clsx from 'clsx';
import { useEffect, useState } from 'react';
import capitalize from 'lodash/capitalize';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import {
  GridColDef,
  GridRowsProp,
  GridSelectionModel,
  GridValueFormatterParams,
} from '@material-ui/data-grid';

import PageTitleWithBack from '../shared/PageTitleWithBack';
import MinimalTable from '../shared/MinimalTable';
import AffiliateDetails from './AffiliateDetails';
import Button from '../shared/Inputs/Button';
import LoadingButton from '../shared/Inputs/LoadingButton';

import useStyles from './styles';
import { useRouter } from '../../hooks/use-router';
import { useSnackbar } from '../../hooks/use-snackbar';
import {
  downloadBlob,
  getClientStatusOptions,
  getClientTypeOptions,
  getCommissionStatusOptions,
  getFormattedDate,
  loginAsUser,
  MUI_THEME,
} from '../../utils/utils';
import {
  exportOrders,
  getOrders,
  getUserOrderDetailsById,
  updateOrderClientDetails,
} from '../../requests/order';
import {
  cancelSubscription,
  chargeSubscription,
  getSubscriptions,
} from '../../requests/subscriptions';
import { getClientAccessToken, getUserDetailsById } from '../../requests/auth';
import { IUser } from '../../interfaces/user';
import { getClientListOptions, updateClientDetails } from '../../requests/clients';
import { getCommissions, updateCommissionStatus } from '../../requests/affiliates';
import { CommissionStatus } from '../../interfaces/affiliates';
import { UserOrderDetail } from '../../interfaces/order';
import { IAssignedUser } from '../../interfaces/shared';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'lodash/debounce';
import TextField from '../shared/Inputs/TextField';
import { Controller, useForm } from 'react-hook-form';
import { IconButton, Menu } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import ConfirmActionDialog from '../shared/ConfirmActionDialog';

interface IProps {
  clientId: string;
}
interface IGridState {
  content: GridRowsProp;
  totalElements: number;
}
interface DialogData {
  open: boolean;
  isLoading: boolean;
  subscriptionId: string;
  operation: string;
  disableChargeNow: boolean;
  disableCancel: boolean;
}

const initialDialogData: DialogData = {
  open: false,
  isLoading: false,
  subscriptionId: '',
  operation: '',
  disableChargeNow: false,
  disableCancel: false,
};

function ClientDetails({ clientId }: IProps) {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const router = useRouter();
  const [clientDetails, setClientDetails] = useState<IUser | null>(null);
  const [clientOrderDetail, setClientOderDetail] = useState<UserOrderDetail | null>(null);
  const [fieldValues, setFieldValues] = useState<{ [key: string]: string | boolean }>({
    status: '',
    clientType: '',
    isAffiliate: false,
    commissionStatus: '',
    transferOrder: '',
  });
  const [currentTabIndex, setcurrentTabIndex] = useState(0);
  const [isClientLoading, setIsClientLoading] = useState(false);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [isSubscriptionsLoading, setIsSubscriptionsLoading] = useState(false);
  const [isCommissionsLoading, setIsCommissionsLoading] = useState(false);
  const [isLoginAsUserLoading, setIsLoginAsUserLoading] = useState(false);
  const [isCommissionStatusUpdating, setIsCommissionStatusUpdating] = useState(false);
  const [{ orderPage, orderPageSize }, setOrderSearchParams] = useState({
    orderPage: 0,
    orderPageSize: 10,
  });
  const [{ subscriptionPage, subscriptionPageSize }, setSubscriptionSearchParams] = useState({
    subscriptionPage: 0,
    subscriptionPageSize: 10,
  });
  const [{ commissionPage, commissionPageSize }, setCommissionSearchParams] = useState({
    commissionPage: 0,
    commissionPageSize: 10,
  });
  const [orders, setOrders] = useState<IGridState>({ content: [], totalElements: 0 });
  const [subscriptions, setSubscriptions] = useState<IGridState>({ content: [], totalElements: 0 });
  const [commissions, setCommissions] = useState<IGridState>({ content: [], totalElements: 0 });
  const [commissionSelectionModel, setCommissionSelectionModel] = useState<GridSelectionModel>([]);
  const [showAffiliateDetails, setShowAffiliateDetails] = useState(false);
  const [exporting, setExporting] = useState(false);
  const isAffiliate = Boolean(clientDetails?.affiliate) && clientDetails?._id === clientId;
  const [ordersSelectionModel, setOrdersSelectionModel] = useState<GridSelectionModel>([]);
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const [clientList, setClientList] = useState<IAssignedUser[]>([]);
  const [isOrderSaveButtonEnable, setIsOrderSaveButtonEnable] = useState(true);
  const [isOrderClientStatusUpdating, setIsOrderClientStatusUpdating] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogData, setDialogData] = useState<DialogData>(initialDialogData);

  let cancelToken: CancelTokenSource;
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<{ clientId: string }>({
    defaultValues: { clientId: '' },
  });
  function handleBack() {
    router.history.replace('/clients');
  }

  useEffect(() => {
    fetchUserDetails();
    fetchUserOrderDetails();
    setcurrentTabIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line
  }, [clientId, orderPage, orderPageSize]);

  useEffect(() => {
    fetchSubscriptions();
    // eslint-disable-next-line
  }, [clientId, subscriptionPage, subscriptionPageSize]);

  useEffect(() => {
    if (isAffiliate) {
      fetchCommissions();
    }
    // eslint-disable-next-line
  }, [clientId, clientDetails, isAffiliate, commissionPage, commissionPageSize]);

  function fetchUserDetails(showLoading = true) {
    setIsClientLoading(showLoading);
    getUserDetailsById(clientId)
      .then((res) => {
        if (showLoading) {
          setIsClientLoading(false);
        }
        setClientDetails(res.data);
        setFieldValues({
          status: res.data.userStatus || '',
          clientType: res.data.clientType || '',
          isAffiliate: res.data.affiliate || false,
        });
      })
      .catch((error: AxiosError) => {
        if (showLoading) {
          setIsClientLoading(false);
        }
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function fetchUserOrderDetails(showLoading = true) {
    setIsClientLoading(showLoading);
    getUserOrderDetailsById(clientId)
      .then((res) => {
        if (showLoading) {
          setIsClientLoading(false);
        }
        setClientOderDetail(res.data);
      })
      .catch((error: AxiosError) => {
        if (showLoading) {
          setIsClientLoading(false);
        }
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function fetchOrders() {
    setIsOrdersLoading(true);
    getOrders({
      page: orderPage,
      pageSize: orderPageSize,
      filterFields: [{ key: 'clientId', searchOperation: 'eq', value: clientId || '' }],
    })
      .then((res) => {
        setIsOrdersLoading(false);
        setOrders({ content: res.data.content, totalElements: res.data.totalElements });
      })
      .catch((error: AxiosError) => {
        setIsOrdersLoading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function fetchSubscriptions() {
    setIsSubscriptionsLoading(true);
    getSubscriptions({
      page: subscriptionPage,
      pageSize: subscriptionPageSize,
      filterFields: [{ key: 'clientId', searchOperation: 'eq', value: clientId || '' }],
    })
      .then((res) => {
        setIsSubscriptionsLoading(false);
        setSubscriptions({
          content: res.data.content,
          totalElements: res.data.totalElements,
        });
      })
      .catch((error: AxiosError) => {
        setIsSubscriptionsLoading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function fetchCommissions() {
    setIsCommissionsLoading(true);
    getCommissions({
      page: commissionPage,
      pageSize: commissionPageSize,
      filterFields: [{ key: 'referrerClientId', searchOperation: 'eq', value: clientId || '' }],
    })
      .then((res) => {
        setIsCommissionsLoading(false);
        setCommissions({
          content:
            res.data.content?.map((commission) => ({ ...commission, id: commission._id })) || [],
          totalElements: res.data.totalElements,
        });
      })
      .catch((error: AxiosError) => {
        setIsCommissionsLoading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleLoginAsUser() {
    setIsLoginAsUserLoading(true);
    getClientAccessToken(clientId)
      .then((res) => {
        setIsLoginAsUserLoading(false);
        loginAsUser(res.data);
      })
      .catch((error: AxiosError) => {
        setIsLoginAsUserLoading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleStatusChange(name: string, value: string | boolean) {
    const prevValue = fieldValues[name];
    setFieldValues({ ...fieldValues, [name]: value });
    updateClientDetails(clientId, { [name]: value })
      .then((res) => {
        if (res.data) {
          if (name === 'isAffiliate') {
            fetchUserDetails(false);
            fetchUserOrderDetails(false);
          }
          showSnackbar({ severity: 'success', message: 'Updated successfully' });
        } else {
          throw new Error('An error occurred. Please try again.');
        }
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
        setFieldValues({ ...fieldValues, [name]: prevValue });
      });
  }

  function handleUpdateCommissionStatus() {
    setIsCommissionStatusUpdating(true);
    updateCommissionStatus({
      commissionIds: commissionSelectionModel as string[],
      newStatus: fieldValues['commissionStatus'] as CommissionStatus,
    })
      .then(() => {
        showSnackbar({ severity: 'success', message: 'Updated successfully' });
        setIsCommissionStatusUpdating(false);
        fetchCommissions();
        setCommissionSelectionModel([]);
        setFieldValues({ ...fieldValues, commissionStatus: '' });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleUpdateOrderClient(data: { clientId: string }) {
    setIsOrderClientStatusUpdating(true);
    updateOrderClientDetails({
      id: ordersSelectionModel as string[],
      updatedClientId: data.clientId,
      oldClientId: clientId,
    })
      .then(() => {
        showSnackbar({ severity: 'success', message: 'Updated successfully' });
        setIsOrderClientStatusUpdating(false);
        fetchOrders();
        setOrdersSelectionModel([]);
        reset({ clientId: '' });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function getSelectField({
    name,
    value,
    options,
    label,
    classNames,
    onChange,
  }: {
    name: string;
    value: string | undefined;
    options: {
      label: string;
      value: string;
    }[];
    label: JSX.Element | string | undefined;
    classNames?: string;
    onChange: (name: string, value: string) => void;
  }) {
    return (
      <FormControl
        variant="outlined"
        size="small"
        className={clsx(classes.statusSelectField, classNames)}
      >
        {label && <div className={classes.fieldTitle}>{label}</div>}
        <Select
          name={name}
          labelId={`${name}-field-label`}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            transformOrigin: { vertical: 'top', horizontal: 'center' },
            getContentAnchorEl: null,
          }}
          value={value}
          onChange={(event) => onChange(event.target.name as string, event.target.value as string)}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  function getAffiliateSwitch() {
    return (
      <div className={classes.affiliateContainer}>
        <div>
          <Switch
            color="primary"
            name="isAffiliate"
            checked={fieldValues['isAffiliate'] as boolean}
            onChange={(event) => handleStatusChange(event.target.name, event.target.checked)}
          />
        </div>
        {clientDetails?.affiliate && (
          <div>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={() => setShowAffiliateDetails(true)}
            >
              View Details
            </Button>
          </div>
        )}
      </div>
    );
  }

  function getFormattedClientInfo() {
    if (!clientDetails || !clientOrderDetail) return {};
    const formattedClientInfo: { [key: string]: JSX.Element | string } = {
      Status: getSelectField({
        name: 'status',
        value: fieldValues['status'] as string,
        options: getClientStatusOptions(),
        label: undefined,
        onChange: handleStatusChange,
      }),
      'Client Type': getSelectField({
        name: 'clientType',
        value: fieldValues['clientType'] as string,
        options: getClientTypeOptions(),
        label: undefined,
        onChange: handleStatusChange,
      }),
      Email: clientDetails.email,
      ...(clientDetails.sppId && { 'SPP Id': clientDetails.sppId }),
      Created: getFormattedDate(clientDetails.createdTime),
      'Last Login': getFormattedDate(clientDetails.lastLogin),
      Phone: clientDetails.phoneNo,
      Address: (
        <>
          <div>{`${clientDetails.address}, ${clientDetails.city}`}</div>
          <div>{`${clientDetails.state ? `${clientDetails.state} ` : ''}${
            clientDetails.zipCode
          }`}</div>
          <div>{`${clientDetails.country}`}</div>
        </>
      ),
      'Stripe Customer Id': clientDetails.stripeCustomerId,
      Affiliate: getAffiliateSwitch(),
      'Total Number Of Orders': clientOrderDetail?.ordersCount,
      'Total Order Amount': `$${clientOrderDetail?.sumOfOrderValues}`,
    };

    return formattedClientInfo;
  }

  function handleCloseAffiliateDetails() {
    setShowAffiliateDetails(false);
  }

  function handleCommissionSelectionModelChange(selectionModel: GridSelectionModel) {
    setCommissionSelectionModel(selectionModel);
  }
  function handleOrderSelectionModelChange(selectionModel: GridSelectionModel) {
    setOrdersSelectionModel(selectionModel);
  }

  function handleUpdateFieldValues(name: string, value: string) {
    setFieldValues({ ...fieldValues, [name]: value });
  }

  function getOrderColumns() {
    const columns: GridColDef[] = [
      {
        field: 'orderId',
        headerName: 'Order ID',
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Button
            component={Link}
            to={`/orders/${btoa(params.id as string)}`}
            size="small"
            variant="text"
            style={{ color: '#4c86ef' }}
          >
            {params.getValue(params.id, 'orderId')}
          </Button>
        ),
      },
      {
        field: 'totalAmount',
        headerName: 'Amount',
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams) =>
          `$${params.getValue(params.id, 'totalAmount')}`,
      },
      { field: 'serviceName', headerName: 'Service', flex: 1, sortable: false },
      { field: 'createdTime', headerName: 'Created Date', flex: 1, sortable: false },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        valueFormatter: (params: GridValueFormatterParams) =>
          `${params.getValue(params.id, 'status') || ''}`.split('_').join(' '),
        sortable: false,
      },
    ];
    return columns;
  }

  function handleOptionsClick(event: React.MouseEvent<HTMLElement>, id: string, status: string) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setDialogData({
      ...initialDialogData,
      subscriptionId: id,
      disableChargeNow: ['cancelled', 'past_due'].includes((status || '').toLowerCase()),
      disableCancel: ['cancelled'].includes((status || '').toLowerCase()),
    });
  }

  function getSubscriptionColumns() {
    const columns: GridColDef[] = [
      { field: 'initialOrderId', headerName: 'Order ID', flex: 1, sortable: false },
      { field: 'serviceName', headerName: 'Service', flex: 1, sortable: false },
      { field: 'currentCycle', headerName: 'Payments', flex: 1, sortable: false },
      {
        field: 'previousPayment',
        headerName: 'Last Payment',
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams) =>
          getFormattedDate(params.getValue(params.id, 'previousPayment') as string),
      },
      {
        field: 'nextPayment',
        headerName: 'Next Payment',
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams) =>
          getFormattedDate(params.getValue(params.id, 'nextPayment') as string),
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        valueFormatter: (params: GridValueFormatterParams) =>
          `${params.getValue(params.id, 'status') || ''}`.split('_').join(' '),
        sortable: false,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (
          <IconButton
            size="small"
            onClick={(event) =>
              handleOptionsClick(
                event,
                params.getValue(params.id, 'id') as string,
                params.getValue(params.id, 'status') as string
              )
            }
          >
            <MoreIcon fontSize="small" />
          </IconButton>
        ),
      },
    ];
    return columns;
  }

  function getCommissionColumns() {
    const columns: GridColDef[] = [
      {
        field: 'orderId',
        headerName: 'Order ID',
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Button
            component={Link}
            to={`/orders/${btoa(params.getValue(params.id, 'orderBySystemId') as string)}`}
            size="small"
            variant="text"
            style={{ color: '#4c86ef' }}
          >
            {params.getValue(params.id, 'orderId')}
          </Button>
        ),
      },
      {
        field: 'refereeClientName',
        headerName: 'Client Name',
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Link
            className={classes.linkBlue}
            to={`/clients/${btoa(params.row.refereeClientId as string)}`}
            onClick={(event) => event.stopPropagation()}
          >
            {params.row.refereeClientName}
          </Link>
        ),
      },
      {
        field: 'amount',
        headerName: 'Commission',
        flex: 1,
        sortable: false,
        renderCell: (params) => `$${params.row.amount || 0}`,
      },
      {
        field: 'createdTime',
        headerName: 'Date',
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams) =>
          getFormattedDate(params.getValue(params.id, 'createdTime') as string),
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => {
          const status = (params.getValue(params.id, 'status') as string) || '';
          return (
            <div className={clsx(classes.commissionStatus, status.toLowerCase())}>
              {capitalize(status.split('_').join(' '))}
            </div>
          );
        },
        sortable: false,
      },
    ];
    return columns;
  }

  function fetchClientListOptions(event: React.ChangeEvent<{}>, search: string) {
    if (search?.length === 0) {
      setIsOrderSaveButtonEnable(true);
    }
    if (event?.type === 'change' && search?.length > 1) {
      if (typeof cancelToken !== 'undefined') {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      cancelToken = axios.CancelToken.source();

      setIsClientsLoading(true);
      getClientListOptions(search, cancelToken.token)
        .then((res) => {
          setIsClientsLoading(false);
          setClientList(res.data);
        })
        .catch((error: AxiosError) => {
          setIsClientsLoading(false);
        });
    }
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleDialogClose() {
    setDialogData(initialDialogData);
  }

  function handleMenuItemClick(operation: string) {
    handleMenuClose();
    setDialogData({ ...dialogData, operation, open: true });
  }

  function handleConfirm() {
    switch (dialogData.operation) {
      case 'charge-now':
        handleChargeNow();
        break;
      case 'cancel':
        handleCancelSubscription();
        break;
      default:
        showSnackbar({ severity: 'error', message: 'Unknown operation' });
        break;
    }
  }

  function handleChargeNow() {
    setDialogData({ ...dialogData, isLoading: true });
    chargeSubscription(dialogData.subscriptionId)
      .then(() => {
        showSnackbar({ severity: 'success', message: 'Subscription charged successfully' });
        handleDialogClose();
        fetchSubscriptions();
      })
      .catch((error: AxiosError) => {
        setDialogData({ ...dialogData, isLoading: false });
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleCancelSubscription() {
    setDialogData({ ...dialogData, isLoading: true });
    cancelSubscription(dialogData.subscriptionId)
      .then(() => {
        showSnackbar({ severity: 'success', message: 'Subscription canceled successfully' });
        handleDialogClose();
        fetchSubscriptions();
      })
      .catch((error: AxiosError) => {
        setDialogData({ ...dialogData, isLoading: false });
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }
  function getTabComponent(tabIndex: number) {
    switch (tabIndex) {
      case 0:
        return (
          <>
            {ordersSelectionModel.length > 0 && (
              <form onSubmit={handleSubmit(handleUpdateOrderClient)}>
                <div className={classes.orderChangeClientContainer}>
                  <div>
                    <Controller
                      name="clientId"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { ref, value, onChange } }) => {
                        const hasError = Boolean(errors.clientId);
                        return (
                          <Autocomplete
                            ref={ref}
                            size="small"
                            options={clientList}
                            loading={isClientsLoading}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option) => option.id === value}
                            value={clientList.find((option) => option.id === value)}
                            onChange={(_, newValue) => {
                              onChange(newValue ? newValue.id : '');
                              setIsOrderSaveButtonEnable(false);
                            }}
                            openOnFocus
                            disableClearable
                            onInputChange={debounce(fetchClientListOptions, 500)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className={classes.orderChangeClientSelectField}
                                placeholder="Type client name to search"
                                variant="outlined"
                                error={hasError}
                                {...(hasError && {
                                  helperText: 'Select a client',
                                })}
                              />
                            )}
                          />
                        );
                      }}
                    />
                  </div>
                  <div>
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      isLoading={isOrderClientStatusUpdating}
                      disabled={isOrderSaveButtonEnable}
                      type="submit"
                    >
                      Move Order
                    </LoadingButton>
                  </div>
                </div>
              </form>
            )}
            <MinimalTable
              page={orderPage}
              pageSize={orderPageSize}
              rows={orders.content}
              columns={getOrderColumns()}
              totalElements={orders.totalElements}
              loading={isOrdersLoading}
              checkboxSelection
              selectionModel={ordersSelectionModel}
              onPageChange={(page) => setOrderSearchParams({ orderPage: page, orderPageSize })}
              onPageSizeChange={(pageSize) =>
                setOrderSearchParams({ orderPage: 0, orderPageSize: pageSize })
              }
              onSelectionModelChange={handleOrderSelectionModelChange}
            />
          </>
        );
      case 1:
        return (
          <>
            <MinimalTable
              page={subscriptionPage}
              pageSize={subscriptionPageSize}
              rows={subscriptions.content}
              columns={getSubscriptionColumns()}
              totalElements={subscriptions.totalElements}
              loading={isSubscriptionsLoading}
              onPageChange={(page) =>
                setSubscriptionSearchParams({ subscriptionPage: page, subscriptionPageSize })
              }
              onPageSizeChange={(pageSize) =>
                setSubscriptionSearchParams({ subscriptionPage: 0, subscriptionPageSize: pageSize })
              }
            />
            <Menu
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                disabled={dialogData.disableChargeNow}
                onClick={() => handleMenuItemClick('charge-now')}
              >
                Charge Now
              </MenuItem>
              <MenuItem
                disabled={dialogData.disableCancel}
                onClick={() => handleMenuItemClick('cancel')}
              >
                Cancel Subscription
              </MenuItem>
            </Menu>
            <ConfirmActionDialog
              open={dialogData.open}
              isLoading={dialogData.isLoading}
              onClose={handleDialogClose}
              onConfirm={handleConfirm}
            />
          </>
        );
      case 2: {
        return (
          <>
            {commissionSelectionModel.length > 0 && (
              <div className={classes.commissionStatusContainer}>
                <div>
                  {getSelectField({
                    name: 'commissionStatus',
                    value: fieldValues['commissionStatus'] as string,
                    options: getCommissionStatusOptions(),
                    label: 'New Commission Status',
                    classNames: classes.commissionStatusSelectField,
                    onChange: handleUpdateFieldValues,
                  })}
                </div>
                <div>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    isLoading={isCommissionStatusUpdating}
                    disabled={!fieldValues['commissionStatus'] || isCommissionStatusUpdating}
                    onClick={handleUpdateCommissionStatus}
                  >
                    Update Status
                  </LoadingButton>
                </div>
              </div>
            )}
            <MinimalTable
              page={commissionPage}
              pageSize={commissionPageSize}
              rows={commissions.content}
              columns={getCommissionColumns()}
              totalElements={commissions.totalElements}
              loading={isCommissionsLoading}
              checkboxSelection
              selectionModel={commissionSelectionModel}
              onPageChange={(page) =>
                setCommissionSearchParams({ commissionPage: page, commissionPageSize })
              }
              onPageSizeChange={(pageSize) =>
                setCommissionSearchParams({ commissionPage: 0, commissionPageSize: pageSize })
              }
              onSelectionModelChange={handleCommissionSelectionModelChange}
            />
          </>
        );
      }
      default:
        return <div>Unknown Tab</div>;
    }
  }
  function handleExportOrders() {
    setExporting(true);
    showSnackbar({
      severity: 'info',
      message: 'Please wait while we prepare the data. Your file will be downloaded any moment',
    });
    exportOrders({
      filterFields: [{ key: 'clientId', searchOperation: 'eq', value: clientId || '' }],
    })
      .then((res) => {
        downloadBlob('Orders', res.data);
        setExporting(false);
        showSnackbar({ severity: 'success', message: 'File has been downloaded' });
      })
      .catch(() => {
        setExporting(false);
        showSnackbar({ severity: 'error', message: 'An error occurred while exporting' });
      });
  }

  return (
    <ThemeProvider theme={MUI_THEME}>
      <Container maxWidth="lg" disableGutters>
        <PageTitleWithBack title="Client Details" disableGutters onBackClick={handleBack} />
        <div className={classes.clientDetailsHeader}>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                variant="circular"
                src={clientDetails?.photoUrl}
                alt={clientDetails?.firstName.charAt(0).toUpperCase()}
              />
            </ListItemAvatar>
            <ListItemText
              primary={clientDetails ? `${clientDetails.firstName} ${clientDetails.lastName}` : ''}
            />
          </ListItem>
          <div>
            <div className={classes.clientDetailsHeaderActionsContainer}>
              <div className={classes.exportOrdersButton}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleExportOrders}
                  disabled={exporting}
                  isLoading={exporting}
                >
                  Export Orders
                </LoadingButton>
              </div>
              <div className={classes.loginAsUserButton}>
                <LoadingButton
                  isLoading={isLoginAsUserLoading}
                  disabled={isLoginAsUserLoading}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleLoginAsUser}
                >
                  Login as User
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.clientInfo}>
          {isClientLoading ? (
            <div className={classes.clientInfoLoadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            Object.entries(getFormattedClientInfo()).map(([key, value], i) => (
              <div key={i} className={classes.clientInfoItem}>
                <div>{key}</div>
                <div>{value}</div>
              </div>
            ))
          )}
        </div>
        <div className={classes.tabContainer}>
          <Tabs
            value={currentTabIndex}
            onChange={(_, newTabIndex) => setcurrentTabIndex(newTabIndex)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Orders" />
            <Tab label="Subscriptions" />
            <Tab label="Commissions" />
          </Tabs>
          {getTabComponent(currentTabIndex)}
        </div>
      </Container>
      {isAffiliate && (
        <AffiliateDetails
          open={showAffiliateDetails}
          clientDetails={clientDetails}
          fetchUserDetails={fetchUserDetails}
          onClose={handleCloseAffiliateDetails}
        />
      )}
    </ThemeProvider>
  );
}

export default ClientDetails;
