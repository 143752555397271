import { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { useForm, Controller, SubmitHandler, useFieldArray } from 'react-hook-form';

import LoadingButton from '../shared/Inputs/LoadingButton';
import Button from '../shared/Inputs/Button';
import TextField from '../shared/Inputs/TextField';
import { AddOnItem, DiscountItem, PackageForm } from '../../interfaces/services';
import { MUI_THEME } from '../../utils/utils';
import useStyles from './styles';
import { REGEX } from '../../constants';

interface IAddEditPackage {
  open: boolean;
  mode: 'add' | 'edit';
  isLoading: boolean;
  data: PackageForm;
  onClose(): void;
  onSubmit: SubmitHandler<PackageForm>;
}

function AddEditPackage({ open, mode, isLoading, data, onClose, onSubmit }: IAddEditPackage) {
  const classes = useStyles();
  const [currentTabIndex, setcurrentTabIndex] = useState(0);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      price: 0,
      addOnItems: [] as AddOnItem[],
      discountItems: [] as DiscountItem[],
    },
  });
  const {
    fields: addOnItemFields,
    append: appendAddOnItem,
    remove: removeAddOnItem,
  } = useFieldArray({ control, name: 'addOnItems' });
  const {
    fields: discountItemFields,
    append: appendDiscountItem,
    remove: removeDiscountItem,
  } = useFieldArray({ control, name: 'discountItems' });
  const addOnItems = watch('addOnItems');
  const discountItems = watch('discountItems');
  const controlledAddOnItems = addOnItemFields.map((field, index) => {
    return {
      ...field,
      ...addOnItems[index],
    };
  });
  const controlledDiscountItems = discountItemFields.map((field, index) => {
    return {
      ...field,
      ...discountItems[index],
    };
  });

  useEffect(() => {
    setcurrentTabIndex(0);
    reset({
      name: mode === 'edit' ? data.name : '',
      description: mode === 'edit' ? data.description : '',
      price: mode === 'edit' ? data.price : 1,
      addOnItems: mode === 'edit' ? data.addOnItems || [] : [],
      discountItems: mode === 'edit' ? data.discountItems || [] : [],
    });
  }, [data, mode, reset]);

  function shouldDisableAddOnItem(index: number) {
    const item = addOnItems[index];
    if (item.defaultOption) return false;
    return addOnItems.some((addOnItem) => addOnItem.defaultOption === true);
  }

  function getTabComponent(tabIndex: number) {
    switch (tabIndex) {
      case 0:
        return (
          <>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...rest } }) => {
                const hasError = Boolean(errors.name);
                const hasRequiredError = errors?.name?.type === 'required';
                return (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    type="text"
                    label="Domain Authority Name"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    error={hasError}
                    {...(hasError && {
                      helperText: (
                        <span>{hasRequiredError && 'Domain authority name is required'}</span>
                      ),
                    })}
                  />
                );
              }}
            />
            <Controller
              name="description"
              control={control}
              rules={{ required: false }}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    type="text"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={6}
                    className={classes.textField}
                  />
                );
              }}
            />
            <Controller
              name="price"
              control={control}
              rules={{ required: true, min: 1 }}
              render={({ field: { ref, ...rest } }) => {
                const hasError = Boolean(errors.price);
                const hasRequiredError = errors?.price?.type === 'required';
                return (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    type="number"
                    label="Charge"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: <span className={classes.dollarSign}>$</span>,
                    }}
                    className={classes.textField}
                    error={hasError}
                    {...(hasError && {
                      helperText: <span>{hasRequiredError && 'Charge is required'}</span>,
                    })}
                  />
                );
              }}
            />
          </>
        );
      case 1:
        return (
          <TableContainer>
            <Table aria-label="add on items table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell align="center">Default</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {controlledAddOnItems.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No add-on items found.
                    </TableCell>
                  </TableRow>
                ) : (
                  controlledAddOnItems.map((row, index) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell>
                          <Controller
                            name={`addOnItems.${index}.value` as const}
                            control={control}
                            rules={{ required: true }}
                            defaultValue={row.value}
                            render={({ field: { ref, ...rest } }) => {
                              const hasError = Boolean(errors?.addOnItems?.[index]?.value);
                              const hasRequiredError =
                                errors?.addOnItems?.[index]?.value?.type === 'required';
                              return (
                                <TextField
                                  {...rest}
                                  inputRef={ref}
                                  variant="outlined"
                                  size="small"
                                  error={hasError}
                                  {...(hasError && {
                                    helperText: (
                                      <span>{hasRequiredError && 'Name is required'}</span>
                                    ),
                                  })}
                                />
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            name={`addOnItems.${index}.price` as const}
                            control={control}
                            rules={{ required: false }}
                            defaultValue={row.price}
                            render={({ field: { ref, onChange, ...rest } }) => {
                              return (
                                <TextField
                                  {...rest}
                                  inputRef={ref}
                                  variant="outlined"
                                  size="small"
                                  onChange={(event) => {
                                    const value = event.target.value;
                                    if (value !== '') {
                                      if (!REGEX.NUMBERS.test(value)) {
                                        return;
                                      }
                                    }
                                    onChange(value);
                                  }}
                                  InputProps={{
                                    startAdornment: <span className={classes.dollarSign}>$</span>,
                                  }}
                                />
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Controller
                            name={`addOnItems.${index}.defaultOption` as const}
                            control={control}
                            defaultValue={row.defaultOption}
                            render={({ field: { value, onChange } }) => {
                              return (
                                <Checkbox
                                  color="primary"
                                  size="small"
                                  className={classes.defaultCheckbox}
                                  checked={value}
                                  onChange={(event) => onChange(event.target.checked)}
                                  disabled={shouldDisableAddOnItem(index)}
                                />
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton size="small" onClick={() => removeAddOnItem(index)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
            <div className={classes.addAddOnButton}>
              <Button
                startIcon={<AddIcon />}
                color="primary"
                variant="text"
                onClick={() =>
                  appendAddOnItem({ value: '500 Words', price: 0, defaultOption: false })
                }
              >
                Add
              </Button>
            </div>
          </TableContainer>
        );
      case 2:
        return (
          <TableContainer>
            <Table aria-label="discount items table">
              <TableHead>
                <TableRow>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Discount (%)</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {controlledDiscountItems.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No discount items found.
                    </TableCell>
                  </TableRow>
                ) : (
                  controlledDiscountItems.map((row, index) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell>
                          <Controller
                            name={`discountItems.${index}.count` as const}
                            control={control}
                            rules={{ required: true, min: 1 }}
                            defaultValue={row.count}
                            render={({ field: { ref, onChange, ...rest } }) => {
                              const hasError = Boolean(errors?.discountItems?.[index]?.count);
                              const hasRequiredError =
                                errors?.discountItems?.[index]?.count?.type === 'required';
                              const hasMinValueError =
                                errors?.discountItems?.[index]?.count?.type === 'min';
                              return (
                                <TextField
                                  {...rest}
                                  inputRef={ref}
                                  variant="outlined"
                                  size="small"
                                  onChange={(event) => {
                                    const value = event.target.value;
                                    if (value !== '') {
                                      if (!REGEX.NUMBERS.test(value)) {
                                        return;
                                      }
                                    }
                                    onChange(value);
                                  }}
                                  error={hasError}
                                  {...(hasError && {
                                    helperText: (
                                      <span>
                                        {hasRequiredError && 'Quantity is required'}
                                        {hasMinValueError && 'Minimum quantity should be 1'}
                                      </span>
                                    ),
                                  })}
                                />
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            name={`discountItems.${index}.percent` as const}
                            control={control}
                            rules={{ required: true, min: 1, max: 100 }}
                            defaultValue={row.percent}
                            render={({ field: { ref, onChange, ...rest } }) => {
                              const hasError = Boolean(errors?.discountItems?.[index]?.percent);
                              const hasRequiredError =
                                errors?.discountItems?.[index]?.percent?.type === 'required';
                              const hasMinValueError =
                                errors?.discountItems?.[index]?.percent?.type === 'min';
                              const hasMaxValueError =
                                errors?.discountItems?.[index]?.percent?.type === 'max';
                              return (
                                <TextField
                                  {...rest}
                                  inputRef={ref}
                                  variant="outlined"
                                  size="small"
                                  onChange={(event) => {
                                    const value = event.target.value;
                                    if (value !== '') {
                                      if (!REGEX.NUMBERS.test(value)) {
                                        return;
                                      }
                                    }
                                    onChange(value);
                                  }}
                                  InputProps={{
                                    endAdornment: <span className={classes.dollarSign}>%</span>,
                                  }}
                                  error={hasError}
                                  {...(hasError && {
                                    helperText: (
                                      <span>
                                        {hasRequiredError && 'Discount is required'}
                                        {(hasMinValueError || hasMaxValueError) &&
                                          'Discount should be between 1 and 100'}
                                      </span>
                                    ),
                                  })}
                                />
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton size="small" onClick={() => removeDiscountItem(index)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
            <div className={classes.addAddOnButton}>
              <Button
                startIcon={<AddIcon />}
                color="primary"
                variant="text"
                onClick={() => appendDiscountItem({ count: 10, percent: 1 })}
              >
                Add
              </Button>
            </div>
          </TableContainer>
        );

      default:
        return <div>Unknown Tab</div>;
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{`${mode === 'add' ? 'Add' : 'Edit'} Package`}</DialogTitle>
        <DialogContent>
          <ThemeProvider theme={MUI_THEME}>
            <Tabs
              value={currentTabIndex}
              onChange={(event, newTabIndex) => setcurrentTabIndex(newTabIndex)}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Package Details" />
              <Tab label="Add On Items" />
              <Tab label="Discount Items" />
            </Tabs>
            <Paper className={classes.packageTabPaperRoot} elevation={0}>
              {getTabComponent(currentTabIndex)}
            </Paper>
          </ThemeProvider>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={onClose}>
            Close
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddEditPackage;
