import { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import Button from '../shared/Inputs/Button';

import { Announcement } from '../../interfaces/announcements';
import { getVisibleAnnouncements } from '../../requests/announcements';
import Percentage from '../../assets/img/percentage.svg';
import useStyles from './styles';

function Announcements() {
  const classes = useStyles();
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  useEffect(() => {
    getVisibleAnnouncements().then((res) => setAnnouncements(res.data));
  }, []);

  if (announcements.length === 0) {
    return null;
  }

  return (
    <div className={classes.announcementContainer}>
      <h2 className={classes.announcementTitle}>Announcements</h2>
      <p className={classes.announcementSubTitle}>News and deals for you</p>
      <div>
        <List className={classes.announcementList}>
          {announcements.map((announcement, i) => (
            <ListItem key={i} className={classes.announcementListItem}>
              <ListItemAvatar>
                <Avatar src={announcement.imageUrl || Percentage} />
              </ListItemAvatar>
              <ListItemText
                classes={{
                  root: classes.announcementListItemText,
                  primary: classes.announcementListItemTextPrimary,
                  secondary: classes.announcementListItemTextSecondary,
                }}
                primary={announcement.name}
                secondary={announcement.description}
              />

              <Button
                component="a"
                variant="contained"
                color="primary"
                href={announcement.linkUrl}
                {...(announcement.linkUrl && { target: '_blank' })}
              >
                Learn More
              </Button>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
}

export default Announcements;
