import { useState } from 'react';
import { AxiosError } from 'axios';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import { DataGrid, GridColDef, GridRowParams, GridRowsProp } from '@material-ui/data-grid';

import ConfirmDeleteDialog from '../shared/ConfirmDeleteDialog';
import useStyles from './styles';
import { useSnackbar } from '../../hooks/use-snackbar';
import { Service } from '../../interfaces/services';
import { deleteService, duplicateService, getCompleteServiceDetails } from '../../requests/service';
import { APP_URL } from '../../constants';
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
  ThemeProvider,
} from '@material-ui/core';
import { IUser } from '../../interfaces/user';
import { IInvoiceDetails } from '../../interfaces/invoice';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { generateServiceInvoice } from '../Orders/utils';
import { MUI_THEME } from '../../utils/utils';
import Button from '../shared/Inputs/Button';

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  totalElements: number;
  loading: boolean;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  onRowClick(params: GridRowParams): void;
  fetchServices(): void;
}

interface DeleteDialogData {
  open: boolean;
  isLoading: boolean;
}

const initialDeleteDialogData: DeleteDialogData = {
  open: false,
  isLoading: false,
};

const initialInvoiceDetails: IInvoiceDetails = {
  invoiceNo: '',
  dueDate: '',
  name: '',
  email: '',
  address: '',
  city: '',
  country: '',
  state: '',
  zipCode: '',
  invoiceCountry: '',
};

function ServicesTable(props: IProps) {
  const {
    rows,
    totalElements,
    page,
    pageSize,
    loading,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    fetchServices,
  } = props;
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [service, setService] = useState({} as Service);
  const [deleteDialogData, setDeleteDialogData] =
    useState<DeleteDialogData>(initialDeleteDialogData);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Service', flex: 1, sortable: false },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <IconButton size="small" onClick={(event) => handleOptionsClick(event, params.row as any)}>
          <MoreIcon fontSize="small" />
        </IconButton>
      ),
    },
  ];
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<IUser>({ defaultValues: initialInvoiceDetails });

  function handleOptionsClick(event: React.MouseEvent<HTMLElement>, data: Service) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setService(data);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleDeleteServiceClick() {
    setAnchorEl(null);
    setDeleteDialogData({ open: true, isLoading: false });
  }

  function handleDuplicateServiceClick() {
    setAnchorEl(null);
    duplicateService(service._id)
      .then((res) => {
        showSnackbar({ severity: 'success', message: 'Service duplicated sucessfully' });
        handleDialogClose();
        fetchServices();
      })
      .catch((error: AxiosError) => {
        showSnackbar({ severity: 'error', message: 'An error occurred. Please try again.' });
      });
  }

  function handleDialogClose() {
    setDeleteDialogData(initialDeleteDialogData);
    setService({} as Service);
  }

  function handleCopyCheckoutUrl() {
    navigator.clipboard
      .writeText(`${APP_URL}/services/${btoa(service._id)}/checkout`)
      .then(() => {
        showSnackbar({ severity: 'success', message: 'Checkout URL copied successfully' });
        setAnchorEl(null);
      })
      .catch(() => {
        showSnackbar({
          severity: 'error',
          message: 'Unable to copy checkout URL',
        });
      });
  }

  function handleGenerateInvoiceClick() {
    setAnchorEl(null);
    setOpenDialog(true);
  }

  function handleDeleteService() {
    deleteService(service._id)
      .then((res) => {
        showSnackbar({ severity: 'success', message: 'Service deleted sucessfully' });
        handleDialogClose();
        fetchServices();
      })
      .catch((error: AxiosError) => {
        showSnackbar({ severity: 'error', message: 'An error occurred. Please try again.' });
      });
  }

  function handleClose(event: {}, reason: 'backdropClick' | 'escapeKeyDown') {
    if (isLoading && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      return;
    }
    setOpenDialog(false);
  }

  const onSubmit: SubmitHandler<IInvoiceDetails> = async (data) => {
    setIsLoading(true);
    const checkoutURL = `${APP_URL}/services/${btoa(service._id)}/checkout`;
    getCompleteServiceDetails(service._id)
      .then((res) => {
        generateServiceInvoice(res.data, checkoutURL, data);
        reset(initialInvoiceDetails);
        setOpenDialog(false);
      })
      .catch((error: AxiosError) => {
        showSnackbar({ severity: 'error', message: 'An error occurred. Please try again.' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function onClose() {
    setOpenDialog(false);
  }

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        rowCount={totalElements}
        page={page}
        pageSize={pageSize}
        paginationMode="server"
        loading={loading}
        pagination
        classes={{ root: classes.servicesTable, row: classes.cursorPointer }}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onRowClick={onRowClick}
      />
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleCopyCheckoutUrl()}>Copy Checkout URL</MenuItem>
        <MenuItem onClick={() => handleGenerateInvoiceClick()}>Generate Invoice</MenuItem>
        <MenuItem onClick={() => handleDuplicateServiceClick()}>Duplicate</MenuItem>
        <MenuItem onClick={() => handleDeleteServiceClick()}>Delete</MenuItem>
      </Menu>
      <ConfirmDeleteDialog
        open={deleteDialogData.open}
        isLoading={deleteDialogData.isLoading}
        onClose={handleDialogClose}
        onConfirm={handleDeleteService}
      />
      <ThemeProvider theme={MUI_THEME}>
        <Dialog open={openDialog} onClose={handleClose}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <Container maxWidth="lg">
                <div className={classes.dialogTitle}>Invoice details</div>
                <Grid container alignItems="center" className={classes.container} spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="invoiceNo"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { ref, ...rest } }) => {
                        const hasError = Boolean(errors.invoiceNo);
                        const hasRequiredError = errors?.invoiceNo?.type === 'required';
                        return (
                          <TextField
                            {...rest}
                            inputRef={ref}
                            type="text"
                            label="Invoice Number"
                            variant="outlined"
                            fullWidth
                            error={hasError}
                            {...(hasError && {
                              helperText: (
                                <span>{hasRequiredError && 'Invoice Number is required'}</span>
                              ),
                            })}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="dueDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { ref, ...rest } }) => {
                        const hasError = Boolean(errors.dueDate);
                        const hasRequiredError = errors?.dueDate?.type === 'required';
                        return (
                          <TextField
                            {...rest}
                            inputRef={ref}
                            type="date"
                            label="Due Date"
                            variant="outlined"
                            fullWidth
                            error={hasError}
                            InputLabelProps={{ shrink: true }}
                            {...(hasError && {
                              helperText: <span>{hasRequiredError && 'Due Date is required'}</span>,
                            })}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { ref, ...rest } }) => {
                        const hasError = Boolean(errors.name);
                        const hasRequiredError = errors?.name?.type === 'required';
                        return (
                          <TextField
                            {...rest}
                            inputRef={ref}
                            type="text"
                            label="Name"
                            variant="outlined"
                            fullWidth
                            error={hasError}
                            {...(hasError && {
                              helperText: <span>{hasRequiredError && 'Name is required'}</span>,
                            })}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { ref, ...rest } }) => {
                        const hasError = Boolean(errors.email);
                        const hasRequiredError = errors?.email?.type === 'required';
                        return (
                          <TextField
                            {...rest}
                            inputRef={ref}
                            type="text"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            error={hasError}
                            {...(hasError && {
                              helperText: <span>{hasRequiredError && 'Email is required'}</span>,
                            })}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Controller
                      name="address"
                      control={control}
                      render={({ field: { ref, ...rest } }) => {
                        return (
                          <TextField
                            {...rest}
                            inputRef={ref}
                            type="text"
                            label="Address"
                            variant="outlined"
                            fullWidth
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Controller
                      name="city"
                      control={control}
                      render={({ field: { ref, ...rest } }) => {
                        return (
                          <TextField
                            {...rest}
                            inputRef={ref}
                            type="text"
                            label="City"
                            variant="outlined"
                            fullWidth
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { ref, ...rest } }) => {
                        return (
                          <TextField
                            {...rest}
                            inputRef={ref}
                            type="text"
                            label="State/Province/Region"
                            variant="outlined"
                            fullWidth
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field: { ref, ...rest } }) => {
                        return (
                          <TextField
                            {...rest}
                            inputRef={ref}
                            type="text"
                            label="Country"
                            variant="outlined"
                            fullWidth
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Controller
                      name="zipCode"
                      control={control}
                      render={({ field: { ref, ...rest } }) => {
                        return (
                          <TextField
                            {...rest}
                            inputRef={ref}
                            type="text"
                            label="Postal/Zip Code"
                            variant="outlined"
                            fullWidth
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Controller
                      name="invoiceCountry"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { ref, value, ...rest } }) => {
                        const hasError = Boolean(errors.invoiceCountry);
                        const hasRequiredError = errors?.invoiceCountry?.type === 'required';

                        return (
                          <FormControl variant="outlined" fullWidth size="medium" error={hasError}>
                            <InputLabel id="invoiceCountryLabel">Invoice Country</InputLabel>
                            <Select
                              {...rest}
                              fullWidth
                              labelId="invoiceCountryLabel"
                              label="Invoice Country"
                              value={value}
                            >
                              <MenuItem value="US">United States</MenuItem>
                              <MenuItem value="IN">INDIA</MenuItem>
                            </Select>
                            {hasRequiredError && (
                              <FormHelperText>Invoice Country is required</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={onClose}>
                Cancel
              </Button>
              <Button color="primary" type="submit" variant="contained">
                Generate
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </ThemeProvider>
    </>
  );
}

export default ServicesTable;
