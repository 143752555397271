import { useState } from 'react';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import ReactQuill from 'react-quill';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MuiButton from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createTheme } from '@material-ui/core/styles';
import { Controller, SubmitHandler, useFieldArray, UseFormReturn } from 'react-hook-form';

import useStyles from './styles';
import TextField from '../shared/Inputs/TextField';
import Button from '../shared/Inputs/Button';
import LoadingButton from '../shared/Inputs/LoadingButton';
import Checkbox from '../shared/Inputs/Checkbox';

import { ServiceForm } from '../../interfaces/services';
import { uploadFile } from '../../requests/common';
import { useSnackbar } from '../../hooks/use-snackbar';

import 'react-quill/dist/quill.snow.css';
import './styles.css';

interface AddEditServiceFormProps {
  operation: 'create' | 'update';
  serviceForm: UseFormReturn<ServiceForm>;
  imageUrl: string | null;
  isLoading: boolean;
  onSubmit: SubmitHandler<ServiceForm>;
  onImageChange(file: File): void;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#DCEAFC',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: ['"Poppins"', 'sans-serif'].join(', '),
        textTransform: 'unset',
      },
      containedPrimary: {
        color: '#577496',
      },
    },
  },
});

function AddEditServiceForm(props: AddEditServiceFormProps) {
  const { operation, serviceForm, isLoading, imageUrl, onSubmit, onImageChange } = props;
  const [isTemplateUploading, setIsTemplateUploading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = serviceForm;
  const { fields, append, remove } = useFieldArray({ control, name: 'subStatuses' });
  const subStatuses = watch('subStatuses');
  const templateUrl = watch('templateUrl');
  const controlledSubStatuses = fields.map((field, index) => {
    return {
      ...field,
      ...subStatuses[index],
    };
  });

  function handleImageChange(files: FileList | null) {
    if (!files?.length) return;
    onImageChange(files[0]);
  }

  function handleTemplateChange(files: FileList | null) {
    if (!files?.length) return;
    const formData = new FormData();
    formData.append('file', files[0]);
    setIsTemplateUploading(true);
    uploadFile(formData)
      .then((res) => {
        setIsTemplateUploading(false);
        const url = res.data.url;
        updateTemplateUrl(url);
      })
      .catch((error: AxiosError) => {
        setIsTemplateUploading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleDeleteTemplate() {
    updateTemplateUrl('');
  }

  function updateTemplateUrl(url: string) {
    setValue('templateUrl', url);
  }

  const isRecurring = watch('paymentTypes').includes('SUBSCRIPTION');
  const isNewService = operation === 'create';

  return (
    <div className={classes.serviceRoot}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...rest } }) => {
                const hasError = Boolean(errors.name);
                const hasRequiredError = errors?.name?.type === 'required';
                return (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    type="text"
                    label="Service Name"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    error={hasError}
                    {...(hasError && {
                      helperText: <span>{hasRequiredError && 'Service name is required'}</span>,
                    })}
                  />
                );
              }}
            />
            <div className={clsx(['description-quill-editor', classes.editor])}>
              <p className={classes.fieldTitle}>Description</p>
              <Controller
                name="description"
                control={control}
                rules={{ required: false }}
                render={({ field: { ref, value, onChange } }) => {
                  return (
                    <>
                      <ReactQuill
                        modules={{
                          toolbar: [['bold', { list: 'ordered' }, { list: 'bullet' }, 'clean']],
                        }}
                        theme="snow"
                        ref={ref}
                        onChange={onChange}
                        value={value}
                      />
                    </>
                  );
                }}
              />
            </div>
            <div className={clsx(['description-quill-editor', classes.editor])}>
              <p className={classes.fieldTitle}>Checkout Description</p>
              <Controller
                name="checkoutDescription"
                control={control}
                rules={{ required: false }}
                render={({ field: { ref, value, onChange } }) => {
                  return (
                    <>
                      <ReactQuill
                        modules={{
                          toolbar: [['bold', { list: 'ordered' }, { list: 'bullet' }, 'clean']],
                        }}
                        theme="snow"
                        ref={ref}
                        onChange={onChange}
                        value={value}
                      />
                    </>
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.packageActions}>
              <div
                className={classes.uploadButtonContainer}
                {...(imageUrl && {
                  style: {
                    backgroundImage: `url(${imageUrl})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: '#c7d4ea',
                    backgroundSize: 'contain',
                  },
                })}
              >
                <input
                  accept="image/*"
                  className={classes.uploadInput}
                  id="service-image-upload-button"
                  type="file"
                  disabled={isNewService}
                  onChange={(event) => handleImageChange(event.target.files)}
                />
                <label htmlFor="service-image-upload-button">
                  <ThemeProvider theme={theme}>
                    <MuiButton
                      variant="contained"
                      color="primary"
                      component="span"
                      disabled={isNewService}
                    >
                      Browse File
                    </MuiButton>
                  </ThemeProvider>
                </label>
              </div>
            </div>
            {isNewService && (
              <p className={classes.serviceImageInfo}>
                You will be able to add the image once the service has been created
              </p>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Controller
              name="serviceType"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <FormControl component="fieldset" className={classes.paymentTypeContainer}>
                    <FormLabel component="legend" className={classes.paymentTypeTitle}>
                      Service Type
                    </FormLabel>
                    <RadioGroup {...rest} ref={ref} aria-label="service-type" row>
                      <FormControlLabel
                        value="REGULAR"
                        control={<Radio color="primary" />}
                        label="Regular"
                      />
                      <FormControlLabel
                        value="CUSTOM"
                        control={<Radio color="primary" />}
                        label="Custom"
                      />
                    </RadioGroup>
                  </FormControl>
                );
              }}
            />
            <Controller
              name="paymentTypes"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, value, onChange } }) => {
                const hasError = Boolean(errors.paymentTypes);
                return (
                  <FormControl
                    component="fieldset"
                    className={classes.paymentTypeContainer}
                    error={hasError}
                  >
                    <FormLabel component="legend" className={classes.paymentTypeTitle}>
                      Payment Types
                    </FormLabel>
                    <FormGroup ref={ref} aria-label="payment-type" row>
                      <FormControlLabel
                        value="SUBSCRIPTION"
                        control={
                          <Checkbox
                            color="primary"
                            checked={value.includes('SUBSCRIPTION')}
                            onChange={(event) => {
                              if (event.target.checked) {
                                onChange(value.concat(['SUBSCRIPTION']));
                              } else {
                                onChange(value.filter((v) => v !== 'SUBSCRIPTION'));
                              }
                            }}
                          />
                        }
                        label="Recurring"
                      />
                      <FormControlLabel
                        value="DIRECT"
                        control={
                          <Checkbox
                            color="primary"
                            checked={value.includes('DIRECT')}
                            onChange={(event) => {
                              if (event.target.checked) {
                                onChange(value.concat(['DIRECT']));
                              } else {
                                onChange(value.filter((v) => v !== 'DIRECT'));
                              }
                            }}
                          />
                        }
                        label="One-Time"
                      />
                    </FormGroup>
                    {hasError && (
                      <FormHelperText>Please select applicable payment types</FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />
            {isRecurring && (
              <>
                <p className={classes.fieldTitle}>Order</p>
                <Controller
                  name="orderDuration"
                  control={control}
                  rules={{ required: true, min: 1 }}
                  render={({ field: { ref, ...rest } }) => {
                    const hasError = Boolean(errors.orderDuration);
                    const hasRequiredError = errors?.orderDuration?.type === 'required';
                    return (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        type="number"
                        variant="outlined"
                        fullWidth
                        error={hasError}
                        className={classes.textField}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">Every</InputAdornment>,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Controller
                                name="frequency"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { ref, value, onChange } }) => {
                                  return (
                                    <Select
                                      inputRef={ref}
                                      native
                                      variant="outlined"
                                      className={classes.orderFrequencySelect}
                                      value={value}
                                      onChange={onChange}
                                    >
                                      <option value="DAY">Day</option>
                                      <option value="WEEK">Week</option>
                                      <option value="MONTH">Month</option>
                                    </Select>
                                  );
                                }}
                              />
                            </InputAdornment>
                          ),
                          classes: { adornedEnd: classes.adornedEnd },
                        }}
                        {...(hasError && {
                          helperText: (
                            <span>
                              {hasRequiredError
                                ? 'Duration is required'
                                : 'Duration should be minimum 1'}
                            </span>
                          ),
                        })}
                      />
                    );
                  }}
                />
              </>
            )}
            <div className={classes.subStatusContainer}>
              <p className={classes.fieldTitle}>Sub Status</p>
              {controlledSubStatuses.length ? (
                <List dense disablePadding>
                  {controlledSubStatuses.map((subStatus, index) => {
                    const hasError = Boolean(errors?.subStatuses?.[index]?.name);
                    const hasRequiredError =
                      errors?.subStatuses?.[index]?.name?.type === 'required';
                    return (
                      <ListItem key={subStatus.id} disableGutters>
                        <Controller
                          name={`subStatuses.${index}.name` as const}
                          control={control}
                          defaultValue={subStatus.name}
                          rules={{ required: true }}
                          render={({ field: { ref, ...rest } }) => {
                            return (
                              <TextField
                                {...rest}
                                inputRef={ref}
                                fullWidth
                                error={hasError}
                                {...(hasError && {
                                  helperText: (
                                    <span>{hasRequiredError && 'Status is required'}</span>
                                  ),
                                })}
                              />
                            );
                          }}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            size="small"
                            color="secondary"
                            onClick={() => remove(index)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              ) : (
                <p>No sub status found. Click Add to create a new sub status.</p>
              )}
              <div className={classes.subStatusAddContainer}>
                <div>
                  <Button
                    size="small"
                    variant="text"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => append({ name: '', sequence: controlledSubStatuses.length })}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <p className={classes.fieldTitle}>Template</p>
              <div className={classes.serviceTemplateContainer}>
                {templateUrl ? (
                  <div className={classes.uploadedServiceTemplateContainer}>
                    <Button component="a" href={templateUrl} download color="primary">
                      Download Template
                    </Button>
                    <div>
                      <IconButton size="small" onClick={handleDeleteTemplate} color="secondary">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <>
                    <input
                      accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      className={classes.uploadInput}
                      id="service-template"
                      type="file"
                      onChange={(event) => handleTemplateChange(event.target.files)}
                    />
                    <label htmlFor="service-template">
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        startIcon={<PublishIcon />}
                        component="span"
                        disabled={isTemplateUploading}
                        isLoading={isTemplateUploading}
                      >
                        Upload Template
                      </LoadingButton>
                    </label>
                  </>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <p className={classes.fieldTitle}>Visibility</p>
        <Controller
          name="visibility"
          control={control}
          render={({ field: { value, name, onChange } }) => {
            return (
              <FormControlLabel
                name={name}
                value="end"
                control={
                  <Checkbox
                    color="primary"
                    checked={value}
                    onChange={(event) => onChange(event.target.checked)}
                  />
                }
                label="Show in services page"
                labelPlacement="end"
              />
            );
          }}
        />
        <div className={classes.visibilityHelpText}>
          Choose whether to list this service in your Client Portal's services page. Service can
          still be used in order forms.
        </div>
        <div className={classes.serviceFormButtonContainer}>
          <div>
            <LoadingButton
              color="primary"
              variant="contained"
              isLoading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddEditServiceForm;
