import makeStyles from '@material-ui/core/styles/makeStyles';
import { Controller, Control, FormState } from 'react-hook-form';

import TextField from './Inputs/TextField';
import { ISignInInput } from '../../interfaces/auth';
import { REGEX } from '../../constants';

const useStyles = makeStyles(() => ({
  textField: { marginTop: 8, marginBottom: 8 },
}));

function SignInForm({
  control,
  formState,
}: {
  control: Control<ISignInInput>;
  formState: FormState<ISignInInput>;
}) {
  const classes = useStyles();
  const { errors } = formState;

  return (
    <>
      <Controller
        name="email"
        control={control}
        rules={{ required: true, pattern: REGEX.EMAIL }}
        render={({ field: { ref, ...rest } }) => {
          const hasError = Boolean(errors.email);
          const hasRequiredError = errors?.email?.type === 'required';
          return (
            <TextField
              {...rest}
              inputRef={ref}
              type="email"
              label="Email"
              variant="outlined"
              fullWidth
              className={classes.textField}
              error={hasError}
              {...(hasError && {
                helperText: (
                  <span>
                    {hasRequiredError ? 'Email is required' : 'Please provide a valid email'}
                  </span>
                ),
              })}
            />
          );
        }}
      />
      <Controller
        name="password"
        control={control}
        rules={{ required: true, minLength: 8 }}
        render={({ field: { ref, ...rest } }) => {
          const hasError = Boolean(errors.password);
          const hasRequiredError = errors?.password?.type === 'required';
          return (
            <TextField
              {...rest}
              inputRef={ref}
              type="password"
              label="Password"
              variant="outlined"
              fullWidth
              className={classes.textField}
              error={hasError}
              {...(hasError && {
                helperText: <span>{hasRequiredError && 'Password is required'}</span>,
              })}
            />
          );
        }}
      />
    </>
  );
}

export default SignInForm;
