import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Client } from '@stomp/stompjs';
import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import StartUpLoader from './StartUpLoader';
import Profile from '../Profile/Profile';
import Team from '../Team/Team';
import AppHeader from '../AppLayout/AppHeader';
import AppDrawer from '../AppLayout/AppDrawer';
import Services from '../Services/Services';
import AddEditService from '../Services/AddEditService';
import DetailedService from '../Services/DetailedService';
import Dashboard from '../Dashboard/Dashboard';
import Welcome from '../Dashboard/Welcome';
import Orders from '../Orders/Orders';
import Clients from '../Clients/Clients';
import Subscriptions from '../Subscriptions/Subscriptions';
import Announcements from '../Announcements/Announcements';
import CheckoutSuccess from '../Checkout/CheckoutSuccess';
import Coupons from '../Coupons/Coupons';
import Footer from './Footer';
import BackToAdmin from './BackToAdmin';
import MinimalAppHeader from './MinimalAppHeader';
import Reports from '../Reports/Reports';
import TrafficLogs from '../Affiliates/TrafficLogs';
import Affiliate from '../Affiliates/Affiliate';
import Creatives from '../Creatives/Creatives';
import Commissions from '../Commissions/Commissions';

import localStorage from '../../utils/local-storage';
import { useRequireAuth } from '../../hooks/use-require-auth';
import { useRouter } from '../../hooks/use-router';
import { useSnackbar } from '../../hooks/use-snackbar';
import { getUserDetails } from '../../requests/auth';
import { isAdmin, isUser } from '../../utils/utils';
import { INotification } from '../../interfaces/shared';
import Affiliates from '../AffiliateSearch/Affiliates';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: 'flex' },
    toolbar: theme.mixins.toolbar,
    main: { flexGrow: 1 },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      backgroundColor: '#FBFBFD',
      minHeight: '100vh',
    },
  })
);

function PrivateRoutes() {
  const classes = useStyles();
  const router = useRouter();
  const { showNotification } = useSnackbar();
  const { user, isAuthenticated, setAuth } = useRequireAuth();
  let stompClient: Client;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  function connectWs() {
    try {
      const accessToken = localStorage.getAccessToken();
      stompClient = new Client({
        brokerURL: `${process.env.REACT_APP_WS_URL}/our-websocket`,
        connectHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
      });

      stompClient.onConnect = function (frame) {
        stompClient.subscribe(`/user/notify/message`, (message) => {
          try {
            showNotification(JSON.parse(message.body) as INotification);
          } catch (error) {}
        });
      };

      stompClient.activate();
    } catch (error) {}
  }

  function fetchUserDetails() {
    setIsLoading(true);
    getUserDetails()
      .then((res) => {
        setIsLoading(false);
        setAuth({ isAuthenticated: true, user: res.data });
        connectWs();
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        setError(error.response?.data || 'Request Failure');
      });
  }

  useEffect(() => {
    if (!localStorage.hasAccessToken()) {
      const pathname = router.location.pathname;
      const isRootPath = pathname === '/';
      return router.replace({
        pathname: 'signin',
        ...(!isRootPath && { search: `redirectUrl=${pathname}` }),
      });
    }
    if (!['/signin', '/signup', '/password/reset'].includes(router.pathname) && !isAuthenticated) {
      if (!isLoading) {
        fetchUserDetails();
      }
    }

    return () => {
      if (stompClient) {
        stompClient.deactivate();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthenticated]);

  if (isLoading) {
    return <StartUpLoader />;
  } else if (error) {
    return <div>An error occurred. Please reload the page to try again.</div>;
  }

  if (!isAuthenticated) {
    return null;
  }
  if (!user) {
    return <Redirect to={{ pathname: '/signin' }} />;
  }
  if (isUser(user) && user.firstLogin) {
    setAuth({ isAuthenticated, user: { ...user, firstLogin: false } });
    return <Redirect to={{ pathname: '/welcome' }} />;
  }

  return (
    <>
      <BackToAdmin />
      <div className={clsx({ [classes.root]: isAdmin(user) })}>
        <Route exact path={['/welcome']}>
          <AppHeader />
          <div>
            <div className={classes.toolbar} />
            <Route exact path="/welcome">
              <Welcome />
            </Route>
            <Footer />
          </div>
        </Route>
        <Route exact path={['/checkout/success']}>
          <MinimalAppHeader />
          <div>
            <div className={classes.toolbar} />
            <Route exact path={['/checkout/success']}>
              <CheckoutSuccess />
            </Route>
            <Footer />
          </div>
        </Route>
        <Route
          exact
          path={[
            '/',
            '/teams',
            '/profile',
            '/services',
            '/services/:serviceId',
            '/services/:serviceId/details',
            '/orders',
            '/orders/:orderId',
            '/clients',
            '/clients/:clientId',
            '/subscriptions',
            '/coupons',
            '/coupons/:couponId',
            '/announcements',
            '/announcements/:announcementId',
            '/creatives',
            '/creatives/:creativesId',
            '/commissions',
            '/commissions/commissionsId',
            '/reports/:reportType',
            '/affiliates/traffic-logs',
            '/affiliate',
            '/affiliates',
          ]}
        >
          <AppDrawer />
          <main className={classes.main}>
            <div className={classes.content}>
              <div className={classes.toolbar} />
              <Route exact path="/">
                <Dashboard />
              </Route>
              <Route exact path="/teams">
                <Team />
              </Route>
              <Route exact path="/profile">
                <Profile />
              </Route>
              <Route exact path="/services">
                <Services />
              </Route>
              <Route exact path="/services/:serviceId">
                <AddEditService />
              </Route>
              <Route exact path="/services/:serviceId/details">
                <DetailedService />
              </Route>
              <Route exact path={['/orders', '/orders/:orderId']}>
                <Orders />
              </Route>
              <Route exact path={['/clients', '/clients/:clientId']}>
                <Clients />
              </Route>
              <Route exact path="/subscriptions">
                <Subscriptions />
              </Route>
              <Route exact path={['/announcements', '/announcements/:announcementId']}>
                <Announcements />
              </Route>
              <Route exact path={['/creatives', '/creatives/:creativesId']}>
                <Creatives />
              </Route>
              <Route exact path={['/commissions', '/commissions/:commissionsId']}>
                <Commissions />
              </Route>
              <Route exact path={['/affiliates']}>
                <Affiliates />
              </Route>
              <Route exact path={['/coupons', '/coupons/:couponId']}>
                <Coupons />
              </Route>
              <Route exact path={['/reports/:reportType']}>
                <Reports />
              </Route>
              <Route exact path="/affiliates/traffic-logs">
                <TrafficLogs />
              </Route>
              <Route exact path="/affiliate">
                <Affiliate />
              </Route>
            </div>
            <Footer />
          </main>
        </Route>
      </div>
    </>
  );
}

export default PrivateRoutes;
