import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import ReactDatePickerMuiTextField from '../shared/ReactDatePickerMuiTextField';
import Button from '../shared/Inputs/Button';

import { useSnackbar } from '../../hooks/use-snackbar';
import { useAuth } from '../../hooks/use-auth';
import { isSuperAdmin } from '../../utils/utils';
import { getAdminDashboardData } from '../../requests/admin';
import { AdminDashboardData } from '../../interfaces/admin';
import { ORDER_STATUS_MAP } from '../../constants';
import useStyles from './styles';

const STROKE_COLOR = '#E5E5E5';
const LABEL_COLOR = '#9C9C9C';

const TableCell = withStyles((theme) =>
  createStyles({
    body: {
      borderBottom: 'none',
      padding: theme.spacing(2, 0, 0),
      '&:first-child': { color: '#1E2F44', fontWeight: 600 },
      '&:last-child': { width: 160, color: '#2C3A6B', fontWeight: 'bold' },
    },
  })
)(MuiTableCell);

function AdminDashboard() {
  const classes = useStyles();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [date, setDate] = useState<{ start: Date | null; end: Date | null }>({
    start: dayjs().startOf('day').subtract(7, 'days').toDate(),
    end: dayjs().endOf('day').toDate(),
  });
  const [dashboardData, setDashboardData] = useState<AdminDashboardData>({} as AdminDashboardData);
  const [isLoading, setIsLoading] = useState(false);
  const hasPrivilegeToViewDetailedReports = isSuperAdmin(user);

  useEffect(() => {
    if (date.start && date.end) {
      setIsLoading(true);
      getAdminDashboardData(date.start.toISOString(), date.end.toISOString())
        .then((res) => {
          setDashboardData(res.data);
          setIsLoading(false);
        })
        .catch((error: AxiosError) => {
          const errorMessage =
            error?.response?.data?.message ||
            'An error occurred while fetching dashboard data. Please try again.';
          showSnackbar({ severity: 'error', message: errorMessage });
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  function handleDateRangeChange(dates: any) {
    const [start, end] = dates;
    setDate({ start, end });
  }

  function getOrderStatusData() {
    const { orderCount } = dashboardData;
    if (!orderCount) return [];
    const orderStatuses = [{ label: 'Total Orders', value: orderCount.totalCount || 0 }];
    Object.entries(orderCount.orderStatusCountMap).forEach(([label, value]) => {
      orderStatuses.push({ label: ORDER_STATUS_MAP[label] || label, value });
    });
    return orderStatuses;
  }

  function getChartData() {
    const { revenueByDate } = dashboardData;
    if (revenueByDate && date.start && date.end) {
      let currentDate = dayjs(date.start);
      const endDate = dayjs(date.end);
      let dates = [];
      do {
        dates.push(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.add(1, 'day');
      } while (currentDate.diff(endDate) <= 0);
      return dates.map((date) => ({
        name: dayjs(date).format('D MMM YY'),
        Revenue: revenueByDate[date] || 0,
      }));
    }
    return [{}];
  }

  return (
    <>
      <div className={classes.buttonContainer}>
        <Box display="flex" alignItems="center">
          <div className={classes.title}>Dashboard</div>
          {isLoading && <CircularProgress color="primary" size={24} className={classes.loader} />}
        </Box>
        <div>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            onChange={handleDateRangeChange}
            startDate={date.start}
            endDate={date.end}
            maxDate={date.start ? dayjs(date.start).add(3, 'months').toDate() : null}
            customInput={<ReactDatePickerMuiTextField className={classes.dateRangePicker} />}
            selectsRange
          />
        </div>
      </div>
      <div className={classes.chartContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={getChartData()}
            margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
            maxBarSize={55}
          >
            <CartesianGrid horizontal={false} stroke={STROKE_COLOR} />
            <XAxis dataKey="name" stroke={STROKE_COLOR} tick={{ fill: LABEL_COLOR }} />
            <YAxis
              stroke={STROKE_COLOR}
              tick={{ fill: LABEL_COLOR }}
              tickFormatter={(value) => `${value}$`}
            />
            <Tooltip />
            <Bar dataKey="Revenue" fill="#FF6F24" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <Grid container spacing={2} className={classes.cardsContainer}>
        <Grid item xs={12} sm={6} md={4}>
          <Card type="revenue" title="Revenue">
            <div>${dashboardData.totalRevenue || 0}</div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            type="agents"
            title="Revenue by Agents"
            {...(hasPrivilegeToViewDetailedReports && { to: '/reports/agents' })}
          >
            <div>Top 5 Agents</div>
            <div>
              <Table>
                <TableBody>
                  {dashboardData.revenueByAdmins?.map((data) => (
                    <TableRow key={data.id}>
                      <TableCell component="th" scope="row">
                        {data.name}
                      </TableCell>
                      <TableCell align="right">${data.revenue || 0}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            type="clients"
            title="Revenue by Clients"
            {...(hasPrivilegeToViewDetailedReports && { to: '/reports/clients' })}
          >
            <div>Top 5 Clients</div>
            <div>
              <Table>
                <TableBody>
                  {dashboardData.revenueByClients?.map((data) => (
                    <TableRow key={data.id}>
                      <TableCell component="th" scope="row">
                        {data.name}
                      </TableCell>
                      <TableCell align="right">${data.revenue || 0}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card type="status" title="Order Status">
            <div>
              {getOrderStatusData().map((data) => (
                <div key={data.label}>
                  <p>{data.value || 0}</p>
                  <p>{data.label}</p>
                </div>
              ))}
            </div>
          </Card>
        </Grid>
        {hasPrivilegeToViewDetailedReports && (
          <Grid item xs={12} sm={6} md={4}>
            <Card type="comparison" title="Clients Comparison">
              <div>
                <Button
                  component={Link}
                  variant="contained"
                  color="primary"
                  disableElevation
                  to="/reports/clients-comparison"
                >
                  View Report
                </Button>
              </div>
            </Card>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <Card type="refund" title="Refund Report">
            <div>Top 5 Orders</div>
            <div>
              <Table>
                <TableBody>
                  {dashboardData.refundOrders?.map((data) => (
                    <TableRow key={data.id}>
                      <TableCell component="th" scope="row">
                        {data.orderId}
                      </TableCell>
                      <TableCell align="right">${data.totalAmount || 0}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Card>
        </Grid>
        {/* {hasPrivilegeToViewDetailedReports && (
          <Grid item xs={12} sm={6} md={4}>
            <Card type="new-clients" title="New Clients">
              <div>
                <Button
                  component={Link}
                  variant="contained"
                  color="primary"
                  disableElevation
                  to="/reports/new-clients"
                >
                  View Report
                </Button>
              </div>
            </Card>
          </Grid>
        )} */}
      </Grid>
    </>
  );
}

function Card({
  type,
  title,
  children,
  to,
}: {
  type: string;
  title: string | JSX.Element;
  children: string | JSX.Element | JSX.Element[];
  to?: string;
}) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.card, type)}>
      <div className={classes.cardHeader}>
        <p className={classes.cardTitle}>{title}</p>
        {to && (
          <div>
            <Button
              component={Link}
              style={{ lineHeight: 1.3 }}
              variant="contained"
              color="primary"
              size="small"
              disableElevation
              to={to}
            >
              View More
            </Button>
          </div>
        )}
      </div>
      {children}
    </div>
  );
}

export default AdminDashboard;
