import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Redirect, useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { useForm } from 'react-hook-form';

import PageTitleWithBack from '../shared/PageTitleWithBack';
import AddEditServiceForm from './AddEditServiceForm';
import Packages from './Packages';
import IntakeForm from './IntakeForm';
import CustomerInterface from './CustomerInterface';

import useStyles from './styles';
import {
  convertServiceToServiceForm,
  convertIntakeFormListToIntakeItems,
  convertIntakeItemsToIntakeFormList,
} from './utils';
import { useRouter } from '../../hooks/use-router';
import { useSnackbar } from '../../hooks/use-snackbar';
import { useAuth } from '../../hooks/use-auth';
import { isAdmin, MUI_THEME } from '../../utils/utils';
import { capitalize } from '../../utils/helpers';
import {
  ServiceForm,
  Package,
  EditIntakeFormDialogData,
  IntakeFormListItem,
  IntakeFormRequest,
  ICustomerInterface,
  ISampleReport,
  IFaq,
  ICustomerInterfaceForm,
  SubStatus,
} from '../../interfaces/services';
import {
  createUpdateCustomerInterface,
  createUpdateIntakeForm,
  createUpdateService,
  getCustomerInterface,
  getIntakeForm,
  getPackages,
  getServiceById,
  updateServiceImage,
} from '../../requests/service';

const initialIntakeFormDialogData = {
  open: false,
  data: {} as IntakeFormListItem,
};

function AddEditService() {
  const classes = useStyles();
  const router = useRouter();
  const params = useParams<{ serviceId: string }>();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [currentTabIndex, setcurrentTabIndex] = useState(0);
  const [serviceFormData, setServiceFormData] = useState({} as ServiceForm);
  const serviceForm = useForm<ServiceForm>({
    defaultValues: {
      name: '',
      description: '',
      checkoutDescription: '',
      serviceType: 'REGULAR',
      paymentTypes: ['SUBSCRIPTION'],
      orderDuration: 1,
      frequency: 'MONTH',
      visibility: true,
      templateUrl: '',
      subStatuses: [] as SubStatus[],
    },
  });
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [packages, setPackages] = useState<Package[]>([]);
  const [intakeFormList, setIntakeFormList] = useState<IntakeFormListItem[]>([]);
  const [intakeFormDialogData, setIntakeFormDialogData] = useState<EditIntakeFormDialogData>(
    initialIntakeFormDialogData
  );
  const customerInterfaceForm = useForm({
    defaultValues: {
      sampleReportDetails: [{ title: '', reportUrl: '', imageUrl: '' }] as ISampleReport[],
      faqs: [{ question: '', answer: '' }] as IFaq[],
    },
  });
  const [loading, setLoading] = useState({
    serviceForm: false,
    packages: false,
    intakeForm: false,
    customerInterface: false,
  });

  const serviceId = getServiceId();
  const operation = serviceId === 'new' ? 'create' : 'update';
  const isNewService = operation === 'create';

  function getServiceId() {
    const serviceId = params.serviceId;
    if (!serviceId) return 'invalid_service_id';
    if (serviceId === 'new') return 'new';
    return atob(serviceId);
  }

  useEffect(() => {
    if (isAdmin(user) && !isNewService && serviceId) {
      setLoading({ ...loading, serviceForm: true });
      getServiceById(serviceId)
        .then((res) => {
          const service = res.data;
          setLoading({ ...loading, serviceForm: false });
          setServiceFormData(convertServiceToServiceForm(service));
          setImageUrl(service.imageUrl);
          serviceForm.reset({
            name: service.name,
            description: service.description || '',
            checkoutDescription: service.checkoutDescription || '',
            serviceType: service.serviceType,
            paymentTypes: service.paymentTypes,
            orderDuration: service.orderDuration,
            frequency: service.frequency,
            visibility: service.visibility,
            templateUrl: service.templateUrl || '',
            subStatuses: service.subStatuses || [],
          });
        })
        .catch((error: AxiosError) => {
          setLoading({ ...loading, serviceForm: false });
          showSnackbar({
            severity: 'error',
            message:
              'An error occurred while fetching service details. Please reload the page to try again.',
          });
        });
    }
    //eslint-disable-next-line
  }, [serviceId]);

  useEffect(() => {
    if (isAdmin(user) && !isNewService && serviceId) {
      setLoading({ ...loading, packages: true });
      getPackages(serviceId)
        .then((res) => {
          setLoading({ ...loading, packages: false });
          setPackages(res.data);
        })
        .catch((error: AxiosError) => {
          setLoading({ ...loading, packages: false });
          showSnackbar({
            severity: 'error',
            message:
              'An error occurred while fetching packages. Please reload the page to try again.',
          });
        });
    }
    //eslint-disable-next-line
  }, [serviceId]);

  useEffect(() => {
    if (isAdmin(user) && !isNewService && serviceId) {
      setLoading({ ...loading, intakeForm: true });
      fetchIntakeForm();
    }
    //eslint-disable-next-line
  }, [serviceId]);

  useEffect(() => {
    if (isAdmin(user) && !isNewService && serviceId) {
      setLoading({ ...loading, customerInterface: true });
      fetchCustomerInterface();
    }
    //eslint-disable-next-line
  }, [serviceId]);

  function fetchCustomerInterface() {
    getCustomerInterface(serviceId)
      .then((res) => {
        setLoading({ ...loading, customerInterface: false });
        resetCustomerInterfaceForm(res.data);
      })
      .catch((error: AxiosError) => {
        setLoading({ ...loading, customerInterface: false });
        showSnackbar({
          severity: 'error',
          message:
            'An error occurred while fetching customer interface data. Please reload the page to try again.',
        });
      });
  }

  function fetchPackages() {
    getPackages(serviceId)
      .then((res) => setPackages(res.data))
      .catch((error: AxiosError) => {
        showSnackbar({
          severity: 'error',
          message:
            'An error occurred while refreshing packages. Please reload the page to try again.',
        });
      });
  }

  function fetchIntakeForm() {
    getIntakeForm(serviceId)
      .then((res) => {
        setLoading({ ...loading, intakeForm: false });
        const intakeItems = res.data.intakeItems;
        intakeItems.sort((item1, item2) => item1.sequence - item2.sequence);
        const data = intakeItems.map(convertIntakeItemsToIntakeFormList);
        setIntakeFormList(data);
      })
      .catch((error: AxiosError) => {
        setLoading({ ...loading, intakeForm: false });
        showSnackbar({
          severity: 'error',
          message:
            'An error occurred while fetching intake form. Please reload the page to try again.',
        });
      });
  }

  function handleSaveService(data: ServiceForm) {
    if (!isNewService) {
      data._id = serviceId;
    }
    setLoading({ ...loading, serviceForm: true });
    createUpdateService({
      ...data,
      subStatuses: data.subStatuses.map((subStatus, index) => ({ ...subStatus, sequence: index })),
    })
      .then((res) => {
        setLoading({ ...loading, serviceForm: false });
        showSnackbar({
          severity: 'success',
          message: `Service ${isNewService ? 'created' : 'updated'} successfully`,
        });
        const service = res.data;
        if (isNewService) {
          router.history.replace(`/services/${btoa(service._id)}`);
        } else {
          setServiceFormData(convertServiceToServiceForm(service));
          setImageUrl(service.imageUrl);
        }
      })
      .catch((error: AxiosError) => {
        setLoading({ ...loading, serviceForm: false });
        showSnackbar({ severity: 'error', message: 'An error occurred. Please try again.' });
      });
  }

  function handleUpdateImage(file: File) {
    if (isNewService || !serviceId) return;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('serviceId', serviceId);
    updateServiceImage(formData)
      .then((res) => {
        setImageUrl(res.data.url);
        showSnackbar({ severity: 'success', message: `Image updated successfully` });
      })
      .catch((error: AxiosError) => {
        showSnackbar({ severity: 'error', message: 'An error occurred. Please try again.' });
      });
  }

  function handleSaveIntakeForm() {
    setLoading({ ...loading, intakeForm: true });
    const requestBody: IntakeFormRequest = {
      serviceId,
      intakeItems: intakeFormList.map(convertIntakeFormListToIntakeItems),
    };
    createUpdateIntakeForm(requestBody)
      .then((res) => {
        setLoading({ ...loading, intakeForm: false });
        const data = res.data.intakeItems.map(convertIntakeItemsToIntakeFormList);
        setIntakeFormList(data);
        showSnackbar({ severity: 'success', message: `Intake form updated successfully` });
      })
      .catch((error: AxiosError) => {
        setLoading({ ...loading, intakeForm: false });
        showSnackbar({
          severity: 'error',
          message: 'An error occurred while updating intake form. Please try again.',
        });
      });
  }

  function handleSaveCustomerInterface(data: ICustomerInterfaceForm) {
    setLoading({ ...loading, customerInterface: true });
    createUpdateCustomerInterface({ ...data, serviceId })
      .then((res) => {
        setLoading({ ...loading, customerInterface: false });
        resetCustomerInterfaceForm(res.data);
        showSnackbar({
          severity: 'success',
          message: 'Customer interface data updated successfully',
        });
      })
      .catch((error: AxiosError) => {
        setLoading({ ...loading, customerInterface: false });
        showSnackbar({
          severity: 'error',
          message: 'An error occurred while updating intake form. Please try again.',
        });
      });
  }

  function resetCustomerInterfaceForm(data: ICustomerInterface) {
    customerInterfaceForm.reset({
      sampleReportDetails: data.sampleReportDetails.map((sampleReport) => ({
        title: sampleReport.title,
        imageUrl: sampleReport.imageUrl,
        reportUrl: sampleReport.reportUrl,
      })),
      faqs: data.faqs.map((faq) => ({ question: faq.question, answer: faq.answer })),
    });
  }

  function handleBackClick() {
    router.history.push('/services');
  }

  function getTabComponent(tabIndex: number) {
    switch (tabIndex) {
      case 0:
        return (
          <AddEditServiceForm
            operation={operation}
            serviceForm={serviceForm}
            imageUrl={imageUrl}
            isLoading={loading.serviceForm}
            onSubmit={handleSaveService}
            onImageChange={handleUpdateImage}
          />
        );
      case 1:
        return <Packages packages={packages} serviceId={serviceId} fetchPackages={fetchPackages} />;
      case 2:
        return (
          <IntakeForm
            service={serviceFormData}
            intakeFormList={intakeFormList}
            setIntakeFormList={setIntakeFormList}
            dialogData={intakeFormDialogData}
            setDialogData={setIntakeFormDialogData}
            handleSaveIntakeForm={handleSaveIntakeForm}
          />
        );
      case 3:
        return (
          <CustomerInterface
            customerInterfaceForm={customerInterfaceForm}
            onSubmit={handleSaveCustomerInterface}
          />
        );

      default:
        return <div>Unknown Tab</div>;
    }
  }

  if (!isAdmin(user)) {
    return <Redirect to="/" />;
  }

  return (
    <Container maxWidth="md">
      <PageTitleWithBack title={`${capitalize(operation)} Service`} onBackClick={handleBackClick} />
      <div>
        <ThemeProvider theme={MUI_THEME}>
          <Tabs
            value={currentTabIndex}
            onChange={(event, newTabIndex) => setcurrentTabIndex(newTabIndex)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Service" />
            <Tab label="Packages" disabled={isNewService} />
            <Tab label="Intake Form" disabled={isNewService} />
            <Tab
              label="Customer Interface"
              disabled={isNewService || !serviceFormData.visibility}
            />
          </Tabs>
          <Paper className={classes.tabPaperRoot} elevation={0}>
            {getTabComponent(currentTabIndex)}
          </Paper>
        </ThemeProvider>
      </div>
    </Container>
  );
}

export default AddEditService;
