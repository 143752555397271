import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import AppHeader from './AppHeader';
import Tooltip from '../shared/Tooltip';
import { ADMIN, USER } from './NavItems';
import useStyles from './styles';
import { useAuth } from '../../hooks/use-auth';
import { isAdmin, isSuperAdmin } from '../../utils/utils';

function AppDrawer() {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { user } = useAuth();

  const [open, setOpen] = useState(false);

  function handleDrawerToggle() {
    setOpen(!open);
  }

  useEffect(() => {
    if (matches) {
      setOpen(false);
    }
  }, [matches]);

  function renderNavItems() {
    const items = isAdmin(user) ? ADMIN : USER;
    return (
      items &&
      items.map((list, i) => (
        <List
          {...(list.title && {
            subheader: <ListSubheader component="div">{list.title}</ListSubheader>,
          })}
          key={`${list.title}-${i}`}
        >
          {list.data.map((item, index) => {
            if (item.path === '/teams' && !isSuperAdmin(user)) {
              return null;
            }

            return (
              <Tooltip
                key={`${item.title}-${index}`}
                title={item.title}
                placement="right"
                arrow
                show={!open}
              >
                <ListItem
                  button
                  component={Link}
                  to={item.path}
                  selected={
                    item.isActive
                      ? item.isActive(location.pathname)
                      : location.pathname === item.path
                  }
                  classes={{ selected: classes.linkListItemSelected }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </Tooltip>
            );
          })}
        </List>
      ))
    );
  }

  function getDrawerProps(): DrawerProps {
    return isAdmin(user)
      ? {
          variant: 'permanent',
          className: clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
          classes: {
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          },
        }
      : { variant: 'temporary', open, onClose: handleDrawerToggle };
  }

  return (
    <>
      <AppHeader onMenuButtonClick={handleDrawerToggle} />
      <Drawer {...getDrawerProps()}>
        {isAdmin(user) && (
          <>
            <div className={classes.toolbar} />
            <List>
              <ListItem button onClick={handleDrawerToggle}>
                <ListItemIcon>{open ? <CloseIcon /> : <MenuIcon />}</ListItemIcon>
                <ListItemText primary="Close Menu" />
              </ListItem>
            </List>
          </>
        )}
        {renderNavItems()}
      </Drawer>
    </>
  );
}

export default AppDrawer;
