import clsx from 'clsx';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import capitalize from 'lodash/capitalize';
import { Redirect } from 'react-router-dom';
import {
  GridColDef,
  GridRowsProp,
  GridSortModel,
  GridValueFormatterParams,
} from '@material-ui/data-grid';
import dayjs from 'dayjs';
import Container from '@material-ui/core/Container';

import Button from '../shared/Inputs/Button';
import MinimalTable from '../shared/MinimalTable';
import TextField from '../shared/Inputs/TextField';

import useStyles from './styles';
import { getClients } from '../../requests/clients';
import { getAffiliateStats, getCommissions } from '../../requests/affiliates';
import { SearchParams } from '../../interfaces/shared';
import { AffiliateStats } from '../../interfaces/affiliates';
import { useAuth } from '../../hooks/use-auth';
import { useSnackbar } from '../../hooks/use-snackbar';
import { getFormattedDate, isAdmin } from '../../utils/utils';
import { APP_URL } from '../../constants';
import DatePicker from 'react-datepicker';
import ReactDatePickerMuiTextField from '../shared/ReactDatePickerMuiTextField';

interface IGridState {
  content: GridRowsProp;
  totalElements: number;
}

function Commissions() {
  const { user } = useAuth();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const [isReferralsLoading, setIsReferralsLoading] = useState(false);
  const [isCommissionsLoading, setIsCommissionsLoading] = useState(false);
  const [
    {
      page: referralsPage,
      pageSize: referralsPageSize,
      sortBy: referralsSortBy,
      sortDirection: referralsSortDirection,
    },
    setReferralsSearchParams,
  ] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    sortBy: 'createdTime',
    sortDirection: 'desc',
  });
  const [
    {
      page: commissionsPage,
      pageSize: commissionsPageSize,
      sortBy: commissionsSortBy,
      sortDirection: commissionsSortDirection,
    },
    setCommissionsSearchParams,
  ] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    filterFields: [],
    sortBy: 'createdTime',
    sortDirection: 'desc',
  });
  const [referrals, setReferrals] = useState<IGridState>({ content: [], totalElements: 0 });
  const [commissions, setCommissions] = useState<IGridState>({ content: [], totalElements: 0 });
  const [stats, setStats] = useState<AffiliateStats>({} as AffiliateStats);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'createdTime', sort: 'desc' },
  ]);
  const [date, setDate] = useState<{ start: Date | null; end: Date | null }>({
    start: null,
    end: null,
  });
  useEffect(() => {
    getAffiliateStats(date.start && date.start.toISOString(), date.end && date.end.toISOString())
      .then((res) => setStats(res.data))
      .catch((error: AxiosError) => {
        const errorMessage =
          error?.response?.data?.message ||
          'An error occurred while fetching stats. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line
  }, [referralsPage, referralsPageSize, referralsSortBy, referralsSortDirection]);

  useEffect(() => {
    fetchCommissions();
    // eslint-disable-next-line
  }, [commissionsPage, commissionsPageSize, commissionsSortBy, commissionsSortDirection]);

  useEffect(() => {
    if (date.end && date.start) {
      fetchCommissions();
    }
    // eslint-disable-next-line
  }, [date]);

  function fetchClients() {
    setIsReferralsLoading(true);
    getClients({
      page: referralsPage,
      pageSize: referralsPageSize,
      filterFields: [{ key: 'referrerClientId', searchOperation: 'eq', value: user?._id }],
      ...(referralsSortBy &&
        referralsSortDirection && {
          sortBy: referralsSortBy,
          sortDirection: referralsSortDirection,
        }),
    })
      .then((res) => {
        setIsReferralsLoading(false);
        setReferrals({
          content: res.data.content.map((client) => {
            client.id = client._id;
            return client;
          }),
          totalElements: res.data.totalElements,
        });
      })
      .catch((error: AxiosError) => {
        setIsReferralsLoading(false);
        const errorMessage =
          error?.response?.data?.message ||
          'An error occurred while fetching referrals. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }
  function handleSortModelChangeForCommissions(model: GridSortModel) {
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      let newSortBy = '';
      let newSortDirection: string | undefined = '';
      if (model.length) {
        newSortBy = model[0].field;
        newSortDirection = model[0].sort as string | undefined;
      }
      setSortModel(model);
      setCommissionsSearchParams({
        page: commissionsPage,
        pageSize: commissionsPageSize,
        ...(newSortBy &&
          newSortDirection && { sortBy: newSortBy, sortDirection: newSortDirection }),
      });
    }
  }
  function handleSortModelChangeForReferral(model: GridSortModel) {
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      let newSortBy = '';
      let newSortDirection: string | undefined = '';
      if (model.length) {
        newSortBy = model[0].field;
        newSortDirection = model[0].sort as string | undefined;
      }
      setSortModel(model);
      setReferralsSearchParams({
        page: referralsPage,
        pageSize: referralsPageSize,
        ...(newSortBy &&
          newSortDirection && { sortBy: newSortBy, sortDirection: newSortDirection }),
      });
    }
  }
  function fetchCommissions() {
    setIsCommissionsLoading(true);
    const filterFields: any = [
      { key: 'referrerClientId', searchOperation: 'eq', value: user?._id },
      { key: 'status', searchOperation: 'in', value: ['APPROVED', 'PAID'] },
    ];
    if (date.start !== null && date.end !== null) {
      filterFields.push({
        key: 'createdTime',
        searchOperation: 'btn',
        value: date.start,
        toValue: date.end,
      });
    }
    getCommissions({
      page: commissionsPage,
      pageSize: commissionsPageSize,
      filterFields,
      ...(commissionsSortBy &&
        commissionsSortDirection && {
          sortBy: commissionsSortBy,
          sortDirection: commissionsSortDirection,
        }),
    })
      .then((res) => {
        setIsCommissionsLoading(false);
        setCommissions({
          content:
            res.data.content?.map((commission) => ({ ...commission, id: commission._id })) || [],
          totalElements: res.data.totalElements,
        });
      })
      .catch((error: AxiosError) => {
        setIsCommissionsLoading(false);
        const errorMessage =
          error?.response?.data?.message ||
          'An error occurred while fetching commissions. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function getReferralsColumns() {
    const columns: GridColDef[] = [
      { field: 'firstName', headerName: 'First Name', flex: 1, sortable: false },
      { field: 'lastName', headerName: 'Last Name', flex: 1, sortable: false },
      {
        field: 'createdTime',
        headerName: 'Signup Date',
        flex: 1,
        sortable: true,
        valueFormatter: (params: GridValueFormatterParams) =>
          getFormattedDate(params.getValue(params.id, 'createdTime') as string),
      },
      {
        field: 'userStatus',
        headerName: 'Status',
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams) =>
          capitalize(params.getValue(params.id, 'userStatus') as string),
      },
    ];
    return columns;
  }

  function getCommissionsColumns() {
    const columns: GridColDef[] = [
      {
        field: 'orderId',
        headerName: 'Order ID',
        flex: 1,
        sortable: false,
      },
      {
        field: 'refereeClientName',
        headerName: 'Client Name',
        flex: 1,
        sortable: false,
      },
      {
        field: 'amount',
        headerName: 'Commission',
        flex: 1,
        sortable: false,
        renderCell: (params) => `$${params.row.amount || 0}`,
      },
      {
        field: 'createdTime',
        headerName: 'Date',
        flex: 1,
        sortable: true,
        valueFormatter: (params: GridValueFormatterParams) =>
          getFormattedDate(params.getValue(params.id, 'createdTime') as string),
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => {
          const status = (params.getValue(params.id, 'status') as string) || '';
          return (
            <div className={clsx(classes.commissionStatus, status.toLowerCase())}>
              {capitalize(status.split('_').join(' '))}
            </div>
          );
        },
        sortable: false,
      },
    ];
    return columns;
  }

  function handleCopyReferralUrl() {
    if (!user) {
      showSnackbar({
        severity: 'error',
        message: 'Unable to copy referral URL',
      });
    }
    navigator.clipboard
      .writeText(`${APP_URL}/r/${user?.affiliateCode}`)
      .then(() => {
        showSnackbar({ severity: 'success', message: 'Referral URL copied successfully' });
      })
      .catch(() => {
        showSnackbar({
          severity: 'error',
          message: 'Unable to copy referral URL',
        });
      });
  }

  if (isAdmin(user)) {
    return <Redirect to="/" />;
  }

  const isAffiliate = Boolean(user?.affiliate);

  function handleDateRangeChange(dates: any) {
    const [start, end] = dates;
    setDate({ start, end });
  }
  return (
    <Container maxWidth="lg" disableGutters>
      <p className={classes.title}>Affiliate Program</p>
      {isAffiliate ? (
        <>
          <div className={classes.commissionsItemContainer}>
            <p className={classes.referralDescription}>
              Get your unique referral link and earn a commission for each order your referred
              clients place with us.
            </p>
            <div className={classes.referralLinkContainer}>
              <div>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={`${APP_URL}/r/${user?.affiliateCode}`}
                  inputProps={{ readOnly: true }}
                />
              </div>
              <div>
                <Button color="primary" variant="contained" onClick={handleCopyReferralUrl}>
                  Copy Link
                </Button>
              </div>
            </div>
          </div>
          <div>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              onChange={handleDateRangeChange}
              startDate={date.start}
              endDate={date.end}
              maxDate={date.start ? dayjs(date.start).add(3, 'months').toDate() : null}
              customInput={<ReactDatePickerMuiTextField className={classes.dateRangePicker} />}
              selectsRange
            />
          </div>
          <div className={classes.commissionsItemContainer}>
            <div className={classes.referralStats}>
              <div>
                <div>Visitors</div>
                <div>{stats.visitors || '0'}</div>
              </div>
              <div>
                <div>Signups</div>
                <div>{stats.signUps || '0'}</div>
              </div>
              <div>
                <div>Clients</div>
                <div>{stats.clients || '0'}</div>
              </div>
              <div>
                <div>Unpaid Earnings</div>
                <div>{`$${stats.unPaidEarnings || 0}`}</div>
              </div>
              <div>
                <div>Total Earnings</div>
                <div>{`$${stats.totalEarnings || 0}`}</div>
              </div>
              <div>
                <div>Total Order Value</div>
                <div>{`$${stats.totalOrderValue || 0}`}</div>
              </div>
            </div>
          </div>
          <p className={classes.title}>Referrals</p>
          <MinimalTable
            page={referralsPage}
            pageSize={referralsPageSize}
            rows={referrals.content}
            columns={getReferralsColumns()}
            totalElements={referrals.totalElements}
            loading={isReferralsLoading}
            onPageChange={(page) => setReferralsSearchParams({ page, pageSize: referralsPageSize })}
            onPageSizeChange={(pageSize) => setReferralsSearchParams({ page: 0, pageSize })}
            onSortModelChange={handleSortModelChangeForReferral}
            sortModel={sortModel}
            sortingMode="server"
          />

          <p className={classes.title}>Commissions</p>
          <MinimalTable
            page={commissionsPage}
            pageSize={commissionsPageSize}
            rows={commissions.content}
            columns={getCommissionsColumns()}
            totalElements={commissions.totalElements}
            loading={isCommissionsLoading}
            onPageChange={(page) =>
              setCommissionsSearchParams({ page, pageSize: commissionsPageSize })
            }
            onPageSizeChange={(pageSize) => setCommissionsSearchParams({ page: 0, pageSize })}
            onSortModelChange={handleSortModelChangeForCommissions}
            sortModel={sortModel}
            sortingMode="server"
          />
        </>
      ) : (
        <div className={classes.commissionsItemContainer}>
          Your account is not enabled for Affiliate Program. Please contact administrator or write
          us at{' '}
          <a href="mailto:customersupport@stanventures.com">customersupport@stanventures.com</a> if
          you'd like to join the Affiliate Program.
        </div>
      )}
    </Container>
  );
}

export default Commissions;
