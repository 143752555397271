import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Redirect, useParams } from 'react-router';
import { GridRowParams, GridRowsProp } from '@material-ui/data-grid';

import CouponsTable from './CouponsTable';
import AddEditCoupon from './AddEditCoupon';
import Button from '../shared/Inputs/Button';

import useStyles from './styles';
import { SearchParams } from '../../interfaces/shared';
import { useAuth } from '../../hooks/use-auth';
import { useSnackbar } from '../../hooks/use-snackbar';
import { useRouter } from '../../hooks/use-router';
import { isAdmin } from '../../utils/utils';
import { getCoupons } from '../../requests/coupons';

interface ICouponsState {
  content: GridRowsProp;
  totalElements: number;
}

function Coupons() {
  const classes = useStyles();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const router = useRouter();
  const params = useParams<{ couponId: string }>();
  const [loading, setLoading] = useState(false);
  const [{ page, pageSize, filterFields }, setSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    filterFields: [{ key: 'status', searchOperation: 'eq', value: 'ACTIVE' }],
  });
  const [coupons, setCoupons] = useState<ICouponsState>({ content: [], totalElements: 0 });

  useEffect(() => {
    fetchCoupons();
    // eslint-disable-next-line
  }, [page, pageSize]);

  function fetchCoupons() {
    if (isAdmin(user)) {
      setLoading(true);
      getCoupons({ page, pageSize, ...(filterFields?.length && { filterFields }) })
        .then((res) => {
          setLoading(false);
          setCoupons({
            content: res.data.content.map((coupon) => {
              coupon.id = coupon._id;
              return coupon;
            }),
            totalElements: res.data.totalElements,
          });
        })
        .catch((error: AxiosError) => {
          const errorMessage =
            error?.response?.data?.message || 'An error occurred. Please try again.';
          showSnackbar({ severity: 'error', message: errorMessage });
        });
    }
  }

  function getCouponId() {
    const couponId = params.couponId;
    if (!couponId) return 'invalid_coupon_id';
    if (couponId === 'new') return 'new';
    return atob(couponId);
  }

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize });
  }

  function handleRowClick(params: GridRowParams) {
    router.history.push(`/coupons/${btoa(params.row.id)}`);
  }

  if (!isAdmin(user)) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {!params.couponId ? (
        <>
          <div className={classes.buttonContainer}>
            <div className={classes.title}>Coupons</div>
            <div>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => router.history.push(`/coupons/new`)}
              >
                Add Coupon
              </Button>
            </div>
          </div>
          <CouponsTable
            page={page}
            pageSize={pageSize}
            rows={coupons.content}
            totalElements={coupons.totalElements}
            loading={loading}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onRowClick={handleRowClick}
            fetchCoupons={fetchCoupons}
          />
        </>
      ) : (
        <AddEditCoupon couponId={getCouponId()} fetchCoupons={fetchCoupons} />
      )}
    </>
  );
}

export default Coupons;
