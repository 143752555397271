import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import CheckoutForm from './CheckoutForm';
import Summary from './Summary';
import AuthDialog from './AuthDialog';
import PageTitleWithBack from '../shared/PageTitleWithBack';
import OrderSuccess from './OrderSuccess';
import Button from '../shared/Inputs/Button';

import { useAuth } from '../../hooks/use-auth';
import { useRouter } from '../../hooks/use-router';
import { useSnackbar } from '../../hooks/use-snackbar';

import { CompleteService, PaymentTypes } from '../../interfaces/services';
import { Countries } from '../../interfaces/shared';
import { IAddress, IUser } from '../../interfaces/user';
import {
  ICompleteCheckoutRequest,
  ICoupon,
  IPackageQuantity,
  IProjectDetailsForm,
  IProjectPackageData,
  ProjectDetailTypes,
} from '../../interfaces/checkout';
import { getCompleteServiceDetails } from '../../requests/service';
import { applyCoupon, completeCheckout } from '../../requests/checkout';
import { getCountries } from '../../requests/common';
import { getOrderData, updateOrderData } from '../../requests/order';
import localStorage from '../../utils/local-storage';

import useStyles from './styles';
import { calculateDiscountedTotal, getDiscountedTotal } from './util';

type AuthType = 'signin' | 'signup';

function Checkout() {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const { showSnackbar } = useSnackbar();
  const { isAuthenticated, user, fetchUserDetails } = useAuth();
  const history = useHistory();
  const params = useParams<{ serviceId: string }>();
  const { query } = useRouter();
  const [authDialogData, setAuthDialogData] = useState<{ open: boolean; authType: AuthType }>({
    open: false,
    authType: 'signin',
  });
  const [service, setService] = useState({} as CompleteService);
  const serviceId = atob(params.serviceId);
  const [packageQuantities, setPackageQuantities] = useState<IPackageQuantity[]>([]);
  const [countries, setCountries] = useState<Countries>([]);
  const [userDetails, setUserDetails] = useState<Partial<IUser>>({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [billingAddress, setBillingAddress] = useState<IAddress>({
    address: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
  });
  const [phoneNo, setPhoneNo] = useState('');
  const [projectDetailsType, setProjectDetailsType] = useState<ProjectDetailTypes>('');
  const projectDetailsForm = useForm<IProjectDetailsForm>({
    defaultValues: {
      fileUrl: '',
      manualEntries: [] as IProjectPackageData[],
    },
  });
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentTypes>('' as PaymentTypes);
  const [errors, setErrors] = useState<{ [key: string]: string | boolean }>({});
  const [shouldValidateErrors, setShouldValidateErrors] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [coupon, setCoupon] = useState<ICoupon | null>(null);
  const [orderInfo, setOrderInfo] = useState<{
    created: boolean;
    orderId: string;
    orderSystemId: string;
  }>({
    created: false,
    orderId: '',
    orderSystemId: '',
  });
  const [isProjectDataUpdating, setIsProjectDataUpdating] = useState(false);

  useEffect(() => {
    if (localStorage.hasAccessToken()) {
      fetchUserDetails();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCompleteServiceDetails(serviceId)
      .then((res) => {
        setService(res.data);
        const paymentTypes = res.data.paymentTypes;
        if (paymentTypes) {
          setSelectedPaymentType(paymentTypes.includes('SUBSCRIPTION') ? 'SUBSCRIPTION' : 'DIRECT');
        }
      })
      .catch((error: AxiosError) => {
        const errMessage =
          error.response?.data?.message ||
          'An error occurred while fetching service details. Please try again.';
        showSnackbar({
          severity: 'error',
          message: errMessage,
        });
      });
    if (!countries.length) {
      fetchCountries();
    }
    // eslint-disable-next-line
  }, [serviceId]);

  useEffect(() => {
    if (isAuthenticated) {
      setUserDetails({
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        email: user?.email || '',
      } as Partial<IUser>);
      if (user?.phoneNo) {
        setPhoneNo(user.phoneNo);
      }
      if (!isBillingAddressEmpty()) {
        setBillingAddress({
          address: user?.address || '',
          city: user?.city || '',
          state: user?.state || '',
          country: user?.country || '',
          zipCode: user?.zipCode || '',
        } as IAddress);
      }
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (coupon !== null) {
      setCoupon(null);
      showSnackbar({
        severity: 'info',
        message: 'Coupon has been deselected. Please apply again.',
      });
    }
    // eslint-disable-next-line
  }, [selectedPaymentType, couponCode]);

  function handleBackClick() {
    history.goBack();
  }

  function handleAuthDialogOpen(authType: AuthType) {
    setAuthDialogData({ open: true, authType });
  }

  function handleSwitchAuthType(authType: AuthType) {
    setAuthDialogData({ ...authDialogData, authType });
  }

  function handleAuthDialogClose() {
    setAuthDialogData({ ...authDialogData, open: false });
  }

  function fetchCountries() {
    getCountries().then((res) => setCountries(res.data));
  }

  function handleDetailsChange(type: string, name: string, value: string) {
    if (type === 'billingAddress') {
      setBillingAddress({ ...billingAddress, [name]: value });
    } else if (type === 'userDetails') {
      setUserDetails({ ...userDetails, [name]: value });
    } else if (type === 'paymentType') {
      setSelectedPaymentType(value as PaymentTypes);
    }
  }

  function handleProjectDetailsTypeChange(type: ProjectDetailTypes) {
    setProjectDetailsType(type);
  }

  function isBillingAddressEmpty() {
    let isEmpty = false;
    Object.keys(billingAddress).forEach((key) => {
      if (isEmpty) return;
      if (billingAddress[key].trim()) {
        isEmpty = true;
      }
    });
    return isEmpty;
  }

  function revalidateFields() {
    if (shouldValidateErrors) {
      getBillingAddressErrors();
    }
  }

  function getBillingAddressErrors() {
    let hasError = false;
    const addressError: { [key: string]: boolean } = {};
    Object.keys(billingAddress).forEach((key) => {
      if (!billingAddress[key].trim() && key !== 'state') {
        hasError = true;
        addressError[key] = true;
      }
    });
    setErrors({ ...addressError, cardElement: errors.cardElement });
    return { hasError, addressError };
  }

  function handleCardElementChange(event: StripeCardElementChangeEvent) {
    setErrors({ ...errors, cardElement: event.error ? event.error.message : '' });
    setCardComplete(event.complete);
  }

  async function handlePlaceOrder() {
    setShouldValidateErrors(true);
    setErrors({ cardElement: errors.cardElement });
    const packageList = packageQuantities.filter((item) => +item.quantity);
    const packageQuantityList = packageList.map((item) => ({
      packageId: item.id,
      count: +item.quantity,
    }));
    const totalPrice = calculateDiscountedTotal(packageList);
    let discountedPrice = 0;
    const isCouponApplied = Boolean(coupon);
    if (isCouponApplied) {
      discountedPrice = getDiscountedTotal(coupon, totalPrice);
    }

    const { hasError } = getBillingAddressErrors();

    if (hasError) {
      return;
    }
    if (!cardComplete) {
      if (elements) {
        elements.getElement(CardElement)?.focus();
      }
      return setErrors({ ...errors, cardElement: 'Your card details are incomplete' });
    }

    if (!isAuthenticated) {
      return handleAuthDialogOpen('signin');
    }

    try {
      setProcessing(true);
      const requestBody: ICompleteCheckoutRequest = {
        billingAddress,
        phoneNo,
        serviceId,
        packageCounts: packageQuantityList,
        paymentType: selectedPaymentType,
        fileUploadUrl: '',
        projectPackageData: [],
        totalPrice,
        ...(isCouponApplied && { couponCode: coupon?.couponCode, discountedPrice }),
        utmSource: query.utm_source as string | null,
        utmCampaign: query.utm_campaign as string | null,
        utmMedium: query.utm_medium as string | null,
        utmContent: query.utm_content as string | null,
        utmTerm: query.utm_term as string | null,
        utmChannel: query.utm_channel as string | null,
        formName: query['Form-Name'] as string | null,
      };
      const response = await completeCheckout(requestBody);
      if (stripe && elements) {
        const cardElement = elements.getElement(CardElement);
        if (cardElement) {
          const paymentResponse = await stripe.confirmCardPayment(response.data.client_secret, {
            payment_method: { card: cardElement },
          });
          const { error: paymentError } = paymentResponse;
          if (paymentError) {
            showSnackbar({
              severity: 'error',
              message: paymentError.message || 'An error occurred. Please try again.',
            });
          } else {
            setOrderInfo({
              created: true,
              orderId: response.data.orderId,
              orderSystemId: response.data.orderSystemId,
            });
            setTimeout(
              () => history.push(`/checkout/success?orderId=${btoa(response.data.orderSystemId)}`),
              250
            );
          }
        }
      } else {
        showSnackbar({ severity: 'error', message: 'An error occurred. Please try again.' });
      }
      setProcessing(false);
    } catch (e) {
      setProcessing(false);
      const errorMessage =
        (e as any)?.response?.data?.message || 'An error occurred. Please try again.';
      showSnackbar({ severity: 'error', message: errorMessage });
    }
  }

  function handleRedirectToOrderDetails() {
    if (orderInfo.orderSystemId) {
      history.replace(`/orders/${btoa(orderInfo.orderSystemId)}`);
    }
  }

  function handleSaveProjectDetails() {
    setIsProjectDataUpdating(true);
    getOrderData(orderInfo.orderSystemId)
      .then(({ data }) => {
        updateOrderData({
          ...data,
          fileUploadUrl:
            projectDetailsType === 'upload' ? projectDetailsForm.getValues().fileUrl : '',
          projectPackageData:
            projectDetailsType === 'manual'
              ? projectDetailsForm.getValues().manualEntries.map((manualEntry) => {
                  manualEntry.values = manualEntry.values.map((value) => {
                    value.role = 'CUSTOMER';
                    return value;
                  });
                  return manualEntry;
                })
              : [],
        })
          .then((res) => {
            setIsProjectDataUpdating(false);
            showSnackbar({
              severity: 'success',
              message: 'Project details added successfully. Redirecting to order details.',
            });
            setTimeout(() => {
              if (orderInfo.orderSystemId) {
                history.replace(`/orders/${btoa(orderInfo.orderSystemId)}`);
              }
            }, 2000);
          })
          .catch((error: AxiosError) => {
            setIsProjectDataUpdating(false);
            const errMessage =
              error.response?.data?.message || 'An error occurred. Please try again.';
            showSnackbar({ severity: 'error', message: errMessage });
          });
      })
      .catch((error: AxiosError) => {
        const errMessage = error.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errMessage });
      });
  }

  function handleApplyCoupon() {
    applyCoupon({ serviceId: service._id, couponCode, paymentType: selectedPaymentType })
      .then((res) => {
        setCoupon(res.data);
        showSnackbar({ severity: 'success', message: 'Coupon applied successfully' });
      })
      .catch((error: AxiosError) => {
        const errMessage = error.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errMessage });
      });
  }

  return (
    <Container maxWidth="lg" disableGutters>
      <Backdrop className={classes.backdrop} open={processing}>
        <CircularProgress color="inherit" />
        <p className={classes.backdropText}>Processing your payment</p>
      </Backdrop>
      <Grid container spacing={1}>
        <Grid item container xs={12} md={8}>
          {orderInfo.created ? (
            <OrderSuccess
              orderId={orderInfo.orderId || ''}
              user={user}
              service={service}
              packageQuantities={packageQuantities}
              projectDetailsType={projectDetailsType}
              projectDetailsForm={projectDetailsForm}
              handleProjectDetailsTypeChange={handleProjectDetailsTypeChange}
              isLoading={isProjectDataUpdating}
              handleSaveProjectDetails={handleSaveProjectDetails}
              handleRedirectToOrderDetails={handleRedirectToOrderDetails}
            />
          ) : (
            <>
              <Grid item xs={12} sm={9} md={9}>
                <PageTitleWithBack
                  title={service.name || 'Service'}
                  disableGutters
                  onBackClick={handleBackClick}
                />
              </Grid>
              {!isAuthenticated && (
                <Grid item xs={12} sm={3} md={3}>
                  <div className={classes.buttonsContainer}>
                    <div>
                      <p className={classes.buttonTitleText}>Have an account?</p>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => handleAuthDialogOpen('signin')}
                      >
                        SIGN IN
                      </Button>
                    </div>
                    <div>
                      <p className={classes.buttonTitleText}>New to Stan?</p>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleAuthDialogOpen('signup')}
                      >
                        SIGN UP
                      </Button>
                    </div>
                  </div>
                </Grid>
              )}
              <Grid item xs={12}>
                <CheckoutForm
                  userDetails={userDetails}
                  phone={[phoneNo, setPhoneNo]}
                  billingAddress={billingAddress}
                  isAuthenticated={isAuthenticated}
                  countries={countries}
                  service={service}
                  packageQuantities={packageQuantities}
                  setPackageQuantities={setPackageQuantities}
                  handleDetailsChange={handleDetailsChange}
                  revalidateFields={revalidateFields}
                  errors={errors}
                  handleCardElementChange={handleCardElementChange}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={4} component="aside">
          <Summary
            packageQuantities={packageQuantities}
            handlePlaceOrder={handlePlaceOrder}
            service={service}
            handlePaymentTypeChange={handleDetailsChange}
            paymentType={selectedPaymentType}
            processing={processing}
            coupon={coupon}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            handleApplyCoupon={handleApplyCoupon}
            orderCreated={orderInfo.created as boolean}
          />
        </Grid>
      </Grid>
      <AuthDialog
        open={authDialogData.open}
        authType={authDialogData.authType}
        onClose={handleAuthDialogClose}
        onAuthTypeChange={handleSwitchAuthType}
      />
    </Container>
  );
}

export default Checkout;
