import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import { getFormattedRole, getOrderStatusOptions } from '../../utils/utils';
import { IAssignedUser } from '../../interfaces/shared';
import { OrderStatus, OrderSubStatus } from '../../interfaces/order';
import useStyles from './styles';

interface IProps {
  status: string;
  assignedTo: string | null;
  subStatuses: OrderSubStatus[];
  assignedUserOptions: IAssignedUser[];
  handleStatusChange(status: OrderStatus): void;
  handleAssigneeChange(assignee: IAssignedUser | null): void;
  handleSubStatusChange(subStatusIndex: number): void;
}

function StatusHeader(props: IProps) {
  const {
    status,
    assignedTo,
    subStatuses,
    assignedUserOptions,
    handleStatusChange,
    handleAssigneeChange,
    handleSubStatusChange,
  } = props;
  const classes = useStyles();

  function onAssigneeChange(value: string) {
    if (value === 'unassigned') return handleAssigneeChange(null);
    const assignee = assignedUserOptions.find((a) => a.id === value);
    handleAssigneeChange(assignee || null);
  }

  function getSubStatusValue() {
    return subStatuses
      .filter((subStatus) => subStatus.completed)
      .map((subStatus) => subStatus.name);
  }

  function renderSubStatusValue() {
    const filteredSubStatus = subStatuses.filter((subStatus) => subStatus.completed);
    if (!filteredSubStatus.length) return '';
    return filteredSubStatus[filteredSubStatus.length - 1].name;
  }

  return (
    <div className={classes.statusHeader}>
      <FormControl variant="outlined" size="small">
        <InputLabel id="status-field-label">Status</InputLabel>
        <Select
          label="Status"
          labelId="status-field-label"
          value={status || ''}
          onChange={(event) => handleStatusChange(event.target.value as OrderStatus)}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            transformOrigin: { vertical: 'top', horizontal: 'center' },
            getContentAnchorEl: null,
          }}
        >
          {getOrderStatusOptions().map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" size="small">
        <InputLabel id="status-field-label">Sub Status</InputLabel>
        <Select
          label="Sub Status"
          labelId="sub-status-field-label"
          multiple
          value={getSubStatusValue()}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            transformOrigin: { vertical: 'top', horizontal: 'center' },
            getContentAnchorEl: null,
          }}
          renderValue={renderSubStatusValue}
        >
          {subStatuses.map((option, index) => (
            <MenuItem key={index} value={option.name} onClick={() => handleSubStatusChange(index)}>
              <Checkbox checked={option.completed} size="small" />
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" size="small">
        <InputLabel id="assigned-to-field-label">Assigned To</InputLabel>
        <Select
          label="Assigned To"
          labelId="assigned-to-field-label"
          value={assignedTo || 'unassigned'}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            transformOrigin: { vertical: 'top', horizontal: 'center' },
            getContentAnchorEl: null,
          }}
          onChange={(event) => onAssigneeChange(event.target.value as string)}
        >
          {assignedUserOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <div>
                <div>{option.name}</div>
                <div className={classes.assignedToRole}>{getFormattedRole(option.role)}</div>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default StatusHeader;
