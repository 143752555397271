import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { GridRowsProp } from '@material-ui/data-grid';

import Filters from './Filters';

import { useAuth } from '../../hooks/use-auth';
import { useSnackbar } from '../../hooks/use-snackbar';
import { IDateFilter, IFilter, SearchParams } from '../../interfaces/shared';
import { IFilterState } from '../../interfaces/commissions';
import { downloadBlob, isAdmin } from '../../utils/utils';
import useStyles from './styles';
import CommissionsTable from './CommissionsTable';
import { exportCommissions, getCommissions } from '../../requests/affiliates';

interface ICommissionsState {
  content: GridRowsProp;
  totalElements: number;
}

const initialFilters: IFilterState = {
  refereeClientName: '',
  referrerClientName: '',
  orderId: '',
  status: 'ALL',
  date: { key: '', from: '', to: '' },
};

function Commissions() {
  const classes = useStyles();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [filters, setFilters] = useState<IFilterState>(initialFilters);
  const [{ page, pageSize, filterFields }, setSearchParams] = useState<SearchParams>({
    page: 0,
    pageSize: 10,
    filterFields: [],
  });
  const [commissions, setCommissions] = useState<ICommissionsState>({
    content: [],
    totalElements: 0,
  });

  useEffect(() => {
    fetchCommissions();
    // eslint-disable-next-line
  }, [page, pageSize, filterFields]);

  function handlePageChange(page: number) {
    setSearchParams({ page, pageSize, filterFields });
  }

  function handlePageSizeChange(pageSize: number) {
    setSearchParams({ page: 0, pageSize, filterFields });
  }

  function handleApplyFilters() {
    const newFilters: IFilter[] = [];
    Object.entries(filters).forEach(([filterKey, filterValue]) => {
      if (filterKey === 'status') {
        if (filterValue === 'ALL') {
          return;
        }
        newFilters.push({
          key: filterKey,
          searchOperation: 'eq',
          value: filterValue,
        });
        return;
      }
      if (filterKey === 'date') {
        const { key, from, to } = filterValue as IDateFilter;
        const fromValue = from ? `${from}T00:00:00.000Z` : '';
        const toValue = to ? `${to}T23:59:59.999Z` : '';
        if (key && fromValue && toValue) {
          newFilters.push({
            key,
            searchOperation: 'btn',
            value: fromValue,
            toValue,
          });
        }
        return;
      }

      if (filterValue) {
        newFilters.push({
          key: filterKey,
          searchOperation: 'like',
          value: filterValue,
        });
      }
    });
    setCommissions({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: newFilters });
  }

  function handleClearFilters() {
    setFilters(initialFilters);
    setCommissions({ content: [], totalElements: 0 });
    setSearchParams({ page: 0, pageSize, filterFields: [] });
  }
  function fetchCommissions() {
    setLoading(true);
    getCommissions({
      page,
      pageSize,
      ...(filterFields?.length && { filterFields }),
    })
      .then((res) => {
        setLoading(false);
        setCommissions({
          content:
            res.data.content?.map((commission) => ({ ...commission, id: commission._id })) || [],
          totalElements: res.data.totalElements,
        });
      })
      .catch((error: AxiosError) => {
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }
  if (!isAdmin(user)) {
    return <Redirect to="/" />;
  }

  function handleExportOrders() {
    setExporting(true);
    showSnackbar({
      severity: 'info',
      message: 'Please wait while we prepare the data. Your file will be downloaded any moment',
    });
    exportCommissions({ ...(filterFields?.length && { filterFields }) })
      .then((res) => {
        downloadBlob('Commissions', res.data);
        setExporting(false);
        showSnackbar({ severity: 'success', message: 'File has been downloaded' });
      })
      .catch(() => {
        setExporting(false);
        showSnackbar({ severity: 'error', message: 'An error occurred while exporting' });
      });
  }

  return (
    <>
      <p className={classes.title}>Commissions</p>
      <Filters
        filters={filters}
        setFilters={setFilters}
        onApplyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
        onExportOrders={handleExportOrders}
        isExporting={exporting}
      />
      <CommissionsTable
        page={page}
        pageSize={pageSize}
        rows={commissions.content}
        totalElements={commissions.totalElements}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        fetchCommissions={fetchCommissions}
      />
    </>
  );
}

export default Commissions;
