import React, { useState } from 'react';
import { AxiosError } from 'axios';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Button from '../shared/Inputs/Button';
import LoadingButton from '../shared/Inputs/LoadingButton';
import TextField from '../shared/Inputs/TextField';

import { useSnackbar } from '../../hooks/use-snackbar';
import { useAuth } from '../../hooks/use-auth';
import { CompleteService } from '../../interfaces/services';
import {
  IProjectDetailsForm,
  IProjectIntakeItem,
  IProjectIntakeItems,
} from '../../interfaces/checkout';
import { Invoice } from '../../interfaces/order';
import { uploadFile } from '../../requests/common';
import { IUser } from '../../interfaces/user';
import { getRandomId } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  projectTypeTitle: { color: '#3A4A5E', fontWeight: 700 },
  projectDetailsAccordion: {
    backgroundColor: '#F5F9FD',
    border: '1px solid #DEDEDE',
    '&.MuiAccordion-root:before': { display: 'none' },
    '&.MuiAccordion-root:not(:last-child)': { borderBottom: 'none' },
    '&.MuiAccordion-root:last-child': { borderBottom: '1px solid #DEDEDE' },
    '&.MuiAccordion-root.Mui-expanded': { borderBottom: '1px solid #DEDEDE' },
  },
  projectDetailsAccordionTitle: { fontSize: '0.875rem', color: '#3D4B5B', fontWeight: 700 },
  projectIntakeItemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    '& > div': { marginBottom: theme.spacing(1) },
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  intakeItemWithDeleteContainer: {
    display: 'flex',
    padding: theme.spacing(1, 0),
    '& > div:first-child': { flexGrow: 1 },
    '& > div:last-child': { marginLeft: theme.spacing(0.5) },
  },
  intakeItemFileName: {
    width: 190,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.875rem',
  },
  uploadInput: { display: 'none' },
}));

interface IProps {
  service: CompleteService;
  projectDetails: IProjectDetailsForm;
  invoice: Invoice;
  setProjectDetails: React.Dispatch<React.SetStateAction<IProjectDetailsForm>>;
}

function ManualProjectDetails(props: IProps) {
  const classes = useStyles();
  const { service, projectDetails, invoice, setProjectDetails } = props;
  const { user } = useAuth();
  const intakeItems = service?.intakeItems || [];

  function getNewProjectIntakeItem() {
    return intakeItems.map<IProjectIntakeItem>((item) => ({
      id: getRandomId(),
      name: item.name,
      placeHolder: item.placeHolder,
      helpText: item.helpText,
      sequence: item.sequence,
      itemType: item.itemType,
      mandatory: item.mandatory,
      value: '',
    }));
  }

  function addEntry(packageId: string) {
    const manualEntries = [...projectDetails.manualEntries];
    const packageOptionIndex = manualEntries.findIndex((p) => p.packageId === packageId);
    if (packageOptionIndex === -1) return;
    const packageOption = manualEntries[packageOptionIndex];
    const copiedPackageValues = [...packageOption.values];
    copiedPackageValues.push({
      id: getRandomId(),
      role: user?.role,
      items: getNewProjectIntakeItem(),
    });
    manualEntries[packageOptionIndex].values = copiedPackageValues;
    setProjectDetails({ ...projectDetails, manualEntries });
  }

  function deleteEntry(packageId: string, rowId: string) {
    const manualEntries = [...projectDetails.manualEntries];
    const packageOptionIndex = manualEntries.findIndex((p) => p.packageId === packageId);
    if (packageOptionIndex === -1) return;
    const packageOption = manualEntries[packageOptionIndex];
    const copiedPackageValues = [...packageOption.values];
    manualEntries[packageOptionIndex].values = copiedPackageValues.filter((c) => c.id !== rowId);
    setProjectDetails({ ...projectDetails, manualEntries });
  }

  function updateIntakeItemValue(packageId: string, rowId: string, itemId: string, value: string) {
    const manualEntries = [...projectDetails.manualEntries];
    const packageOptionIndex = manualEntries.findIndex((p) => p.packageId === packageId);
    if (packageOptionIndex === -1) return;
    const packageOption = manualEntries[packageOptionIndex];
    const copiedPackageValues = [...packageOption.values];
    const rowIndex = copiedPackageValues.findIndex((v) => v.id === rowId);
    if (rowIndex === -1) return;
    const copiedRow = copiedPackageValues[rowIndex];
    const copiedItems = [...copiedRow.items];
    const itemIndex = copiedItems.findIndex((item) => item.id === itemId);
    if (itemIndex === -1) return;
    const copiedItem = { ...copiedItems[itemIndex] };
    copiedItem.value = value;
    copiedItems[itemIndex] = copiedItem;
    copiedPackageValues[rowIndex].items = copiedItems;
    manualEntries[packageOptionIndex].values = copiedPackageValues;
    setProjectDetails({ ...projectDetails, manualEntries });
  }

  return (
    <>
      <p className={classes.projectTypeTitle}>Project Details</p>
      <div>
        {!projectDetails.manualEntries.length ? (
          <p>No entries found</p>
        ) : (
          projectDetails.manualEntries.map((p) => {
            const packageName = p.packageName;
            const invoiceItem = invoice?.invoiceItems?.find(
              (item) => item.packageId === p.packageId
            );
            const quantity = invoiceItem?.quantity;
            const price = invoiceItem?.price;

            return (
              <Accordion
                key={p.packageId}
                elevation={0}
                classes={{ root: classes.projectDetailsAccordion }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.projectDetailsAccordionTitle}>
                    {`${packageName} ${quantity && price ? `(${quantity} x $${price})` : ''}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box width="100%">
                    <IntakeItem
                      user={user}
                      packageId={p.packageId}
                      rows={p.values}
                      deleteEntry={deleteEntry}
                      updateIntakeItemValue={updateIntakeItemValue}
                    />
                  </Box>
                </AccordionDetails>
                <AccordionActions>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => addEntry(p.packageId)}
                  >
                    {p.values.length ? 'Add More' : 'Create'}
                  </Button>
                </AccordionActions>
              </Accordion>
            );
          })
        )}
      </div>
    </>
  );
}

function IntakeItem({
  user,
  packageId,
  rows,
  deleteEntry,
  updateIntakeItemValue,
}: {
  user: IUser | null;
  packageId: string;
  rows: IProjectIntakeItems[];
  deleteEntry(packageId: string, rowId: string): void;
  updateIntakeItemValue(packageId: string, rowId: string, itemId: string, value: string): void;
}) {
  const classes = useStyles();
  const role = user?.role;

  return (
    <>
      {rows.length ? (
        rows.map((row) => {
          const rowId = row?.id || '';
          const disabled = !role || role !== row.role;

          return (
            <div key={rowId} className={classes.intakeItemWithDeleteContainer}>
              <div>
                <IntakeItemField
                  packageId={packageId}
                  rowId={rowId}
                  items={row.items}
                  disabled={disabled}
                  updateIntakeItemValue={updateIntakeItemValue}
                />
              </div>
              <div>
                <IconButton
                  size="small"
                  color="secondary"
                  disabled={disabled}
                  onClick={() => deleteEntry(packageId, rowId)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          );
        })
      ) : (
        <p>No entries found. Please create a new entry.</p>
      )}
    </>
  );
}

function IntakeItemField({
  packageId,
  rowId,
  items,
  disabled,
  updateIntakeItemValue,
}: {
  packageId: string;
  rowId: string;
  items: IProjectIntakeItem[];
  disabled: boolean;
  updateIntakeItemValue(packageId: string, rowId: string, itemId: string, value: string): void;
}) {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const [isUploading, setIsUploading] = useState(false);

  function handleFileChange(itemId: string, files: FileList | null) {
    if (!files?.length) return;
    const formData = new FormData();
    formData.append('file', files[0]);
    setIsUploading(true);
    uploadFile(formData)
      .then((res) => {
        setIsUploading(false);
        const url = res.data.url;
        updateIntakeItemValue(packageId, rowId, itemId, url);
      })
      .catch((error: AxiosError) => {
        setIsUploading(false);
        const errorMessage =
          error?.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errorMessage });
      });
  }

  function handleRemoveFile(itemId: string) {
    updateIntakeItemValue(packageId, rowId, itemId, '');
  }

  return (
    <div className={classes.projectIntakeItemContainer}>
      {items.map((item) => {
        const itemId = item?.id || '';
        const value = item.value;
        switch (item.itemType) {
          case 'FILE_UPLOAD': {
            const id = `contained-button-file-${itemId}`;
            return (
              <div key={itemId}>
                {!value ? (
                  <>
                    <input
                      className={classes.uploadInput}
                      id={id}
                      type="file"
                      accept=".doc,.docx,.xls,.xlsx"
                      disabled={disabled}
                      onChange={(event) => handleFileChange(itemId, event.target.files)}
                    />
                    <label htmlFor={id}>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        component="span"
                        isLoading={isUploading}
                        disabled={disabled}
                      >
                        {item.name}
                      </LoadingButton>
                    </label>
                  </>
                ) : (
                  <List dense disablePadding>
                    <ListItem>
                      <ListItemText
                        classes={{ primary: classes.intakeItemFileName }}
                        primary={
                          <span>
                            <a href={value} download>
                              {`${value}`.split('/').slice(-1)}
                            </a>
                          </span>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          size="small"
                          color="secondary"
                          disabled={disabled}
                          onClick={() => handleRemoveFile(itemId)}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                )}
              </div>
            );
          }
          default: {
            const isMultiline = item.itemType === 'TEXT_AREA';
            return (
              <TextField
                key={itemId}
                label={item.name}
                type={item.itemType === 'NUMBER' ? 'number' : 'text'}
                multiline={isMultiline}
                variant="outlined"
                value={value || ''}
                InputProps={{ readOnly: disabled }}
                onChange={(event) =>
                  updateIntakeItemValue(packageId, rowId, itemId, event.target.value)
                }
                {...(item.placeHolder && { placeholder: item.placeHolder })}
                {...(item.helpText && { helperText: <span>{item.helpText}</span> })}
                {...(isMultiline && { rows: 3 })}
              />
            );
          }
        }
      })}
    </div>
  );
}

export default ManualProjectDetails;
