import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  titleContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  title: { color: '#172B70', fontSize: 22, fontWeight: 700 },
  filtersWithActionsContainer: { display: 'flex', alignItems: 'center' },
  filtersContainer: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    marginRight: theme.spacing(1),
    flex: 1,
    flexWrap: 'wrap',
    '& > div': { width: 200, marginBottom: theme.spacing(1) },
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  dateFilterButton: {
    padding: '6px 21px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
    justifyContent: 'space-between',
  },
  dateFilterPopover: { marginTop: theme.spacing(0.5) },
  dateFilterContainer: { padding: theme.spacing(2) },
  dateFilterDatePickerContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  dateFilterDatePicker: { fontSize: '0.875rem' },
  filterActionsContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  autoCompleteLabel: { fontSize: '0.875rem', color: '#384F7A' },
  assignedUserName: { fontWeight: 500 },
  assignedUserRole: { fontSize: '0.75rem' },
  orderDetailsGrid: { [theme.breakpoints.down('sm')]: { flexDirection: 'column-reverse' } },
  orderInfoContainer: { position: 'sticky', top: 100 },
  orderInfo: {
    background: '#FFFFFF',
    border: '1px solid #E4EBF8',
    borderRadius: 4,
    padding: theme.spacing(2, 3),
  },
  serviceName: { color: '#172B70', fontWeight: 600, fontSize: '1rem', marginTop: 0 },
  orderInfoItem: {
    display: 'flex',
    color: '#384F7A',
    padding: theme.spacing(1, 0),
    '& > div:first-child': { flex: 1 },
    '& > div:last-child': { flex: 1, fontWeight: 500, wordBreak: 'break-word' },
  },
  tabPaperRoot: { flexGrow: 1, padding: theme.spacing(2), border: '1px solid #E4EBF8' },
  statusHeader: {
    margin: theme.spacing(-2, -2, 1),
    padding: theme.spacing(2),
    borderBottom: '1px solid #E4EBF8',
    display: 'flex',
    justifyContent: 'space-around',
    '& > div': { width: 250 },
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  assignedToRole: { fontSize: '0.75rem', flex: 1 },
  orderHistoryContainer: {},
  orderHistoryItem: {
    display: 'flex',
    padding: theme.spacing(2),
    '& > div:first-child': { flex: 1 },
    '& > div:last-child': { textAlign: 'right' },
    '&:nth-child(odd)': { backgroundColor: '#F5F5F5', borderRadius: 4 },
  },
  orderHistoryNameContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  orderHistoryName: { color: '#919AAD' },
  orderHistoryAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: '0.3px solid #FF6F24',
    backgroundColor: '#FFEBE6',
    color: '#FF6F24',
    marginRight: theme.spacing(1),
  },
  orderHistoryDate: { fontWeight: 500 },
  orderHistoryTime: { fontSize: '0.75rem' },
  cursorPointer: { cursor: 'pointer' },
  ordersTable: { height: 'calc(100vh - 250px) !important' },
  uploadingReportContainer: { margin: theme.spacing(2, 0, 1) },
  reportUploadedTitle: { color: '#172B70', fontWeight: 600 },
  dropZone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#C4C4C4',
    borderStyle: 'dashed',
    backgroundColor: '#F6F6F6',
    color: '#8999AB',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
  },
  dropActive: { borderColor: '#2196f3' },
  dropAccept: { borderColor: '#00e676' },
  dropReject: { borderColor: '#ff1744' },
  reportTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#3A4A5E',
    fontWeight: 500,
  },
  reportFilesContainer: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #DADBDC',
    padding: theme.spacing(1),
    borderRadius: 4,
    marginBottom: theme.spacing(1),
    '& > div:first-child': { marginRight: theme.spacing(1) },
    '& > div:nth-child(2)': { flexGrow: 1 },
    '& > div:last-child': { marginLeft: theme.spacing(1) },
  },
  reportFileIconContainer: { display: 'flex' },
  projectDataTitle: { color: '#172B70', fontWeight: 600 },
  saveButtonContainer: { margin: theme.spacing(2, 0, 0) },
  reasonForCancelation: {
    color: '#172B70',
    fontSize: 16,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  messagesTitle: { color: '#3A4A5E', fontWeight: 700 },
  messagesLoader: { marginLeft: theme.spacing(1) },
  messageEditor: { marginTop: theme.spacing(1) },
  messageButtons: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  messageBody: { padding: 0 },
  messagesPaperRoot: {
    flexGrow: 1,
    border: '1px solid #E4EBF8',
    maxHeight: '30em',
    overflowY: 'scroll',
  },
  messageItem: {
    padding: theme.spacing(2),
    '&:not(:last-child)': { borderBottom: '1px solid #E4EBF8' },
  },
  messageNameContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
  },
  messageAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: '0.3px solid #FF6F24',
    backgroundColor: '#FFEBE6',
    color: '#FF6F24',
    marginRight: theme.spacing(1),
  },
  messageTime: { fontSize: 12 },
  emptyMessagePlaceholder: { marginLeft: theme.spacing(2) },
  refreshMessageIcon: { marginLeft: theme.spacing(1), cursor: 'pointer' },
  link: { color: 'inherit', '&:hover': { color: 'inherit' }, '&:visited': { color: 'inherit' } },
  linkBlue: {
    color: '#384F7A',
    '&:hover': { color: '#384F7A' },
    '&:visited': { color: '#384F7A' },
  },
  createOrderContainer: {
    background: '#FFFFFF',
    border: '1px solid #E4EBF8',
    borderRadius: 4,
  },
  createOrderForm: {
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.sm,
      padding: theme.spacing(2, 0),
    },
  },
  formField: { margin: theme.spacing(1, 0), padding: theme.spacing(1, 0) },
  fieldTitle: {
    color: '#172B70',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    fontSize: '0.875rem',
  },
  createButtonContainer: { margin: theme.spacing(2, 0) },
  detailsFormContainer: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    border: '1px solid #F0F0F0',
    borderRadius: 4,
  },
  sectionTitle: { color: '#172B70', fontWeight: 700, marginTop: 0 },
  packageTitle: { verticalAlign: 'middle', marginRight: theme.spacing(0.5) },
  packageDescriptionIcon: { verticalAlign: 'middle' },
  total: { color: '#3A4A5E', fontWeight: 500, fontSize: '1.5rem' },
  uploadInput: { display: 'none' },
  uploadedImageContainer: {
    padding: theme.spacing(1),
    backgroundColor: '#EAEAEA',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  imageName: { color: '#172B70', margin: 0, fontSize: '0.875rem', fontWeight: 500 },
  viewDeleteIconButton: { margin: theme.spacing(0, 0.5) },
  orderNotes: {
    marginTop: '12px',
    backgroundColor: '#F5F9FD',
    border: '1px solid #DEDEDE',
    width: '100%',
    padding: '10px',
  },
  orderNotesTitle: { color: '#3A4A5E', fontWeight: 700 },
}));
