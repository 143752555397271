const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const PASSWORD_REGEXP = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
const NUMBERS_REGEXP = /^\d+$/;
const URL_REGEXP =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

const APP_URL = process.env.REACT_APP_URL || 'http://localhost:3000';
const STAN_VENTURES_BASE_URL =
  process.env.REACT_APP_STAN_VENTURES_URL || 'https://stanventures.com';

const STRIPE_KEY_OLD = process.env.REACT_APP_STRIPE_KEY_OLD || '';
const STRIPE_KEY_NEW = process.env.REACT_APP_STRIPE_KEY_NEW || '';

const FOOTER_CATEGORIES = [
  {
    title: 'Company',
    categories: [
      {
        title: 'Blog',
        url: `${STAN_VENTURES_BASE_URL}/blog`,
      },
      {
        title: 'About Us',
        url: `${STAN_VENTURES_BASE_URL}/about-us`,
      },
      {
        title: 'Our Team',
        url: `${STAN_VENTURES_BASE_URL}/about-team`,
      },
      {
        title: 'SEO Podcast',
        url: `${STAN_VENTURES_BASE_URL}/seo-podcast`,
      },
      {
        title: 'Case Studies',
        url: `${STAN_VENTURES_BASE_URL}/google-update-case-study`,
      },
      {
        title: 'Contact Us',
        url: `${STAN_VENTURES_BASE_URL}/contact-us`,
      },
    ],
  },
  {
    title: 'Popular Blogs',
    categories: [
      {
        title: 'Google Algorithm Updates',
        url: `${STAN_VENTURES_BASE_URL}/blog/google-algorithm-update-2019`,
      },
      {
        title: 'Affordable SEO For Small Business',
        url: `${STAN_VENTURES_BASE_URL}/blog/affordable-seo-services-for-businesses`,
      },
      {
        title: 'How to Increase Domain Authority',
        url: `${STAN_VENTURES_BASE_URL}/blog/domain-authority`,
      },
      {
        title: 'How to Start SEO Business',
        url: `${STAN_VENTURES_BASE_URL}/blog/secrets-of-best-seo-companies`,
      },
    ],
  },
  {
    title: 'Our Services',
    categories: [
      {
        title: 'Blogger Outreach Service',
        url: `${STAN_VENTURES_BASE_URL}/blogger-outreach-services`,
      },
      {
        title: 'Powerful Link Building Service',
        url: `${STAN_VENTURES_BASE_URL}/powerful-link-building-service`,
      },
      {
        title: 'Fully Managed SEO',
        url: `${STAN_VENTURES_BASE_URL}/managed-seo-services`,
      },
      {
        title: 'Content Marketing Services',
        url: `${STAN_VENTURES_BASE_URL}/content-marketing-service`,
      },
      {
        title: 'Blogger Writing Services',
        url: `${STAN_VENTURES_BASE_URL}/blog-writing-services`,
      },
      {
        title: 'Guest Posting Services',
        url: `${STAN_VENTURES_BASE_URL}/guest-post-services`,
      },
    ],
  },
];

const REGEX = {
  EMAIL: EMAIL_REGEXP,
  PASSWORD: PASSWORD_REGEXP,
  NUMBERS: NUMBERS_REGEXP,
  URL: URL_REGEXP,
};

const ORDER_STATUS_MAP: { [key: string]: string } = {
  Abandoned_Checkout: 'Abandoned Checkout',
  Payment_Failed: 'Payment Failed',
  Active: 'Active',
  Inputs_Required: 'Inputs Required',
  Working: 'Working',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
};

const USER_ROLE_MAP: { [key: string]: string } = {
  SUPER_ADMIN: 'Super Admin',
  ADMIN: 'Admin',
  MANAGER: 'Manager',
  CUSTOMER: 'Customer',
};

const SUBSCRIPTION_STATUS_MAP: { [key: string]: string } = {
  Active: 'Active',
  Due: 'Due',
  Past_Due: 'Past Due',
  Cancelled: 'Canceled',
};

const CLIENT_STATUS_MAP: { [key: string]: string } = {
  LEAD: 'Lead',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

const CLIENT_TYPE_MAP: { [key: string]: string } = {
  BUSINESS: 'Business',
  AGENCY: 'Agency',
  AFFILIATE: 'Affiliate',
  BLOG: 'Blog',
};

const COMMISSION_STATUS_MAP: { [key: string]: string } = {
  CREATED: 'Created',
  APPROVED: 'Approved',
  PAID: 'Paid',
};

const LOCAL_STORAGE_TOKEN_KEY_MAP = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  ADMIN_ACCESS_TOKEN: 'admin_access_token',
};

export {
  REGEX,
  APP_URL,
  STAN_VENTURES_BASE_URL,
  STRIPE_KEY_OLD,
  STRIPE_KEY_NEW,
  FOOTER_CATEGORIES,
  ORDER_STATUS_MAP,
  USER_ROLE_MAP,
  SUBSCRIPTION_STATUS_MAP,
  CLIENT_STATUS_MAP,
  CLIENT_TYPE_MAP,
  COMMISSION_STATUS_MAP,
  LOCAL_STORAGE_TOKEN_KEY_MAP,
};
