import axios from '../axios';
import { IFilterOptions, PageResponse, SearchParams } from '../interfaces/shared';
import {
  Package,
  PackageForm,
  Service,
  ServiceForm,
  IntakeFormRequest,
  IntakeFormResponse,
  CompleteService,
  ICustomerInterface,
} from '../interfaces/services';
import { CancelToken } from 'axios';

export function getServices(requestBody: SearchParams) {
  return axios.post<PageResponse<Service>>('/service/find', requestBody);
}

export function createUpdateService(requestBody: ServiceForm) {
  return axios.post<Service>('/service/serviceDetail', requestBody);
}

export function getServiceById(serviceId: string) {
  return axios.get<Service>('/service/details', { params: { id: serviceId } });
}

export function updateServiceImage(requestBody: FormData) {
  return axios.post<{ url: string }>('/service/upload/image', requestBody, {
    headers: { 'content-type': 'multipart/form-data' },
  });
}

export function deleteService(serviceId: string) {
  return axios.delete('/service/serviceDetail', {
    params: { id: serviceId },
  });
}

export function getPackages(serviceId: string) {
  return axios.get<Package[]>('/service/serviceOptions', { params: { id: serviceId } });
}

export function createUpdatePackage(requestBody: PackageForm) {
  return axios.post<Package>('/service/serviceOption', requestBody);
}

export function deletePackage(packageId: string, serviceId: string) {
  return axios.delete('/service/serviceOption', {
    params: { serviceOptionId: packageId, serviceId },
  });
}

export function getIntakeForm(serviceId: string) {
  return axios.get<IntakeFormResponse>('/service/intakeDetail', { params: { id: serviceId } });
}

export function createUpdateIntakeForm(requestBody: IntakeFormRequest) {
  return axios.post<IntakeFormResponse>('/service/intakeDetail', requestBody);
}

export function getCompleteServiceDetails(serviceId: string) {
  return axios.get<CompleteService>('/service/fullDetail', { params: { id: serviceId } });
}

export function getAllCompleteServices() {
  return axios.get<Service[]>('/service/visible/fullDetail');
}

export function getVisibleServices() {
  return axios.get<Service[]>('/service/visible');
}

export function duplicateService(serviceId: string) {
  return axios.get('/service/clone', { params: { id: serviceId } });
}

export function createUpdateCustomerInterface(requestBody: ICustomerInterface) {
  return axios.post<ICustomerInterface>('/service/customerInterface', requestBody);
}

export function getCustomerInterface(serviceId: string) {
  return axios.get<ICustomerInterface>('/service/customerInterface', { params: { serviceId } });
}

export function getServiceListOptions() {
  return axios.get<IFilterOptions[]>('/service/dropDown');
}

export function getServiceListOptionsOnSearch(search: string, cancelToken?: CancelToken) {
  return axios.get<IFilterOptions[]>(`/service/dropDown/${search}`, { cancelToken });
}
