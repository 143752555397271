import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import OrderSuccess from './OrderSuccess';
import Summary from './Summary';
import { useAuth } from '../../hooks/use-auth';
import { useRouter } from '../../hooks/use-router';
import { useSnackbar } from '../../hooks/use-snackbar';

import { getInvoice, getOrderData, getOrderInfo, updateOrderData } from '../../requests/order';
import {
  ICoupon,
  IPackageQuantity,
  IProjectDetailsForm,
  IProjectPackageData,
  ProjectDetailTypes,
} from '../../interfaces/checkout';
import { Invoice, OrderInfo } from '../../interfaces/order';
import useStyles from './styles';
import { CompleteService, Package, PaymentTypes } from '../../interfaces/services';
import { getCompleteServiceDetails } from '../../requests/service';
import { getAppliedCoupon } from '../../requests/checkout';

function CheckoutSuccess() {
  const classes = useStyles();
  const router = useRouter();
  const { showSnackbar } = useSnackbar();
  const orderId = router.query.orderId ? atob(router.query.orderId as string) : '';

  const { user } = useAuth();
  const [packageQuantities, setPackageQuantities] = useState<IPackageQuantity[]>([]);
  const [projectDetailsType, setProjectDetailsType] = useState<ProjectDetailTypes>('');
  const [isProjectDataUpdating, setIsProjectDataUpdating] = useState(false);
  const [orderInfo, setOrderInfo] = useState<OrderInfo>({} as OrderInfo);
  const [service, setService] = useState({} as CompleteService);
  const [invoice, setInvoice] = useState({} as Invoice);
  const [couponCode, setCouponCode] = useState('');
  const [coupon, setCoupon] = useState<ICoupon | null>(null);
  const projectDetailsForm = useForm<IProjectDetailsForm>({
    defaultValues: {
      fileUrl: '',
      manualEntries: [] as IProjectPackageData[],
    },
  });

  useEffect(() => {
    getOrderInfo(orderId).then((res) => {
      setOrderInfo(res.data);
      fetchServiceData(res.data.serviceId);
      if (res.data.couponCode) {
        setCouponCode(res.data.couponCode);
        fetchAppliedCoupon(res.data.serviceId, res.data.couponCode, res.data.paymentType);
      }
      if (res.data.invoiceId) {
        getInvoice(res.data.invoiceId).then((res) => setInvoice(res.data));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    if (!isEmpty(invoice) && !isEmpty(service)) {
      const packages = service.serviceOptions;
      if (packages?.length) {
        const formattedPackages = packages?.map<IPackageQuantity>((serviceOption: Package) => {
          const packageId = serviceOption.serviceOptionId;
          const invoiceItem = invoice.invoiceItems?.find(
            (invoice) => invoice.packageId === packageId
          );
          return {
            id: packageId,
            name: serviceOption.name,
            description: serviceOption.description,
            price: serviceOption.price,
            quantity: `${invoiceItem ? invoiceItem.quantity : 0}`,
            discountItems:
              serviceOption.discountItems?.reduce((acc, item) => {
                acc[`${item.count}`] = item.percent;
                return acc;
              }, {} as { [key: string]: number }) || {},
          };
        });
        setPackageQuantities(formattedPackages);
      }
    }
    // eslint-disable-next-line
  }, [invoice, service]);

  function fetchServiceData(serviceId: string) {
    getCompleteServiceDetails(serviceId)
      .then((res) => setService(res.data))
      .catch((error: AxiosError) => {
        const errMessage =
          error.response?.data?.message ||
          'An error occurred while fetching service details. Please try again.';
        showSnackbar({
          severity: 'error',
          message: errMessage,
        });
      });
  }

  function fetchAppliedCoupon(serviceId: string, couponCode: string, paymentType: PaymentTypes) {
    getAppliedCoupon(serviceId, couponCode, paymentType)
      .then((res) => setCoupon(res.data))
      .catch((error: AxiosError) => {
        const errMessage = error.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errMessage });
      });
  }

  function handleProjectDetailsTypeChange(type: ProjectDetailTypes) {
    setProjectDetailsType(type);
  }

  function handleRedirectToOrderDetails() {
    if (orderId) {
      router.history.replace(`/orders/${btoa(orderId)}`);
    }
  }

  function handleSaveProjectDetails() {
    setIsProjectDataUpdating(true);
    getOrderData(orderId)
      .then(({ data }) => {
        updateOrderData({
          ...data,
          fileUploadUrl:
            projectDetailsType === 'upload' ? projectDetailsForm.getValues().fileUrl : '',
          projectPackageData:
            projectDetailsType === 'manual'
              ? projectDetailsForm.getValues().manualEntries.map((manualEntry) => {
                  manualEntry.values = manualEntry.values.map((value) => {
                    value.role = 'CUSTOMER';
                    return value;
                  });
                  return manualEntry;
                })
              : [],
        })
          .then((res) => {
            setIsProjectDataUpdating(false);
            showSnackbar({
              severity: 'success',
              message: 'Project details added successfully. Redirecting to order details.',
            });
            setTimeout(() => {
              if (orderId) {
                router.history.replace(`/orders/${btoa(orderId)}`);
              }
            }, 2000);
          })
          .catch((error: AxiosError) => {
            setIsProjectDataUpdating(false);
            const errMessage =
              error.response?.data?.message || 'An error occurred. Please try again.';
            showSnackbar({ severity: 'error', message: errMessage });
          });
      })
      .catch((error: AxiosError) => {
        const errMessage = error.response?.data?.message || 'An error occurred. Please try again.';
        showSnackbar({ severity: 'error', message: errMessage });
      });
  }

  function handleDetailsChange(type: string, name: string, value: string) {}

  function handlePlaceOrder() {}

  if (!orderId) {
    return <div className={classes.checkoutSuccessContent}>Invalid Order</div>;
  }

  return (
    <div className={classes.checkoutSuccessContent}>
      <Container maxWidth="lg" disableGutters>
        <Grid container spacing={1}>
          <Grid item container xs={12} md={8}>
            <OrderSuccess
              orderId={orderInfo.orderId || ''}
              user={user}
              service={service}
              packageQuantities={packageQuantities}
              projectDetailsType={projectDetailsType}
              projectDetailsForm={projectDetailsForm}
              handleProjectDetailsTypeChange={handleProjectDetailsTypeChange}
              isLoading={isProjectDataUpdating}
              handleSaveProjectDetails={handleSaveProjectDetails}
              handleRedirectToOrderDetails={handleRedirectToOrderDetails}
            />
          </Grid>
          <Grid item xs={12} md={4} component="aside">
            <Summary
              packageQuantities={packageQuantities}
              handlePlaceOrder={handlePlaceOrder}
              service={service}
              handlePaymentTypeChange={handleDetailsChange}
              paymentType={orderInfo.paymentType || 'DIRECT'}
              processing={false}
              coupon={coupon}
              couponCode={couponCode}
              setCouponCode={setCouponCode}
              handleApplyCoupon={() => {}}
              orderCreated
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default CheckoutSuccess;
