import axios from '../axios';

import {
  ISignInRequest,
  ISignInResponse,
  ISignUpRequest,
  IResetPasswordRequest,
} from '../interfaces/auth';
import { IUser } from '../interfaces/user';

export function signIn(requestBody: ISignInRequest) {
  return axios.post<ISignInResponse>('/user/signIn', requestBody);
}

export function signUp(requestBody: ISignUpRequest) {
  return axios.post('/user/signUp', requestBody, { withCredentials: true });
}

export function getUserDetails() {
  return axios.get<IUser>('/user/detail');
}

export function getUserDetailsById(id: string) {
  return axios.get<IUser>(`/user/detail/${id}`);
}

export function updateUserDetails(requestBody: IUser) {
  return axios.post('/user/update', requestBody);
}

export function updateProfilePicture(requestBody: FormData) {
  return axios.post<{ url: string }>('/user/upload/image', requestBody, {
    headers: { 'content-type': 'multipart/form-data' },
  });
}

export function sendPasswordResetLink(email: string) {
  return axios.get('/user/resetPassword', { params: { email } });
}

export function resetPassword(requestBody: IResetPasswordRequest) {
  return axios.post('/user/updatePassword', requestBody);
}

export function updateAccessToken(refreshToken: string) {
  return axios.get<ISignInResponse>('/user/refreshToken', { params: { token: refreshToken } });
}

export function getClientAccessToken(id: string) {
  return axios.get<string>(`/user/token/${id}`);
}
