import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import ContactsIcon from '@material-ui/icons/Contacts';
import Settings from '@material-ui/icons/Settings';
import People from '@material-ui/icons/People';
import LoopIcon from '@material-ui/icons/Loop';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';

export const ADMIN = [
  {
    title: '',
    data: [
      {
        path: '/',
        title: 'Dashboard',
        icon: <DashboardIcon />,
      },
      {
        path: '/orders',
        isActive(pathname: string) {
          return pathname.includes('orders');
        },
        title: 'Orders',
        icon: <AssignmentTurnedIn />,
      },
      {
        path: '/subscriptions',
        title: 'Subscriptions',
        icon: <LoopIcon />,
      },
      {
        path: '/clients',
        isActive(pathname: string) {
          return pathname.includes('clients');
        },
        title: 'Clients',
        icon: <ContactsIcon />,
      },
      {
        path: '/services',
        isActive(pathname: string) {
          return pathname.includes('services');
        },
        title: 'Services',
        icon: <Settings />,
      },
      {
        path: '/coupons',
        title: 'Coupons',
        icon: <LocalOfferIcon />,
      },
      {
        path: '/announcements',
        title: 'Announcements',
        icon: <AnnouncementIcon />,
      },
      {
        path: '/teams',
        title: 'Teams',
        icon: <People />,
      },
      {
        path: '/creatives',
        title: 'Creatives',
        icon: <EmojiObjectsIcon />,
      },
    ],
  },
  {
    title: 'Affiliates',
    data: [
      {
        path: '/affiliates',
        title: 'Affiliates',
        icon: <FlipCameraAndroidIcon />,
      },
      {
        path: '/commissions',
        title: 'Commissions',
        icon: <MonetizationOnIcon />,
      },
      {
        path: '/affiliates/traffic-logs',
        title: 'Traffic Logs',
        icon: <AssessmentIcon />,
      },
    ],
  },
];

export const USER = [
  {
    title: 'Menu',
    data: [
      {
        path: '/',
        title: 'Dashboard',
        icon: <DashboardIcon />,
      },
      {
        path: '/orders',
        isActive(pathname: string) {
          return pathname.includes('orders');
        },
        title: 'Orders',
        icon: <AssignmentTurnedIn />,
      },
      {
        path: '/services',
        isActive(pathname: string) {
          return pathname.includes('services');
        },
        title: 'Services',
        icon: <Settings />,
      },
      {
        path: '/affiliate',
        title: 'Affiliate',
        icon: <AttachMoneyIcon />,
      },
      {
        path: '/creatives',
        isActive(pathname: string) {
          return pathname.includes('creatives');
        },
        title: 'Creatives',
        icon: <EmojiObjectsIcon />,
      },
    ],
  },
];
