import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import Button from '../shared/Inputs/Button';

import localStorage from '../../utils/local-storage';
import { backToAdmin } from '../../utils/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backToAdminContainer: {
      position: 'fixed',
      right: 8,
      top: 56,
      zIndex: 1201,
      [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
        top: 48,
      },
      [theme.breakpoints.up('sm')]: {
        top: 64,
      },
    },
    backToAdminButton: { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
  })
);

function BackToAdmin() {
  const classes = useStyles();

  function showBackToAdminButton() {
    return localStorage.hasAccessToken() && localStorage.hasAdminAccessToken();
  }

  if (!showBackToAdminButton()) {
    return null;
  }

  return (
    <div className={classes.backToAdminContainer}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        disableElevation
        endIcon={<ExitToAppIcon />}
        className={classes.backToAdminButton}
        onClick={backToAdmin}
      >
        Back to Admin
      </Button>
    </div>
  );
}

export default BackToAdmin;
