import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  title: { color: '#172B70', fontSize: 22, fontWeight: 700 },
}));

function PageTitleWithBack({
  title,
  disableGutters = false,
  onBackClick,
  titleClassName = '',
}: {
  title: string;
  disableGutters?: boolean;
  onBackClick(): void;
  titleClassName?: string;
}) {
  const classes = useStyles();

  return (
    <div>
      <ListItem disableGutters={disableGutters}>
        <ListItemIcon>
          <IconButton onClick={onBackClick} size="small">
            <ArrowBackIcon htmlColor="#577496" />
          </IconButton>
        </ListItemIcon>
        <ListItemText
          primary={title}
          classes={{ primary: `${classes.title} ${titleClassName}` }}
        ></ListItemText>
      </ListItem>
    </div>
  );
}

export default PageTitleWithBack;
