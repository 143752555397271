import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  title: { color: '#172B70', fontSize: 22, fontWeight: 700 },
  titleContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  creativesTable: { height: 'calc(100vh - 250px) !important' },
  filtersWithActionsContainer: { display: 'flex', alignItems: 'center' },
  filtersContainer: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    marginRight: theme.spacing(1),
    flex: 1,
    flexWrap: 'wrap',
    '& > div': { width: 200, marginBottom: theme.spacing(1) },
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  filterActionsContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  cursorPointer: { cursor: 'pointer' },
  creativeDetailsContainer: {
    background: '#FFFFFF',
    border: '1px solid #E4EBF8',
    borderRadius: 4,
  },
  creativeDetailsForm: {
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.sm,
      padding: theme.spacing(2, 0),
    },
  },
  formField: { margin: theme.spacing(1, 0), padding: theme.spacing(1, 0) },
  fieldTitle: {
    color: '#172B70',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    fontSize: '0.875rem',
  },
  formControlLabel: { color: '#2F3034', fontSize: '0.875rem' },
  saveButtonContainer: { margin: theme.spacing(2, 0) },
  errorHelperText: {
    color: '#f44336',
    fontSize: '0.75rem',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    marginTop: 3,
    marginLeft: 14,
    marginRight: 14,
  },
  uploadInput: { display: 'none' },
  uploadedImageContainer: {
    padding: theme.spacing(1),
    backgroundColor: '#EAEAEA',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  imageName: { color: '#172B70', margin: 0, fontSize: '0.875rem', fontWeight: 500 },
  viewDeleteIconButton: { margin: theme.spacing(0, 0.5) },
  dateFilterButton: {
    padding: '6px 21px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
    justifyContent: 'space-between',
  },
  dateFilterPopover: { marginTop: theme.spacing(0.5) },
  dateFilterContainer: { padding: theme.spacing(2) },
  dateFilterDatePickerContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  dateFilterDatePicker: { fontSize: '0.875rem' },
}));
