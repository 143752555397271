import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  title: { color: '#172B70', fontSize: 22, fontWeight: 700 },
  trafficLogsTable: { height: 'calc(100vh - 250px) !important' },
  filtersWithActionsContainer: { display: 'flex', alignItems: 'center' },
  filtersContainer: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    marginRight: theme.spacing(1),
    flex: 1,
    flexWrap: 'wrap',
    '& > div': { width: 200, marginBottom: theme.spacing(1) },
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  filterActionsContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  linkBlue: {
    color: '#384F7A',
    '&:hover': { color: '#384F7A' },
    '&:visited': { color: '#384F7A' },
  },
  commissionsItemContainer: {
    background: '#FFFFFF',
    border: '1px solid #E4EBF8',
    borderRadius: 4,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  referralDescription: { fontWeight: 500, color: '#172B70' },
  referralLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& > div:first-child': { width: '50%' },
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  referralStats: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    '& > div': {
      margin: theme.spacing(0, 1, 1, 0),
      '& > div:first-child': { fontWeight: 500, color: '#172B70', marginBottom: theme.spacing(2) },
      '& > div:last-child': { fontSize: '1.125rem', fontWeight: 600 },
    },
  },
  commissionStatus: {
    padding: theme.spacing(0.5, 3),
    fontWeight: 600,
    lineHeight: '20px',
    borderRadius: 4,
    '&.created': { backgroundColor: '#FFF6D4', color: '#FFAD0E' },
    '&.paid': { backgroundColor: '#C7F5D7', color: '#1EA866' },
    '&.approved': { backgroundColor: '#E2F3FF', color: '#4772A4' },
  },
  dateRangePicker: { minWidth: 256, marginBottom: '16px' },
}));
