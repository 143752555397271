import { LOCAL_STORAGE_TOKEN_KEY_MAP } from '../constants';

function get(key: string): string | null {
  return window.localStorage.getItem(key);
}

function set(key: string, value: string): void {
  window.localStorage.setItem(key, value);
}

function remove(key: string): void {
  window.localStorage.removeItem(key);
}

function clear(): void {
  window.localStorage.clear();
}

function getAccessToken(): string | null {
  return get(LOCAL_STORAGE_TOKEN_KEY_MAP.ACCESS_TOKEN);
}

function setAccessToken(value: string): void {
  set(LOCAL_STORAGE_TOKEN_KEY_MAP.ACCESS_TOKEN, value);
}

function setAdminAccessToken(value: string): void {
  set(LOCAL_STORAGE_TOKEN_KEY_MAP.ADMIN_ACCESS_TOKEN, value);
}

function getAdminAccessToken(): string | null {
  return get(LOCAL_STORAGE_TOKEN_KEY_MAP.ADMIN_ACCESS_TOKEN);
}

function setRefreshToken(value: string): void {
  set(LOCAL_STORAGE_TOKEN_KEY_MAP.REFRESH_TOKEN, value);
}

function getRefreshToken(): string | null {
  return get(LOCAL_STORAGE_TOKEN_KEY_MAP.REFRESH_TOKEN);
}

function hasAccessToken(): boolean {
  return getAccessToken() !== null;
}

function hasAdminAccessToken(): boolean {
  return getAdminAccessToken() !== null;
}

const localStorage = {
  get,
  set,
  remove,
  clear,
  getAccessToken,
  setAccessToken,
  getAdminAccessToken,
  setAdminAccessToken,
  hasAccessToken,
  hasAdminAccessToken,
  setRefreshToken,
  getRefreshToken,
};

export default localStorage;
