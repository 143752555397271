import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  title: { color: '#172B70', fontSize: 22, fontWeight: 700 },
  clientsTable: { height: 'calc(100vh - 250px) !important' },
  filtersWithActionsContainer: { display: 'flex', alignItems: 'center' },
  filtersContainer: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    marginRight: theme.spacing(1),
    flex: 1,
    flexWrap: 'wrap',
    '& > div': { width: 200, marginBottom: theme.spacing(1) },
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  filterActionsContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  cursorPointer: { cursor: 'pointer' },
  clientDetailsHeader: { margin: theme.spacing(2, 0), display: 'flex', alignItems: 'center' },
  clientDetailsHeaderActionsContainer: {
    display: 'flex',
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  loginAsUserButton: { width: 131 },
  exportOrdersButton: { width: 140 },
  statusSelectField: { width: 150 },
  commissionStatusSelectField: { width: 200 },
  orderChangeClientSelectField: { width: 400 },

  clientInfo: {
    background: '#FFFFFF',
    border: '1px solid #E4EBF8',
    borderRadius: 4,
    padding: theme.spacing(2, 3),
    minHeight: 320,
  },
  clientInfoLoadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    minHeight: 'inherit',
  },
  clientInfoItem: {
    display: 'flex',
    color: '#384F7A',
    padding: theme.spacing(1, 0),
    alignItems: 'center',
    '& > div:first-child': { flex: 1 },
    '& > div:last-child': { flex: 1, fontWeight: 500, wordBreak: 'break-word' },
  },
  affiliateContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  tabContainer: { marginTop: theme.spacing(2) },
  minimalTable: { height: 400 },
  affiliateUrl: {
    color: '#000000de',
    fontSize: '1rem',
    marginBottom: theme.spacing(2),
    '& a': { color: '#384F7A' },
  },
  percentageSign: { fontWeight: 'bold', color: '#577496', paddingRight: theme.spacing(1) },
  commissionTypeCheckbox: { padding: 4 },
  addCommissionItemButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1, 0),
  },
  linkBlue: {
    color: '#384F7A',
    '&:hover': { color: '#384F7A' },
    '&:visited': { color: '#384F7A' },
  },
  fieldTitle: {
    color: '#172B70',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    fontSize: '0.875rem',
  },
  commissionStatusContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
    marginBottom: theme.spacing(1),
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  orderChangeClientContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
    marginBottom: theme.spacing(1),
    '& > div:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  commissionStatus: {
    padding: theme.spacing(0.5, 3),
    fontWeight: 600,
    lineHeight: '20px',
    borderRadius: 4,
    '&.created': { backgroundColor: '#FFF6D4', color: '#FFAD0E' },
    '&.paid': { backgroundColor: '#C7F5D7', color: '#1EA866' },
    '&.approved': { backgroundColor: '#E2F3FF', color: '#4772A4' },
  },
}));
