export function getReportTitle(reportType: string) {
  switch (reportType) {
    case 'agents':
      return 'Agents';
    case 'clients':
      return 'Clients';
    case 'new-clients':
      return 'New Clients';
    case 'clients-comparison':
      return 'Clients Comparison';
    default:
      return null;
  }
}
