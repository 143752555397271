import {
  DataGrid,
  GridColDef,
  GridFeatureMode,
  GridRowsProp,
  GridSelectionModel,
  GridSortModel,
} from '@material-ui/data-grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles, Theme } from '@material-ui/core/styles';

interface IProps {
  page: number;
  pageSize: number;
  rows: GridRowsProp;
  columns: GridColDef[];
  totalElements: number;
  loading: boolean;
  checkboxSelection?: boolean;
  selectionModel?: GridSelectionModel;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  onSelectionModelChange?(selectionModel: GridSelectionModel, details?: any): void;
  onSortModelChange?(model: GridSortModel): void;
  sortModel?: GridSortModel;
  sortingMode?: GridFeatureMode;
}
const useStyles = makeStyles((theme: Theme) => createStyles({ minimalTable: { height: 400 } }));

function MinimalTable(props: IProps) {
  const classes = useStyles();
  const {
    page,
    pageSize,
    rows,
    columns,
    totalElements,
    loading,
    checkboxSelection,
    selectionModel,
    onPageChange,
    onPageSizeChange,
    onSelectionModelChange,
    onSortModelChange,
    sortModel,
    sortingMode,
  } = props;
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableSelectionOnClick
      checkboxSelection={checkboxSelection}
      rowsPerPageOptions={[5, 10, 25]}
      rowCount={totalElements}
      page={page}
      pageSize={pageSize}
      selectionModel={selectionModel}
      paginationMode="server"
      loading={loading}
      pagination
      classes={{ root: classes.minimalTable }}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onSelectionModelChange={onSelectionModelChange}
      onSortModelChange={onSortModelChange}
      sortModel={sortModel}
      sortingMode={sortingMode}
    />
  );
}

export default MinimalTable;
