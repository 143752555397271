import axios from '../axios';
import {
  AffiliateSearch,
  AffiliateStats,
  AffiliateTrack,
  AffiliateTrackUser,
  Commission,
  UpdateCommissionStatus,
} from '../interfaces/affiliates';
import { IFilter, PageResponse, SearchParams } from '../interfaces/shared';

export function trackAffiliate(requestBody: AffiliateTrackUser) {
  return axios.post('/affiliate/track', requestBody, { withCredentials: true });
}

export function getCommissions(requestBody: SearchParams) {
  return axios.post<PageResponse<Commission>>('/commission/find', requestBody);
}

export function updateCommissionStatus(requestBody: UpdateCommissionStatus) {
  return axios.put('/commissions', requestBody);
}

export function getTrafficLogs(requestBody: SearchParams) {
  return axios.post<PageResponse<AffiliateTrack>>('/affiliate/track/find', requestBody);
}

export function getAffiliateStats(start: string | null, end: string | null) {
  return axios.get<AffiliateStats>('/affiliate/status', {
    params: { value: start, toValue: end },
  });
}

export function exportCommissions(requestBody: { filterFields?: IFilter[] }) {
  return axios.post('/commissions/export', requestBody, { responseType: 'blob' });
}

export function getAffiliates(requestBody: SearchParams) {
  return axios.post<PageResponse<AffiliateSearch>>('/affiliates/find', requestBody);
}

export function exportAffiliates(requestBody: { filterFields?: IFilter[] }) {
  return axios.post('/affiliates/export', requestBody, { responseType: 'blob' });
}
