import { useState } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import Button from '../shared/Inputs/Button';
import TextField from '../shared/Inputs/TextField';
import { useRouter } from '../../hooks/use-router';
import { sendPasswordResetLink } from '../../requests/auth';
import { REGEX } from '../../constants';

import Email from '../../assets/img/email.svg';
import Lock from '../../assets/img/lock.svg';

import useStyles from './styles';

interface IEmailInput {
  email: '';
}

function ForgotPassword() {
  const classes = useStyles();
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [error, setError] = useState(null);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IEmailInput>({ defaultValues: { email: '' } });

  const onSubmit: SubmitHandler<IEmailInput> = async ({ email }) => {
    setError(null);
    setIsLoading(true);
    try {
      await sendPasswordResetLink(email);
      setIsLoading(false);
      setIsEmailSent(true);
    } catch (err) {
      const errorMessage = err?.response?.data?.message || 'An error occurred. Please try again.';
      setIsLoading(false);
      setError(errorMessage);
    }
  };

  return (
    <div className={classes.centered}>
      <Paper className={classes.forgotPasswordPaper}>
        {!isEmailSent ? (
          <>
            <div className={classes.forgotPasswordTitleContainer}>
              <div className={classes.lockIcon}>
                <img src={Lock} alt="lock" className={classes.lockIconImg} />
              </div>
              <div className={classes.forgotPasswordTitle}>Forgot Password?</div>
            </div>
            <p className={classes.forgotPasswordSubTitle}>
              Enter the email address you registered and we’ll send you instructions to reset your
              password.
            </p>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true, pattern: REGEX.EMAIL }}
                  render={({ field: { ref, ...rest } }) => {
                    const hasError = Boolean(errors.email);
                    const hasRequiredError = errors?.email?.type === 'required';
                    return (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        type="email"
                        label="Email address"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        error={hasError}
                        {...(hasError && {
                          helperText: (
                            <span>
                              {hasRequiredError
                                ? 'Email is required'
                                : 'Please provide a valid email'}
                            </span>
                          ),
                        })}
                      />
                    );
                  }}
                />
                <div>{error && <Alert severity="error">{error}</Alert>}</div>
                <div
                  className={`${classes.signInBtnContainer} ${classes.forgotPasswordButtonContainer}`}
                >
                  <div>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={[classes.sendEmailBtn, classes.loadingButton].join(' ')}
                      disabled={isLoading}
                    >
                      Send Email
                      {isLoading && (
                        <LinearProgress
                          color="primary"
                          variant="indeterminate"
                          className={classes.buttonLinearProgress}
                        />
                      )}
                    </Button>
                  </div>
                  <div>
                    <Link to="/signin" className={classes.troubleSigningIn}>
                      Back to Login
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </>
        ) : (
          <div className={classes.textAlignCenter}>
            <div>
              <img src={Email} alt="email" className={classes.emailIcon} />
            </div>
            <p className={classes.forgotPasswordTitle}>Check your email</p>
            <p className={classes.forgotPasswordSubTitle}>
              We have sent you the necessary instructions to create your account
            </p>
            <div className={classes.backToLoginButtonContainer}>
              <Button
                color="primary"
                variant="contained"
                className={classes.sendEmailBtn}
                onClick={() => router.history.push('/signin')}
              >
                Back to Login
              </Button>
            </div>
            <div>
              <span className={`${classes.troubleSigningIn} ${classes.cursorPointer}`}>
                Resend Email
              </span>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
}

export default ForgotPassword;
