import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';

import ReportsTable from './ReportsTable';
import PageTitleWithBack from '../shared/PageTitleWithBack';
import ReactDatePickerMuiTextField from '../shared/ReactDatePickerMuiTextField';
import LoadingButton from '../shared/Inputs/LoadingButton';

import { useAuth } from '../../hooks/use-auth';
import { useSnackbar } from '../../hooks/use-snackbar';

import useStyles from './styles';
import { getReportTitle } from './utils';
import { downloadBlob, isSuperAdmin } from '../../utils/utils';
import { AgentReport, ClientComparisonReport, ClientReport } from '../../interfaces/admin';
import {
  exportAgentsReportData,
  exportClientsReportData,
  exportNewClientsReportData,
  getAgentsReportData,
  getClientsComparisonReportData,
  getClientsReportData,
  getNewClientsReportData,
} from '../../requests/admin';
import { getRandomId } from '../../utils/helpers';

const DEFAULT_DATE_RANGE = {
  start: dayjs().startOf('day').subtract(6, 'days').toDate(),
  end: dayjs().endOf('day').toDate(),
};

function getInitialDate1(reportType: string) {
  if (reportType === 'clients-comparison') {
    return {
      start: dayjs().startOf('day').subtract(13, 'days').toDate(),
      end: dayjs().endOf('day').subtract(7, 'days').toDate(),
    };
  }
  return DEFAULT_DATE_RANGE;
}

function Reports() {
  const classes = useStyles();
  const { reportType } = useParams<{ reportType: string }>();
  const history = useHistory();
  const reportTitle = getReportTitle(reportType);
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const hasPrivilege = isSuperAdmin(user);

  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [agentReportData, setAgentReportData] = useState<AgentReport[]>([]);
  const [clientReportData, setClientReportData] = useState<ClientReport[]>([]);
  const [newClientReportData, setNewClientReportData] = useState<ClientReport[]>([]);
  const [clientComparisonReportData, setClientComparisonReportData] = useState<
    ClientComparisonReport[]
  >([]);
  const [date1, setDate1] = useState<{ start: Date | null; end: Date | null }>(
    getInitialDate1(reportType)
  );
  const [date2, setDate2] = useState<{ start: Date | null; end: Date | null }>(DEFAULT_DATE_RANGE);
  const [{ page, pageSize }, setPage] = useState({ page: 0, pageSize: 10 });

  useEffect(() => {
    if (hasPrivilege && reportTitle && date1.start && date1.end) {
      setPage({ page: 0, pageSize });
      switch (reportType) {
        case 'agents': {
          setIsLoading(true);
          getAgentsReportData(date1.start.toISOString(), date1.end.toISOString())
            .then((res) => {
              setIsLoading(false);
              setAgentReportData(res.data.map((item) => ({ ...item, id: getRandomId() })));
            })
            .catch((error: AxiosError) => {
              const errorMessage =
                error?.response?.data?.message ||
                'An error occurred while fetching data. Please try again.';
              showSnackbar({ severity: 'error', message: errorMessage });
              setIsLoading(false);
            });
          break;
        }
        case 'clients': {
          setIsLoading(true);
          getClientsReportData(date1.start.toISOString(), date1.end.toISOString())
            .then((res) => {
              setIsLoading(false);
              setClientReportData(res.data.map((item) => ({ ...item, id: getRandomId() })));
            })
            .catch((error: AxiosError) => {
              const errorMessage =
                error?.response?.data?.message ||
                'An error occurred while fetching data. Please try again.';
              showSnackbar({ severity: 'error', message: errorMessage });
              setIsLoading(false);
            });
          break;
        }
        case 'new-clients': {
          setIsLoading(true);
          getNewClientsReportData(date1.start.toISOString(), date1.end.toISOString())
            .then((res) => {
              setIsLoading(false);
              setNewClientReportData(res.data.map((item) => ({ ...item, id: getRandomId() })));
            })
            .catch((error: AxiosError) => {
              const errorMessage =
                error?.response?.data?.message ||
                'An error occurred while fetching data. Please try again.';
              showSnackbar({ severity: 'error', message: errorMessage });
              setIsLoading(false);
            });
          break;
        }
        case 'clients-comparison': {
          if (date2.start && date2.end) {
            setIsLoading(true);
            getClientsComparisonReportData(
              date1.start.toISOString(),
              date1.end.toISOString(),
              date2.start.toISOString(),
              date2.end.toISOString()
            )
              .then((res) => {
                setIsLoading(false);
                setClientComparisonReportData(
                  res.data.map((item) => ({ ...item, id: getRandomId() }))
                );
              })
              .catch((error: AxiosError) => {
                const errorMessage =
                  error?.response?.data?.message ||
                  'An error occurred while fetching data. Please try again.';
                showSnackbar({ severity: 'error', message: errorMessage });
                setIsLoading(false);
              });
          }
          break;
        }
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPrivilege, reportTitle, reportType, date1, date2, pageSize]);

  function getReportData() {
    if (hasPrivilege && reportTitle) {
      switch (reportType) {
        case 'agents':
          return agentReportData;
        case 'clients':
          return clientReportData;
        case 'new-clients':
          return newClientReportData;
        case 'clients-comparison':
          return clientComparisonReportData;
        default:
          return [];
      }
    }
    return [];
  }

  function handleExport() {
    if (reportTitle && date1.start && date1.end) {
      const startDate = date1.start.toISOString();
      const endDate = date1.end.toISOString();
      const range = `${startDate} - ${endDate}`;

      switch (reportType) {
        case 'agents': {
          setIsExporting(true);
          showSnackbar({
            severity: 'info',
            message:
              'Please wait while we prepare the data. Your file will be downloaded any moment',
          });
          exportAgentsReportData(startDate, endDate)
            .then((res) => {
              downloadBlob(`agents-${range}`, res.data);
              setIsExporting(false);
              showSnackbar({ severity: 'success', message: 'File has been downloaded' });
            })
            .catch(() => {
              setIsExporting(false);
              showSnackbar({ severity: 'error', message: 'An error occurred while exporting' });
            });
          break;
        }
        case 'clients': {
          setIsExporting(true);
          showSnackbar({
            severity: 'info',
            message:
              'Please wait while we prepare the data. Your file will be downloaded any moment',
          });
          exportClientsReportData(startDate, endDate)
            .then((res) => {
              downloadBlob(`clients-${range}`, res.data);
              setIsExporting(false);
              showSnackbar({ severity: 'success', message: 'File has been downloaded' });
            })
            .catch(() => {
              setIsExporting(false);
              showSnackbar({ severity: 'error', message: 'An error occurred while exporting' });
            });
          break;
        }
        case 'new-clients': {
          setIsExporting(true);
          showSnackbar({
            severity: 'info',
            message:
              'Please wait while we prepare the data. Your file will be downloaded any moment',
          });
          exportNewClientsReportData(startDate, endDate)
            .then((res) => {
              downloadBlob(`new-clients-${range}`, res.data);
              setIsExporting(false);
              showSnackbar({ severity: 'success', message: 'File has been downloaded' });
            })
            .catch(() => {
              setIsExporting(false);
              showSnackbar({ severity: 'error', message: 'An error occurred while exporting' });
            });
          break;
        }
        default:
          break;
      }
    } else {
      showSnackbar({
        severity: 'info',
        message: 'Please provide a valid date range to export data',
      });
    }
  }

  function handleBack() {
    history.push('/');
  }

  function handlePageChange(page: number) {
    setPage({ page, pageSize });
  }

  function handlePageSizeChange(pageSize: number) {
    setPage({ page: 0, pageSize });
  }

  function handleDateRange1Change(dates: any) {
    const [start, end] = dates;
    setDate1({ start, end });
    if (reportType === 'clients-comparison') {
      setDate2({ start: null, end: null });
    }
  }

  function handleDateRange2Change(dates: any) {
    const [start, end] = dates;
    setDate2({ start, end });
  }

  if (!hasPrivilege) {
    return <Redirect to="/" />;
  }

  const isValidReportType = Boolean(reportTitle);
  return (
    <>
      <div className={classes.titleContainer}>
        <PageTitleWithBack
          title={`Reports - ${isValidReportType ? reportTitle : 'Unknown'}`}
          disableGutters
          onBackClick={handleBack}
        />
        <div className={classes.dateRangePickerContainer}>
          <div>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              onChange={handleDateRange1Change}
              startDate={date1.start}
              endDate={date1.end}
              customInput={
                <ReactDatePickerMuiTextField
                  className={classes.dateRangePicker}
                  {...(reportType === 'clients-comparison' && { label: 'R1' })}
                />
              }
              selectsRange
            />
          </div>
          {reportType === 'clients-comparison' && (
            <div>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                onChange={handleDateRange2Change}
                startDate={date2.start}
                endDate={date2.end}
                customInput={
                  <ReactDatePickerMuiTextField className={classes.dateRangePicker} label="R2" />
                }
                selectsRange
              />
            </div>
          )}
          {reportType !== 'clients-comparison' && (
            <div>
              <LoadingButton
                color="primary"
                variant="contained"
                disableElevation
                style={{ height: '100%' }}
                isLoading={isExporting}
                disabled={isExporting}
                onClick={handleExport}
              >
                <GetAppIcon />
              </LoadingButton>
            </div>
          )}
        </div>
      </div>
      {isValidReportType ? (
        <ReportsTable
          reportType={reportType}
          loading={isLoading}
          data={getReportData()}
          page={page}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      ) : (
        <div>Unknown report</div>
      )}
    </>
  );
}

export default Reports;
