import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '../shared/Inputs/TextField';
import Button from '../shared/Inputs/Button';
import useStyles from './styles';
import { IFilterState } from '../../interfaces/creatives';
import { Popover } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

interface IProps {
  filters: IFilterState;
  setFilters: React.Dispatch<React.SetStateAction<IFilterState>>;
  onApplyFilters(): void;
  onClearFilters(): void;
}

interface IDateFilterProps {
  filters: IFilterState;
  setFilters: React.Dispatch<React.SetStateAction<IFilterState>>;
}

const creativeStatusFields = [
  { label: 'ALL', value: 'ALL' },
  { label: 'ACTIVE', value: 'ACTIVE' },
  { label: 'INACTIVE', value: 'INACTIVE' },
];

function Filters(props: IProps) {
  const classes = useStyles();
  const { filters, setFilters, onApplyFilters, onClearFilters } = props;

  function onChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  }

  function handleChange(name: string, value: any) {
    setFilters({ ...filters, [name]: value });
  }

  return (
    <div className={classes.filtersWithActionsContainer}>
      <div className={classes.filtersContainer}>
        <TextField
          variant="outlined"
          size="small"
          label="Title"
          name="name"
          value={filters.name}
          onChange={onChange}
        />
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel id="date-field-label">Status</InputLabel>
          <Select
            label="Status"
            labelId="Status-label"
            value={filters.status}
            onChange={(event) => handleChange('status', event.target.value)}
            MenuProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              transformOrigin: { vertical: 'top', horizontal: 'center' },
              getContentAnchorEl: null,
            }}
          >
            {creativeStatusFields.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DateFilter filters={filters} setFilters={setFilters} />
      </div>
      <div className={classes.filterActionsContainer}>
        <div>
          <Button variant="contained" color="primary" size="small" onClick={onApplyFilters}>
            Apply Filters
          </Button>
        </div>
        <div>
          <Button variant="outlined" size="small" onClick={onClearFilters}>
            Clear Filters
          </Button>
        </div>
      </div>
    </div>
  );
}

function DateFilter(props: IDateFilterProps) {
  const { filters, setFilters } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleChange(key: string, value: string) {
    const date = { ...filters.date, [key]: value };
    setFilters({ ...filters, date });
  }

  const open = Boolean(anchorEl);
  const id = open ? 'date-filter-popover' : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        size="large"
        className={classes.dateFilterButton}
        fullWidth
      >
        Validity
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={{ paper: classes.dateFilterPopover }}
      >
        <div className={classes.dateFilterContainer}>
          <div className={classes.dateFilterDatePickerContainer}>
            <div>
              <TextField
                variant="outlined"
                size="small"
                label="From"
                type="date"
                value={filters.date.from}
                onChange={(event) => handleChange('from', event.target.value)}
                InputProps={{ classes: { root: classes.dateFilterDatePicker } }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div>
              <TextField
                variant="outlined"
                size="small"
                label="To"
                type="date"
                value={filters.date.to}
                onChange={(event) => handleChange('to', event.target.value)}
                InputProps={{ classes: { root: classes.dateFilterDatePicker } }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}
export default Filters;
