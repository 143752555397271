import { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import Button from '../shared/Inputs/Button';
import TextField from '../shared/Inputs/TextField';
import Checkbox from '../shared/Inputs/Checkbox';
import { REGEX } from '../../constants';
import useStyles from '../Auth/styles';
import { EPrivileges, IAddEditAdmin } from '../../interfaces/team';

interface IAddEditTeam {
  open: boolean;
  mode: 'add' | 'edit';
  isLoading: boolean;
  error: string;
  admin: any;
  privileges: EPrivileges[];
  onClose(): void;
  onSubmit: SubmitHandler<IAddEditAdmin>;
}

function AddEditTeam({
  open,
  mode,
  isLoading,
  error,
  admin,
  privileges,
  onClose,
  onSubmit,
}: IAddEditTeam) {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<IAddEditAdmin>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      privileges: '',
      password: '',
    },
  });
  const [updatePassword, setUpdatePassword] = useState(false);

  useEffect(() => {
    reset({
      email: mode === 'edit' ? admin.email : '',
      firstName: mode === 'edit' ? admin.firstName : '',
      lastName: mode === 'edit' ? admin.lastName : '',
      privileges: mode === 'edit' ? (admin.privileges === null ? '' : admin.privileges) : '',
      password: '',
    });
    setUpdatePassword(false);
  }, [admin, mode, reset]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{`${mode === 'add' ? 'Add' : 'Edit'} team member`}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="email"
            control={control}
            rules={{ required: true, pattern: REGEX.EMAIL }}
            render={({ field: { ref, ...rest } }) => {
              const hasError = Boolean(errors.email);
              const hasRequiredError = errors?.email?.type === 'required';
              return (
                <TextField
                  {...rest}
                  inputRef={ref}
                  type="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                  error={hasError}
                  InputProps={{ readOnly: mode === 'edit' }}
                  {...(hasError && {
                    helperText: (
                      <span>
                        {hasRequiredError ? 'Email is required' : 'Please provide a valid email'}
                      </span>
                    ),
                  })}
                />
              );
            }}
          />
          <div className={classes.textFields}>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...rest } }) => {
                const hasError = Boolean(errors.firstName);
                const hasRequiredError = errors?.firstName?.type === 'required';
                return (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    type="text"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    error={hasError}
                    {...(hasError && {
                      helperText: <span>{hasRequiredError && 'First name is required'}</span>,
                    })}
                  />
                );
              }}
            />
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...rest } }) => {
                const hasError = Boolean(errors.lastName);
                const hasRequiredError = errors?.lastName?.type === 'required';
                return (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    type="text"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    error={hasError}
                    {...(hasError && {
                      helperText: <span>{hasRequiredError && 'Last name is required'}</span>,
                    })}
                  />
                );
              }}
            />
          </div>
          <Controller
            name="privileges"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...rest } }) => {
              const hasError = Boolean(errors.privileges);
              return (
                <TextField
                  {...rest}
                  inputRef={ref}
                  select
                  label="Privileges"
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                  error={hasError}
                  {...(hasError && {
                    helperText: <span>{hasError && 'Privilege is required'}</span>,
                  })}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {privileges.map((privilege) => (
                    <MenuItem key={privilege} value={privilege}>
                      {privilege}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />
          {mode === 'edit' && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={updatePassword}
                    onChange={(event) => setUpdatePassword(event.target.checked)}
                    color="primary"
                  />
                }
                label="Update Password"
              />
            </div>
          )}
          {(mode === 'add' || updatePassword) && (
            <Controller
              name="password"
              control={control}
              rules={{ required: true, pattern: REGEX.PASSWORD }}
              render={({ field: { ref, ...rest } }) => {
                const hasError = Boolean(errors.password);
                return (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    type="password"
                    label="Password"
                    variant="outlined"
                    inputProps={{ maxLength: 32 }}
                    fullWidth
                    className={classes.textField}
                    error={hasError}
                    helperText={
                      <span>
                        Use 8 to 32 characters, one digit, one symbol (!@#$%^&amp;*), one lowercase
                        and uppercase letter
                      </span>
                    }
                  />
                );
              }}
            />
          )}
          <div>{error && <Alert severity="error">{error}</Alert>}</div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={onClose}>
            Close
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isLoading}
            className={classes.loadingButton}
          >
            {`${mode === 'add' ? 'Add' : 'Edit'} Account`}
            {isLoading && (
              <LinearProgress
                color="primary"
                variant="indeterminate"
                className={classes.buttonLinearProgress}
              />
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddEditTeam;
