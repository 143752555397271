import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';

import Button from '../shared/Inputs/Button';
import PageTitleWithBack from '../shared/PageTitleWithBack';

import { Service } from '../../interfaces/services';
import { getAllCompleteServices } from '../../requests/service';
import { useSnackbar } from '../../hooks/use-snackbar';
import useStyles from './styles';

const ListItem = withStyles((theme) =>
  createStyles({
    root: { margin: theme.spacing(0.5, 0), border: '1px solid transparent' },
    selected: {
      backgroundColor: '#FFE2D3 !important',
      border: '1px solid #FF6F24',
      borderRadius: 2,
      color: '#FF6F24',
      '&:hover': {
        backgroundColor: '#FFE2D3 !important',
      },
    },
  })
)(MuiListItem);

function DetailedService() {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const history = useHistory();
  const params = useParams<{ serviceId: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState<Service[]>([]);
  const [service, setService] = useState<Service>({} as Service);

  const serviceId = atob(params.serviceId);

  useEffect(() => {
    setIsLoading(true);
    getAllCompleteServices()
      .then((res) => {
        setServices(res.data);
        setService(res.data.find((s) => s._id === serviceId) || ({} as Service));
        setIsLoading(false);
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        showSnackbar({
          severity: 'error',
          message:
            error?.response?.data?.message ||
            'An error occurred while fetching services. Please try again.',
        });
      });
    //eslint-disable-next-line
  }, []);

  function handleBackClick() {
    history.goBack();
  }

  function handlePlaceOrderClick() {
    history.push(`/services/${btoa(service._id)}/checkout`);
  }

  const handleChangeService = (s: Service) => () => {
    history.replace(`/services/${btoa(s._id)}/details`);
    setService(s);
  };

  const faqs = service?.customerInterface?.faqs || [];

  return (
    <Container maxWidth="lg" disableGutters>
      <PageTitleWithBack
        title="Back"
        titleClassName={classes.titleClassName}
        disableGutters
        onBackClick={handleBackClick}
      />
      <Grid container spacing={2} className={classes.detailedServiceGrid}>
        <Grid item xs={12} md={8}>
          <div>
            <h3>{service?.name || ''}</h3>
            <p
              className={classes.serviceDescription}
              dangerouslySetInnerHTML={{ __html: service?.description || '' }}
            ></p>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.buyNowButton}
              onClick={handlePlaceOrderClick}
              disabled={isLoading || !service._id}
            >
              Buy Now
            </Button>
            <div className={classes.sampleReportContainer}>
              <Grid container spacing={2}>
                {service?.customerInterface?.sampleReportDetails?.map((sampleReport) => (
                  <Grid
                    key={sampleReport.id}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    className={classes.sampleReportGrid}
                  >
                    <h3>{sampleReport.title}</h3>
                    <Card elevation={0}>
                      <CardMedia
                        className={classes.reportImage}
                        image={sampleReport.imageUrl}
                        title={sampleReport.title}
                      />
                      <CardActions>
                        <Button
                          size="small"
                          variant="contained"
                          fullWidth
                          className={classes.viewReportButton}
                          onClick={() => window.open(sampleReport.reportUrl, '_blank')}
                        >
                          View Report
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
            {faqs.length > 0 && (
              <div>
                <h3>Frequently Asked Questions (FAQ)</h3>
                <div className={classes.faqAccordionContainer}>
                  {faqs.map((faq) => (
                    <Accordion
                      key={faq.id}
                      elevation={0}
                      classes={{
                        expanded: classes.faqAccordionExpanded,
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.faqAccordionTitle}>
                          {faq.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          className={classes.faqAccordionDetails}
                          dangerouslySetInnerHTML={{ __html: faq.answer }}
                        ></div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={4} component="aside">
          <div className={classes.serviceListContainer}>
            <div className={classes.serviceList}>
              <List
                aria-label="our services"
                subheader={<ListSubheader component="div">Our Services</ListSubheader>}
              >
                {services?.map((s) => (
                  <ListItem
                    key={s._id}
                    button
                    selected={s._id === service._id}
                    onClick={handleChangeService(s)}
                  >
                    <ListItemText
                      primary={s.name}
                      classes={{ primary: classes.serviceListItemText }}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={handlePlaceOrderClick}
              disabled={isLoading || !service._id}
            >
              Place Order
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default DetailedService;
